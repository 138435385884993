import { FC, useEffect, useState } from "react";
import {
  Tag,
  Descriptions,
  Skeleton,
  Row,
  Avatar,
  Typography,
  Empty,
} from "antd";
import { dateFormatter } from "src/shared/config/constants";
import { UserOutlined } from "@ant-design/icons";
import Currency from "src/shared/components/currency/Currency";
import BlitzMultiplierChart from "./components/BlitzMultiplierChart";
import { useNavigate } from "react-router-dom";
import GameName from "src/shared/components/GameName/gameName";
import { db, fixDigits, getCdnPhoto, isTriumphEmployee } from "src/helpers";
import { collection, doc, onSnapshot, Unsubscribe } from "firebase/firestore";
import { blitzTournamentConverter } from "src/converters";

const { Title, Link } = Typography;

interface Props {
  row: FinishBlitzTournamentTrxV2 | null;
  user: AppUserPublic;
}

const FinishBlitzTournament: FC<Props> = ({ row: trx, user }) => {
  const navigate = useNavigate();

  const [blitzTournament, setTournamentDetails] =
    useState<BlitzTournamentV2 | null>(null);

  useEffect(() => {
    let unsub: Unsubscribe | undefined;
    async function setup() {
      if (trx) {
        const { tournamentId, gameId } = trx;
        const gamesCol = collection(db, "games");
        const gameRef = doc(gamesCol, gameId);
        const tournamentsCol = collection(
          gameRef,
          "blitzTournamentsV2"
        ).withConverter(blitzTournamentConverter);
        const tournamentRef = doc(tournamentsCol, tournamentId);
        unsub = onSnapshot(
          tournamentRef,
          (snap) => {
            const data = snap.data();
            if (data) {
              setTournamentDetails(data);
            }
          },
          (error) => console.error(error.message)
        );
      }
    }

    setup();
    return () => {
      unsub?.();
    };
  }, [trx]);

  if (!trx || !blitzTournament) return <Skeleton title paragraph />;

  if (!blitzTournament || !(blitzTournament.version === "2" || blitzTournament.version === "2.4")) return <Empty />;
  return (
    <>
      <Descriptions
        title="Tournament Details"
        layout="horizontal"
        colon
        column={1}
        bordered
        className="pb-2"
      >
        <Descriptions.Item label="Tournament Definition ID">
          {blitzTournament.tournamentDefinitionId}
        </Descriptions.Item>
        <Descriptions.Item label="Score">
          {blitzTournament.score}
        </Descriptions.Item>
        {blitzTournament.status === "finished" && (
          <Descriptions.Item label="Multiplier">
            {fixDigits(blitzTournament.multiplier, 4)}
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Type">
          <Tag color="magenta">{blitzTournament.type}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Percentile">
          {((blitzTournament.percentile || 0) * 100).toFixed(2)}
        </Descriptions.Item>
        <Descriptions.Item label="Seed">
          {blitzTournament.seed}
        </Descriptions.Item>
        <Descriptions.Item label="Version">
          {blitzTournament.version}
        </Descriptions.Item>
        <Descriptions.Item label="UID">{blitzTournament.uid}</Descriptions.Item>
        {isTriumphEmployee() && (
          <Descriptions.Item label="Game ID">
            {blitzTournament.gameId}
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Payout">
          <Currency
            value={blitzTournament.payout}
            prefix="$"
            currency="cents-to-dollar"
          />
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          <Tag color="lime"> {blitzTournament.status}</Tag>
        </Descriptions.Item>

        <Descriptions.Item label="Archived">
          {blitzTournament.tournamentDefinition.archived ? "Yes" : "No"}
        </Descriptions.Item>
        <Descriptions.Item label="Entry Price">
          <Currency
            value={blitzTournament.tournamentDefinition.entryPrice}
            prefix="$"
            currency="cents-to-dollar"
          />
        </Descriptions.Item>
        <Descriptions.Item label="Max Tokens">
          {blitzTournament.tournamentDefinition.maxTokens}
        </Descriptions.Item>
        <Descriptions.Item label="Created At">
          {dateFormatter(blitzTournament.createdAt)}
        </Descriptions.Item>
        <Descriptions.Item label="Finished At">
          {dateFormatter(blitzTournament.finishedAt)}
        </Descriptions.Item>
      </Descriptions>
      <BlitzMultiplierChart
        multiple={blitzTournament.multiplier}
        score={blitzTournament.score}
        data={blitzTournament.blitzMultipliers}
      />
      <Row justify="center">
        <Avatar size={100} src={getCdnPhoto(user.profilePhotoPath)}>
          <UserOutlined />
        </Avatar>
      </Row>
      <Row justify="center">
        <Title level={4}>{user.username}</Title>
      </Row>

      <Descriptions
        title="Transaction Details"
        layout="horizontal"
        colon
        column={1}
        bordered
        className="pb-2"
      >
        <Descriptions.Item label="ID">{trx.uid}</Descriptions.Item>
        <Descriptions.Item label="Trace ID">{trx.traceId}</Descriptions.Item>

        <Descriptions.Item label="App User username">
          {user.username}
        </Descriptions.Item>
        <Descriptions.Item label="App User ID">
          {trx.appUserUid}
        </Descriptions.Item>
        <Descriptions.Item label="Type">
          <Tag color="blue">{trx.type}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Description">
          {trx.description}
        </Descriptions.Item>
        <Descriptions.Item label="Amount">
          <Currency currency="cents-to-dollar" prefix="$" value={trx.amount} />
        </Descriptions.Item>
        <Descriptions.Item label="Token Amount">
          <Currency
            currency="cents-to-dollar"
            prefix="$"
            value={trx.tokenAmount}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Bonus Cash Amount">
          <Currency
            currency="cents-to-dollar"
            prefix="$"
            value={trx.bonusCashAmount}
          />
        </Descriptions.Item>
        {isTriumphEmployee() && (
          <Descriptions.Item label="Game ID">{trx.gameId}</Descriptions.Item>
        )}
        <Descriptions.Item label="Game">
          <GameName id={trx.gameId} />
        </Descriptions.Item>
        <Descriptions.Item label="Tournament ID">
          <Link
            onClick={() =>
              navigate(
                `/games/${trx.gameId}/tournaments/blitz/${trx.tournamentId}`
              )
            }
          >
            {trx.tournamentId}
          </Link>
        </Descriptions.Item>
        <Descriptions.Item label="Created Date">
          {dateFormatter(trx.createdAt)}
        </Descriptions.Item>
        <Descriptions.Item label="Transaction Date">
          {dateFormatter(trx.transactionDate)}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default FinishBlitzTournament;
