import { Card, Col, Row, Steps, Typography } from "antd";
import { collection, doc, onSnapshot, Unsubscribe } from "firebase/firestore";
import { FC, useEffect, useState } from "react";
import { useAppSelector } from "src/app/hooks";
import { orgConverter } from "src/converters";
import { db, generateFirestorePath } from "src/helpers";
import TriumphPage from "src/shared/layout/TriumphPage";
import AccountSettings from "./components/setup/accounts";
import CustomerInfoSettings from "./components/setup/customerInfo";
import InitiateMicrodeposit from "./components/setup/initiateMicrodeposit";

const BalancesOnboarding: FC = () => {
  const { user } = useAppSelector((state) => state.userState);
  const [org, setOrg] = useState<Organization>();
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    let unsubOrganizationSnapshot: Unsubscribe | undefined;
    if (user?.activeOrgId) {
      const organizationRef = collection(
        db,
        generateFirestorePath("organizations")
      ).withConverter(orgConverter);

      const orgDoc = doc(organizationRef, user.activeOrgId);
      unsubOrganizationSnapshot = onSnapshot(orgDoc, (orgsSnap) => {
        const org = orgsSnap.data();
        if (org) {
          setOrg(org);
          if (org.dwollaId) {
            setCurrent(1);
          }
          if (
            org.dwollaFundingSrc &&
            org.dwollaFundingSrc.status === "unverified"
          ) {
            setCurrent(2);
          }
          if (org.dwollaFundingSrc?.status === "verified") {
            setCurrent(3);
          }
          if (org.dwollaFundingSrc?.status === "maxAttemptsReached") {
            setCurrent(4);
          }
        }
      });
    }
    return () => {
      unsubOrganizationSnapshot?.();
    };
  }, [user?.activeOrgId]);
  const { Text } = Typography;

  const balancesCardTitle = (
    <Row style={{ width: "100%" }} justify="space-between" align="middle">
      <Text>Balances</Text>
    </Row>
  );

  return (
    <TriumphPage>
      <Row justify="center">
        <Col span={18}>
          <Card
            title={balancesCardTitle}
            headStyle={{ borderWidth: 0, fontSize: 28 }}
            bordered={false}
            style={{
              width: "100%",
            }}
          >
            <Steps
              responsive
              labelPlacement="vertical"
              current={current}
              onChange={(curr) => setCurrent(curr)}
            >
              <Steps.Step
                disabled={current !== 0}
                title="Customer Information"
              />

              <Steps.Step
                disabled={current !== 1}
                title="Account Information"
                description="(Add funding source)"
              />
              <Steps.Step disabled={current !== 2} title="Verify Account" />
              <Steps.Step disabled={current !== 3} title="Complete" />
            </Steps>
            <div className="pt-3">
              {current === 0 && <CustomerInfoSettings />}

              {current === 1 && <AccountSettings />}

              {current === 2 && <InitiateMicrodeposit org={org} />}
              {current === 3 && <Row justify="center">Account is verified</Row>}

              {current === 4 && (
                <Row justify="center">Max attempts reached.</Row>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </TriumphPage>
  );
};

export default BalancesOnboarding;
