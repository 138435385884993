import { FC, useEffect, useState } from "react";
import TriumphPage from "src/shared/layout/TriumphPage";
import { Button, Col, Divider, Row, Typography, message } from "antd";
import BannerEditableList, {
  BannerDataType,
} from "./components/BannerEditableList";
import { PlusCircleOutlined } from "@ant-design/icons";
import BannerPreview from "./components/BannerPreview";
import { axiosInstance } from "src/helpers";
import OverlayLoader from "src/shared/components/overlayLoader/overlayLoader";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Unsubscribe } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { colNames, getCollection } from "src/collections";

const Banners: FC = () => {
  const [, contextHolder] = message.useMessage();
  const [list, setList] = useState<Array<BannerDataType>>([]);
  const [selectedView, setSelectedView] = useState<BannerDataType | null>(null);

  const [activeItem, setActiveItem] = useState<string | null>(null);
  const { game } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let unsubBanners: Unsubscribe;
    if (game) {
      const gameRef = doc(getCollection(colNames.games), game);
      const bannerRef = getCollection(colNames.banners, gameRef);

      unsubBanners = onSnapshot(bannerRef, (colSnap) => {
        const config: typeof list = [];
        for (const snap of colSnap.docs) {
          console.log(snap.data());
          const data = snap.data();
          config.push(data);
        }
        setList(config);
      });
    }
    return () => {
      unsubBanners?.();
    };
  }, [game]);

  const onAddItem = () => {
    const id = uuidv4();
    setList((prev) => [
      ...prev,
      {
        backgroundColor: "",
        buttonBackgroundColor: "",
        buttonTextColor: "",
        name: "",
        buttonIcon: "",
        buttonText: "",
        link: "",
        title: "",
        subtitle: "",
        id: "",
        isNew: true,
        newId: uuidv4(),
        updatedAt: 0,
        createdAt: 0,
      },
    ]);

    setActiveItem(`${id}`);
  };

  const onRemoveItem = async (item: (typeof list)[0]) => {
    if (item.isNew) {
      setList((prev) => {
        const removed = prev.filter((f) => f.newId !== item.newId);
        return removed;
      });
      setActiveItem(null);
    } else {
      try {
        setIsLoading(true);
        await axiosInstance.post<{ success: string }>(
          `admin/games/${game}/banner/delete`,
          {
            id: item.id,
          }
        );

        message.success("Banner deleted successfully");
        setActiveItem(null);
      } catch (e) {
        message.error("Banner deletion failed");
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <TriumphPage>
      {/* Message Context */}
      {contextHolder}
      {isLoading && <OverlayLoader />}

      <BannerPreview
        data={selectedView}
        onClose={() => setSelectedView(null)}
      />
      <Row justify={"space-between"}>
        <Col span={1}>
          <Row>
            <Button
              disabled={!!activeItem}
              type="link"
              onClick={onAddItem}
              className="pb-2 add-notif"
            >
              <PlusCircleOutlined />
            </Button>
            <Typography.Text strong style={{ paddingLeft: 4 }}>
              Actions
            </Typography.Text>
          </Row>
        </Col>
        <Col span={3}>
          <Row>
            <Typography.Text strong className="pl-2">
              Name
            </Typography.Text>
          </Row>
        </Col>
        <Col span={3}>
          <Row>
            <Typography.Text strong className="pl-2">
              Title
            </Typography.Text>
          </Row>
        </Col>
        <Col span={4}>
          <Row>
            <Typography.Text strong className="pl-2">
              Subtitle
            </Typography.Text>
          </Row>
        </Col>
        <Col span={2}>
          <Row>
            <Typography.Text strong className="pl-2">
              Link
            </Typography.Text>
          </Row>
        </Col>
        <Col span={2}>
          <Row>
            <Typography.Text strong className="pl-2">
              Button Text
            </Typography.Text>
          </Row>
        </Col>
        <Col span={2}>
          <Row>
            <Typography.Text strong className="pl-2">
              Button Text Color
            </Typography.Text>
          </Row>
        </Col>
        <Col span={3}>
          <Row>
            <Typography.Text strong className="pl-2">
              Button Color
            </Typography.Text>
          </Row>
        </Col>
        <Col span={2}>
          <Row>
            <Typography.Text strong className="pl-2">
              Background Color
            </Typography.Text>
          </Row>
        </Col>
        <Col span={2}>
          <Row>
            <Typography.Text strong className="pl-2">
              Icon Logo
            </Typography.Text>
          </Row>
        </Col>
      </Row>
      <Divider />
      {list.map((e, i) => (
        <BannerEditableList
          onView={setSelectedView}
          data={e}
          setActive={setActiveItem}
          onRemove={onRemoveItem}
          isActive={
            activeItem === null
              ? true
              : activeItem !== null && `${e.id || e.newId}` === activeItem
          }
          index={i}
        />
      ))}
    </TriumphPage>
  );
};

export default Banners;
