import React, { ChangeEvent, useState } from "react";
import { Col, Row, Tag } from "antd";
import { axiosInstance } from "src/helpers";
import { useAppSelector } from "src/app/hooks";
import { LoadingOutlined } from "@ant-design/icons";

interface Props {
  onNext: () => void;
}

const UploadCertificate: React.FC<Props> = ({ onNext }) => {
  const { user } = useAppSelector((state) => state.userState);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  async function onChange(e: ChangeEvent<HTMLInputElement>) {
    const file = e.currentTarget.files?.[0];
    setError("");
    setIsLoading(false);

    if (!file) {
      setError("No File Attached");
      return;
    }
    try {
      setIsLoading(true);
      const content = await readFileContent(file);
      if (typeof content === "string") {
        const data = await axiosInstance.post(
          `/admin/orgs/${user?.activeOrgId}/apple_pay_cer`,
          { cer: content.split("base64,")[1] }
        );

        if (data.status === 200) {
          onNext();
        } else {
          setError("Failed to upload certificate");
        }
      } else {
        setError("Failed to upload certificate");
      }
    } catch (error) {
      setError("Failed to upload certificate");
    } finally {
      setIsLoading(false);
    }
  }

  function readFileContent(file: File) {
    const reader = new FileReader();
    return new Promise<string | ArrayBuffer | null | undefined>(
      (resolve, reject) => {
        reader.onload = (event) => {
          const result = event?.target?.result;
          return resolve(result);
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      }
    );
  }

  return (
    <>
      <Row justify="center" className="pb-2">
        Download Apple pay certificate and upload it here.
        <Col>{isLoading && <LoadingOutlined />}</Col>
      </Row>
      <Row justify="center">
        {!isLoading && (
          <input
            disabled={isLoading}
            type="file"
            accept=".cer, .pem, .crt"
            onChange={onChange}
          />
        )}
      </Row>
      {error && (
        <Row>
          <Tag color="orange">{error}</Tag>
        </Row>
      )}
    </>
  );
};

export default UploadCertificate;
