import { Descriptions, Spin } from "antd";
import { FC, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useAppDispatch, useAppSelector } from "src/app/hooks";

import {
  getAdminUserById,
  adminUserSelect,
} from "src/features/adminUsers/AdminUsersSlice";
import { dateFormatter } from "src/shared/config/constants";
interface Props {
  eventDetails: AdminSetRequiresReviewEvent;
}

const AdminSetRequiresReview: FC<Props> = ({ eventDetails }) => {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  const { users, isFetchingUserById } = useAppSelector(adminUserSelect);

  useEffect(() => {
    dispatch(getAdminUserById(eventDetails.triumphEmployeeUid));
  }, [dispatch, eventDetails]);

  return (
    <>
      <Descriptions
        layout={!isMobile ? "horizontal" : "vertical"}
        column={1}
        bordered
        title="Personal Details"
        className="pb-2"
      >
        <Descriptions.Item label="ID">{eventDetails.uid}</Descriptions.Item>
        <Descriptions.Item label="Requires Review">
          {eventDetails.requiresReview ? "Yes" : "No"}
        </Descriptions.Item>
        <Descriptions.Item label="Triumph Employee ID">
          {eventDetails.triumphEmployeeUid}
        </Descriptions.Item>
        <Descriptions.Item label="Triumph Employee Name">
          {!isFetchingUserById ? (
            users[eventDetails.triumphEmployeeUid]?.displayName
          ) : (
            <Spin />
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Type">
          {eventDetails.eventType}
        </Descriptions.Item>
        <Descriptions.Item label="Sequence ID">
          {eventDetails.sequenceId}
        </Descriptions.Item>
        <Descriptions.Item label="Created at">
          {dateFormatter(eventDetails.createdAt)}
        </Descriptions.Item>
        <Descriptions.Item label="Note Content Type">
          {eventDetails.noteContentType}
        </Descriptions.Item>
        <Descriptions.Item label="Note Contents">
          {eventDetails.noteContents}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default AdminSetRequiresReview;
