import { Button, Col, Form, Modal, Row, Select, Tag } from "antd";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { axiosInstance } from "src/helpers";
import DollarInput from "src/shared/components/dollarInput/dollarInput";
import {
  formSubmit,
  validatePositiveNumericValue,
} from "src/shared/config/constants";

interface Props {
  config: DepositDefinition | null;
  isAdmin: boolean;
  onClose: () => void;
}
const DepositConfigForm: FC<Props> = ({ config, isAdmin, onClose }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { game } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [configData, setConfigData] = useState<DepositDefinition | null>(null);
  const [isTouched, setIsTouched] = useState<{
    [c: string]: boolean;
  }>({});
  const [form] = Form.useForm<DepositDefinition>();

  const handleSubmit = formSubmit<DepositDefinition>(form);

  useEffect(() => {
    if (config) {
      setIsOpen(true);
      const data = {
        ...config,
        depositAmount:
          typeof config.depositAmount === "number"
            ? config.depositAmount / 100
            : config.depositAmount,
        bonusCash:
          typeof config.bonusCash === "number"
            ? config.bonusCash / 100
            : config.bonusCash,
      };
      form.setFieldsValue(data);
      setConfigData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  function onDialogClose() {
    onClose();
    setIsLoading(false);
    form.resetFields();
    setIsOpen(false);
    setError("");
    setConfigData(null);
  }

  async function onSave(data: DepositDefinition) {
    setError("");

    try {
      setIsLoading(true);

      const formData: Omit<
        DepositDefinition,
        "createdAt" | "updatedAt" | "uid"
      > = {
        bonusCash:
          typeof data.bonusCash === "number"
            ? data.bonusCash * 100
            : data.bonusCash || null,
        depositAmount:
          typeof data.depositAmount === "number"
            ? data.depositAmount * 100
            : data.depositAmount,
        isBestValue: data.isBestValue || false,
        tokens: null,
        archived: false,
      };
      if (!config) {
        // add
        await axiosInstance.post<{ success: string }>(
          `/internal/games/${game}/deposit_definitions`,
          formData
        );
      } else {
        // edit
        await axiosInstance.post<{ success: string }>(
          `/internal/games/${game}/deposit_definitions/${config.uid}`,
          formData
        );
      }
      onDialogClose();
    } catch (error) {
      setError("Unknown Server Error");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  function onChange(
    name: keyof DepositDefinition,
    value: string | number | null
  ) {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      [name]: value,
    });
    if (configData) {
      setIsTouched({
        ...isTouched,
        [name]: form.getFieldValue(name) !== configData[name],
      });
    }
  }

  return (
    <>
      {isAdmin ? (
        <Button
          onClick={() => {
            form.resetFields();
            setIsOpen(true);
          }}
          type="primary"
        >
          Add Config
        </Button>
      ) : (
        <></>
      )}
      <Modal
        title={`${!config ? "Add" : "Edit"} Deposit Definition`}
        centered
        open={isOpen}
        onOk={() => handleSubmit(onSave)}
        okButtonProps={{
          disabled: config
            ? Object.values(isTouched).findIndex((f) => f === true) === -1
            : false,
        }}
        onCancel={onDialogClose}
        destroyOnClose
        confirmLoading={isLoading}
      >
        {isOpen && (
          <Form
            name="Deposit Definition Config"
            className="spaced-form"
            form={form}
            autoComplete="off"
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  initialValue={configData?.depositAmount}
                  label="Deposit Amount*"
                  className="app-config-input-wrap"
                  name="depositAmount"
                  rules={[
                    {
                      validator: validatePositiveNumericValue(
                        "Deposit Amount",
                        0
                      ),
                    },
                  ]}
                >
                  <DollarInput
                    name="depositAmount"
                    size="middle"
                    onChange={(value) => onChange("depositAmount", value)}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  initialValue={configData?.bonusCash}
                  label="Bonus Cash"
                  className="app-config-input-wrap"
                  name="bonusCash"
                  rules={[
                    {
                      validator: validatePositiveNumericValue("Bonus Cash", 0),
                    },
                  ]}
                >
                  <DollarInput
                    name="bonusCash"
                    size="middle"
                    onChange={(value) => onChange("bonusCash", value)}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  initialValue={configData?.isBestValue || false}
                  label="Is Best Value"
                  className="app-config-input-wrap"
                  name="isBestValue"
                >
                  <Select
                    size="middle"
                    className="normal-select"
                    onChange={(value) => onChange("isBestValue", value)}
                  >
                    <Select.Option value={true}>Yes</Select.Option>
                    <Select.Option value={false}>No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {error && <Tag color="warning">{error}</Tag>}
          </Form>
        )}
      </Modal>
    </>
  );
};

export default DepositConfigForm;
