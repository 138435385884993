import { Col, Descriptions, Row, Skeleton, Tag, Timeline } from "antd";
import { Unsubscribe } from "firebase/auth";
import {
  collection,
  doc,
  documentId,
  onSnapshot,
  Query,
  query,
  where,
} from "firebase/firestore";
import { FC, useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import BlitzMultiplierChart from "src/components/balanceTransactions/components/BlitzMultiplierChart";
import {
  blitzTournamentConverter,
  transactionsConverter,
} from "src/converters";
import {
  db,
  fixDigits,
  generateFirestorePath,
  isTriumphEmployee,
} from "src/helpers";
import {
  appUserPublicSelect,
  getAppUserPublicDetails,
} from "src/features/appUsersPublic/appUserPublicSlice";
import Currency, {
  formatCurrency,
} from "src/shared/components/currency/Currency";
import GameName from "src/shared/components/GameName/gameName";
import { dateFormatter } from "src/shared/config/constants";
import TriumphPage from "src/shared/layout/TriumphPage";
import IntermediateScoreGraph from "../IntermediateScore";
import ReplayVideo from "../ReplayVideo";

const BlitzTournamentDetailPage: FC = () => {
  const { id, game } = useParams();
  const [tournament, setTournament] = useState<BlitzTournamentV2>();
  const [userTransactions, setUserTransactions] = useState<
    BalanceTransaction[]
  >([]);
  const { usersPublic: users, loading: usersLoading } =
    useAppSelector(appUserPublicSelect);
  var isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.userState);

  useEffect(() => {
    if (tournament) {
      const appUserUid = tournament.uid;
      dispatch(getAppUserPublicDetails(appUserUid));
    }
  }, [dispatch, tournament]);

  useEffect(() => {
    let unsubTournamentSnapshot: Unsubscribe | undefined;
    if (game && id) {
      const gameRef = collection(db, generateFirestorePath("games"));
      const gameDoc = doc(gameRef, game);

      const tournamentRef = collection(
        gameDoc,
        generateFirestorePath("blitzTournamentsV2")
      ).withConverter(blitzTournamentConverter);

      const q = query(tournamentRef, where(documentId(), "==", id));
      unsubTournamentSnapshot = onSnapshot(q, (tournament) => {
        setTournament(tournament.docs[0].data());
      });
    }
    return () => {
      unsubTournamentSnapshot?.();
    };
  }, [game, id]);

  useEffect(() => {
    let unsubSnapshot: Unsubscribe | undefined;
    if (tournament) {
      const appUsersRef = collection(db, generateFirestorePath("appUsers"));
      const userDoc = doc(appUsersRef, tournament.uid);
      const userBalanceTransactionRef = collection(
        userDoc,
        generateFirestorePath("balanceTransactions")
      ).withConverter(transactionsConverter);

      let userQuery: Query<BalanceTransaction>;
      if (isTriumphEmployee()) {
        userQuery = query(
          userBalanceTransactionRef,
          where("tournamentId", "==", id)
        );
      } else {
        userQuery = query(
          userBalanceTransactionRef,
          where("tournamentId", "==", id),
          where("orgId", "==", user?.activeOrgId)
        );
      }

      unsubSnapshot = onSnapshot(userQuery, (userTrxSnap) => {
        const userTrxDef: typeof userTransactions = [];
        for (const userDefSnap of userTrxSnap.docs) {
          userTrxDef.push(userDefSnap.data());
        }

        setUserTransactions(userTrxDef);
      });
    }
    return () => {
      unsubSnapshot?.();
    };
  }, [tournament, id, user?.activeOrgId]);

  const tournamentEvents = useMemo(() => {
    if (
      !userTransactions ||
      usersLoading === "fetching" ||
      !tournament ||
      !users
    )
      return [];

    function getDescription(trx: BalanceTransaction) {
      switch (trx.type) {
        case "start-blitz": {
          return `paid amount of $${formatCurrency(
            trx.amount * -1,
            "cents-to-dollar"
          )} and bonus cash of $${formatCurrency(
            trx.bonusCashAmount * -1,
            "cents-to-dollar"
          )} to start the tournament`;
        }
        case "finish-blitz": {
          return `${
            trx.description.split("player ")[1]
          } and was paid amount of $${formatCurrency(
            trx.amount,
            "cents-to-dollar"
          )}`;
        }
        default: {
          return "";
        }
      }
    }

    return userTransactions
      .map((trx) => ({
        name: "Player",
        username: users[tournament.uid].username,
        event: getDescription(trx),
        time: trx.transactionDate,
      }))
      .sort((a, b) => (a.time ?? 0) - (b.time ?? 0));
  }, [userTransactions, usersLoading, tournament, users]);

  if (!tournament) return <Skeleton avatar title paragraph />;

  return (
    <TriumphPage>
      <Descriptions
        title="Tournament Details"
        layout={!isMobile ? "horizontal" : "vertical"}
        colon
        column={1}
        bordered
        className="pb-2"
      >
        <Descriptions.Item label="Tournament Definition ID">
          {tournament.tournamentDefinitionId}
        </Descriptions.Item>
        <Descriptions.Item label="Score">{tournament.score}</Descriptions.Item>
        <Descriptions.Item label="Replay">
          <ReplayVideo
            replay={tournament.replay}
            username={users?.[tournament.uid]?.username}
          />
        </Descriptions.Item>

        {tournament.status === "finished" && (
          <Descriptions.Item label="Multiplier">
            {fixDigits(tournament.multiplier, 4)}
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Type">
          <Tag color="magenta">{tournament.type}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Percentile">
          {((tournament.percentile || 0) * 100).toFixed(2)}
        </Descriptions.Item>
        <Descriptions.Item label="Seed">{tournament.seed}</Descriptions.Item>
        <Descriptions.Item label="Version">
          {tournament.version}
        </Descriptions.Item>
        <Descriptions.Item label="UID">
          <Link className="ant-typography" to={`/users/${tournament.uid}`}>
            {tournament.uid}
          </Link>
        </Descriptions.Item>
        {isTriumphEmployee() && (
          <Descriptions.Item label="Game ID">
            {tournament.gameId}
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Game">
          <GameName id={tournament.gameId} />
        </Descriptions.Item>
        <Descriptions.Item label="Payout">
          <Currency
            value={tournament.payout}
            prefix="$"
            currency="cents-to-dollar"
          />
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          <Tag color="lime"> {tournament.status}</Tag>
        </Descriptions.Item>

        <Descriptions.Item label="Archived">
          {tournament.tournamentDefinition.archived ? "Yes" : "No"}
        </Descriptions.Item>
        <Descriptions.Item label="Entry Price">
          <Currency
            value={tournament.tournamentDefinition.entryPrice}
            prefix="$"
            currency="cents-to-dollar"
          />
        </Descriptions.Item>
        <Descriptions.Item label="Max Tokens">
          {tournament.tournamentDefinition.maxTokens}
        </Descriptions.Item>
        <Descriptions.Item label="Created At">
          {dateFormatter(tournament.createdAt)}
        </Descriptions.Item>
        <Descriptions.Item label="Finished At">
          {dateFormatter(tournament.finishedAt)}
        </Descriptions.Item>
      </Descriptions>

      <BlitzMultiplierChart
        multiple={tournament.multiplier}
        score={tournament.score}
        data={tournament.blitzMultipliers}
      />

      {user?.activeOrgId && (
        <IntermediateScoreGraph
          tournamentId={tournament.tournamentUid}
          gameId={tournament.gameId}
          orgId={user.activeOrgId}
        />
      )}
      <Row justify="center" align="middle">
        <Col span={!isMobile ? 12 : 24}>
          <Timeline mode="alternate">
            {tournamentEvents.map((item, index) => (
              <Timeline.Item
                key={item.time}
                color={index % 2 === 0 ? "red" : "green"}
                position="left"
              >
                {item.username} {item.event} at {dateFormatter(item.time)}
              </Timeline.Item>
            ))}
          </Timeline>
        </Col>
      </Row>
    </TriumphPage>
  );
};

export default BlitzTournamentDetailPage;
