import { Modal, Skeleton } from "antd";
import { FC } from "react";
import OrgDeposit from "./OrgDeposit";
import OrgDepositRewithdrawal from "./OrgDepositRewithdrawal";
import OrgDisbursement from "./OrgDisbursement";
import OrgDisbursementRedeposit from "./OrgDisbursementRedeposit";
import OrgTriumphDeposit from "./OrgTriumphDeposit";
import OrgWithdrawal from "./OrgWithdrawal";
import OrgWithdrawalRedeposit from "./OrgWithdrawalRedeposit";

interface Props {
  selectedRow: OrgBalanceTransaction | null;
  onClose: () => void;
}

interface ModalProps extends Omit<Props, "onClose"> {}

const RenderModalContent: FC<ModalProps> = ({ selectedRow }) => {
  if (!selectedRow) return <Skeleton avatar title paragraph />;
  switch (selectedRow.type) {
    case "org-deposit":
      return <OrgDeposit data={selectedRow} />;
    case "org-deposit-rewithdrawal":
      return <OrgDepositRewithdrawal data={selectedRow} />;
    case "org-disbursement":
      return <OrgDisbursement data={selectedRow} />;
    case "org-disbursement-redeposit":
      return <OrgDisbursementRedeposit data={selectedRow} />;
    case "org-triumph-deposit":
      return <OrgTriumphDeposit data={selectedRow} />;
    case "org-withdrawal":
      return <OrgWithdrawal data={selectedRow} />;
    case "org-withdrawal-redeposit":
      return <OrgWithdrawalRedeposit data={selectedRow} />;
    default:
      return <></>;
  }
};

const OrgBalanceTrxModal: FC<Props> = ({ selectedRow, onClose }) => {
  return (
    <Modal
      title="Organization Balance Transactions Details"
      centered
      open={!!selectedRow}
      onOk={onClose}
      onCancel={onClose}
      width={1000}
    >
      <RenderModalContent selectedRow={selectedRow} />
    </Modal>
  );
};

export default OrgBalanceTrxModal;
