import { FC, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Avatar, Divider, List, Modal, Spin, Tabs } from "antd";
import UserSearchBar from "./user/searchbar";
import { Link } from "react-router-dom";
import { SearchDataType } from "./search.types";
import AsyncTournamentSearchBar from "./asyncTournament/searchbar";
import BlitzTournamentSearchBar from "./blitzTournament/searchbar";

const defaultOpen = "user-search";
const Search: FC = () => {
  const [showSearch, setShowSearch] = useState(false);
  const [selectedTab, setSelectedTab] = useState(defaultOpen);
  const [isSearching, setIsSearching] = useState(false);
  const [data, setData] = useState<SearchDataType[]>([]);

  const onClose = () => {
    setShowSearch(false);
    setIsSearching(false);
    setData([]);
    setSelectedTab(defaultOpen);
  };
  return (
    <>
      <Modal
        title="Search"
        open={showSearch}
        onCancel={onClose}
        footer={null}
        destroyOnClose={true}
        width="50vw"
        className="search-bar-modal-with-spin"
      >
        {isSearching && (
          <div className="spin-overlay-wrap">
            <Spin />
          </div>
        )}
        This is an exact match search
        <Tabs
          defaultActiveKey={defaultOpen}
          activeKey={selectedTab}
          destroyInactiveTabPane
          items={[
            {
              key: "user-search",
              label: "Search Users",
              children: (
                <UserSearchBar setData={setData} isFetching={setIsSearching} />
              ),
            },
            {
              key: "search-async-tournaments",
              label: "Search Async Tournaments",
              children: (
                <AsyncTournamentSearchBar
                  setData={setData}
                  isFetching={setIsSearching}
                />
              ),
            },
            {
              key: "search-blitz-tournaments",
              label: "Search Blitz Tournaments",
              children: (
                <BlitzTournamentSearchBar
                  setData={setData}
                  isFetching={setIsSearching}
                />
              ),
            },
          ]}
          onChange={(e) => {
            setSelectedTab(e);
            setData([]);
          }}
        />
        <>
          <Divider />
          <List
            className="demo-loadmore-list"
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item: SearchDataType) => (
              <List.Item
                actions={[
                  <Link
                    onClick={() => onClose()}
                    to={item.url}
                    key="list-loadmore-edit"
                  >
                    open
                  </Link>,
                  <Link to={item.url} target="blank" key="list-loadmore-more">
                    open in new tab
                  </Link>,
                ]}
              >
                <List.Item.Meta
                  avatar={
                    <Avatar size={64} src={item.icon}>
                      {item.title?.[0]}
                    </Avatar>
                  }
                  title={item.title}
                  description={item.description.split("\n").map((e) => (
                    <p>{e}</p>
                  ))}
                />
              </List.Item>
            )}
          />
        </>
      </Modal>
      <div className="global-search-wrap" onClick={() => setShowSearch(true)}>
        Search
        <SearchOutlined />
      </div>
    </>
  );
};

export default Search;
