import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  collection,
  doc,
  documentId,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db, generateFirestorePath } from "src/helpers";
import { colNames, getCollection } from "src/collections";
/** type imports */
import type { Query } from "firebase/firestore";
import type { RootState } from "src/app/store";

interface ITournamentPayload {
  tournamentId?: string;
  game: string;
  version?: "1" | "2" | "2.4";
}

type GameState = {
  loading: boolean;
  tournament: { [x: string]: AsyncGroupTournamentV1 };
  blitzTournament: { [x: string]: BlitzTournamentV1 | BlitzTournamentV2 };
  listOfGames: {
    id: string;
    name: string;
    tier: "realMoney" | "freeMatchmaking";
  }[];
  listOfAllGames: {
    id: string;
    name: string;
    goLiveStatus: Game["goLiveStatus"];
  }[];
};

const initialState: GameState = {
  loading: false,
  tournament: {},
  blitzTournament: {},
  listOfGames: [],
  listOfAllGames: [],
};

export const getGroupTournamentDetails = createAsyncThunk(
  "games/getGroupTournamentDetails",
  async (payload: ITournamentPayload) => {
    try {
      const gameDoc = doc(getCollection(colNames.games), payload.game);

      const groupTournamentsColRef = getCollection(
        colNames.groupTournaments,
        gameDoc
      );

      const q = query(
        groupTournamentsColRef,
        where(documentId(), "==", payload.tournamentId)
      );
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs[0].data();

      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  {
    condition: (
      payload,
      { getState, extra }: { getState: () => RootState; extra: any }
    ) => {
      const { gameState } = getState();
      if (typeof payload.tournamentId === "undefined") return false;
      if (gameState.tournament.hasOwnProperty(payload.tournamentId))
        return false;
      return true;
    },
  }
);

export const getBlitzTournamentDetails = createAsyncThunk(
  "games/getBlitzTournamentDetails",
  async (payload: ITournamentPayload) => {
    try {
      const gameDoc = doc(getCollection(colNames.games), payload.game);
      let q: Query<BlitzTournamentV1 | BlitzTournamentV2>;

      if (payload.version === "2" || payload.version === "2.4") {
        q = query(
          getCollection(colNames.blitzTournamentsV2, gameDoc),
          where(documentId(), "==", payload.tournamentId)
        );
      } else {
        q = query(
          getCollection(colNames.blitzTournaments, gameDoc),
          where(documentId(), "==", payload.tournamentId)
        );
      }

      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs[0].data();
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  {
    condition: (
      payload,
      { getState, extra }: { getState: () => RootState; extra: any }
    ) => {
      const { gameState } = getState();
      if (typeof payload.tournamentId === "undefined") return false;
      if (gameState.blitzTournament.hasOwnProperty(payload.tournamentId))
        return false;
      return true;
    },
  }
);

export const getGames = createAsyncThunk<
  GameState["listOfGames"],
  void,
  { state: RootState }
>("games/getGames", async (payload, { getState }) => {
  try {
    const { user } = getState().userState;
    const { orgs } = getState().orgState;
    // if unauthenticated, return no games
    if (!user) {
      return [];
    }

    // if user has no selected orgId return no games
    if (!user.activeOrgId) {
      return [];
    }
    const activeOrg = orgs[user.activeOrgId];
    // don't query firestore if no org is active, or
    // current active org has no associated games
    if (!activeOrg || (activeOrg && activeOrg.gameIds.length === 0)) {
      return [];
    }
    // get all games owned by this admin user's organization
    const gameRef = query(
      collection(db, generateFirestorePath("games")),
      where(documentId(), "in", activeOrg.gameIds)
    );
    const gameSnapshot = await getDocs(gameRef);

    const games = [];
    for (const doc of gameSnapshot.docs) {
      const id = doc.id;
      const data = doc.data();
      games.push({ name: data.displayName, id, tier: data.tier });
    }

    return games;
  } catch (error) {
    console.error(error);
    throw error;
  }
});

export const getAllGames = createAsyncThunk<
  GameState["listOfAllGames"],
  void,
  { state: RootState }
>(
  "games/getAllGames",
  async () => {
    try {
      const gameRef = query(collection(db, generateFirestorePath("games")));
      const gameSnapshot = await getDocs(gameRef);

      const games = [];
      for (const doc of gameSnapshot.docs) {
        const id = doc.id;
        const data = doc.data();
        games.push({
          name: data.displayName,
          id,
          goLiveStatus: data.goLiveStatus,
        });
      }

      return games;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  {
    condition: (
      payload,
      { getState, extra }: { getState: () => RootState; extra: any }
    ) => {
      const { gameState } = getState();

      if (gameState.listOfAllGames.length > 0) return false;
      return true;
    },
  }
);

const gameSlice = createSlice({
  name: "games",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getGroupTournamentDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getGroupTournamentDetails.fulfilled, (state, action) => {
      state.loading = false;
      const tournamentId = action.meta.arg.tournamentId;
      if (tournamentId) {
        state.tournament[tournamentId] = action.payload;
      }
    });
    builder.addCase(getGroupTournamentDetails.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getBlitzTournamentDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getBlitzTournamentDetails.fulfilled, (state, action) => {
      state.loading = false;
      const tournamentId = action.meta.arg.tournamentId;
      if (tournamentId) {
        state.blitzTournament[tournamentId] = action.payload;
      }
    });
    builder.addCase(getBlitzTournamentDetails.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getGames.pending, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getGames.fulfilled, (state, action) => {
      state.listOfGames = action.payload;
      state.loading = false;
    });
    builder.addCase(getGames.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getAllGames.pending, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getAllGames.fulfilled, (state, action) => {
      state.listOfAllGames = action.payload;
      state.loading = false;
    });
    builder.addCase(getAllGames.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const gameSelect = (state: RootState) => state.gameState;

export default gameSlice.reducer;
