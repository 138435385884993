import { Row } from "antd";
import { FC } from "react";
import Contract from "src/components/settings/Contract";

const InternalMasterContract: FC = () => {
  return (
    <>
      <Row justify="center">
        <Contract isMaster={true} />
      </Row>
    </>
  );
};

export default InternalMasterContract;
