import { FC, useEffect, useState } from "react";
import SchedulePNForm from "./SchedulePNForm";
import { PlusCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Row, Typography, message } from "antd";
import { useParams } from "react-router-dom";
import OverlayLoader from "src/shared/components/overlayLoader/overlayLoader";
import { axiosInstance } from "src/helpers";

type Props = {
  notifications: { [x: string]: PushNotification } | null;
  offerslist: Array<ConfigureOffer>;
};
const SchedulePNList: FC<Props> = ({ notifications, offerslist }) => {
  const [activeItem, setActiveItem] = useState<string | null>(null);
  const [list, setList] = useState<
    Array<PushNotification & { isNew?: boolean; newId?: number }>
  >([]);

  const { game } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (notifications) {
      setList(Object.values(notifications));
    }
  }, [notifications]);

  const onAddItem = () => {
    const id = Date.now();
    setList((prev) => [
      ...prev,
      {
        notificationId: "",
        gameId: "",
        title: "",
        body: "",
        subtitle: "",
        createdAt: 0,
        updatedAt: 0,
        scheduledIn: 0,
        isNew: true,
        newId: id,
        offer: "none",
      },
    ]);

    setActiveItem(`${id}`);
  };

  const onRemoveItem = async (item: (typeof list)[0]) => {
    if (item.isNew) {
      setList((prev) => {
        const removed = prev.filter((f) => f.newId !== item.newId);
        return removed;
      });
      setActiveItem(null);
    } else {
      try {
        setIsLoading(true);
        await axiosInstance.post<{ success: string }>(
          `admin/games/${game}/push_notification/delete`,
          {
            notificationId: item.notificationId,
          }
        );

        message.success("Schedule PN deleted successfully");
        setActiveItem(null);
      } catch (e) {
        message.error("Schedule PN deletion failed");

        console.error(e);
      } finally {
        setIsLoading(false);
      }
    }
  };
  return (
    <>
      {isLoading && <OverlayLoader />}
      <Row align="middle">
        <Col span={1}>
          <Button
            disabled={!!activeItem}
            type="link"
            onClick={onAddItem}
            className=" add-notif"
          >
            <PlusCircleOutlined />
          </Button>
        </Col>
        <Col span={3}>
          <Typography.Text strong>Scheduled In (days)</Typography.Text>
        </Col>
        <Col span={5}>
          <Typography.Text strong>Notification Name</Typography.Text>
        </Col>
        <Col span={6}>
          <Typography.Text strong>Title</Typography.Text>
        </Col>
        <Col span={5}>
          <Typography.Text strong>Body</Typography.Text>
        </Col>
        <Col span={3}>
          <Typography.Text strong>Offer</Typography.Text>
        </Col>
        <Col span={1}>
          <Typography.Text strong>Actions</Typography.Text>
        </Col>
        <Divider style={{ margin: "10px 0" }} />
      </Row>
      {list.length === 0 ? (
        <>
          <LoadingOutlined />
        </>
      ) : (
        list
          .sort((a, b) => a.scheduledIn - b.scheduledIn)
          .map((e, i) => (
            <SchedulePNForm
              setActive={setActiveItem}
              isActive={
                activeItem === null
                  ? true
                  : activeItem !== null &&
                    `${e.notificationId || e.newId}` === activeItem
              }
              data={{
                ...e,
                scheduledIn: e.scheduledIn / (60 * 60 * 24 * 1000),
              }}
              key={e.notificationId || e.newId}
              index={i}
              onRemove={onRemoveItem}
              offerslist={offerslist}
            />
          ))
      )}
    </>
  );
};

export default SchedulePNList;
