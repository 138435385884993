import { FC, useEffect, useState } from "react";
import TriumphPage from "src/shared/layout/TriumphPage";
import { Space, Table } from "antd";
import { Unsubscribe } from "firebase/auth";
import { collection, onSnapshot } from "firebase/firestore";
import {
  appUsersPublicConverter,
  gameConverter,
  orgConverter,
  supportTicketConverter,
} from "src/converters";
import { db } from "src/helpers";
import { dateFormatter } from "src/shared/config/constants";
import { Link } from "react-router-dom";
/** type imports */
import type { TableColumnsType } from "antd";

interface SupportTicketTableProps extends SupportTicket {
  appUserName: string;
  orgName: string;
  gameDisplayName: string;
}

const InternalSupport: FC = () => {
  const [appUsersPublic, setAppUsersPublic] = useState<{
    [x: string]: AppUserPublic;
  } | null>(null);
  const [orgs, setOrgs] = useState<{ [x: string]: Organization } | null>(null);
  const [games, setGames] = useState<{ [x: string]: Game } | null>(null);
  const [supportTickets, setSupportTickets] = useState<{
    [x: string]: SupportTicketTableProps;
  } | null>(null);

  useEffect(() => {
    let unsubAppUsersPublic: Unsubscribe;

    const appUsersPublicRef = collection(db, "appUsersPublic").withConverter(
      appUsersPublicConverter
    );
    unsubAppUsersPublic = onSnapshot(appUsersPublicRef, (colSnap) => {
      const users: typeof appUsersPublic = {};
      for (const snap of colSnap.docs) {
        const userId = snap.id;
        users[userId] = snap.data();
      }
      setAppUsersPublic(users);
    });
    return () => {
      unsubAppUsersPublic?.();
    };
  }, []);

  // fetch orgs list
  useEffect(() => {
    let unsubOrgs: Unsubscribe;
    const orgsRef = collection(db, "organizations").withConverter(orgConverter);

    unsubOrgs = onSnapshot(orgsRef, (colSnap) => {
      const orgsList: typeof orgs = {};
      for (const snap of colSnap.docs) {
        const orgId = snap.id;
        orgsList[orgId] = snap.data();
      }
      setOrgs(orgsList);
    });
    return () => {
      unsubOrgs?.();
    };
  }, []);

  // fetch games list
  useEffect(() => {
    let unsubGames: Unsubscribe;
    const gamesRef = collection(db, "games").withConverter(gameConverter);

    unsubGames = onSnapshot(gamesRef, (colSnap) => {
      const gamesList: typeof games = {};
      for (const snap of colSnap.docs) {
        const gameId = snap.id;
        gamesList[gameId] = snap.data();
      }
      setGames(gamesList);
    });
    return () => {
      unsubGames?.();
    };
  }, []);

  useEffect(() => {
    let unsubSupportTickets: Unsubscribe;
    const supportTicketsRef = collection(db, "supportTickets").withConverter(
      supportTicketConverter
    );
    unsubSupportTickets = onSnapshot(supportTicketsRef, (colSnap) => {
      const tickets: typeof supportTickets = {};
      for (const snap of colSnap.docs) {
        const supportTicketId = snap.id;
        const data = snap.data();
        if (
          appUsersPublic &&
          data.appUserUid in appUsersPublic &&
          orgs &&
          data.orgId in orgs &&
          games &&
          data.gameId in games
        ) {
          tickets[supportTicketId] = {
            ...data,
            appUserName: appUsersPublic[data.appUserUid].username,
            orgName: orgs[data.orgId].name,
            gameDisplayName: games[data.gameId].displayName,
          };
        }
      }
      setSupportTickets(tickets);
    });
    return () => {
      unsubSupportTickets?.();
    };
  }, [appUsersPublic, games, orgs]);

  const columns: TableColumnsType<SupportTicketTableProps> = [
    {
      title: "Ticket ID",
      dataIndex: "uid",
      key: "uid",
    },
    {
      title: "User",
      dataIndex: "appUserName",
      key: "appUserName",
    },
    {
      title: "Organization",
      dataIndex: "orgName",
      key: "orgName",
    },
    {
      title: "Game",
      dataIndex: "gameDisplayName",
      key: "gameDisplayName",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      defaultSortOrder: "descend",
      sorter: (a, b) => (a.createdAt || 0) - (b.createdAt || 0),
      render: (a) => dateFormatter(a),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: (a, b) => (a.createdAt || 0) - (b.createdAt || 0),
      render: (a) => dateFormatter(a),
    },
  ];

  function expandedRowRender(record: SupportTicketTableProps) {
    const tournamentDir =
      record.tournamentType === "async-group" ? "asyncGroupv1" : "blitz";
    const columns: TableColumnsType<SupportTicketTableProps> = [
      {
        title: "Tournament Type",
        dataIndex: "tournamentType",
        key: "tournamentType",
      },
      {
        title: "Tournament ID",
        dataIndex: "tournamentId",
        key: "tournamentId",
        render: (id, record) => (
          <Link
            to={`/games/${record.gameId}/tournaments/${tournamentDir}/${id}`}
          >
            {id}
          </Link>
        ),
      },
    ];
    return (
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Table
          columns={columns}
          rowKey="tournamentId"
          dataSource={Object.values(supportTickets || {}).filter(
            (v) => v.uid === record.uid
          )}
          pagination={false}
        />
      </Space>
    );
  }

  return (
    <TriumphPage>
      <Table
        loading={!supportTickets}
        columns={columns}
        rowKey="uid"
        dataSource={Object.values(supportTickets || {})}
        expandable={{ expandedRowRender, defaultExpandedRowKeys: ["0"] }}
      />
    </TriumphPage>
  );
};

export default InternalSupport;
