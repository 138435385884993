import {
  Button,
  Modal,
  Space,
  Switch,
  Table,
  TableColumnsType,
  TablePaginationConfig,
} from "antd";
import { collection, doc, onSnapshot, Unsubscribe } from "firebase/firestore";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { tournamentGroupDefConverter } from "src/converters";
import { axiosInstance, db } from "src/helpers";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import AsyncGroupConfigForm from "./asyncGroupConfigForm";
import Currency from "src/shared/components/currency/Currency";
import TriumphPage from "src/shared/layout/TriumphPage";
import { dateFormatter } from "src/shared/config/constants";

interface Props {
  isAdmin: boolean;
}

const AsyncGroupConfigList: FC<Props> = ({ isAdmin }) => {
  const { game } = useParams();
  const [trnmntConfigs, setTrnmntConfigs] = useState<{
    [x: string]: TournamentGroupConfig;
  } | null>(null);

  const [selectedConfig, setSelectedConfig] =
    useState<TournamentGroupConfig | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    let unSubAsyncGroupTournamentConfig: Unsubscribe;
    if (game) {
      const gamesRef = collection(db, "games");

      const gamesDoc = doc(gamesRef, game);

      const asyncTournamentConfigRef = collection(
        gamesDoc,
        "groupTournamentDefinitions"
      ).withConverter(tournamentGroupDefConverter);

      unSubAsyncGroupTournamentConfig = onSnapshot(
        asyncTournamentConfigRef,
        (colSnap) => {
          const config: typeof trnmntConfigs = {};
          for (const snap of colSnap.docs) {
            const uid = snap.id;
            const data = snap.data();
            config[uid] = { ...data, uid };
          }
          setTrnmntConfigs(config);
        }
      );
    }
    return () => {
      unSubAsyncGroupTournamentConfig?.();
    };
  }, [game]);

  const paginationConfig: TablePaginationConfig = {
    pageSize: 50,
    className: "mobile-pagination-small",
  };

  async function onArchive(data: TournamentGroupConfig) {
    try {
      await axiosInstance.post<{ success: string }>(
        `/internal/games/${game}/async_group_definitions/${data.uid}`,
        { archived: !data.archived }
      );
    } catch (error) {
      console.error(error);
    }
  }

  const columns: TableColumnsType<TournamentGroupConfig> = [
    {
      title: "UID",
      dataIndex: "uid",
      key: "uid",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },

    {
      title: "Size",
      dataIndex: "size",
      key: "size",
    },

    {
      title: "Entry Price",
      dataIndex: "entryPrice",
      key: "entryPrice",
      render: (v) => (
        <Currency value={v} currency="cents-to-dollar" prefix="$" />
      ),
    },
    {
      title: "Entry Gems",
      dataIndex: "entryGems",
      key: "entryGems",
    },
    {
      title: "Total Payout Cash",
      key: "totalPayoutCash",
      render: (record: TournamentGroupConfig) => (
        <Currency
          value={record.rewards.reduce((prev, curr) => {
            const currentPayout: number = curr.payout || 0;

            return prev + currentPayout;
          }, 0)}
          currency="cents-to-dollar"
          prefix="$"
        />
      ),
    },
    {
      title: "Total Payout Bonus Cash",
      key: "totalPayoutBonusCash",
      render: (record: TournamentGroupConfig) => (
        <Currency
          value={record.rewards.reduce((prev, curr) => {
            const currentPayoutBonusCash: number = curr.payoutBonusCash || 0;

            return prev + currentPayoutBonusCash;
          }, 0)}
          currency="cents-to-dollar"
          prefix="$"
        />
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.createdAt - b.createdAt,
      render: (a) => dateFormatter(a),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
      render: (a) => dateFormatter(a),
    },
    {
      title: "Archive",
      dataIndex: "archived",
      key: "archived",
      render: (value, record) => (
        <Switch
          checked={value}
          disabled={!isAdmin}
          onChange={() =>
            Modal.confirm({
              title: `Confirm ${value ? "Un-Archive" : "Archive"} ?`,
              icon: <ExclamationCircleOutlined />,
              content: `By clicking confirm the record will be ${
                value ? "un-archived" : "archived"
              }.`,
              okText: "Confirm",
              cancelText: "Back",
              onOk: () => onArchive(record),
              okCancel: true,
            })
          }
        />
      ),
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   key: "action",
    //   width: 100,
    //   render: (_, record) => (
    //     <>
    //       <Button
    //         onClick={() => setSelectedConfig(record)}
    //         icon={<EditOutlined style={{ fontSize: 24 }} />}
    //       />
    //     </>
    //   ),
    // },
  ];

  const adminKeys: string[] = ["uid", "createdAt", "updatedAt"];

  const expandedRowRender = (record: TournamentGroupConfig) => {
    const columns: TableColumnsType<TournamentGroupConfig["rewards"][number]> =
      [
        {
          title: "Place",
          render: (record, reward, index) => <div>{index + 1}</div>,
        },
        {
          title: "Payout",
          dataIndex: "payout",
          key: "payout",
          render: (v) => (
            <Currency value={v} currency="cents-to-dollar" prefix="$" />
          ),
        },
        {
          title: "Payout Bonus Cash",
          dataIndex: "payoutBonusCash",
          key: "payoutBonusCash",
          render: (v) => (
            <Currency value={v} currency="cents-to-dollar" prefix="$" />
          ),
        },
        {
          title: "Payout Gems",
          dataIndex: "payoutGems",
          key: "payoutGems",
        },
      ];
    return (
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Table
          columns={columns}
          dataSource={trnmntConfigs?.[record.uid].rewards ?? []}
          pagination={false}
        />
      </Space>
    );
  };

  return (
    <TriumphPage>
      {isAdmin ? (
        <Button type="primary" onClick={() => navigate("/internal/games")}>
          Return to Games List
        </Button>
      ) : (
        <></>
      )}{" "}
      <AsyncGroupConfigForm
        config={selectedConfig}
        isAdmin={isAdmin}
        onClose={() => setSelectedConfig(null)}
      />
      <Table
        columns={columns.filter((item) =>
          !isAdmin
            ? typeof item.key === "string" && !adminKeys.includes(item.key)
            : true
        )}
        expandable={{ expandedRowRender, defaultExpandedRowKeys: ["0"] }}
        loading={trnmntConfigs === null}
        rowKey="uid"
        dataSource={Object.values(trnmntConfigs || {})}
        pagination={paginationConfig}
        className="mobile-table-small"
      />
    </TriumphPage>
  );
};

export default AsyncGroupConfigList;
