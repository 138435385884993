import { Button, Col, Form, Popover, Row } from "antd";
import { FC, useEffect, useState } from "react";
import { SketchPicker } from "react-color";

const ColorConfig: FC<{
  data: { [x: string]: ColorConfig };
  onChange: (e: string) => void;
}> = ({ data, onChange }) => {
  const [primaryColor, setPrimaryColor] = useState<string>();

  useEffect(() => {
    if (data.primary) {
      setPrimaryColor(data.primary?.value);
    }
  }, [data]);

  return (
    <Row>
      <Col span={12}>
        <Form.Item
          label="Primary color"
          className="app-config-input-wrap"
          name="primary-color"
        >
          <Popover
            content={
              <SketchPicker
                className="primary-color-picker"
                color={primaryColor}
                onChangeComplete={(e) => {
                  setPrimaryColor(e.hex);
                  onChange(e.hex);
                }}
              />
            }
            title="Select Primary Color"
            trigger="click"
          >
            <Button
              style={{
                background: primaryColor,
                color: primaryColor,
                border: "5px solid white",
              }}
            >
              <span className="d-none">-</span>
            </Button>
          </Popover>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default ColorConfig;
