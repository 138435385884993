import React, { useMemo, useState } from "react";
import { Table, TableColumnsType, Typography, DatePicker, Button } from "antd";
import {
  onSnapshot,
  query,
  where,
  orderBy,
  QueryDocumentSnapshot,
  startAfter,
  limit,
  QueryConstraint,
  Unsubscribe,
  collection,
  doc,
  endBefore,
  limitToLast,
} from "firebase/firestore";

import { db } from "src/helpers";

import { useAppSelector } from "src/app/hooks";

import Currency from "src/shared/components/currency/Currency";
import moment from "moment";
import Pagination from "src/shared/components/pagination/Pagination";
import { dateFormatter, PAGE_SIZE_OPTIONS } from "src/shared/config/constants";
import TriumphPage from "src/shared/layout/TriumphPage";

/** type imports */
import type { TablePaginationConfig } from "antd";
import type { RangeValue } from "rc-picker/lib/interface";
import { orgBalanceTrxConverter } from "src/converters";
import OrgBalanceTrxModal from "./orgTrxModal";

type TrxTableProps = OrgBalanceTransaction;

const { Link } = Typography;

const defaultPageSize = 100;

const OrganizationTransactions: React.FC = () => {
  const { user } = useAppSelector((state) => state.userState);

  const [selectedRow, setSelectedRow] = useState<OrgBalanceTransaction | null>(
    null
  );
  const [transactions, setTransactions] = React.useState<{
    [uid: string]: OrgBalanceTransaction;
  } | null>(null);

  const [dateFilter, setDateFilter] = useState<RangeValue<moment.Moment>>();
  const [isLoading, setIsLoading] = useState(false);
  const [tableQuery, setTableQuery] = useState<{
    [x: string]: QueryConstraint;
  }>({});
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [lastVisible, setLastVisible] =
    useState<QueryDocumentSnapshot<OrgBalanceTransaction> | null>(null);
  const [firstVisible, setFirstVisible] =
    useState<QueryDocumentSnapshot<OrgBalanceTransaction> | null>(null);

  React.useEffect(() => {
    let unsubTrxCol: Unsubscribe | undefined;
    if (tableQuery && user && user.activeOrgId) {
      unsubTrxCol?.();
      setIsLoading(true);

      const orgId = user.activeOrgId;

      const orgRef = doc(collection(db, "organizations"), orgId);

      const orgBalanceTrxColRef = collection(
        orgRef,
        "orgBalanceTransactions"
      ).withConverter(orgBalanceTrxConverter);

      const q = query(
        orgBalanceTrxColRef,
        orderBy("createdAt", "desc"),
        ...Object.values(tableQuery),
        ...(Object.keys(tableQuery).length === 0 ? [limit(pageSize)] : [])
      );

      unsubTrxCol = onSnapshot(
        q,
        (colSnap) => {
          const defs: typeof transactions = {};
          for (const tourDefSnap of colSnap.docs) {
            const tourDefId = tourDefSnap.id;
            const tourDef = tourDefSnap.data();
            defs[tourDefId] = tourDef;
          }

          if (colSnap.docs.length > 0) {
            setLastVisible(colSnap.docs[colSnap.docs.length - 1]);
            setFirstVisible(colSnap.docs[0]);
          }
          setTransactions(defs);
          setIsLoading(false);
        },
        (error) => console.error(error.message)
      );
    }
    return () => {
      unsubTrxCol?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableQuery, user]);

  const typeFilters: {
    text: string;
    value: OrgBalanceTransaction["type"];
  }[] = Object.values(
    Object.values(transactions ?? {}).reduce<{
      [type: string]: { text: string; value: OrgBalanceTransaction["type"] };
    }>((aggreg, trx) => {
      if (trx.type in aggreg) {
        return aggreg;
      } else {
        aggreg[trx.type] = { text: trx.type, value: trx.type };
        return aggreg;
      }
    }, {})
  );

  const columns: TableColumnsType<TrxTableProps> = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      filters: typeFilters,
      onFilter: (value, record) => record.type === value,
      render: (v, record) => (
        <Link onClick={() => setSelectedRow(record)}>{v}</Link>
      ),
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (v) => (
        <Currency value={v} currency="cents-to-dollar" prefix="$" />
      ),
    },
    {
      title: "Promo Amount",
      dataIndex: "promoAmount",
      key: "promoAmount",
      render: (v) => (
        <Currency value={v} currency="cents-to-dollar" prefix="$" />
      ),
    },
    {
      title: "Triumph Amount",
      dataIndex: "triumphAmount",
      key: "triumphAmount",
      render: (v) => (
        <Currency value={v} currency="cents-to-dollar" prefix="$" />
      ),
    },

    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "createdAt",
      dataIndex: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
      defaultSortOrder: "descend",
      key: "createdAt",
      render: (v) => dateFormatter(v),
    },
  ];

  function resetFilters() {
    setPageSize(defaultPageSize);
    setFirstVisible(null);
    setLastVisible(null);
  }

  function computeQueries(
    type: "next" | "prev" | "page-size" | "date",
    filter?: number | RangeValue<moment.Moment>
  ) {
    let q: typeof tableQuery = {};
    switch (type) {
      case "date": {
        q = {
          "date-start": where(
            "createdAt",
            ">=",
            typeof filter !== "number" && filter?.[0]?.valueOf()
          ),
          "date-end": where(
            "createdAt",
            "<=",
            typeof filter !== "number" && filter?.[1]?.valueOf()
          ),
        };
        break;
      }
      case "next": {
        q = {
          ...tableQuery,
          next: startAfter(lastVisible),
          "page-size": limit(pageSize),
        };
        break;
      }
      case "prev": {
        q = {
          ...tableQuery,
          next: endBefore(firstVisible),
          "page-size": limitToLast(pageSize),
        };
        break;
      }
      case "page-size": {
        if (typeof filter === "number") {
          setPageSize(filter);
          q = { ...tableQuery, "page-size": limit(filter) };
        }
        break;
      }
    }
    setTableQuery(q);
  }

  const paginationConfig: TablePaginationConfig = {
    pageSize: pageSize,
    onChange: (page, size) => setPageSize(size),
    pageSizeOptions: PAGE_SIZE_OPTIONS.map((e) => `${e}`),
    className: `mobile-pagination-small ${!dateFilter && "hide-pagination"}`,
  };

  const dataSource = useMemo(() => {
    return Object.entries(transactions ?? {}).map(
      ([id, transaction]): TrxTableProps => {
        switch (transaction.type) {
          case "org-deposit":
          case "org-deposit-rewithdrawal":
          case "org-disbursement":
          case "org-disbursement-redeposit":
          case "org-triumph-deposit":
          case "org-withdrawal":
          case "org-withdrawal-redeposit":
          default:
            return transaction;
        }
      }
    );
  }, [transactions]);

  return (
    <TriumphPage>
      <OrgBalanceTrxModal
        selectedRow={selectedRow}
        onClose={() => setSelectedRow(null)}
      />
      <div className="range-picker-wrap">
        {dateFilter && (
          <Button
            type="dashed"
            onClick={() => {
              resetFilters();
              setDateFilter(null);
              setTableQuery({});
            }}
          >
            Reset to first {defaultPageSize} records
          </Button>
        )}
        <DatePicker.RangePicker
          value={dateFilter}
          onChange={(e) => {
            resetFilters();
            setDateFilter(e);
            computeQueries("date", e);
          }}
        />
      </div>
      <Table
        columns={columns}
        loading={transactions === null || isLoading}
        rowKey="uid"
        dataSource={dataSource}
        pagination={paginationConfig}
        className="mobile-table-small"
      />
      {!dateFilter && (
        <Pagination
          disableNext={Object.keys(transactions || {}).length < defaultPageSize}
          pageSize={pageSize}
          onNext={() => {
            computeQueries("next");
          }}
          onPrevious={() => {
            computeQueries("prev");
          }}
          onPageSize={(size: number) => {
            setPageSize(size);
            computeQueries("page-size", size);
          }}
        />
      )}
    </TriumphPage>
  );
};

export default OrganizationTransactions;
