import { FC, useEffect, useState } from "react";
import {
  Avatar,
  Collapse,
  Divider,
  Row,
  Tag,
  Descriptions,
  Typography,
  Skeleton,
  Table,
  TableColumnsType,
} from "antd";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import {
  UserOutlined,
  CaretRightOutlined,
  LoadingOutlined,
  BulbOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { dateFormatter } from "src/shared/config/constants";
import Currency from "src/shared/components/currency/Currency";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import GameName from "src/shared/components/GameName/gameName";
import { db, getCdnPhoto, isTriumphEmployee } from "src/helpers";
import {
  appUserPublicSelect,
  getAppUserPublicArrayDetails,
} from "src/features/appUsersPublic/appUserPublicSlice";
import { collection, doc, onSnapshot, Unsubscribe } from "firebase/firestore";
import { asyncGroupv1TournamentConverter } from "src/converters";

const { Title, Link } = Typography;
const { Panel } = Collapse;

interface Props {
  row: StartGroupTournamentTrx | null;
  user: AppUserPublic;
}

const StartGroupTournament: FC<Props> = ({ row: trx, user }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { usersPublic } = useAppSelector(appUserPublicSelect);
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });

  const [tournamentDetails, setTournamentDetails] =
    useState<AsyncGroupTournamentV1 | null>(null);

  useEffect(() => {
    let unsub: Unsubscribe | undefined;
    async function setup() {
      if (trx) {
        // get tournament document
        const { tournamentId, gameId } = trx;
        const gamesCol = collection(db, "games");
        const gameRef = doc(gamesCol, gameId);
        const tournamentsCol = collection(
          gameRef,
          "groupTournaments"
        ).withConverter(asyncGroupv1TournamentConverter);
        const tournamentRef = doc(tournamentsCol, tournamentId);
        unsub = onSnapshot(
          tournamentRef,
          (snap) => {
            const data = snap.data();
            if (data) {
              setTournamentDetails(data);
            }
          },
          (error) => console.error(error.message)
        );
      }
    }

    setup();
    return () => {
      unsub?.();
    };
  }, [trx]);

  useEffect(() => {
    if (tournamentDetails) {
      dispatch(
        getAppUserPublicArrayDetails(
          tournamentDetails.players.map((player) => player.uid)
        )
      );
    }
  }, [dispatch, tournamentDetails]);

  function selectedUsers(): AsyncGroupPlayer[] {
    const players = [];
    if (tournamentDetails)
      for (let index = 0; index < tournamentDetails.players.length; index++) {
        const trn = tournamentDetails?.players[index];
        const player = usersPublic[trn.uid];
        if (player) players.push({ ...player, ...trn });
      }
    return players;
  }

  const StatusTag: FC = () => {
    if (!tournamentDetails) return <></>;
    const { status } = tournamentDetails;
    switch (status) {
      case "finished":
        return (
          <Tag
            className="tournament-status-tag"
            color="green"
            icon={<BulbOutlined />}
          >
            Finished
          </Tag>
        );
      case "in-progress":
        return (
          <Tag
            className="tournament-status-tag"
            color="processing"
            icon={<LoadingOutlined />}
          >
            In Progress
          </Tag>
        );
      case "waiting-to-match":
        return (
          <Tag
            className="tournament-status-tag"
            color="warning"
            icon={<ClockCircleOutlined />}
          >
            Waiting To Match
          </Tag>
        );
      default:
        return <></>;
    }
  };

  if (!tournamentDetails || !trx) return <Skeleton title paragraph />;

  const columns: TableColumnsType<AsyncGroupPlayer> = [
    {
      title: "Picture",
      dataIndex: "profilePhotoPath",
      key: "profilePhotoPath",
      render: (value: string) => (
        <Avatar shape="square" size={32} src={getCdnPhoto(value)}></Avatar>
      ),
    },
    {
      title: "User Name",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Matched At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value) => dateFormatter(value),
    },
    {
      title: "Score Submitted At",
      dataIndex: "finalScore",
      key: "submittedAt",
      render: (value: FinalScore | null) =>
        value && dateFormatter(value.createdAt),
    },
    {
      title: "Final Score",
      dataIndex: "finalScore",
      key: "finalScore",
      render: (value: FinalScore | null) => value && value.value,
    },
    {
      title: "Place",
      dataIndex: "place",
      key: "place",
      width: 130,
      defaultSortOrder: "ascend",
      sorter: (a, b) =>
        (tournamentDetails?.results?.[a.uid]?.place || 0) -
        (tournamentDetails?.results?.[b.uid]?.place || 0),
      render: (_, record) =>
        tournamentDetails?.results?.[record.uid]?.place || "-",
    },

    {
      title: "Average Score",
      dataIndex: "weightedAverageScore",
      key: "weightedAverageScore",
      render: (value) => value.toFixed(2),
    },

    {
      title: "System Reported",
      dataIndex: "finalScore",
      key: "systemReported",
      render: (value: FinalScore | null) =>
        value && value.systemReported ? "Yes" : "No",
    },
  ];

  const resultColumns: TableColumnsType<GroupResult & { uid: string }> = [
    {
      title: "User name",
      dataIndex: "uid",
      key: "uid",
      render: (value: string) => usersPublic[value]?.username,
    },
    {
      title: "Claimed At",
      dataIndex: "claimedAt",
      key: "claimedAt",
      render: (value: GroupResult["claimedAt"]) => dateFormatter(value),
    },
    {
      title: "Payout",
      dataIndex: "payout",
      key: "payout",
      render: (value: GroupResult["payout"]) => (
        <Currency value={value} currency="cents-to-dollar" prefix="$" />
      ),
    },
    {
      title: "Payout Bonus Cash",
      dataIndex: "payoutBonusCash",
      key: "payoutBonusCash",
      render: (value: GroupResult["payoutBonusCash"]) => (
        <Currency value={value} currency="cents-to-dollar" prefix="$" />
      ),
    },
    {
      title: "Place",
      dataIndex: "place",
      key: "place",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.place - b.place,
    },
    {
      title: "Place Count",
      dataIndex: "placeCount",
      key: "placeCount",
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
    },
    {
      title: "Won",
      dataIndex: "won",
      key: "won",
      render: (value: GroupResult["won"]) => (value ? "Yes" : "No"),
    },
  ];
  return (
    <>
      <Row justify="center" className="pb-2">
        <StatusTag />
      </Row>
      <Row>
        <Title level={4}>Players</Title>
      </Row>
      <Table
        columns={columns}
        rowKey="uid"
        scroll={{ x: 800 }}
        dataSource={selectedUsers()}
        pagination={{ pageSize: 10 }}
        className="mobile-table-small"
      />

      <Divider />
      <Descriptions
        title="Tournament Details"
        layout={!isMobile ? "horizontal" : "vertical"}
        colon
        column={1}
        bordered
        className="pb-2"
      >
        <Descriptions.Item label="RNG">
          {tournamentDetails.RNG}
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          <Tag color="geekblue">{tournamentDetails.status}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Version">
          {tournamentDetails.version}
        </Descriptions.Item>
        <Descriptions.Item label="Type">
          {tournamentDetails.type}
        </Descriptions.Item>
        <Descriptions.Item label="Config Id">
          {tournamentDetails.configId}
        </Descriptions.Item>
        {isTriumphEmployee() && (
          <Descriptions.Item label="Game Id">
            {tournamentDetails.gameId}
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Participants Max">
          {tournamentDetails.participantsMax}
        </Descriptions.Item>
        <Descriptions.Item label="Participants Size">
          {tournamentDetails.participantsSize}
        </Descriptions.Item>
        <Descriptions.Item label="Participants">
          {Object.values(usersPublic)
            .filter((user) => tournamentDetails.participants.includes(user.uid))
            .map((user) => user.username)
            .join(", ")}
        </Descriptions.Item>
        <Descriptions.Item label="Winners">
          {Object.values(usersPublic)
            .filter((user) => tournamentDetails.winnerUids?.includes(user.uid))
            .map((user) => user.username)
            .join(", ")}
        </Descriptions.Item>

        <Descriptions.Item label="Created At">
          {dateFormatter(tournamentDetails.createdAt)}
        </Descriptions.Item>
        <Descriptions.Item label="Finished At">
          {dateFormatter(tournamentDetails.finishedAt)}
        </Descriptions.Item>
        <Descriptions.Item label="Last Matched At">
          {dateFormatter(tournamentDetails.lastMatchedAt)}
        </Descriptions.Item>
      </Descriptions>
      <Divider />
      {tournamentDetails.results && (
        <>
          <Title>Results</Title>
          <Table
            columns={resultColumns}
            rowKey="uid"
            scroll={{ x: 800 }}
            dataSource={Object.entries(tournamentDetails.results).map(
              ([uid, value]) => ({ uid, ...value })
            )}
            pagination={{ pageSize: 10 }}
            className="mobile-table-small"
          />
        </>
      )}

      <Collapse
        bordered={false}
        defaultActiveKey={["1"]}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        className="site-collapse-Descriptions.-collapse"
      >
        <Panel
          header={
            <Descriptions>
              <Descriptions.Item label="Config ID">
                {tournamentDetails.configId}
              </Descriptions.Item>
            </Descriptions>
          }
          key="1"
          className="site-collapse-Descriptions.-panel"
        >
          <Descriptions layout="horizontal" colon column={1} bordered>
            <Descriptions.Item label="isArchived">
              <Tag color="cyan">
                {tournamentDetails.config.archived ? "Yes" : "No"}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Entry Price">
              <Currency
                currency="cents-to-dollar"
                prefix="$"
                value={-tournamentDetails.config.entryPrice}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Name">
              {tournamentDetails.config.name}
            </Descriptions.Item>
            <Descriptions.Item label="Type">
              {tournamentDetails.config.type}
            </Descriptions.Item>
            <Descriptions.Item label="Size">
              {tournamentDetails.config.size}
            </Descriptions.Item>
            <Descriptions.Item label="Emoji">
              {tournamentDetails.config.emoji}
            </Descriptions.Item>
          </Descriptions>
        </Panel>
      </Collapse>
      <Divider />
      <Row justify="center">
        <Avatar size={100} src={getCdnPhoto(user.profilePhotoPath)}>
          <UserOutlined />
        </Avatar>
      </Row>
      <Row justify="center">
        <Title level={4}>{user.username}</Title>
      </Row>

      <Descriptions
        title="Transaction Details"
        layout={!isMobile ? "horizontal" : "vertical"}
        colon
        column={1}
        bordered
        className="pb-2"
      >
        <Descriptions.Item label="ID">{trx.uid}</Descriptions.Item>
        <Descriptions.Item label="Trace ID">{trx.traceId}</Descriptions.Item>
        <Descriptions.Item label="App User username">
          {user.username}
        </Descriptions.Item>
        <Descriptions.Item label="App User ID">
          {trx.appUserUid}
        </Descriptions.Item>
        <Descriptions.Item label="Type">
          <Tag color="blue">{trx.type}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Description">
          {trx.description}
        </Descriptions.Item>
        <Descriptions.Item label="Amount">
          <Currency currency="cents-to-dollar" prefix="$" value={trx.amount} />
        </Descriptions.Item>
        <Descriptions.Item label="Bonus Cash Amount">
          <Currency
            currency="cents-to-dollar"
            prefix="$"
            value={trx.bonusCashAmount}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Game">
          <GameName id={trx.gameId} />
        </Descriptions.Item>
        <Descriptions.Item label="Tournament ID">
          <Link
            onClick={() =>
              navigate(
                `/games/${trx.gameId}/tournaments/asyncgroupv1/${trx.tournamentId}`
              )
            }
          >
            {trx.tournamentId}
          </Link>
        </Descriptions.Item>
        <Descriptions.Item label="Created Date">
          {dateFormatter(trx.createdAt)}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default StartGroupTournament;
