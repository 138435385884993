import { FC } from "react";
import { Tag, Descriptions, Skeleton, Row, Avatar, Typography } from "antd";
import { dateFormatter } from "src/shared/config/constants";

import { UserOutlined } from "@ant-design/icons";
import Currency from "src/shared/components/currency/Currency";
import { getCdnPhoto } from "src/helpers";

const { Title } = Typography;

interface Props {
  row: TriumphDepositTrx | null;
  user: AppUserPublic;
}

const TriumphDeposit: FC<Props> = ({ row: transactionalDetails, user }) => {
  if (!transactionalDetails) return <Skeleton title paragraph />;

  return (
    <>
      <Row justify="center">
        <Avatar size={100} src={getCdnPhoto(user.profilePhotoPath)}>
          <UserOutlined />
        </Avatar>
      </Row>
      <Row justify="center">
        <Title level={4}>{user.username}</Title>
      </Row>

      <Descriptions
        title="Transaction Details"
        layout="horizontal"
        colon
        column={1}
        bordered
        className="pb-2"
      >
        <Descriptions.Item label="ID">{transactionalDetails.uid}</Descriptions.Item>
        <Descriptions.Item label="Trace ID">{transactionalDetails.traceId}</Descriptions.Item>

        <Descriptions.Item label="App User username">
          {user.username}
        </Descriptions.Item>
        <Descriptions.Item label="App User ID">
          {transactionalDetails.appUserUid}
        </Descriptions.Item>
        <Descriptions.Item label="Triumph Employee Uid">
          {transactionalDetails.triumphEmployeeUid}
        </Descriptions.Item>
        <Descriptions.Item label="Type">
          <Tag color="blue">{transactionalDetails.type}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Description">
          {transactionalDetails.description}
        </Descriptions.Item>
        <Descriptions.Item label="Amount">
          <Currency
            currency="cents-to-dollar"
            prefix="$"
            value={transactionalDetails.amount}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Token Amount">
          <Currency
            currency="cents-to-dollar"
            prefix="$"
            value={transactionalDetails.tokenAmount}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Bonus Cash Amount">
          <Currency
            currency="cents-to-dollar"
            prefix="$"
            value={transactionalDetails.bonusCashAmount}
          />
        </Descriptions.Item>

        <Descriptions.Item label="Created Date">
          {dateFormatter(transactionalDetails.createdAt)}
        </Descriptions.Item>
        <Descriptions.Item label="Transaction Date">
          {dateFormatter(transactionalDetails.transactionDate)}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default TriumphDeposit;
