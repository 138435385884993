/** type imports */
import type {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from "firebase/firestore";

export const converter = <T>() => ({
  toFirestore(data: T) {
    return data;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<T>): T {
    return snapshot.data();
  },
});

export const statisticConverter: FirestoreDataConverter<Statistic> = {
  toFirestore(data) {
    return data;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<Statistic>) {
    const data = snapshot.data();
    return data;
  },
};

export const quoteConverter: FirestoreDataConverter<Quote> = {
  toFirestore(data) {
    return data;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<Quote>) {
    const data = snapshot.data();
    return data;
  },
};

export const gameConverter: FirestoreDataConverter<Game> = {
  toFirestore(data) {
    return data;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<Game>) {
    const data = snapshot.data();
    return data;
  },
};

export const missionsConverter: FirestoreDataConverter<MissionConfig> = {
  toFirestore(data) {
    return data;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<MissionConfig>) {
    const data = snapshot.data();
    return data;
  },
};

export const tournamentGroupDefConverter: FirestoreDataConverter<TournamentGroupConfig> =
  {
    toFirestore(data) {
      return data;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot<TournamentGroupConfig>) {
      const data = snapshot.data();
      return data;
    },
  };

export const transactionsConverter: FirestoreDataConverter<BalanceTransaction> =
  {
    toFirestore(data) {
      return data;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot<BalanceTransaction>) {
      const data = snapshot.data();
      return data;
    },
  };

export const appUsersConverter: FirestoreDataConverter<AppUser> = {
  toFirestore(data) {
    return data;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<AppUser>) {
    const data = snapshot.data();
    return data;
  },
};
export const appUsersPublicConverter: FirestoreDataConverter<AppUserPublic> = {
  toFirestore(data) {
    return data;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<AppUserPublic>) {
    const data = snapshot.data();
    return data;
  },
};

export const blitzBucketConverter: FirestoreDataConverter<BlitzBucket> = {
  toFirestore(data) {
    return data;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<BlitzBucket>) {
    const data = snapshot.data();
    return data;
  },
};

export const async1v1TournamentConverter: FirestoreDataConverter<Async1v1TournamentV1> =
  {
    toFirestore(data) {
      return data;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot<Async1v1TournamentV1>) {
      const data = snapshot.data();
      return data;
    },
  };

export const blitzTournamentConverter: FirestoreDataConverter<BlitzTournamentV2> =
  {
    toFirestore(data) {
      return data;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot<BlitzTournamentV2>) {
      const data = snapshot.data();
      return data;
    },
  };

export const blitzTournamentConfigConverter: FirestoreDataConverter<BlitzDefinitionV2> =
  {
    toFirestore(data) {
      return data;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot<BlitzDefinitionV2>) {
      const data = snapshot.data();
      return data;
    },
  };

export const asyncGroupv1TournamentConverter: FirestoreDataConverter<AsyncGroupTournamentV1> =
  {
    toFirestore(data) {
      return data;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot<AsyncGroupTournamentV1>) {
      const data = snapshot.data();
      return data;
    },
  };

export const orgConverter: FirestoreDataConverter<Organization> = {
  toFirestore(data) {
    return data;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<Organization>) {
    const data = snapshot.data();
    return data;
  },
};

export const orgBalanceTrxConverter: FirestoreDataConverter<OrgBalanceTransaction> =
  {
    toFirestore(data) {
      return data;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot<OrgBalanceTransaction>) {
      const data = snapshot.data();
      return data;
    },
  };

export const orgTransferConverter: FirestoreDataConverter<OrgDwollaTransfer> = {
  toFirestore(data) {
    return data;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<OrgDwollaTransfer>) {
    const data = snapshot.data();
    return data;
  },
};

export const masterContractConverter: FirestoreDataConverter<MasterContract> = {
  toFirestore(data) {
    return data;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<MasterContract>) {
    const data = snapshot.data();
    return data;
  },
};

export const contractConverter: FirestoreDataConverter<Contract> = {
  toFirestore(data) {
    return data;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<Contract>) {
    const data = snapshot.data();
    return data;
  },
};

export const adminUserConverter: FirestoreDataConverter<AdminUser> = {
  toFirestore(data) {
    return data;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<AdminUser>) {
    const data = snapshot.data();
    return data;
  },
};

export const OffersConverter: FirestoreDataConverter<Offer> = {
  toFirestore(data) {
    return data;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<Offer>) {
    const data = snapshot.data();
    return data;
  },
};

export const DepoitDefinitionConverter: FirestoreDataConverter<DepositDefinition> =
  {
    toFirestore(data) {
      return data;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot<DepositDefinition>) {
      const data = snapshot.data();
      return data;
    },
  };

export const blitzDataConverter: FirestoreDataConverter<BlitzData> = {
  toFirestore(data) {
    return data;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<BlitzData>) {
    const data = snapshot.data();
    return data;
  },
};

export const internalPhoneNumberConverter: FirestoreDataConverter<InternalPhoneNumber> =
  {
    toFirestore(data) {
      return data;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot<InternalPhoneNumber>) {
      const data = snapshot.data();
      return data;
    },
  };

export const supportTicketConverter: FirestoreDataConverter<SupportTicket> = {
  toFirestore(data) {
    return data;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<SupportTicket>) {
    const data = snapshot.data();
    return data;
  },
};

export const eventConverter: FirestoreDataConverter<UserEvent> = {
  toFirestore(data: UserEvent) {
    return data;
  },

  fromFirestore(snapshot: QueryDocumentSnapshot<UserEvent>) {
    const data = snapshot.data();
    return data;
  },
};

export const userStateConverter: FirestoreDataConverter<UserState> = {
  toFirestore(data: UserState) {
    return data;
  },

  fromFirestore(snapshot: QueryDocumentSnapshot<UserState>) {
    const data = snapshot.data();
    return data;
  },
};

export const intermediateScoreConverter: FirestoreDataConverter<IntermediateScore> =
  {
    toFirestore(data: IntermediateScore) {
      return data;
    },

    fromFirestore(snapshot: QueryDocumentSnapshot<IntermediateScore>) {
      const data = snapshot.data();
      return data;
    },
  };
