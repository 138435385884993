import { Avatar, Menu, MenuProps, MenuTheme } from "antd";
import React, { FC, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "src/app/hooks";
import {
  BookOutlined,
  RocketOutlined,
  PlusOutlined,
  TrophyOutlined,
  LockOutlined,
  GroupOutlined,
} from "@ant-design/icons";
import { isTriumphEmployee } from "src/helpers";
import CreateGame from "src/components/Games/components/CreateGame/createGame";
import { useThemeSwitcher } from "src/components/theme/ThemeContextInterface";

interface Props {
  setShouldCollapse: React.Dispatch<React.SetStateAction<boolean>>;
}

const AppSideMenu: FC<Props> = ({ setShouldCollapse }) => {
  const navigate = useNavigate();

  const { listOfGames } = useAppSelector((state) => state.gameState);

  const [addingGame, setAddingGame] = useState(false);
  const [selectedMenuKey, setSelectedMenuKey] = useState<string[]>([]);

  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  const { currentTheme } = useThemeSwitcher();

  // org overview menu item key names
  const triumphInternalKey = "triumph-internal";
  const usersKey = "users";
  const userTrxKey = "userTransactions";
  const allGamesKey = "allGames";
  const newGameKey = "newGame";
  const gamesConfigKey = "game-config";
  const blitzKey = "tournaments-blitz";
  const asyncGroupKey = "async-group";
  const gamesMenuItems: MenuProps["items"] = [
    // Bal Trxs
    {
      key: userTrxKey,
      label: "User Transactions",
      icon: <BookOutlined style={{ fontSize: "20px" }} />,
    },
    // Games list
    {
      key: gamesConfigKey,
      label: "Games",
      icon: <RocketOutlined style={{ fontSize: "20px" }} />,
      children:
        listOfGames.length === 0
          ? [
              {
                label: "Add Game",
                key: newGameKey,
                onClick: () => {},
                icon: (
                  <Avatar
                    shape="square"
                    size="default"
                    icon={<PlusOutlined />}
                  />
                ),
              },
            ]
          : [
              {
                key: allGamesKey,
                label: "All Games",
              },
              ...listOfGames.map((e) => {
                return {
                  key: `game-config/${e.id}`,
                  label: e.name,
                };
              }),
              {
                label: "Add Game",
                key: newGameKey,
                onClick: () => {},
                icon: (
                  <Avatar
                    shape="square"
                    size="default"
                    icon={<PlusOutlined />}
                  />
                ),
              },
            ],
    },

    // Tournaments
    {
      key: "tournaments",
      label: "Tournaments",
      icon: <TrophyOutlined style={{ fontSize: "20px" }} />,
      children: [
        {
          key: blitzKey,
          label: "Blitz",
        },
        {
          key: asyncGroupKey,
          label: "Async Group",
        },
      ],
    },
  ];

  // organization menu item key names
  const orgKey = "org";
  const membersKey = "members";
  const balancesKey = "bank";
  const applePayKey = "apple-pay";
  const apiKeysKey = "api-keys";
  const appleCertsKey = "apple-keys";
  const orgOverviewKey = "organization";
  const platformAgreementKey = "platformAgreement";
  const organizationContractKey = "organizationContract";
  const orgTransactions = "orgTransactions";

  const orgMenuItems: MenuProps["items"] = [
    {
      key: orgOverviewKey,
      label: "Organization",
      icon: <GroupOutlined style={{ fontSize: "20px" }} />,
      children: [
        // Company
        {
          key: orgKey,
          label: "Settings",
        },
        // Members
        {
          key: membersKey,
          label: "Members",
        },
        // Balances
        {
          key: balancesKey,
          label: "Balances",
        },
        // Platform Agreement
        {
          key: platformAgreementKey,
          label: "Platform Agreement",
        },
        // API Keys
        {
          key: apiKeysKey,
          label: "API Keys",
        },
        // Apple Keys
        {
          key: appleCertsKey,
          label: "Apple Keys",
        },
        // Apple Pay
        {
          key: applePayKey,
          label: "Apple Pay",
        },
        // Developer Contract
        {
          key: organizationContractKey,
          label: "Contract",
        },
        // Org. Transactions
        {
          key: orgTransactions,
          label: "Organization Transactions",
        },
      ],
    },
  ];

  const internalGamesKey = "internalGames";
  const internalOrgsKey = "internalOrgs";
  const internalStatsKey = "internalStats";
  const internalMasterContractKey = "internalMasterContract";
  const internalPhoneNumbersKey = "internalPhoneNumbers";
  const internalAllBalanceTrxKey = "internalAllBalanceTrx";
  const offersKey = "offers";
  const supportKey = "support";
  const versionKey = "version";
  const reviewUserKey = "user-require-review";

  const adminMenuItems: MenuProps["items"] = [
    isTriumphEmployee()
      ? {
          key: triumphInternalKey,
          label: "Triumph Internal",
          icon: <LockOutlined style={{ fontSize: "20px" }} />,
          children: [
            {
              key: usersKey,
              label: "All Users",
            },
            {
              key: internalAllBalanceTrxKey,
              label: "All Balance Transactions",
            },
            {
              key: internalGamesKey,
              label: "Games",
            },
            { key: internalStatsKey, label: "Statistics" },
            { key: internalOrgsKey, label: "Organizations" },
            {
              key: internalMasterContractKey,
              label: "Master Contract",
            },
            {
              key: internalPhoneNumbersKey,
              label: "Phone Numbers",
            },
            {
              key: offersKey,
              label: "Offers",
            },
            // Support
            {
              key: supportKey,
              label: "Support",
            },
            // Versioning
            {
              key: versionKey,
              label: "Latest Versions",
            },
            {
              key: reviewUserKey,
              label: "User Require Review",
            },
          ],
        }
      : null,
  ];

  // navigate to appropriate component
  function handleSideMenuSelection(key: string) {
    let destination: string | null = null;
    const gameId = key.split("/").at(-1);

    if (key === userTrxKey) {
      destination = "/user_balance_transactions";
    } else if (key === allGamesKey) {
      destination = "/games/allGames/stats";
    } else if (key.includes(gamesConfigKey)) {
      destination = `/games/${gameId}/stats`;
    } else if (key === blitzKey) {
      destination = "/tournaments/blitz";
    } else if (key === asyncGroupKey) {
      destination = "/tournaments/async-group";
    } else if (key === orgKey) {
      destination = "/organization/settings";
    } else if (key === membersKey) {
      destination = "/organization/members";
    } else if (key === balancesKey) {
      destination = "/organization/balances";
    } else if (key === applePayKey) {
      destination = "/organization/apple-pay";
    } else if (key === appleCertsKey) {
      destination = "/organization/apple-keys";
    } else if (key === apiKeysKey) {
      destination = "/organization/api-keys";
    } else if (key === platformAgreementKey) {
      destination = "/organization/platform-agreement";
    } else if (key === organizationContractKey) {
      destination = "/organization/contract";
    } else if (key === orgTransactions) {
      destination = "/organization/org-balance-transactions";
    } else if (key === newGameKey) {
      /** this opens a modal only and doesn't immediately navigate to anywhere */
      setAddingGame(true);
    } else if (key === internalGamesKey) {
      destination = "/internal/games";
    } else if (key === internalOrgsKey) {
      destination = "/internal/orgs";
    } else if (key === internalStatsKey) {
      destination = "/internal/stats";
    } else if (key === internalMasterContractKey) {
      destination = "/internal/master_contract";
    } else if (key === internalPhoneNumbersKey) {
      destination = "/internal/internal_phone_numbers";
    } else if (key === usersKey) {
      destination = "/internal/users";
    } else if (key === offersKey) {
      destination = "/internal/offers";
    } else if (key === supportKey) {
      destination = "/internal/support";
    } else if (key === versionKey) {
      destination = "/internal/version";
    } else if (key === reviewUserKey) {
      destination = "/internal/user_require_review";
    } else if (key === internalAllBalanceTrxKey) {
      destination = "/internal/balance_transactions";
    }

    if (destination) {
      navigate(destination);
      setShouldCollapse(isMobile && true);
      setSelectedMenuKey([key]);
    }
  }

  return (
    <>
      <CreateGame open={addingGame} onClose={() => setAddingGame(false)} />
      <Menu
        className="side-menu text-disable-button"
        mode="inline"
        theme={currentTheme as MenuTheme}
        items={[
          ...gamesMenuItems,
          ...orgMenuItems,
          ...(isTriumphEmployee() ? adminMenuItems : []),
        ]}
        selectedKeys={selectedMenuKey}
        onClick={(e) => {
          handleSideMenuSelection(e.key);
        }}
      ></Menu>
    </>
  );
};

export default AppSideMenu;
