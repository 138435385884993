import { Table, TableColumnsType, TablePaginationConfig } from "antd";
import { FC } from "react";
import { Link, useParams } from "react-router-dom";
import { useAppSelector } from "src/app/hooks";
import { gameSelect } from "src/features/games/gamesSlice";

export type UserTournament = {
  tournamentId: string;
  type: "blitz" | "async-group";
  url: string;
};
type Props = { data: UserTournament[] };
const UserTournaments: FC<Props> = ({ data }) => {
  const { id } = useParams();
  const columns: TableColumnsType<{
    tournamentId: string;
    type: "blitz" | "async-group";
    url: string;
  }> = [
    {
      title: "Tournament ID",
      dataIndex: "tournamentId",
      key: "tournamentId",
      render: (value, record) => (
        <Link className="ant-typography" to={record.url}>
          {value}
        </Link>
      ),
    },
    {
      title: "Tournament Category",
      dataIndex: "type",
      key: "type",
    },
  ];
  const { loading } = useAppSelector(gameSelect);
  const pageinationConfig: TablePaginationConfig = {
    defaultPageSize: 50,
    className: "normal-option-size",
  };
  return (
    <>
      <Table
        columns={columns}
        loading={loading}
        rowKey="tournamentId"
        dataSource={id ? [...data] : []}
        pagination={pageinationConfig}
        className="mobile-table-small bottom"
      />
    </>
  );
};

export default UserTournaments;
