import {
  Button,
  Modal,
  Switch,
  Table,
  TableColumnsType,
  TablePaginationConfig,
} from "antd";
import { collection, doc, onSnapshot, Unsubscribe } from "firebase/firestore";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DepoitDefinitionConverter } from "src/converters";
import { axiosInstance, db } from "src/helpers";
import { EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import Currency from "src/shared/components/currency/Currency";
import DepositConfigForm from "./DepositConfigForm";
import TriumphPage from "src/shared/layout/TriumphPage";
import { dateFormatter } from "src/shared/config/constants";

interface Props {
  isAdmin: boolean;
}

const DepositConfigList: FC<Props> = ({ isAdmin }) => {
  const { game } = useParams();
  const [depositConfigs, setDepositConfigs] = useState<{
    [x: string]: DepositDefinition;
  } | null>(null);
  const navigate = useNavigate();

  const [selectedConfig, setSelectedConfig] =
    useState<DepositDefinition | null>(null);

  useEffect(() => {
    let unSubDepositConfig: Unsubscribe;
    if (game) {
      const gamesRef = collection(db, "games");

      const gamesDoc = doc(gamesRef, game);

      const depositConfigRef = collection(
        gamesDoc,
        "depositDefinitions"
      ).withConverter(DepoitDefinitionConverter);

      unSubDepositConfig = onSnapshot(depositConfigRef, (colSnap) => {
        const config: typeof depositConfigs = {};
        for (const snap of colSnap.docs) {
          const uid = snap.id;
          const data = snap.data();
          config[uid] = { ...data, uid };
        }
        setDepositConfigs(config);
      });
    }
    return () => {
      unSubDepositConfig?.();
    };
  }, [game]);

  const paginationConfig: TablePaginationConfig = {
    pageSize: 50,
    className: "mobile-pagination-small",
  };

  async function onArchive(data: DepositDefinition) {
    try {
      await axiosInstance.post<{ success: string }>(
        `/internal/games/${game}/deposit_definitions/${data.uid}`,
        { archived: !data.archived }
      );
    } catch (error) {
      console.error(error);
    }
  }

  const columns: TableColumnsType<DepositDefinition> = [
    {
      title: "UID",
      dataIndex: "uid",
      key: "uid",
    },
    {
      title: "Deposit Amount",
      dataIndex: "depositAmount",
      key: "depositAmount",
      render: (v) => (
        <Currency value={v} currency="cents-to-dollar" prefix="$" />
      ),
    },
    {
      title: "Bonus Cash",
      dataIndex: "bonusCash",
      key: "bonusCash",
      render: (v) => (
        <Currency value={v} currency="cents-to-dollar" prefix="$" />
      ),
    },
    {
      title: "is Best Value",
      dataIndex: "isBestValue",
      key: "isBestValue",
      render: (v) => (v ? "Yes" : "No"),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      defaultSortOrder: "descend",
      sorter: (a, b) => (a.createdAt ?? 0) - (a.createdAt ?? 0),
      render: (a) => dateFormatter(a),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: (a, b) => (a.createdAt ?? 0) - (a.createdAt ?? 0),
      render: (a) => dateFormatter(a),
    },
    {
      title: "Archive",
      dataIndex: "archived",
      key: "archived",
      render: (value, record) => (
        <Switch
          checked={value}
          disabled={!isAdmin}
          onChange={() =>
            Modal.confirm({
              title: `Confirm ${value ? "Un-Archive" : "Archive"} ?`,
              icon: <ExclamationCircleOutlined />,
              content: `By clicking confirm the record will be ${
                value ? "un-archived" : "archived"
              }.`,
              okText: "Confirm",
              cancelText: "Back",
              onOk: () => onArchive(record),
              okCancel: true,
            })
          }
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 100,
      render: (_, record) => (
        <>
          <Button
            onClick={() => setSelectedConfig(record)}
            icon={<EditOutlined style={{ fontSize: 24 }} />}
          />
        </>
      ),
    },
  ];

  const adminKeys: string[] = [];

  return (
    <TriumphPage>
      {isAdmin ? (
        <Button type="primary" onClick={() => navigate("/internal/games")}>
          Return to Games List
        </Button>
      ) : (
        <></>
      )}{" "}
      <DepositConfigForm
        config={selectedConfig}
        isAdmin={isAdmin}
        onClose={() => setSelectedConfig(null)}
      />
      <Table
        columns={columns.filter((item) =>
          !isAdmin
            ? typeof item.key === "string" && !adminKeys.includes(item.key)
            : true
        )}
        loading={depositConfigs === null}
        rowKey="uid"
        dataSource={Object.values(depositConfigs || {})}
        pagination={paginationConfig}
        className="mobile-table-small"
      />
    </TriumphPage>
  );
};

export default DepositConfigList;
