import { Descriptions, Spin } from "antd";
import { FC, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import {
  adminUserSelect,
  getAdminUserById,
} from "src/features/adminUsers/AdminUsersSlice";
import Currency from "src/shared/components/currency/Currency";
import { dateFormatter } from "src/shared/config/constants";

interface Props {
  data: OrgDepositTrx;
}
const OrgDeposit: FC<Props> = ({ data }) => {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  const { users, isFetchingUserById } = useAppSelector(adminUserSelect);

  useEffect(() => {
    dispatch(getAdminUserById(data.adminUserUid));
  }, [dispatch, data]);

  return (
    <>
      <Descriptions
        layout={!isMobile ? "horizontal" : "vertical"}
        column={1}
        bordered
        title="Personal Details"
        className="pb-2"
      >
        <Descriptions.Item label="UID">{data.uid}</Descriptions.Item>
        <Descriptions.Item label="Admin User Uid">
          {data.adminUserUid}
        </Descriptions.Item>
        <Descriptions.Item label="Admin User">
          {isFetchingUserById ? (
            <Spin />
          ) : (
            users && users[data.adminUserUid]?.displayName
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Amount">
          <Currency value={data.amount} prefix="$" currency="cents-to-dollar" />{" "}
        </Descriptions.Item>
        <Descriptions.Item label="Promo Amount">
          <Currency
            value={data.promoAmount}
            prefix="$"
            currency="cents-to-dollar"
          />
        </Descriptions.Item>
        <Descriptions.Item label="Triumph Amount">
          <Currency
            value={data.triumphAmount}
            prefix="$"
            currency="cents-to-dollar"
          />
        </Descriptions.Item>
        <Descriptions.Item label="Org Id">{data.orgId}</Descriptions.Item>
        <Descriptions.Item label="Trace Id">{data.traceId}</Descriptions.Item>

        <Descriptions.Item label="Type">{data.type}</Descriptions.Item>
        <Descriptions.Item label="Description">
          {data.description}
        </Descriptions.Item>
        <Descriptions.Item label="Created At">
          {dateFormatter(data.createdAt)}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default OrgDeposit;
