import {FC} from "react";
import {ConfigureOfferDataType} from "./OffersEditableList";
import {Button, Modal, Typography} from "antd";
import {getCdnUrl} from "src/helpers";
import {CloseCircleFilled} from "@ant-design/icons";

type Props = {
    data: ConfigureOfferDataType | null;
    onClose: () => void;
};
const OfferPreview: FC<Props> = ({data, onClose}) => {
    return (
        <>
            <Modal
                title="Preview Offer"
                centered
                open={!!data}
                onOk={onClose}
                onCancel={onClose}
                width={"70vw"}
            >
                {data && (
                    <div className="preview-offer-wrap">
                        <div className="preview">
                            <div className="close">
                                <CloseCircleFilled
                                    style={{fontSize: "22px", opacity: "20%"}}
                                />
                            </div>
                            <div
                                className="p-wrap"
                                style={{
                                    backgroundImage: `url(${getCdnUrl()}${data.imagePath})`,
                                    backgroundSize: "contain",
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: 10,
                                }}
                            >
                                <Typography.Title
                                    style={{
                                        color: data.color,
                                        fontFamily: "-apple-system",
                                        fontSize: 9,
                                        letterSpacing: "-0.08em",
                                        maxWidth: "80%",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {data.tag}
                                </Typography.Title>
                                <div className="heading">
                                    <Typography.Title
                                        style={{
                                            fontFamily: "-apple-system",
                                            maxWidth: "85%",
                                            justifySelf: "center",
                                            lineHeight: "24px",
                                        }}
                                        level={4}
                                    >
                                        {data.heading}
                                    </Typography.Title>
                                </div>
                                <div
                                    className="amount-wrap"
                                    style={
                                        data.purchasePrice === 0
                                            ? {alignSelf: "center"}
                                            : {alignSelf: "start"}
                                    }
                                >
                                    {(data.purchasePrice ?? 0) > 0 && (
                                        <>
                                            <div className="amount-box">
                                                <p
                                                    style={{
                                                        color: data.color,
                                                        fontWeight: 500,
                                                        fontSize: 22,
                                                        marginTop: 4,
                                                        marginBottom: -8,
                                                        minWidth: "75px",
                                                    }}
                                                >
                                                    ${data.purchasePrice}
                                                </p>
                                                <p className="cash-label">Deposit</p>
                                            </div>
                                            <div
                                                style={{
                                                    fontFamily: "-apple-system",
                                                    fontSize: "16px",
                                                    fontWeight: "bolder",
                                                }}
                                            >
                                                <Typography.Title
                                                    style={{
                                                        color: "white",
                                                        fontFamily: "-apple-system",
                                                        fontSize: 24,
                                                        fontWeight: "lighter",
                                                        paddingLeft: 5,
                                                        paddingRight: 5,
                                                        width: "40px"
                                                    }}
                                                >
                                                    +
                                                </Typography.Title>
                                            </div>
                                        </>
                                    )}

                                    <div className="amount-box">
                                        <p
                                            style={{
                                                color: data.color,
                                                fontWeight: 500,
                                                fontSize: 22,
                                                marginTop: 4,
                                                marginBottom: -8,
                                                minWidth: "100px",
                                            }}
                                        >
                                            ${data.bonusCashReward}
                                        </p>
                                        <p className="cash-label">Bonus Cash</p>
                                    </div>
                                </div>
                                <Button
                                    style={{

                                        height: "40px",
                                        backgroundColor: data.color,
                                        color: "black",
                                        borderRadius: "6px",
                                        border: "none",
                                    }}
                                >
                                    <Typography.Title
                                        style={{
                                            color: "black",
                                            fontFamily: "-apple-system",
                                            fontSize: 13,
                                            letterSpacing: "-0.05em",
                                            fontWeight: "600",
                                            marginBottom: 0,
                                        }}
                                    >
                                        {data.purchasePrice === 0 ? "Claim Now" : "Deposit Now"}
                                    </Typography.Title>
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </Modal>
        </>
    );
};

export default OfferPreview;
