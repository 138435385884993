import { FC } from "react";
import { BannerDataType } from "./BannerEditableList";
import { Modal, Row, Typography } from "antd";
import { getCdnUrl } from "src/helpers";

type Props = {
  data: BannerDataType | null;
  onClose: () => void;
};

const { Text } = Typography;

const BannerPreview: FC<Props> = ({ data, onClose }) => {
  if (!data) return null;

  /** PREVIEW banner element */
  const banner = (
    <div className="preview-banner-wrap">
      <div className="preview">
        <div className="close"></div>
        <div
          className="banner-container"
          style={{ backgroundColor: data.backgroundColor }}
        >
          <Row
            className="banner-title-container"
            justify="center"
            style={{ width: "100%" }}
          >
            <Text className="banner-title">{data.title}</Text>
          </Row>
          <Row className="banner-subtitle-container" justify="center">
            <Text className="banner-subtitle">{data.subtitle}</Text>
          </Row>

          <Row
            align="middle"
            justify="center"
            className="banner-button-body"
            style={{ backgroundColor: data.buttonBackgroundColor }}
          >
            <div style={{ width: 24, height: 24, marginRight: 8 }}>
              <img
                src={`${getCdnUrl()}${data.buttonIcon}`}
                alt=""
                className="banner-button-icon"
              />
            </div>
            <div
              className="banner-button-text"
              style={{ color: data.buttonTextColor }}
            >
              {data.buttonText}
            </div>
          </Row>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Modal
        title="Preview Banner"
        className="modalStyle"
        centered
        open={!!data}
        onOk={onClose}
        onCancel={onClose}
        destroyOnClose={true}
        width={"200px"}
        modalRender={() => banner}
      />
    </>
  );
};

export default BannerPreview;
