import { FC } from "react";
import TriumphPage from "src/shared/layout/TriumphPage";

const ComplianceQuestionnaire: FC = () => {
  return (
    <TriumphPage>
      <iframe
        title="Compliance Questionnaire"
        style={{ width: "99%", height: "75vh", border: "none" }}
        src="https://forms.zohopublic.com/jamin3/form/GAMECOMPLIANCEQUESTIONNAIRE/formperma/G4KjAadhPd-AtjXzVWHlqvYko1_RjtYQN5-BgtShmNw?zf_rszfm=1"
      />
    </TriumphPage>
  );
};

export default ComplianceQuestionnaire;
