import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  message,
  Select,
  DatePicker,
} from "antd";
import { FC, useEffect, useMemo, useState } from "react";
import UploadImage from "src/shared/components/UploadV2";
import ColorPicker from "src/shared/components/colorPicker";
import { formSubmit } from "src/shared/config/constants";
import {
  CheckCircleOutlined,
  MinusCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { axiosInstance, getCdnUrl } from "src/helpers";
import OverlayLoader from "src/shared/components/overlayLoader/overlayLoader";
import { useParams } from "react-router-dom";
import moment, { Moment } from "moment-timezone";
import DurationInput from "./DurationInput";

export type ConfigureOfferDataType = ConfigureOffer & {
  isNew?: boolean;
  newId?: number;
};
type Props = {
  data: ConfigureOfferDataType;
  onRemove: (x: ConfigureOfferDataType) => void;
  setActive: (item: string | null) => void;
  isActive: boolean;
  onView: (data: ConfigureOfferDataType) => void;
  index: number;
};
const OffersEditableList: FC<Props> = ({
  data,
  onRemove,
  setActive,
  isActive,
  onView,
  index,
}) => {
  const [form] = Form.useForm();
  const [isTouched, setIsTouched] = useState<{
    [x: string]: boolean;
  }>({});
  const [isLoading, setIsLoading] = useState(false);
  const { game } = useParams();

  function onChange(
    name: keyof ConfigureOffer,
    value: string | number | null | Moment
  ) {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      [name]: value,
    });
    setIsTouched({
      ...isTouched,
      [name]: form.getFieldValue(name) !== data[name],
    });
  }
  const handleSubmit = formSubmit<ConfigureOffer>(form);

  const onSave = async (values: ConfigureOfferDataType) => {
    try {
      setIsLoading(true);

      if (data.isNew) {
        await axiosInstance.post<{ success: string }>(
          `admin/games/${game}/offer`,
          {
            ...values,
            expiration: values.expiration
              ? moment(values.expiration).toISOString(true)
              : "",
          }
        );
        message.success("Offer created successfully");
        setActive(null);
      } else {
        console.log(`data: ${JSON.stringify(data)}`);
        console.log(
          `Sending ${JSON.stringify({
            ...values,
            uid: data.uid,
          })}`
        );
        await axiosInstance.post<{ success: string }>(
          `admin/games/${game}/offer/update`,
          {
            ...values,
            expiration: values.expiration
              ? moment(values.expiration).toISOString(true)
              : "",
            uid: data.uid,
          }
        );
        message.success("Offer updated successfully");
        setActive(null);
      }
    } catch (e) {
      message.error("Failed to save data");

      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };
  const itemId = data.uid || data.newId;

  const isAnyValueChanged = useMemo(() => {
    if (data.isNew) return true;
    if (Object.keys(isTouched).length === 0) return false;
    if (Object.values(isTouched).findIndex((f) => f === true) >= 0) return true;
  }, [data, isTouched]);

  useEffect(() => {
    setActive(isAnyValueChanged ? `${itemId}` : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnyValueChanged]);

  const dataType = form.getFieldValue("type") || data.type;

  return (
    <div key={`${itemId}-${index}`}>
      {isLoading && <OverlayLoader />}

      <Form
        className="w-100"
        name="Configure Offer Form"
        form={form}
        autoComplete="off"
      >
        <Row justify="space-between">
          <Col span={1}>
            <div className="configure-offer-icons">
              <Button
                disabled={!isActive}
                type="link"
                onClick={() => handleSubmit(onSave)}
                className="save-offer"
              >
                <CheckCircleOutlined />
              </Button>
              <Button
                disabled={!isActive}
                type="link"
                onClick={() => onRemove(data)}
                className="delete-offer"
              >
                <MinusCircleOutlined />
              </Button>
              <Button
                type="link"
                onClick={() => onView(form.getFieldsValue())}
                className="view-offer"
              >
                <EyeOutlined />
              </Button>
            </div>
          </Col>
          <Col span={1}>
            {" "}
            <Form.Item
              name="imagePath"
              initialValue={data.imagePath}
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <UploadImage
                name={`${itemId}-imagePath`}
                disabled={!isActive}
                hideLabel
                hideUploadLabel
                id="imagePath"
                path={`games/${game}/offers`}
                url={
                  form.getFieldValue("imagePath")
                    ? `${getCdnUrl()}${form.getFieldValue("imagePath")}`
                    : ""
                }
                className="offer-image"
                onUpload={(imgData) => {
                  onChange("imagePath", imgData);
                }}
                onRemoveImg={() => {
                  onChange("imagePath", "");
                }}
              />
            </Form.Item>
          </Col>
          <Col span={1}>
            <Form.Item
              name="color"
              initialValue={data.color}
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <ColorPicker
                title="Tag Color"
                height={50}
                value={form.getFieldValue("color")}
                onChange={(e) => onChange("color", e)}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            {" "}
            <Form.Item
              className="offer-editable-field"
              name="heading"
              initialValue={data.heading}
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input.TextArea
                className="offer-editable-field"
                disabled={!isActive}
                showCount
                maxLength={30}
                onChange={(e) => onChange("heading", e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              name="tag"
              initialValue={data.tag}
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input
                className="offer-editable-field"
                disabled={!isActive}
                showCount
                maxLength={20}
                onChange={(e) => onChange("tag", e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={1.5}>
            {" "}
            <Form.Item
              className="offer-editable-field"
              name="purchasePrice"
              initialValue={data.purchasePrice}
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <InputNumber
                disabled={!isActive}
                type="number"
                min={0}
                onChange={(e) => onChange("purchasePrice", e)}
              />
            </Form.Item>
          </Col>
          <Col span={1.5}>
            <Form.Item
              className="offer-editable-field"
              name="bonusCashReward"
              initialValue={data.bonusCashReward}
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <InputNumber
                disabled={!isActive}
                type="number"
                min={0}
                onChange={(e) => onChange("bonusCashReward", e)}
              />
            </Form.Item>
          </Col>
          <Col span={1.5}>
            <Form.Item
              className="offer-editable-field"
              name="gemsReward"
              initialValue={data.gemsReward}
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <InputNumber
                disabled={!isActive}
                type="number"
                min={0}
                onChange={(e) => onChange("gemsReward", e)}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item
              initialValue={data.type || "all"}
              name="type"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Select
                className="normal-select"
                size="middle"
                onChange={(value) => onChange("type", value)}
              >
                <Select.Option value="all">All</Select.Option>
                <Select.Option value="filtered">New users</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={2}>
            {dataType === "all" ? (
              <Form.Item
                className="offer-editable-field expiration-editable"
                name="expiration"
                label="Expiration"
                initialValue={moment(data.expiration)}
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <DatePicker
                  showTime
                  format={""}
                  onChange={(e) => {
                    onChange("expiration", e);
                  }}
                  style={{ width: 200, height: 50 }}
                  disabled={!isActive}
                />
              </Form.Item>
            ) : (
              <>
                <Form.Item
                  className="offer-editable-field"
                  name="duration"
                  // label="Duration"
                  initialValue={data.duration}
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                >
                  <DurationInput
                    disabled={!isActive}
                    defaultValue={data.duration}
                    onChange={(e, v) => onChange("duration", e)}
                  />
                </Form.Item>
              </>
            )}
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default OffersEditableList;
