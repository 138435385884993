import {
  createSlice,
  createAsyncThunk,
  SerializedError,
} from "@reduxjs/toolkit";
import { getDocs, query, where, documentId } from "firebase/firestore";
import { colNames, getCollection } from "src/collections";
/** type imports */
import type { RootState } from "src/app/store";

interface StatisticsState {
  statistics: { [id: string]: Statistic } | null;
  loading: "idle" | "pending";
  currentRequestId: string | undefined;
  error: null | SerializedError;
  referralsGraph: { [referralUid: string]: string[] } | null;
}

const initialState: StatisticsState = {
  statistics: null,
  loading: "idle",
  currentRequestId: undefined,
  error: null,
  referralsGraph: null,
};

export const fetchStats = createAsyncThunk<
  StatisticsState["statistics"],
  void,
  { state: RootState }
>("stats/fetchStats", async (payload, { getState, requestId }) => {
  const { loading, currentRequestId } = getState().statsState;
  const { orgs } = getState().orgState;

  const { user } = getState().userState;
  if (!user) {
    throw new Error("Oops! You're not authenticated!");
  }
  if (loading !== "pending" || requestId !== currentRequestId) {
    return null;
  }

  if (!user.activeOrgId) {
    return null;
  }
  const activeOrg = orgs[user.activeOrgId];
  const gameIds = activeOrg.gameIds;

  const statsColRef = getCollection(colNames.statistics);
  const q = query(statsColRef, where(documentId(), "in", gameIds));
  const querySnapshot = await getDocs(q);
  const stats: StatisticsState["statistics"] = {};
  for (const doc of querySnapshot.docs) {
    const id = doc.id;
    const stat = doc.data();
    stats[id] = stat;
  }
  return stats;
});

const statsSlice = createSlice({
  name: "stats",
  initialState,
  reducers: {
    clearStats(state) {
      state.statistics = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStats.pending, (state, action) => {
      const {
        meta: { requestId },
      } = action;

      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = requestId;
      }
    });
    builder.addCase(fetchStats.fulfilled, (state, action) => {
      const {
        meta: { requestId },
        payload,
      } = action;

      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.statistics = payload;
        state.currentRequestId = undefined;
        state.loading = "idle";
        state.error = null;
      }
    });
    builder.addCase(fetchStats.rejected, (state, action) => {
      const {
        meta: { requestId },
        error,
      } = action;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = error;
        state.currentRequestId = undefined;
      }
    });
  },
});

export const { clearStats } = statsSlice.actions;
export const statisticSelect = (state: RootState) => state.statsState;
export default statsSlice.reducer;
