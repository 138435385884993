import { Button, Form, InputNumber, Modal, Row, Tag } from "antd";
import { FC, useState } from "react";
import { axiosInstance } from "src/helpers";
import { validateNumericValue } from "src/shared/config/constants";
interface Props {
  org: Organization;
}

const InitiateTransfer: FC<Props> = ({ org }) => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function onClose() {
    form.resetFields();
    setOpen(!open);
    setError("");
    setIsLoading(false);
  }

  function onOpen() {
    setOpen(true);
  }

  function onChange(name: string, value: string | number | null) {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      [name]: value,
    });
  }

  async function onSubmit() {
    setError("");
    const { withdrawalAmount } = form.getFieldsValue();
    if (
      withdrawalAmount === undefined ||
      withdrawalAmount === null ||
      withdrawalAmount === "" ||
      withdrawalAmount === 0
    ) {
      setError(
        "Please enter valid amount that you want to withdraw from your current balance."
      );
      return;
    }

    if (withdrawalAmount < 0) {
      setError("Cannot submit negative numbers.");
      return;
    }
    if (withdrawalAmount > org.balance) {
      setError(
        "Withdrawal amount should be less than or equal to your current balance."
      );
      return;
    }

    setIsLoading(true);
    try {
      await axiosInstance.post(
        `/admin/orgs/${org.uid}/dwolla_initiate_withdrawal`,
        {
          withdrawalAmount: withdrawalAmount * 100,
        }
      );
      onClose();
    } catch (error) {
      setError(error instanceof Error ? error.message : "Unknown Error");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Button onClick={onOpen}>Initiate transfer</Button>
      <Modal
        title="Initiate Transfer"
        centered
        open={open}
        onCancel={onClose}
        onOk={onSubmit}
        destroyOnClose
        confirmLoading={isLoading}
      >
        <>
          <Form
            name="withdrawalAmount"
            className="spaced-form "
            form={form}
            onFinish={onSubmit}
            autoComplete="off"
          >
            <Row>
              <Form.Item
                className="app-config-input-wrap w-100"
                label="Withdrawal Amount"
                name="withdrawalAmount"
                rules={[
                  {
                    required: true,
                    message: "Withdrawal amount is required",
                    validator: validateNumericValue,
                  },
                ]}
              >
                <InputNumber
                  name="withdrawalAmount"
                  size="middle"
                  type="number"
                  prefix="$"
                  addonAfter="USD"
                  className="w-100"
                  onChange={(value) => onChange("withdrawalAmount", value)}
                />
              </Form.Item>
            </Row>
            {error && (
              <Row>
                <Tag color="orange">{error}</Tag>
              </Row>
            )}
          </Form>
        </>
      </Modal>
    </>
  );
};

export default InitiateTransfer;
