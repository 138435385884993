import { FC, useEffect } from "react";
import { Modal } from "antd";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import StartBlitzTournamentV2 from "./StartBlitzTournamentV2";
import FinishBlitzTournamentV2 from "./FinishBlitzTournamentV2";
import RefereeBonusTransaction from "./RefereeBonusTransaction";
import StartGroupTournament from "./StartGroupTournament";
import FinishGroupTournament from "./FinishGroupTournament";
import NewGame from "./NewGame";
import AccountCreationDeposit from "./AccountCreationDeposit";
import TriumphDeposit from "./TriumphDeposit";
import DepositTransaction from "./Deposit";
import WithdrawalTransaction from "./WithDrawal";
import WithdrawalRedepositTransaction from "./WithdrawalRedeposit";
import {
  appUserPublicSelect,
  getAppUserPublicDetails,
} from "src/features/appUsersPublic/appUserPublicSlice";
// import { useMediaQuery } from "react-responsive";

interface Props {
  selectedRow: BalanceTransaction | null;
  onClose: () => void;
}

const UserBalanceTransactionDetails: FC<Props> = ({ selectedRow, onClose }) => {
  const { usersPublic } = useAppSelector(appUserPublicSelect);
  const dispatch = useAppDispatch();
  // var isMobile = useMediaQuery({ query: "(max-width: 800px)" });

  const appUserId = selectedRow?.appUserUid;
  const selectedUser = appUserId ? usersPublic[appUserId] : null;
  useEffect(() => {
    if (appUserId && !selectedUser) {
      dispatch(getAppUserPublicDetails(appUserId));
    }
  }, [selectedUser, appUserId, dispatch]);

  const RenderModalContent: FC = () => {
    if (!selectedUser || !selectedRow) return <></>;
    switch (selectedRow.type) {
      case "start-blitz":
        if (selectedRow.version === "2" || selectedRow.version === "2.4") {
          return (
            <StartBlitzTournamentV2 row={selectedRow} user={selectedUser} />
          );
        } else {
          return <></>;
        }
      case "finish-blitz":
        if (selectedRow.version === "2" || selectedRow.version === "2.4") {
          return (
            <FinishBlitzTournamentV2 row={selectedRow} user={selectedUser} />
          );
        } else {
          return <></>;
        }

      case "referrer-bonus":
        return (
          <RefereeBonusTransaction row={selectedRow} user={selectedUser} />
        );
      case "start-group-tournament":
        return <StartGroupTournament row={selectedRow} user={selectedUser} />;
      case "finish-group-tournament":
        return <FinishGroupTournament row={selectedRow} user={selectedUser} />;
      case "new-game":
        return <NewGame row={selectedRow} user={selectedUser} />;
      case "account-creation-deposit":
        return <AccountCreationDeposit row={selectedRow} user={selectedUser} />;
      case "triumph-deposit":
        return <TriumphDeposit row={selectedRow} user={selectedUser} />;
      case "deposit":
        return <DepositTransaction row={selectedRow} user={selectedUser} />;
      case "withdrawal":
        return <WithdrawalTransaction row={selectedRow} user={selectedUser} />;
      case "withdrawal-redeposit":
        return (
          <WithdrawalRedepositTransaction
            row={selectedRow}
            user={selectedUser}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <Modal
      title="Balance Transaction Details"
      centered
      open={!!selectedRow}
      onOk={onClose}
      onCancel={onClose}
      width={1000}
    >
      <RenderModalContent />
    </Modal>
  );
};

export default UserBalanceTransactionDetails;
