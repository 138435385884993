import { Card, Col, Row, Steps, Typography } from "antd";
import { collection, doc, onSnapshot, Unsubscribe } from "firebase/firestore";
import { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { orgConverter } from "src/converters";
import { getGames } from "src/features/games/gamesSlice";
import {
  clearStats,
  fetchStats,
} from "src/features/statistics/statisticsSlice";
import { db, generateFirestorePath } from "src/helpers";
import TriumphPage from "src/shared/layout/TriumphPage";
import DownloadCsr from "./components/downloadCsr";
import MerchantIdForm from "./components/merchantId";
import UploadCertificate from "./components/uploadCert";

const ApplePay: FC = () => {
  const dispatch = useAppDispatch();
  const { orgs } = useAppSelector((state) => state.orgState);
  const { user } = useAppSelector((state) => state.userState);
  const [merchantId, setMerchantId] =
    useState<Organization["merchantId"]>(null);

  const [current, setCurrent] = useState(0);

  // fetch other org info (games / stats) for this user
  useEffect(() => {
    const fetchOrgData = async () => {
      if (orgs) {
        await Promise.all([dispatch(getGames()), dispatch(fetchStats())]);
      } else {
        dispatch(clearStats());
      }
    };
    fetchOrgData();
  }, [dispatch, orgs]);

  useEffect(() => {
    let unsubOrganizationSnapshot: Unsubscribe;
    if (user && user.activeOrgId) {
      const organizationRef = collection(
        db,
        generateFirestorePath("organizations")
      ).withConverter(orgConverter);

      const orgDoc = doc(organizationRef, user.activeOrgId);
      unsubOrganizationSnapshot = onSnapshot(orgDoc, (orgsSnap) => {
        const data = orgsSnap.data();

        setMerchantId(data?.merchantId ?? null);
        if (data?.merchantId || data?.applePayStatus === "csr-generated") {
          setCurrent(1);
        }
        if (data?.applePayStatus === "cer-uploaded") {
          setCurrent(3);
        }
      });
    }
    return () => {
      unsubOrganizationSnapshot?.();
    };
  }, [user]);

  const { Text } = Typography;

  const applePayCardTitle = (
    <>
      <Row style={{ width: "100%" }} justify="space-between" align="middle">
        <Text>Apple Pay</Text>
      </Row>
    </>
  );

  return (
    <TriumphPage>
      <Row justify="center">
        <Col span={18}>
          <Card
            title={applePayCardTitle}
            headStyle={{ borderWidth: 0, fontSize: 28 }}
            bordered={false}
            style={{
              width: "100%",
            }}
          >
            <Steps
              responsive
              labelPlacement="vertical"
              current={current}
              onChange={(curr) => setCurrent(curr)}
            >
              <Steps.Step
                disabled={current !== 0}
                className="merchant-id-step"
                title="Merchant ID"
                description={merchantId ?? ""}
              />
              <Steps.Step
                disabled={current !== 1}
                title="Download certificate signing request"
              />
              <Steps.Step disabled={current !== 2} title="Upload Certificate" />
              <Steps.Step disabled={current !== 3} title="Complete" />
            </Steps>
            <div className="pt-3">
              {current === 0 && <MerchantIdForm onNext={() => setCurrent(1)} />}
              {current === 1 && <DownloadCsr onNext={() => setCurrent(2)} />}
              {current === 2 && (
                <UploadCertificate onNext={() => setCurrent(3)} />
              )}
              {current === 3 && <Row justify="center">Process Completed</Row>}
            </div>
          </Card>
        </Col>
      </Row>
    </TriumphPage>
  );
};

export default ApplePay;
