import { collection, doc, onSnapshot, query, where } from "firebase/firestore";
import { FC, useEffect, useMemo, useState } from "react";
import { intermediateScoreConverter } from "src/converters";
import { db, generateFirestorePath, getRandomColor } from "src/helpers";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title as ChartTitle,
  Tooltip,
  Legend,
  TooltipItem,
} from "chart.js";
import "chart.js/auto";
import { Line } from "react-chartjs-2";
import { Divider } from "antd";

import { useAppSelector } from "src/app/hooks";
import { appUserPublicSelect } from "src/features/appUsersPublic/appUserPublicSlice";
import dayjs from "dayjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ChartTitle,
  Tooltip,
  Legend
);

const STEP_SIZE = 3;
const getOptions = (maxTicksLimit: number) => ({
  responsive: true,
  plugins: {
    title: {
      display: true,
      text: "Intermediate Scores",
    },
    tooltip: {
      callbacks: {
        footer: (items: TooltipItem<"line">[]) =>
          `Sequence ID: ${items
            .map(
              (item) =>
                `(${item.dataset?.label}=${
                  (item.raw as { sequenceId: number }).sequenceId
                })`
            )
            .join(", ")}`,
      },
    },
  },
  interaction: {
    intersect: false,
  },
  parsing: {
    xAxisKey: "time",
    yAxisKey: "score",
  },
  // scaleShowValues: true,
  scales: {
    xAxis: {
      display: true,
      title: {
        display: true,
        text: "Time Elapsed (seconds)",
      },
      ticks: {
        autoSkip: true,
        source: "data",
        maxTicksLimit,
        stepSize: STEP_SIZE,
      },
    },
    yAxis: {
      display: true,
      title: {
        display: true,
        text: "Score",
      },
    },
  },
});

interface Props {
  tournamentId: string;
  gameId: string;
  orgId: string;
}

const IntermediateScoreGraph: FC<Props> = ({ tournamentId, gameId, orgId }) => {
  const [intermediateScore, setIntermediateScore] = useState<{
    [x: string]: Array<IntermediateScore>;
  }>({});

  const { usersPublic } = useAppSelector(appUserPublicSelect);

  useEffect(() => {
    const gamesRef = doc(
      collection(db, generateFirestorePath("games")),
      gameId
    );

    const intermediateColRef = collection(
      gamesRef,
      "intermediateScores"
    ).withConverter(intermediateScoreConverter);

    const intermediateQueryRef = query(
      intermediateColRef,
      where("orgId", "==", orgId),
      where("tournamentId", "==", tournamentId)
    );

    const unsubSnapshot = onSnapshot(intermediateQueryRef, (scoreSnap) => {
      const scoreDef: typeof intermediateScore = {};
      for (const scoreDefSnap of scoreSnap.docs) {
        const data = scoreDefSnap.data();
        const prev = scoreDef[data.appUserUid];

        scoreDef[data.appUserUid] = [...(prev || []), data];
      }

      setIntermediateScore(scoreDef);
    });

    return () => {
      unsubSnapshot();
    };
  }, [gameId, tournamentId, orgId]);

  const dataSet = useMemo(() => {
    let largestDataSet = 0;

    const sorted = Object.values(intermediateScore)
      .reduce((prev, curr) => {
        if (curr.length > largestDataSet) {
          largestDataSet = curr.length;
        }
        return [...prev, ...curr];
      }, [])
      .sort((a, b) => a.createdAt - b.createdAt);

    const lastTimeStamp = sorted[sorted.length - 1]?.createdAt;
    const fistTimeStamp = sorted[0]?.createdAt;
    const totalLabels = dayjs(lastTimeStamp).diff(fistTimeStamp, "s");
    const labels = [];
    for (let index = 0; index < totalLabels + 1; index++) {
      labels.push(index + "s");
    }

    const slicedLabels = labels.slice(0, largestDataSet * STEP_SIZE);

    return {
      maxTicksLimit: Math.ceil(slicedLabels.length / STEP_SIZE),
      graphData: {
        labels: slicedLabels,
        datasets: Object.entries(intermediateScore).map(([userId, value]) => {
          const sortedValue = value.sort((a, b) => a.createdAt - b.createdAt);
          const valueT1 = sortedValue[0].createdAt;
          return {
            data: sortedValue.map((e) => ({
              score: e.value,
              time: dayjs(e.createdAt).diff(valueT1, "s") + "s",
              sequenceId: e.sequenceId,
            })),

            borderColor: getRandomColor(),
            backgroundColor: getRandomColor(),
            fill: false,
            tension: 0.4,
            label: usersPublic[userId]?.username,
          };
        }),
      },
    };
  }, [intermediateScore, usersPublic]);

  const options = getOptions(dataSet.maxTicksLimit);
  return (
    <>
      <Divider />
      <Line options={options} data={dataSet.graphData} />
      <Divider />
    </>
  );
};

export default IntermediateScoreGraph;
