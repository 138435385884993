import { Button, Row, Typography } from "antd";
import { FC, useState } from "react";
import TriumphPage from "src/shared/layout/TriumphPage";
import CreateGame from "../Games/components/CreateGame/createGame";
import { PlusOutlined } from "@ant-design/icons";
import { useAppSelector } from "src/app/hooks";

const AnalyticsWelcome: FC<{ isDashboard?: boolean }> = ({ isDashboard }) => {
  const [isOpenGame, setIsOpenGame] = useState(false);
  const { listOfGames } = useAppSelector((state) => state.gameState);
  return (
    <TriumphPage>
      <Row justify="center">
        <img
          className="triumph-logo"
          src="/Logo_Gradient.png"
          alt="Triumph Logo"
          width={64}
        />
      </Row>
      <Row justify="center">
        <Typography.Title level={2}>
          Triumph Analytics Dashboard
        </Typography.Title>
      </Row>

      <Row justify="center">
        <Typography.Text>
          {listOfGames.length === 0 ? (
            <>
              You have no games. To access the Analytics Dashboard, you must
              first create a game
            </>
          ) : isDashboard ? (
            <>
              There is nothing much to show on Analytics Board, come back later
              or refresh.
            </>
          ) : (
            <></>
          )}
        </Typography.Text>
      </Row>
      {listOfGames.length === 0 && (
        <Row justify="center">
          <CreateGame open={isOpenGame} onClose={() => setIsOpenGame(false)} />
          <Button
            type="link"
            icon={<PlusOutlined />}
            onClick={() => {
              setIsOpenGame(true);
            }}
          >
            Add New Game
          </Button>
        </Row>
      )}
    </TriumphPage>
  );
};

export default AnalyticsWelcome;
