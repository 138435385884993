import { FC } from "react";
import { useAppSelector } from "src/app/hooks";

interface Props {
  id: string;
}

const GameName: FC<Props> = ({ id }) => {
  const { listOfGames } = useAppSelector((state) => state.gameState);

  if (id === "none") return <>None</>;

  const selectedGame = listOfGames.find((f) => f.id === id);

  if (selectedGame) {
    return <>{selectedGame.name}</>;
  }
  return <></>;
};

export default GameName;
