export async function generatePrivateKey() {
  const exportKey = async (key: CryptoKey): Promise<Uint8Array> => {
    const exported = await window.crypto.subtle.exportKey("raw", key);
    return new Uint8Array(exported);
  };

  const key = await window.crypto.subtle.generateKey(
    {
      name: "AES-GCM",
      length: 256,
    },
    true,
    ["encrypt", "decrypt"]
  );
  const exportedKey = await exportKey(key);
  return Array.from(exportedKey)
    .map((byte) => byte.toString(16))
    .join("");
}
