import { collection, doc, onSnapshot } from "firebase/firestore";
import { FC, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { transactionsConverter } from "src/converters";
import UserTransactions from "./userTransactions";
import { db, generateFirestorePath } from "src/helpers";
import { Descriptions, Divider, Tabs } from "antd";
import Currency from "src/shared/components/currency/Currency";
import AbuseEvents from "./abuseEvents";
import UserStateDetails from "src/components/internal/components/userState/userStateDetails";
import UserStateNotes from "src/components/internal/components/userState/userStateNotes";
import UserTournaments, { UserTournament } from "./userTournaments";

interface Props {
  user: AppUser & AppUserPublic;
  userState?: UserState;
  userTournament: UserTournament[];
}
const TransactionDetails: FC<Props> = ({ user, userState, userTournament }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  const [activeTab, setActiveTab] = useState("balance-trx");
  const lg = useMediaQuery({ maxWidth: 1600, minWidth: 800 });
  const xl = useMediaQuery({ minWidth: 1600 });

  const [transactions, setTransactions] = useState<{
    [transactionId: string]: BalanceTransaction;
  } | null>(null);

  const statLabels = {
    totalDeposits: "Total Deposits",
    totalWithdrawals: "Total Withdrawals",
  } as const;

  const columnSize = () => {
    if (xl) return 3;
    if (lg) return 2;
    if (isMobile) return 1;
    return 3;
  };

  useEffect(() => {
    const userRef = collection(db, generateFirestorePath("appUsers"));

    const userDoc = doc(userRef, user.uid);

    const balanceTransactionRef = collection(
      userDoc,
      generateFirestorePath("balanceTransactions")
    ).withConverter(transactionsConverter);

    const unsubTournCol = onSnapshot(balanceTransactionRef, (colSnap) => {
      const defs: typeof transactions = {};
      for (const tourDefSnap of colSnap.docs) {
        const tourDefId = tourDefSnap.id;
        const tourDef = tourDefSnap.data();
        defs[tourDefId] = tourDef;
      }
      setTransactions(defs);
    });

    return () => {
      unsubTournCol();
    };
  }, [user.uid]);

  function getUserStatistic(statId: string) {
    let stat = 0;
    if (!transactions) {
      return stat;
    }

    // TODO: eventually refactor these reducers, only 'type' differs
    switch (statId) {
      // get total user has deposited across all games
      case "totalDeposits": {
        stat = Object.values(transactions).reduce(
          (previousValue, { type, amount }) => {
            if (type === "deposit") {
              return previousValue + amount;
            }
            return previousValue;
          },
          0
        );
        break;
      }

      // get total user has withdrawn across all games
      case "totalWithdrawals": {
        stat = Object.values(transactions).reduce(
          (previousValue, { type, amount }) => {
            if (type === "withdrawal") {
              return previousValue + amount;
            }
            if (type === "withdrawal-redeposit") {
              return previousValue + amount;
            }
            return previousValue;
          },
          0
        );
        break;
      }

      default:
        break;
    }
    return stat;
  }

  return (
    <>
      <Descriptions
        layout={!isMobile ? "horizontal" : "vertical"}
        column={columnSize()}
        bordered
        title="User Statistics"
        className="pb-2"
      >
        {Object.entries(statLabels).map(([statId, label]) => {
          return (
            <Descriptions.Item key={statId} label={label}>
              {
                <Currency
                  currency="cents-to-dollar"
                  prefix="$"
                  value={getUserStatistic(statId)}
                />
              }
            </Descriptions.Item>
          );
        })}
      </Descriptions>
      <Divider />
      {userState && (
        <>
          <UserStateDetails
            hideUsername
            details={{ ...userState, id: userState.appUserUid }}
          />

          <Divider />
        </>
      )}
      <Tabs
        className="triumph-tab text-disable-button"
        activeKey={activeTab}
        type="card"
        size="large"
        // destroyInactiveTabPane
        onChange={(activeKey) => setActiveTab(activeKey)}
        items={[
          {
            label: "Balance Transactions",
            key: "balance-trx",
            children: <UserTransactions transactions={transactions} />,
          },

          {
            label: "Abuse Events",
            key: "abuse-events",
            children: <AbuseEvents user={user} />,
          },
          {
            label: "Abuse/Fraud Notes",
            key: "abuse-fraud-notes",
            children: userState && (
              <UserStateNotes
                details={{ ...userState, id: userState.appUserUid }}
              />
            ),
          },
          {
            label: "User Tournaments",
            key: "user-tournaments",
            children: <UserTournaments data={userTournament} />,
          },
        ]}
      />
    </>
  );
};

export default TransactionDetails;
