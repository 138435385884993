import { Button, Col, Form, Input, Row, Spin, Tag } from "antd";
import { Unsubscribe } from "firebase/auth";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { FC, useEffect, useState } from "react";
import { axiosInstance, db } from "src/helpers";
import TriumphPage from "src/shared/layout/TriumphPage";
import { versionRegex } from "src/shared/config/constants";

const InternalVersions: FC = () => {
  const [form] = Form.useForm();
  const [global, setGlobal] = useState<{
    [x: string]: string;
  }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isTouched, setIsTouched] = useState<{
    [c: string]: boolean;
  }>({});

  useEffect(() => {
    let unsubGlobal: Unsubscribe;
    const globalRef = doc(collection(db, "appConfig"), "global");

    unsubGlobal = onSnapshot(globalRef, (snap) => {
      if (snap.exists()) {
        setGlobal(snap.data());
      }
    });

    return () => {
      unsubGlobal?.();
    };
  }, []);

  useEffect(() => {
    if (global) {
      form.setFieldsValue(global);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [global]);

  function onChange(
    name: "latestVersion" | "latestStableVersion",
    value: string | number | null
  ) {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      [name]: value,
    });
    if (global) {
      setIsTouched({
        ...isTouched,
        [name]: form.getFieldValue(name) !== global[name],
      });
    }
  }

  async function onSave() {
    setError("");
    const isUndefined =
      Object.values(form.getFieldsValue()).findIndex(
        (f) => f === undefined || f === null
      ) !== -1;

    const isError = form.getFieldsError().find((f) => f.errors.length > 0);
    if (isUndefined || isError) {
      setError("Please fill all fields.");
      return;
    }

    setIsLoading(true);
    const data = form.getFieldsValue();
    const formData = {
      latestStableVersion: data.latestStableVersion,
      latestVersion: data.latestVersion,
    };
    try {
      await axiosInstance.post(
        "/internal/versions/update_latest_versions",
        formData
      );
      setIsTouched({});
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }
  return (
    <TriumphPage>
      <Spin
        spinning={
          !global ||
          !("latestStableVersion" in global) ||
          !("latestVersion" in global)
        }
      >
        <Form
          name="Internal Versions"
          form={form}
          autoComplete="off"
          className="app-config-form"
        >
          <Row>
            <Col span={12}>
              <Form.Item
                className="app-config-input-wrap"
                initialValue={global["latestStableVersion"]}
                label="Latest Stable Version &nbsp;"
                name="latestStableVersion"
                rules={[
                  {
                    required: true,
                    message: "Latest stable version is required",
                    pattern: versionRegex,
                  },
                ]}
              >
                <Input
                  name="latestStableVersion"
                  onChange={(e) =>
                    onChange("latestStableVersion", e.target.value)
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                className="app-config-input-wrap"
                initialValue={global["latestVersion"]}
                label="Latest Version &nbsp;"
                name="latestVersion"
                rules={[
                  {
                    required: true,
                    message: "Latest version is required",
                    pattern: versionRegex,
                  },
                ]}
              >
                <Input
                  name="latestVersion"
                  onChange={(e) => onChange("latestVersion", e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <Button
                loading={isLoading}
                onClick={onSave}
                type="primary"
                disabled={
                  global
                    ? Object.values(isTouched).findIndex((f) => f === true) ===
                      -1
                    : false
                }
              >
                Save
              </Button>
            </Col>
          </Row>
          {error && <Tag color="warning">{error}</Tag>}
        </Form>
      </Spin>
    </TriumphPage>
  );
};

export default InternalVersions;
