import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Tag,
  Typography,
} from "antd";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { axiosInstance } from "src/helpers";
import {
  formSubmit,
  validatePositiveNumericValue,
} from "src/shared/config/constants";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { v4 } from "uuid";
import DollarInput from "src/shared/components/dollarInput/dollarInput";

interface Props {
  config: TournamentGroupConfig | null;
  isAdmin: boolean;
  onClose: () => void;
}

const AsyncGroupConfigForm: FC<Props> = ({ config, isAdmin, onClose }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { game } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [configData, setConfigData] = useState<TournamentGroupConfig | null>(
    null
  );
  const [isTouched, setIsTouched] = useState<{
    [c: string]: boolean;
  }>({});

  const [rewards, setRewards] = useState<{
    [s: string]: TournamentGroupConfig["rewards"][0];
  }>({});

  const [form] = Form.useForm();

  const handleSubmit = formSubmit(form);

  function prizeParser(index: number) {
    if (index === 1) return "1st";
    if (index === 2) return "2nd";
    if (index === 3) return "3rd";
    return `${index}th`;
  }

  useEffect(() => {
    if (config) {
      setIsOpen(true);
      const def: { [x: string]: TournamentGroupConfig["rewards"][0] } = {};
      config.rewards?.forEach((e) => {
        const id = v4();
        def[id] = {
          ...e,
          payout: typeof e.payout === "number" ? e.payout / 100 : e.payout,
          payoutBonusCash:
            typeof e.payoutBonusCash === "number"
              ? e.payoutBonusCash / 100
              : e.payoutBonusCash,
        };
      });
      setRewards(def);

      form.setFieldsValue({
        ...config,
        entryPrice:
          typeof config.entryPrice === "number"
            ? config.entryPrice / 100
            : config.entryPrice,
        rewards: def,
      });
      setConfigData({
        ...config,
        entryPrice:
          typeof config.entryPrice === "number"
            ? config.entryPrice / 100
            : config.entryPrice,
        rewards: config.rewards.map((e) => ({
          ...e,
          payout: typeof e.payout === "number" ? e.payout / 100 : e.payout,
          payoutBonusCash:
            typeof e.payoutBonusCash === "number"
              ? e.payoutBonusCash / 100
              : e.payoutBonusCash,
        })),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  function onDialogClose() {
    onClose();
    setIsLoading(false);
    form.resetFields();
    setRewards({});
    setIsTouched({});
    setError("");
    setConfigData(null);
    setIsOpen(false);
  }

  async function onSave() {
    setError("");
    const { entryPrice, size } = form.getFieldsValue();

    // validation for entryPrice multiply number of players to be greater than sum of rewards
    const sumOfRewardCash = Object.values(rewards).reduce((prev, curr) => {
      const currentPayoutBonusCash: number = curr.payoutBonusCash || 0;
      const currentPayout: number = curr.payout || 0;

      return prev + currentPayoutBonusCash + currentPayout;
    }, 0);

    if (sumOfRewardCash > entryPrice * size) {
      setError("Sum of reward bonus cash cannot be greater than entry price");
      return;
    }
    try {
      setIsLoading(true);

      const data = form.getFieldsValue();

      if (!config) {
        // add
        const formData: Omit<
          TournamentGroupConfig,
          | "uid"
          | "type"
          | "entryTokens"
          | "archived"
          | "emoji"
          | "createdAt"
          | "updatedAt"
        > = {
          rewards: Object.values(rewards || {}).map((e) => ({
            ...e,
            payout: typeof e.payout === "number" ? e.payout * 100 : e.payout,
            payoutBonusCash:
              typeof e.payoutBonusCash === "number"
                ? e.payoutBonusCash * 100
                : e.payoutBonusCash,
          })),
          size: data.size,
          name: data.name,
          entryPrice: data.entryPrice * 100,
          entryGems: data.entryGems,
        };
        await axiosInstance.post<{ success: string }>(
          `/internal/games/${game}/async_group_definitions`,
          formData
        );
      } else {
        // edit
        const formData: Pick<TournamentGroupConfig, "name" | "archived"> = {
          name: data.name,
          archived: configData?.archived || false,
        };
        await axiosInstance.post<{ success: string }>(
          `/internal/games/${game}/async_group_definitions/${config.uid}`,
          formData
        );
      }
      onDialogClose();
    } catch (error) {
      setError("Unknown Server Error");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  function onChange(
    name: keyof TournamentGroupConfig,
    value:
      | string
      | number
      | { [s: string]: TournamentGroupConfig["rewards"][0] }
      | null
  ) {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      [name]: value,
    });
    if (configData) {
      setIsTouched({
        ...isTouched,
        [name]: form.getFieldValue(name) !== configData[name],
      });
    }
  }

  function onAddRewardArray() {
    let reward = { ...rewards };
    const id = v4();
    reward[id] = {
      payout: 0,
      payoutTokens: null,
      payoutBonusCash: 0,
      payoutGems: 0,
    };
    setRewards(reward);
    onChange("rewards", reward);
  }

  function onRemoveRewardArray(index: string) {
    let reward = { ...rewards };
    delete reward[index];
    setRewards(reward);
    onChange("rewards", reward);
  }

  return (
    <>
      {isAdmin ? (
        <Button
          onClick={() => {
            form.resetFields();
            setIsOpen(true);
          }}
          type="primary"
        >
          Add Config
        </Button>
      ) : (
        <></>
      )}
      <Modal
        title={`${!config ? "Add" : "Edit"} Async Group Tournament Config`}
        centered
        open={isOpen}
        onOk={() => handleSubmit(onSave)}
        okButtonProps={{
          disabled: config
            ? Object.values(isTouched).findIndex((f) => f === true) === -1
            : false,
        }}
        onCancel={onDialogClose}
        destroyOnClose
        width={1000}
        confirmLoading={isLoading}
      >
        {isOpen && (
          <Form name="Async Group Game Config" form={form} autoComplete="off">
            <Row>
              <Col span={12}>
                <Form.Item
                  initialValue={configData?.name}
                  label="Name"
                  className="app-config-input-wrap"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Name value is required",
                    },
                  ]}
                >
                  <Input
                    name="name"
                    size="middle"
                    onChange={(e) => onChange("name", e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  initialValue={configData?.size}
                  label="Size"
                  className="app-config-input-wrap"
                  name="size"
                  rules={[
                    {
                      required: configData === null,
                      message: "Size value is required",
                    },
                  ]}
                >
                  <InputNumber
                    name="size"
                    type="number"
                    size="middle"
                    disabled={configData !== null}
                    onChange={(e) => onChange("size", e)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  initialValue={configData?.entryPrice}
                  label="Entry Price"
                  className="app-config-input-wrap"
                  name="entryPrice"
                  rules={[
                    {
                      required: configData === null,
                      validator: validatePositiveNumericValue("Entry Price", 0),
                    },
                  ]}
                >
                  <DollarInput
                    name="entryPrice"
                    size="middle"
                    disabled={configData !== null}
                    onChange={(value) => onChange("entryPrice", value)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  initialValue={configData?.entryGems}
                  label="Entry Gems"
                  className="app-config-input-wrap"
                  name="entryGems"
                  rules={[
                    {
                      required: configData === null,
                      validator: validatePositiveNumericValue("Entry Gems", 0),
                    },
                  ]}
                >
                  <DollarInput
                    name="entryGems"
                    size="middle"
                    disabled={configData !== null}
                    onChange={(value) => onChange("entryGems", value)}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Typography.Title level={5}>
              Rewards
              <span className="add-leaderboard">
                {configData === null ? (
                  <PlusCircleOutlined onClick={onAddRewardArray} />
                ) : (
                  <></>
                )}
              </span>
            </Typography.Title>
            {Object.keys(rewards || {}).map((selectedReward, index) => (
              <div key={selectedReward}>
                <Divider />
                <Typography.Text>
                  {prizeParser(index + 1)} Prize
                </Typography.Text>
                <Row justify="center" align="middle">
                  <Col span={6}>
                    <Form.Item
                      initialValue={rewards[selectedReward].payout}
                      label="Payout"
                      className="app-config-input-wrap"
                      name={`rewards.${selectedReward}.payout`}
                    >
                      <DollarInput
                        name="payout"
                        size="middle"
                        disabled={configData !== null}
                        onChange={(value) => {
                          const prevValue = {
                            ...rewards,
                            [selectedReward]: {
                              ...rewards[selectedReward],
                              payout: Number(value),
                            },
                          };
                          setRewards(prevValue);
                          onChange("rewards", prevValue);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      initialValue={rewards[selectedReward].payoutBonusCash}
                      label="Payout Bonus Cash"
                      className="app-config-input-wrap"
                      name={`rewards.${selectedReward}.payoutBonusCash`}
                    >
                      <DollarInput
                        name="payoutBonusCash"
                        size="middle"
                        disabled={configData !== null}
                        onChange={(value) => {
                          const prevValue = {
                            ...rewards,
                            [selectedReward]: {
                              ...rewards[selectedReward],
                              payoutBonusCash: Number(value),
                            },
                          };
                          setRewards(prevValue);
                          onChange("rewards", prevValue);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      initialValue={rewards[selectedReward].payoutGems}
                      label="Payout Gems"
                      className="app-config-input-wrap"
                      name={`rewards.${selectedReward}.payoutGems`}
                    >
                      <DollarInput
                        name="payoutGems"
                        size="middle"
                        disabled={configData !== null}
                        onChange={(value) => {
                          const prevValue = {
                            ...rewards,
                            [selectedReward]: {
                              ...rewards[selectedReward],
                              payoutGems: Number(value),
                            },
                          };
                          setRewards(prevValue);
                          onChange("rewards", prevValue);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  {configData === null ? (
                    <Col span={1}>
                      <MinusCircleOutlined
                        className="add-leaderboard delete w-100 h-100"
                        onClick={() => onRemoveRewardArray(selectedReward)}
                      />
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>
              </div>
            ))}
            {error && <Tag color="warning">{error}</Tag>}
          </Form>
        )}
      </Modal>
    </>
  );
};

export default AsyncGroupConfigForm;
