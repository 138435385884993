import { Col, Row, Form, Input, Select, Button, Tag } from "antd";
import { FC, useState } from "react";
import { useAppSelector } from "src/app/hooks";
import { axiosInstance, dwolla } from "src/helpers";

const AccountSettings: FC = () => {
  const [form] = Form.useForm();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAppSelector((state) => state.userState);
  function onChange(name: string, value: string | null) {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      [name]: value,
    });
  }

  async function onSave() {
    try {
      setError("");
      const isUndefined =
        Object.values(form.getFieldsValue()).findIndex(
          (f) => f === undefined || f === null || f === ""
        ) !== -1;

      const isError = form.getFieldsError().find((f) => f.errors.length > 0);
      if (isUndefined || isError) {
        setError("Please fill all fields.");
        return;
      }

      const { routingNumber, accountNumber, type, name } =
        form.getFieldsValue();
      setIsLoading(true);
      const tokenResponse = await axiosInstance.post(
        `/admin/orgs/${user?.activeOrgId}/dwolla_funding_source_token`
      );
      if (tokenResponse.status !== 200) {
        setIsLoading(true);
        setError("Failed to generate session.");
        return;
      }

      dwolla.fundingSources.create(
        tokenResponse.data.token,
        {
          routingNumber,
          accountNumber,
          type,
          name,
        },
        async (err, res) => {
          if (err) {
            setError(
              err._embedded?.errors.map((e) => e.message).join(",\n") ||
                err.message
            );
            setIsLoading(false);
          } else {
            try {
              const response = await axiosInstance.post(
                `/admin/orgs/${user?.activeOrgId}/dwolla_create_funding_source`,
                {
                  fundingSrcLocation: res._links["funding-source"].href,
                }
              );
              if (response.status !== 200) {
                setError("Failed to create funding source");
              }
              await axiosInstance.post(
                `/admin/orgs/${user?.activeOrgId}/dwolla_initiate_microdeposits`
              );
            } catch (err) {
              setError(err instanceof Error ? err.message : "Unknown Error");
            } finally {
              setIsLoading(false);
            }
          }
        }
      );
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  return (
    <>
      <Form
        name="payments"
        className="spaced-form"
        form={form}
        initialValues={{
          accountNumber: "",
          routingNumber: "",
          name: "",
          type: "",
        }}
        onFinish={onSave}
        autoComplete="off"
      >
        <Row justify="center">
          <Col span={14}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Name is required",
                },
              ]}
            >
              <Input
                name="name"
                className="pd-8"
                size="large"
                onChange={(e) => onChange("name", e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item
              label="Account Number"
              name="accountNumber"
              rules={[
                {
                  required: true,
                  message: "Account Number is required",
                },
              ]}
            >
              <Input
                name="accountNumber"
                className="pd-8"
                size="large"
                onChange={(e) => onChange("accountNumber", e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item
              label="Routing Number"
              name="routingNumber"
              rules={[
                {
                  required: true,
                  message: "Routing Number is required",
                },
              ]}
            >
              <Input
                name="routingNumber"
                className="pd-8"
                size="large"
                onChange={(e) => onChange("routingNumber", e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item
              label="Type"
              name="type"
              rules={[
                {
                  required: true,
                  message: "Type is required",
                },
              ]}
            >
              <Select
                size="large"
                onChange={(value) => onChange("type", value)}
              >
                <Select.Option value="checking">Checking</Select.Option>
                <Select.Option value="savings">Savings</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {error && <Tag color="warning">{error}</Tag>}

        <Row justify="end">
          <Button loading={isLoading} onClick={onSave} type="primary">
            Save
          </Button>
        </Row>
      </Form>
    </>
  );
};

export default AccountSettings;
