import { Card, Col, Row, Typography } from "antd";
import { FC, useState } from "react";
import { Unsubscribe } from "firebase/auth";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { useEffect } from "react";
import { useAppSelector } from "src/app/hooks";
import { orgConverter } from "src/converters";
import { db, generateFirestorePath } from "src/helpers";
import KeyTable from "./components/KeyTable";

const { Title, Text } = Typography;

const APIKeys: FC = () => {
    const { user } = useAppSelector((state) => state.userState);
    const [org, setOrg] = useState<Organization>();

    useEffect(() => {
        let unsubOrganizationSnapshot: Unsubscribe;
        if (user && user.activeOrgId) {
            const organizationRef = collection(
                db,
                generateFirestorePath("organizations")
            ).withConverter(orgConverter);

            const orgDoc = doc(organizationRef, user.activeOrgId);
            unsubOrganizationSnapshot = onSnapshot(orgDoc, (orgsSnap) => {
                const data = orgsSnap.data();
                setOrg(data);
            });
        }
        return () => {
            unsubOrganizationSnapshot();
        };
    }, [user]);

    const cardStyles = {
        width: "800px",
        margin: "20px auto",
        borderRadius: "10px",
    };

    return (
        <Row justify="center">
            <Col span={24}>
                <Card
                    headStyle={{ borderWidth: 0, fontSize: 28 }}
                    bordered={false}
                    style={cardStyles}
                >
                    <div style={{ marginBottom: 32 }}>
                        <Title level={3} style={{ marginBottom: 16 }}>
                            API Keys
                        </Title>
                        <div style={{ marginBottom: 24 }}>
                            <Text>
                                The keys below are used to authenticate your application with our API.
                            </Text>
                        </div>
                        <div>
                            <Text strong>
                                You will not be able to view your secret key value again once it has been generated, be sure to store it securely.
                                Do not share your API key with others, or expose it in your application.
                            </Text>
                        </div>
                    </div>
                    {org && <KeyTable />}
                </Card>
            </Col>
        </Row>
    );
};

export default APIKeys;
