import { Descriptions } from "antd";
import Title from "antd/lib/typography/Title";
import {
  collection,
  doc,
  FirestoreDataConverter,
  onSnapshot,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "firebase/firestore";
import { FC, useEffect, useState } from "react";
import { db, generateFirestorePath } from "src/helpers";
import { dateFormatter } from "src/shared/config/constants";
import { useMediaQuery } from "react-responsive";
import GameName from "src/shared/components/GameName/gameName";

const fireStoreConverter: FirestoreDataConverter<UserGameState> = {
  toFirestore(data) {
    return data;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot<UserGameState>,
    options: SnapshotOptions
  ): UserGameState {
    const data = snapshot.data(options);
    return data;
  },
};

const UserGameStates: FC<{ user: AppUser & AppUserPublic }> = ({ user }) => {
  const [gameStates, setGameStates] = useState<Array<UserGameState>>();
  var isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  const lg = useMediaQuery({ maxWidth: 1600, minWidth: 800 });
  const xl = useMediaQuery({ minWidth: 1600 });

  const columnSize = () => {
    if (xl) return 3;
    if (lg) return 2;
    if (isMobile) return 1;
    return 3;
  };

  useEffect(() => {
    const userRef = collection(db, generateFirestorePath("appUsers"));

    const userDoc = doc(userRef, user.uid);

    const gameStatesRef = collection(
      userDoc,
      generateFirestorePath("gameStates")
    ).withConverter(fireStoreConverter);

    const unsubGameStatesSnapshot = onSnapshot(gameStatesRef, (gameId) => {
      const gameStatesDef: typeof gameStates = [];
      for (const gameDefSnap of gameId.docs) {
        gameStatesDef.push(gameDefSnap.data());
      }
      setGameStates(gameStatesDef);
    });

    return () => {
      unsubGameStatesSnapshot();
    };
  }, [user]);
  if (!gameStates) return <></>;
  return (
    <>
      {gameStates.length > 0 && <Title level={3}>Game States</Title>}
      {gameStates.map((game) => (
        <Descriptions
          key={game.gameId}
          layout={!isMobile ? "horizontal" : "vertical"}
          column={columnSize()}
          bordered
          title={<GameName id={game.gameId} />}
          className="pb-2"
        >
          <Descriptions.Item label="HotStreak">
            {game.hotStreak}
          </Descriptions.Item>
          <Descriptions.Item label="HotStreak Confetti">
            {game.hotStreakConfetti ? "Yes" : "No"}
          </Descriptions.Item>
          {typeof game.percentile === "number" && (
            <Descriptions.Item label="Percentile">
              {(game.percentile * 100).toFixed(2)}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Weight">{game.weight}</Descriptions.Item>
          <Descriptions.Item label="Average Score">
            {game.avgScore}
          </Descriptions.Item>
          <Descriptions.Item label="Created At">
            {dateFormatter(game.createdAt)}
          </Descriptions.Item>
          <Descriptions.Item label="Updated At">
            {dateFormatter(game.updatedAt)}
          </Descriptions.Item>
        </Descriptions>
      ))}
    </>
  );
};

export default UserGameStates;
