import { Form, InputNumber } from "antd";
import { FC, useEffect, useState } from "react";

type Props = {
  onChange: (v: number, e: NumberType) => void;
  defaultValue?: number;
  disabled?: boolean;
};

type NumberType = {
  w: number | null;
  h: number | null;
  d: number | null;
  m: number | null;
  s: number | null;
};

const totalWeeks = 604800000;
const totalDays = 86400000;
const totalHours = 3600000;
const totalMinutes = 60000;
const totalSeconds = 1000;

const DurationInput: FC<Props> = ({
  defaultValue,
  onChange: onChangeValue,
  disabled,
}) => {
  const [time, setTime] = useState<NumberType>({
    w: 0,
    d: 0,
    h: 0,
    m: 0,
    s: 0,
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (defaultValue) {
      // break

      const w = truncateNumber(defaultValue / totalWeeks);
      const d = truncateNumber((defaultValue - w * totalWeeks) / totalDays);
      const h = truncateNumber(
        (defaultValue - w * totalWeeks - d * totalDays) / totalHours
      );
      const m = truncateNumber(
        (defaultValue - w * totalWeeks - d * totalDays - h * totalHours) /
          totalMinutes
      );
      const s =
        (defaultValue -
          w * totalWeeks -
          d * totalDays -
          h * totalHours -
          m * totalMinutes) /
        totalSeconds;

      setTime({ w, d, h, m, s });

      setIsLoading(false);
    }
  }, [defaultValue]);

  const truncateNumber = (num: number) => {
    return Number(`${num}`.split(".")[0]);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);
  const onChange = (value: NumberType) => {
    // combine

    const { w, d, h, m, s } = value;
    const sum =
      (w ? w : 0) * totalWeeks +
      (d ? d : 0) * totalDays +
      (h ? h : 0) * totalHours +
      (m ? m : 0) * totalMinutes +
      (s ? s : 0) * totalSeconds;
    onChangeValue(sum, value);
  };

  if (isLoading) return <></>;
  return (
    <div style={{ display: "flex" }}>
      <Form.Item
        className="offer-editable-field duration-editable"
        name="weeks"
        label="Weeks"
        initialValue={time.w}
        rules={[
          {
            required: true,
            message: "Required",
          },
        ]}
      >
        <InputNumber
          disabled={disabled}
          type="number"
          min={0}
          value={time.w}
          controls={false}
          style={{ width: 40 }}
          onChange={(e) => {
            setTime((v) => ({ ...v, w: e }));
            onChange({ ...time, w: e });
          }}
        />
      </Form.Item>
      <Form.Item
        className="offer-editable-field duration-editable"
        name="days"
        initialValue={time.d}
        label="Days"
        rules={[
          {
            required: true,
            message: "Required",
          },
        ]}
      >
        <InputNumber
          disabled={disabled}
          type="number"
          min={0}
          controls={false}
          style={{ width: 40 }}
          value={time.d}
          onChange={(e) => {
            setTime((v) => ({ ...v, d: e }));
            onChange({ ...time, d: e });
          }}
        />
      </Form.Item>
      <Form.Item
        className="offer-editable-field duration-editable"
        name="hours"
        initialValue={time.h}
        label="Hrs."
        rules={[
          {
            required: true,
            message: "Required",
          },
        ]}
      >
        <InputNumber
          disabled={disabled}
          type="number"
          min={0}
          controls={false}
          style={{ width: 40 }}
          value={time.h}
          onChange={(e) => {
            setTime((v) => ({ ...v, h: e }));
            onChange({ ...time, h: e });
          }}
        />
      </Form.Item>
      <Form.Item
        className="offer-editable-field duration-editable"
        name="minutes"
        label="Min."
        initialValue={time.m}
        rules={[
          {
            required: true,
            message: "Required",
          },
        ]}
      >
        <InputNumber
          disabled={disabled}
          type="number"
          min={0}
          controls={false}
          style={{ width: 40 }}
          value={time.m}
          onChange={(e) => {
            setTime((v) => ({ ...v, m: e }));
            onChange({ ...time, m: e });
          }}
        />
      </Form.Item>
      <Form.Item
        className="offer-editable-field duration-editable "
        name="seconds"
        label="Sec"
        initialValue={time.s}
        rules={[
          {
            required: true,
            message: "Required",
          },
        ]}
      >
        <InputNumber
          disabled={disabled}
          type="number"
          controls={false}
          min={0}
          style={{ width: 40 }}
          value={time.s}
          onChange={(e) => {
            setTime((v) => ({ ...v, s: e }));
            onChange({ ...time, s: e });
          }}
        />
      </Form.Item>
    </div>
  );
};

export default DurationInput;
