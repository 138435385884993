import { FC, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { Skeleton } from "antd";
import {
  collection,
  doc,
  documentId,
  onSnapshot,
  Query,
  query,
  where,
} from "firebase/firestore";
import { db, generateFirestorePath, isTriumphEmployee } from "src/helpers";
import { Unsubscribe } from "firebase/auth";
import {
  asyncGroupv1TournamentConverter,
  transactionsConverter,
} from "src/converters";
import TournamentGroupDetailsContainer from "./TournamentGroupDetails";
import {
  appUserPublicSelect,
  getAppUserPublicArrayDetails,
} from "src/features/appUsersPublic/appUserPublicSlice";
import TriumphPage from "src/shared/layout/TriumphPage";
import TournamentTimeline from "./TournamentTimeline";
import IntermediateScoreGraph from "../IntermediateScore";

const GroupTournamentDetails: FC = () => {
  const { id, game } = useParams();
  const [tournament, setTournament] = useState<AsyncGroupTournamentV1>();
  const { usersPublic } = useAppSelector(appUserPublicSelect);
  const dispatch = useAppDispatch();

  const [userTransactions, setUserTransactions] = useState<{
    [x: string]: BalanceTransaction[];
  }>({});

  const { user } = useAppSelector((state) => state.userState);

  useEffect(() => {
    if (tournament) {
      dispatch(getAppUserPublicArrayDetails(tournament.participants));
    }
  }, [dispatch, tournament]);

  useEffect(() => {
    let unsubTournamentSnapshot: Unsubscribe | undefined;
    if (game && id) {
      const gameRef = collection(db, generateFirestorePath("games"));
      const gameDoc = doc(gameRef, game);
      const tournamentRef = collection(
        gameDoc,
        generateFirestorePath("groupTournaments")
      ).withConverter(asyncGroupv1TournamentConverter);

      const q = query(tournamentRef, where(documentId(), "==", id));
      unsubTournamentSnapshot = onSnapshot(q, (trnmnt) => {
        setTournament(trnmnt.docs[0].data());
      });
    }
    return () => {
      unsubTournamentSnapshot?.();
    };
  }, [game, id]);

  const selectedUsers = useMemo(() => {
    if (!id || !tournament) return;
    let user: { [x: string]: AppUserPublic & AsyncGroupPlayer } = {};
    for (const element of tournament.players) {
      if (element.uid in usersPublic) {
        user[element.uid] = { ...usersPublic[element.uid], ...element };
      }
    }
    return user;
  }, [id, tournament, usersPublic]);

  useEffect(() => {
    let unsubSnapshots: Unsubscribe[] = [];
    const appUsersRef = collection(db, generateFirestorePath("appUsers"));
    if (tournament) {
      unsubSnapshots = tournament.participants.map((participantId) => {
        const userDoc = doc(appUsersRef, participantId);

        const userBalanceTransactionRef = collection(
          userDoc,
          generateFirestorePath("balanceTransactions")
        ).withConverter(transactionsConverter);

        let queryRef: Query<BalanceTransaction>;

        if (isTriumphEmployee()) {
          queryRef = query(
            userBalanceTransactionRef,
            where("tournamentId", "==", tournament.uid)
          );
        } else {
          queryRef = query(
            userBalanceTransactionRef,
            where("tournamentId", "==", tournament.uid),
            where("orgId", "==", user?.activeOrgId)
          );
        }
        return onSnapshot(queryRef, (userTrxSnap) => {
          const userTrxDef: BalanceTransaction[] = [];
          for (const userDefSnap of userTrxSnap.docs) {
            userTrxDef.push(userDefSnap.data());
          }

          setUserTransactions((data) => ({
            ...data,
            [userTrxDef[0].appUserUid]: userTrxDef,
          }));
        });
      });
    }
    return () => {
      for (const unsub of unsubSnapshots) {
        unsub();
      }
    };
  }, [tournament, user?.activeOrgId]);

  if (!id || !selectedUsers) return <Skeleton paragraph title />;

  return (
    <TriumphPage>
      <TournamentGroupDetailsContainer
        selectedUser={selectedUsers}
        userTransactions={userTransactions}
        tournament={tournament}
      />
      {tournament && user?.activeOrgId && (
        <IntermediateScoreGraph
          tournamentId={tournament.uid}
          gameId={tournament.gameId}
          orgId={user.activeOrgId}
        />
      )}
      {tournament && (
        <TournamentTimeline
          userTransactions={userTransactions}
          tournament={tournament}
        />
      )}
    </TriumphPage>
  );
};

export default GroupTournamentDetails;
