import { collection, doc, onSnapshot } from "firebase/firestore";
import React from "react";
import { useParams } from "react-router-dom";
import { db } from "src/helpers";
import {
  blitzTournamentConfigConverter,
  DepoitDefinitionConverter as depositDefinitionConverter,
  gameConverter,
  orgConverter,
  tournamentGroupDefConverter,
} from "src/converters";
import { Button, Card, Descriptions, Spin, Tag } from "antd";
import { useAppSelector } from "src/app/hooks";
import TriumphPage from "src/shared/layout/TriumphPage";
/** type imports */
import type { Unsubscribe } from "firebase/firestore";

const GoLiveChecklist: React.FC = () => {
  const { game: gameId } = useParams();

  const [game, setGameObj] = React.useState<null | Game>(null);
  const [depositDefinitions, setDepositDefinitions] = React.useState<{
    [id: string]: DepositDefinition;
  }>({});
  const [asyncGroupTournamentDefinitions, setAsyncGroupTournamentDefinitions] =
    React.useState<{
      [id: string]: TournamentGroupConfig;
    }>({});
  const [blitzV2TournamentDefinitions, setBlitzV2TournamentDefinitions] =
    React.useState<{
      [id: string]: BlitzDefinitionV2;
    }>({});

  const [activeOrg, setActiveOrg] = React.useState<null | Organization>(null);

  const [copySuccess, setCopySuccess] = React.useState("");

  const { user } = useAppSelector((state) => state.userState);

  React.useEffect(() => {
    let unsubGamesDetails: Unsubscribe | undefined;
    let unsubDepositDefinitions: Unsubscribe | undefined;
    let unsubGroupTournamentDefinitions: Unsubscribe | undefined;
    let unsubBlitzV2TournamentDefinitions: Unsubscribe | undefined;

    const gamesRef = collection(db, "games").withConverter(gameConverter);
    if (gameId) {
      const gameDoc = doc(gamesRef, gameId);
      unsubGamesDetails = onSnapshot(gameDoc, (gameSnap) => {
        const gameDef = gameSnap.data();
        if (gameDef) {
          setGameObj(gameDef);
        }
      });

      const depositDefinitionsReference = collection(
        gameDoc,
        "depositDefinitions"
      ).withConverter(depositDefinitionConverter);
      unsubDepositDefinitions = onSnapshot(
        depositDefinitionsReference,
        (collectionSnap) => {
          const defs: typeof depositDefinitions = {};
          for (const depositDefSnap of collectionSnap.docs) {
            const depositDefId = depositDefSnap.id;
            const depositDef = depositDefSnap.data();
            defs[depositDefId] = depositDef;
          }
          setDepositDefinitions(defs);
        }
      );

      const groupTournamentDefinitionsReference = collection(
        gameDoc,
        "groupTournamentDefinitions"
      ).withConverter(tournamentGroupDefConverter);
      unsubGroupTournamentDefinitions = onSnapshot(
        groupTournamentDefinitionsReference,
        (collectionSnap) => {
          const defs: typeof asyncGroupTournamentDefinitions = {};
          for (const tournamentDefSnap of collectionSnap.docs) {
            const tournamentDefId = tournamentDefSnap.id;
            const tournamentDef = tournamentDefSnap.data();
            defs[tournamentDefId] = tournamentDef;
          }
          setAsyncGroupTournamentDefinitions(defs);
        }
      );

      const blitzV2TournamentDefinitionsReference = collection(
        gameDoc,
        "blitzTournamentDefinitions"
      ).withConverter(blitzTournamentConfigConverter);
      unsubBlitzV2TournamentDefinitions = onSnapshot(
        blitzV2TournamentDefinitionsReference,
        (collectionSnap) => {
          const defs: typeof blitzV2TournamentDefinitions = {};
          for (const tournamentDefSnap of collectionSnap.docs) {
            const tournamentDefId = tournamentDefSnap.id;
            const tournamentDef = tournamentDefSnap.data();
            defs[tournamentDefId] = tournamentDef;
          }
          setBlitzV2TournamentDefinitions(defs);
        }
      );
    }

    return () => {
      unsubGamesDetails?.();
      unsubDepositDefinitions?.();
      unsubGroupTournamentDefinitions?.();
      unsubBlitzV2TournamentDefinitions?.();
    };
  }, [gameId]);

  React.useEffect(() => {
    let unsubOrg: Unsubscribe | undefined;
    if (user) {
      const orgId = user.activeOrgId;
      if (orgId) {
        const orgRef = doc(
          collection(db, "organizations"),
          orgId
        ).withConverter(orgConverter);

        unsubOrg = onSnapshot(orgRef, (docSnap) => {
          const org = docSnap.data();
          if (org) {
            setActiveOrg(org);
          }
        });
      }
    }

    return () => {
      unsubOrg?.();
    };
  }, [user]);

  async function copyToClipboard(value: string) {
    navigator.clipboard.writeText(value);
    setCopySuccess("Copied!");

    await new Promise((resolve) => setTimeout(resolve, 1000));

    setCopySuccess("");
  }

  return (
    <TriumphPage>
      <Spin spinning={game === null}>
        <Descriptions bordered column={1}>
          <Descriptions.Item label="Game Config">
            {typeof game?.appStoreURL === "string" &&
            typeof game?.appleBundleId === "string" ? (
              <div>✅</div>
            ) : (
              <div>❌</div>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Apple Keys">
            {activeOrg?.apnsStatus === "key-uploaded" &&
            typeof activeOrg?.apnsKeyId === "string" &&
            activeOrg?.appleDeviceCheckStatus === "key-uploaded" &&
            typeof activeOrg?.appleDeviceCheckKeyId === "string" &&
            typeof activeOrg?.appleTeamId === "string" ? (
              <div>✅</div>
            ) : (
              <div>❌</div>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Apple Pay">
            {activeOrg?.applePayStatus === "cer-uploaded" &&
            typeof activeOrg?.merchantId === "string" ? (
              <div>✅</div>
            ) : (
              <div>❌</div>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Compliance Status">
            <Tag
              color={
                game?.complianceStatus === "approved" &&
                activeOrg?.platformAgreement === "signed"
                  ? "success"
                  : game?.complianceStatus === "pending-approval"
                  ? "warning"
                  : "error"
              }
            >
              {game?.complianceStatus}
            </Tag>
          </Descriptions.Item>
        </Descriptions>
      </Spin>

      {game !== null && typeof game.uid === "string" ? (
        <Card
          style={{
            margin: "2rem auto",
            width: "25%",
            textAlign: "center",
            fontSize: "large",
          }}
          title="Your Game ID"
        >
          <p>{game.uid}</p>
          <p>
            <Button
              onClick={() => {
                copyToClipboard(game.uid);
              }}
              type="primary"
            >
              Copy
            </Button>
          </p>
          <span style={{ color: "gray" }}>
            {copySuccess === "" ? <>&nbsp;</> : copySuccess}
          </span>
        </Card>
      ) : (
        <></>
      )}
    </TriumphPage>
  );
};

export default GoLiveChecklist;
