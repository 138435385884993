import {
  Button,
  Row,
  Table,
  TableColumnsType,
  TablePaginationConfig,
  Typography,
} from "antd";
import dayjs from "dayjs";
import csvDownload from "json-to-csv-export";
import { FC, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import BalanceTransactionDetails from "src/components/balanceTransactions/TransactionModal";
import { getAppUserDetails } from "src/features/appUsers/AppUserSlice";
import {
  getAllGames,
  getBlitzTournamentDetails,
  getGroupTournamentDetails,
} from "src/features/games/gamesSlice";
import { isTriumphEmployee } from "src/helpers";
import Currency from "src/shared/components/currency/Currency";

import GameNameV2 from "src/shared/components/GameName/gameNameV2";

const { Title, Link } = Typography;

type TrxTableProps = BalanceTransaction & {
  gameId: string;
};

const UserTransactions: FC<{
  transactions: {
    [transactionId: string]: BalanceTransaction;
  } | null;
}> = ({ transactions }) => {
  const dispatch = useAppDispatch();
  const { listOfGames } = useAppSelector((state) => state.gameState);
  const [isExporting, setIsExporting] = useState(false);

  const [selectedRow, setSelectedRow] = useState<BalanceTransaction | null>(
    null
  );

  useEffect(() => {
    dispatch(getAllGames());
  }, [dispatch]);

  const typeFilters: { text: string; value: BalanceTransaction["type"] }[] =
    Object.values(
      Object.values(transactions ?? {}).reduce<{
        [type: string]: { text: string; value: BalanceTransaction["type"] };
      }>((aggreg, trx) => {
        if (trx.type in aggreg) {
          return aggreg;
        } else {
          aggreg[trx.type] = { text: trx.type, value: trx.type };
          return aggreg;
        }
      }, {})
    );

  const gameFilter: { text: string; value: string }[] = Object.values(
    Object.values(transactions ?? {}).reduce<{
      [game: string]: { text: string; value: string };
    }>((aggreg, trx) => {
      const gameId = "gameId" in trx ? trx.gameId : null;
      if (!gameId) {
        return aggreg;
      } else if (gameId in aggreg) {
        return aggreg;
      } else {
        aggreg[gameId] = {
          text: listOfGames.find((f) => f.id === gameId)?.name || gameId,
          value: gameId,
        };
        return aggreg;
      }
    }, {})
  );

  async function saveCSV(colKeys: string[]) {
    const exportTrxs: object[] = dataSource.map((balTrx) => ({
      gameId: balTrx.type === "triumph-deposit" ? "null" : balTrx.gameId,
      type: balTrx.type,
      orgAmount: balTrx.orgAmount,
      orgPromoAmount: balTrx.orgPromoAmount,
      triumphAmount: balTrx.triumphAmount,
      amount: balTrx.amount,
      bonusCashAmount: balTrx.bonusCashAmount,
      createdAt: balTrx.createdAt,
      appUserUid: balTrx.appUserUid,
      uid: balTrx.uid,
      gemAmount: balTrx.type === "triumph-deposit" ? balTrx.gemAmount : "null"
    }));

    const data = {
      data: exportTrxs,
      filename: `export_transactions`,
      delimiter: ",",
      headers: colKeys,
    };
    csvDownload(data);
  }

  const columns: TableColumnsType<TrxTableProps> = [
    {
      title: "Game",
      dataIndex: "gameId",
      key: "gameId",
      render: (value) => <GameNameV2 id={value} />,
      filters: gameFilter,
      onFilter: (value, record) => record.gameId === value,
    },

    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      filters: typeFilters,
      onFilter: (value, record) => record.type === value,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      sorter: (a, b) => a.amount - b.amount,
      render: (amount) => (
        <Currency value={amount} currency="cents-to-dollar" prefix="$" />
      ),
    },
    {
      title: "Bonus Cash Amount",
      dataIndex: "bonusCashAmount",
      key: "bonusCashAmount",
      sorter: (a, b) => a.bonusCashAmount - b.bonusCashAmount,
      render: (amount) => (
        <Currency value={amount} currency="cents-to-dollar" prefix="$" />
      ),
    },
    {
      title: "Gem Amount",
      dataIndex: "gemAmount",
      key: "gemAmount",

    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
      render: (a) => dayjs(a).format("M/D/YY h:mm A"),
      defaultSortOrder: "descend",
    },

    {
      title: "Balance Transaction ID",
      dataIndex: "uid",
      key: "uid",
      render: (id, record) => {
        if (
          [
            "finish-tournament",
            "start-tournament",
            "finish-blitz",
            "start-blitz",
            "referrer-bonus",
            "start-group-tournament",
            "finish-group-tournament",
            "leaderboard-award",
            "new-game",
            "account-creation-deposit",
            "triumph-deposit",
            "deposit",
            "withdrawal",
            "withdrawal-redeposit",
          ].includes(record.type)
        ) {
          return (
            <Link
              onClick={(e) => {
                e.stopPropagation();
                onSelectRow(record);
              }}
            >
              {id}
            </Link>
          );
        }
        return id;
      },
    },
  ];

  if (isTriumphEmployee()) {
    columns.unshift({
      title: "Game ID",
      dataIndex: "gameId",
      key: "gameId",
    });
  }

  const pageinationConfig: TablePaginationConfig = {
    defaultPageSize: 100,
    className: "normal-option-size",
  };
  const onSelectRow = (record: TrxTableProps) => {
    switch (record.type) {
      case "start-blitz":
        dispatch(
          getBlitzTournamentDetails({
            game: record.gameId,
            tournamentId: record.tournamentId,
            version: record.version,
          })
        );
        break;
      case "finish-blitz":
        dispatch(
          getBlitzTournamentDetails({
            game: record.gameId,
            tournamentId: record.tournamentId,
            version: record.version,
          })
        );
        break;
      case "referrer-bonus":
        dispatch(getAppUserDetails(record.refereeUid));
        break;
      case "start-group-tournament":
        dispatch(
          getGroupTournamentDetails({
            game: record.gameId,
            tournamentId: record.tournamentId,
          })
        );
        break;
      case "finish-group-tournament":
        dispatch(
          getGroupTournamentDetails({
            game: record.gameId,
            tournamentId: record.tournamentId,
          })
        );
        break;
    }
    setSelectedRow(record);
  };

  const dataSource = useMemo(() => {
    return Object.entries(transactions ?? {}).map(
      ([id, transaction]): TrxTableProps => {
        switch (transaction.type) {
          case "start-tournament":
          case "finish-tournament":
          case "start-blitz":
          case "finish-blitz":
          case "referrer-bonus":
          case "finish-group-tournament":
          case "start-group-tournament":
          case "new-game":
          case "account-creation-deposit":
          case "deposit":
          case "withdrawal":
          case "withdrawal-redeposit": {
            return { ...transaction };
          }
          default: {
            return {
              ...transaction,
              gameId:
                "gameId" in transaction ? transaction.gameId ?? "none" : "none",
            };
          }
        }
      }
    );
  }, [transactions]);

  return (
    <>
      <BalanceTransactionDetails
        selectedRow={selectedRow}
        onClose={() => setSelectedRow(null)}
      />
      <Title level={5}>Balance Transactions</Title>

      <Row style={{ width: "fit-content" }}>
        <Button
          shape="round"
          className="mb-2"
          onClick={async () => {
            setIsExporting(true);
            await saveCSV(columns.map((col) => col.key as string));
            setIsExporting(false);
          }}
        >
          {isExporting ? "Exporting..." : "Export to .csv"}
        </Button>
      </Row>
      <Table
        columns={columns}
        loading={transactions === null}
        rowKey="uid"
        dataSource={dataSource}
        pagination={pageinationConfig}
        className="mobile-table-small bottom"
      />
    </>
  );
};
export default UserTransactions;
