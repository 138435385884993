import { Button, Card, Col, Form, message, Row, Spin, Typography } from "antd";
import React, { useState } from "react";
import { FC } from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import FloatInput from "src/shared/components/base/FloatInput";
import { getGames } from "src/features/games/gamesSlice";
import { getOrganizations } from "src/features/orgs/orgSlice";
import {
  clearStats,
  fetchStats,
} from "src/features/statistics/statisticsSlice";
import { axiosInstance } from "src/helpers";
import { validateStringValue } from "src/shared/config/constants";
import TriumphPage from "src/shared/layout/TriumphPage";

const OrganizationSettings: FC = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { user } = useAppSelector((state) => state.userState);
  const { orgs } = useAppSelector((state) => state.orgState);
  const [activeOrg, setActiveOrg] = useState<Organization | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isTouched, setIsTouched] = useState<{
    [c: string]: boolean;
  }>({});
  const [error, setError] = useState(false);

  // fetch other org info (games / stats) for this user
  React.useEffect(() => {
    const fetchOrgData = async () => {
      if (orgs && user) {
        await Promise.all([dispatch(getGames()), dispatch(fetchStats())]);
        if (user.activeOrgId) {
          setActiveOrg(orgs[user.activeOrgId]);
          form.setFieldsValue(orgs[user.activeOrgId]);
        }
      } else {
        dispatch(clearStats());
      }
    };
    fetchOrgData();
  }, [dispatch, form, orgs, user]);

  const onSave = async () => {
    if (!activeOrg) {
      return;
    }

    setIsLoading(true);

    const { name } = form.getFieldsValue();

    const orgSettingsUpdate = {
      name,
    };
    await axiosInstance.post<{ success: string }>(
      `/admin/orgs/${user?.activeOrgId}/update_info`,
      orgSettingsUpdate
    );

    await dispatch(getOrganizations());
    setIsLoading(false);
    message.success("Updated!");
  };

  const onChange = (
    name: keyof Organization,
    value: string | number | null | string[]
  ) => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      [name]: value,
    });
    if (activeOrg) {
      setIsTouched({
        ...isTouched,
        [name]: form.getFieldValue(name) !== activeOrg[name],
      });

      setError(!value);
    }
  };

  const { Text, Title } = Typography;

  const orgCardTitle = (
    <>
      <Row style={{ width: "100%" }} justify="space-between" align="middle">
        <Text>Organization</Text>
      </Row>
    </>
  );

  return (
    <TriumphPage>
      <Row justify="center">
        <Col span={18}>
          {isLoading ? (
            <Spin size="large" />
          ) : (
            <Card
              title={orgCardTitle}
              headStyle={{ borderWidth: 0, fontSize: 28 }}
              bordered={false}
              style={{
                width: "100%",
              }}
            >
              {activeOrg && (
                <>
                  <Row gutter={[12, 24]} justify={"start"}>
                    <Form
                      name="Company Form"
                      style={{ width: "100%" }}
                      form={form}
                      autoComplete="off"
                    >
                      <Row>
                        <Col span={24}>
                          <Title
                            className="app-config-input-wrap"
                            style={{ paddingBottom: "10px" }}
                            level={5}
                          >
                            {" "}
                            Details{" "}
                          </Title>
                          <Form.Item
                            initialValue={activeOrg.name}
                            className="app-config-input-wrap"
                            name="name"
                            rules={[
                              {
                                required: true,
                                message: "Organization name is required.",
                                validator: validateStringValue,
                              },
                            ]}
                          >
                            <FloatInput
                              name="name"
                              placeholder="Organization Name"
                              label="Organization Name"
                              value={form.getFieldValue("name")}
                              size="large"
                              onChange={(e) => onChange("name", e.target.value)}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Row>
                  <Row justify="end">
                    <Button
                      loading={isLoading}
                      style={{ alignSelf: "flex-end", marginRight: "10px" }}
                      onClick={onSave}
                      type="primary"
                      disabled={
                        !Object.values(isTouched).includes(true) || error
                      }
                    >
                      Save changes
                    </Button>
                  </Row>
                </>
              )}
            </Card>
          )}
        </Col>
      </Row>
    </TriumphPage>
  );
};

export default OrganizationSettings;
