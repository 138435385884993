import { Button, Col, Row, Timeline } from "antd";
import { FC, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useAppSelector } from "src/app/hooks";
import { appUserPublicSelect } from "src/features/appUsersPublic/appUserPublicSlice";
import { isTriumphEmployee } from "src/helpers";
import { formatCurrency } from "src/shared/components/currency/Currency";
import { dateFormatter } from "src/shared/config/constants";
import ReCreditForm from "./ReCreditForm";
interface Props {
  tournament: AsyncGroupTournamentV1;
  userTransactions: {
    [x: string]: BalanceTransaction[];
  };
}
const TournamentTimeline: FC<Props> = ({ tournament, userTransactions }) => {
  var isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  const { usersPublic: users, loading: usersLoading } =
    useAppSelector(appUserPublicSelect);
  const [selectedAppUserUid, setSelectedAppUserId] = useState<string | null>(
    null
  );

  const tournamentEvents = useMemo(() => {
    if (
      !userTransactions ||
      usersLoading === "fetching" ||
      !tournament ||
      !users
    )
      return [];

    function getDescription(trx: BalanceTransaction) {
      switch (trx.type) {
        case "start-group-tournament":
          return `paid amount of $${formatCurrency(
            trx.amount * -1,
            "cents-to-dollar"
          )} and bonus cash of $${formatCurrency(
            trx.bonusCashAmount,
            "cents-to-dollar"
          )} to start the group tournament`;
        case "finish-group-tournament":
          return `finished group tournament and was paid amount of $${formatCurrency(
            trx.amount,
            "cents-to-dollar"
          )} and bonus cash of $${formatCurrency(
            trx.bonusCashAmount,
            "cents-to-dollar"
          )}`;
        case "reverse-start-group-tournament":
          return `left the group tournament and was paid back amount of $${formatCurrency(
            trx.amount,
            "cents-to-dollar"
          )} and bonus cash of $${formatCurrency(
            trx.bonusCashAmount,
            "cents-to-dollar"
          )}`;
        default:
          return `${trx.description} and transaction of ${trx.amount} occured.`;
      }
    }

    const transactions = Object.values(userTransactions).reduce(
      (prev, curr) => [...prev, ...curr],
      []
    );

    return transactions
      .map((trx) => {
        return {
          name: "Player",
          username: users[trx.appUserUid].username,
          event: getDescription(trx),
          time: trx.transactionDate,
          appUserUid: trx.appUserUid,
          type: trx.type,
        };
      })
      .sort((a, b) => (a.time ?? 0) - (b.time ?? 0));
  }, [tournament, userTransactions, users, usersLoading]);

  return (
    <>
      <ReCreditForm
        selectedAppUserUid={selectedAppUserUid}
        onCancel={() => setSelectedAppUserId(null)}
        tournamentId={tournament.uid}
      />
      <Row justify="center" align="middle">
        <Col span={!isMobile ? 12 : 24}>
          <Timeline mode="alternate">
            {tournamentEvents.map((item, index) => (
              <Timeline.Item
                key={item.time}
                color={index % 2 === 0 ? "red" : "green"}
                position={index % 2 === 0 ? "left" : "right"}
              >
                <Row justify="center">
                  {item.username} {item.event} at {dateFormatter(item.time)}
                  {isTriumphEmployee() &&
                    item.type === "start-group-tournament" && (
                      <Button
                        disabled={
                          !!userTransactions[item.appUserUid].find(
                            (f) => f.type === "reverse-start-group-tournament"
                          )
                        }
                        onClick={() => {
                          setSelectedAppUserId(item.appUserUid);
                        }}
                      >
                        Re Credit
                      </Button>
                    )}
                </Row>
              </Timeline.Item>
            ))}
          </Timeline>
        </Col>
      </Row>
    </>
  );
};

export default TournamentTimeline;
