import { Divider, Row, Skeleton } from "antd";
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  Unsubscribe,
  where,
} from "firebase/firestore";
import { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  colGroupNames,
  colNames,
  getCollection,
  getCollectionGroup,
} from "src/collections";
import { userStateConverter } from "src/converters";

import { db, generateFirestorePath } from "src/helpers";
import TriumphPage from "src/shared/layout/TriumphPage";
import UserActionButtons from "./components/actions";
import TransactionDetails from "./components/transactionDetails";
import UserGameStates from "./components/userGameState";
import UserInfo from "./components/userInformation";
import { UserTournament } from "./components/userTournaments";

const UserDetails: FC = () => {
  const { id } = useParams();
  const [appUser, setAppUser] = useState<AppUser | null>(null);
  const [userPublic, setUserPublic] = useState<AppUserPublic | null>(null);
  const [userState, setUserState] = useState<UserState>();
  const [tournamentsList, setTournamentsList] = useState<Array<UserTournament>>(
    []
  );
  const getTournementsByUserId = useCallback(async () => {
    const blitzTournamentsCol = getCollectionGroup(
      colGroupNames.blitzTournamentsV2
    );

    const blitzTournamentsQuery = query(
      blitzTournamentsCol,
      where("uid", "==", id)
    );

    const asyncGroupTournamentsCol = getCollectionGroup(
      colGroupNames.groupTournaments
    );

    const asyncGroupTournamentsQuery = query(
      asyncGroupTournamentsCol,
      where("participants", "array-contains", id)
    );

    const snapShotAsyncGroup = await getDocs(asyncGroupTournamentsQuery);

    const snapShotBlitz = await getDocs(blitzTournamentsQuery);

    let blitzData: UserTournament[] = [];
    snapShotBlitz.forEach((doc) => {
      if (doc.exists()) {
        const data = doc.data();
        const trnmnt = data.tournamentUid;
        const game = data.gameId;
        const url = `/games/${game}/tournaments/blitz/${trnmnt}`;

        blitzData.push({ url, type: "blitz", tournamentId: trnmnt });
      }
    });

    let asyncGroupData: UserTournament[] = [];
    snapShotAsyncGroup.forEach((doc) => {
      if (doc.exists()) {
        const data = doc.data();

        const trnmnt = data.uid;
        const game = data.gameId;

        const url = `/games/${game}/tournaments/asyncGroupv1/${trnmnt}`;
        asyncGroupData.push({ url, type: "async-group", tournamentId: trnmnt });
      }
    });

    setTournamentsList([...asyncGroupData, ...blitzData]);
  }, [id]);

  useEffect(() => {
    let unsubUserStateCol: Unsubscribe;
    let unsubAppUser: Unsubscribe;
    let unsubPublicUser: Unsubscribe;
    if (id) {
      getTournementsByUserId();
      const userColRef = getCollection(colNames.appUsers);
      const publicUserColRef = getCollection(colNames.appUsersPublic);

      const appUserQuery = doc(userColRef, id);
      const publicUserQuery = doc(publicUserColRef, id);

      unsubAppUser = onSnapshot(appUserQuery, (colSnap) => {
        setAppUser(colSnap.data() || null);
      });

      unsubPublicUser = onSnapshot(publicUserQuery, (colSnap) => {
        setUserPublic(colSnap.data() || null);
      });

      const userStateRef = collection(
        db,
        generateFirestorePath("appUserAbuse")
      ).withConverter(userStateConverter);

      const userStateDocRef = doc(userStateRef, id);

      unsubUserStateCol = onSnapshot(userStateDocRef, (colSnap) => {
        setUserState(colSnap.data());
      });
    }
    return () => {
      unsubUserStateCol?.();
      unsubPublicUser?.();
      unsubAppUser?.();
    };
  }, [getTournementsByUserId, id]);

  if (!userPublic || !appUser) return <Skeleton avatar paragraph title />;

  const user: AppUser & AppUserPublic = {
    ...appUser,
    ...userPublic,
  };
  return (
    <TriumphPage>
      <Row justify="end">
        <UserActionButtons userState={userState} user={user} />
      </Row>
      <UserInfo user={user} />
      <Divider />
      <UserGameStates user={user} />
      <TransactionDetails
        userTournament={tournamentsList}
        userState={userState}
        user={user}
      />
    </TriumphPage>
  );
};
export default UserDetails;
