import { Descriptions } from "antd";
import { FC } from "react";
import { useMediaQuery } from "react-responsive";

import Currency from "src/shared/components/currency/Currency";
import { dateFormatter } from "src/shared/config/constants";

interface Props {
  data: OrgDisbursementTrx;
}
const OrgDisbursement: FC<Props> = ({ data }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });

  return (
    <>
      <Descriptions
        layout={!isMobile ? "horizontal" : "vertical"}
        column={1}
        bordered
        title="Personal Details"
        className="pb-2"
      >
        <Descriptions.Item label="UID">{data.uid}</Descriptions.Item>

        <Descriptions.Item label="Amount">
          <Currency value={data.amount} prefix="$" currency="cents-to-dollar" />{" "}
        </Descriptions.Item>
        <Descriptions.Item label="Promo Amount">
          <Currency
            value={data.promoAmount}
            prefix="$"
            currency="cents-to-dollar"
          />
        </Descriptions.Item>
        <Descriptions.Item label="Triumph Amount">
          <Currency
            value={data.triumphAmount}
            prefix="$"
            currency="cents-to-dollar"
          />
        </Descriptions.Item>
        <Descriptions.Item label="Org Id">{data.orgId}</Descriptions.Item>
        <Descriptions.Item label="Trace Id">{data.traceId}</Descriptions.Item>

        <Descriptions.Item label="Type">{data.type}</Descriptions.Item>
        <Descriptions.Item label="Description">
          {data.description}
        </Descriptions.Item>
        <Descriptions.Item label="Created At">
          {dateFormatter(data.createdAt)}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};
export default OrgDisbursement;
