import {
  Modal,
  Table,
  TableColumnsType,
  TablePaginationConfig,
  Typography,
} from "antd";
import { collection, onSnapshot } from "firebase/firestore";
import { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { userStateConverter } from "src/converters";
import { db, generateFirestorePath } from "src/helpers";
import { dateFormatter } from "src/shared/config/constants";
import TriumphPage from "src/shared/layout/TriumphPage";
import { useNavigate } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import UserStateDetail from "./userStateDetails";
import {
  appUserPublicSelect,
  getAppUserPublicArrayDetails,
} from "src/features/appUsersPublic/appUserPublicSlice";
import { FilterValue } from "antd/lib/table/interface";

interface TableProps extends UserState {
  id: string;
}
const UserStateList: FC = () => {
  const [appUserState, setAppUserState] = useState<{
    [x: string]: UserState;
  }>();

  const [selectedRow, setSelectedRow] = useState<TableProps | null>(null);
  const [reviewFilter, setReviewFilter] = useState<FilterValue | null>([true]);
  const [bannedFilter, setBannedFilter] = useState<FilterValue | null>(null);

  const { usersPublic } = useAppSelector(appUserPublicSelect);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const userStateRef = collection(
      db,
      generateFirestorePath("appUserAbuse")
    ).withConverter(userStateConverter);

    const unsubUserCol = onSnapshot(userStateRef, (colSnap) => {
      const defs: typeof appUserState = {};
      for (const userStateDefSnap of colSnap.docs) {
        const stateDefId = userStateDefSnap.id;
        const stateDef = userStateDefSnap.data();
        defs[stateDefId] = stateDef;
      }
      setAppUserState(defs);
      dispatch(
        getAppUserPublicArrayDetails(
          Object.values(defs).map((e) => e.appUserUid)
        )
      );
    });
    return () => {
      unsubUserCol();
    };
  }, [dispatch]);

  const pageinationConfig: TablePaginationConfig = {
    defaultPageSize: 100,
    className: "normal-option-size",
  };

  const columns: TableColumnsType<TableProps> = [
    {
      title: "App user id",
      dataIndex: "appUserUid",
      key: "appUserUid",
    },
    {
      title: "App user name",
      dataIndex: "appUserUid",
      key: "appUserUid",
      render: (v) => (
        <Typography.Link onClick={() => navigate(`/users/${v}`)}>
          {usersPublic[v]?.username}
        </Typography.Link>
      ),
    },
    {
      title: "Requires Review",
      dataIndex: "requiresReview",
      key: "requiresReview",
      filteredValue: reviewFilter,
      defaultFilteredValue: [true],
      filterResetToDefaultFilteredValue: true,
      filtered: true,
      render: (v) => (v ? "Yes" : "No"),
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      onFilter: (value, record) => record.requiresReview === value,
    },
    {
      title: "Is banned",
      dataIndex: "banned",
      filteredValue: bannedFilter,
      key: "banned",
      render: (v) => (v ? "Yes" : "No"),
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      onFilter: (value, record) => record.banned === value,
    },
    {
      title: "Last Event SequenceId",
      defaultSortOrder: "descend",
      dataIndex: "lastEventSequenceId",
      sorter: (a, b) => a.lastEventSequenceId - b.lastEventSequenceId,
      key: "lastEventSequenceId",
    },

    {
      title: "Last Event Time",
      dataIndex: "lastEventTime",
      key: "lastEventTime",
      render: (v) => dateFormatter(v),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
      key: "createdAt",
      render: (v) => dateFormatter(v),
    },
    {
      title: "Details",
      dataIndex: "id",
      key: "id",
      render: (v, record) => (
        <EyeOutlined onClick={() => setSelectedRow(record)} />
      ),
    },
  ];

  function onClose() {
    setSelectedRow(null);
  }
  return (
    <TriumphPage>
      <Modal
        centered
        open={!!selectedRow}
        onOk={onClose}
        onCancel={onClose}
        width={1000}
      >
        {selectedRow && <UserStateDetail details={selectedRow} />}
      </Modal>
      <Table
        columns={columns}
        onChange={(_, filters) => {
          setReviewFilter(filters["requiresReview"]);
          setBannedFilter(filters["banned"]);
        }}
        loading={appUserState === null}
        rowKey="id"
        dataSource={Object.entries(appUserState ?? {}).map(([id, users]) => ({
          id,
          ...users,
        }))}
        pagination={pageinationConfig}
        className="mobile-table-small bottom"
      />
    </TriumphPage>
  );
};

export default UserStateList;
