import { Button, Col, Form, Input, Row, message } from "antd";
import { FC, useEffect, useMemo, useState } from "react";
import UploadImage from "src/shared/components/UploadV2";
import ColorPicker from "src/shared/components/colorPicker";
import { formSubmit } from "src/shared/config/constants";
import {
  CheckCircleOutlined,
  MinusCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";

import { axiosInstance, getCdnUrl } from "src/helpers";
import OverlayLoader from "src/shared/components/overlayLoader/overlayLoader";
import { useParams } from "react-router-dom";

export type BannerDataType = Banner & {
  isNew?: boolean;
  newId?: string;
};
type Props = {
  data: BannerDataType;
  onRemove: (x: BannerDataType) => void;
  setActive: (item: string | null) => void;
  isActive: boolean;
  onView: (data: BannerDataType) => void;
  index: number;
};
const BannerEditableList: FC<Props> = ({
  data,
  onRemove,
  setActive,
  isActive,
  onView,
  index,
}) => {
  const [form] = Form.useForm();
  const [isTouched, setIsTouched] = useState<{
    [x: string]: boolean;
  }>({});
  const [isLoading, setIsLoading] = useState(false);
  const { game } = useParams();
  const itemId = data.id || data.newId;
  function onChange(name: keyof Banner, value: string | number | null) {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      [name]: value,
    });
    setIsTouched({
      ...isTouched,
      [name]: form.getFieldValue(name) !== data[name],
    });
  }
  const handleSubmit = formSubmit<Banner>(form);

  const onSave = async (values: BannerDataType) => {
    try {
      setIsLoading(true);
      const { isNew, newId, ...payload } = values;
      if (data.isNew) {
        await axiosInstance.post<{ success: string }>(
          `admin/games/${game}/banner`,
          { ...payload, id: itemId }
        );
        message.success("Banner created successfully");
        setActive(null);
      } else {
        await axiosInstance.post<{ success: string }>(
          `admin/games/${game}/banner/update`,
          {...payload, id: itemId}
        );
        message.success("Banner updated successfully");
        setActive(null);
      }
    } catch (e) {
      message.error("Failed to save data");
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const isAnyValueChanged = useMemo(() => {
    if (data.isNew) return true;
    if (Object.keys(isTouched).length === 0) return false;
    if (Object.values(isTouched).findIndex((f) => f === true) >= 0) return true;
  }, [data, isTouched]);

  useEffect(() => {
    setActive(isAnyValueChanged ? `${itemId}` : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnyValueChanged]);

  return (
    <div key={`${itemId}-${index}`}>
      {isLoading && <OverlayLoader />}

      <Form
        className="w-100"
        name="Configure Banner Form"
        form={form}
        autoComplete="off"
      >
        <Row justify="space-between">
          <Col span={1}>
            <div className="configure-offer-icons">
              <Button
                disabled={!isActive}
                type="link"
                onClick={() => handleSubmit(onSave)}
                className="save-offer"
              >
                <CheckCircleOutlined />
              </Button>
              <Button
                disabled={!isActive}
                type="link"
                onClick={() => onRemove(data)}
                className="delete-offer"
              >
                <MinusCircleOutlined />
              </Button>
              <Button
                type="link"
                onClick={() => onView(form.getFieldsValue())}
                className="view-offer"
              >
                <EyeOutlined />
              </Button>
            </div>
          </Col>
          <Col span={3}>
            {" "}
            <Form.Item
              name="name"
              initialValue={data.name}
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input
                className="offer-editable-field"
                disabled={!isActive}
                showCount
                maxLength={20}
                onChange={(e) => onChange("name", e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            {" "}
            <Form.Item
              className="offer-editable-field"
              name="title"
              initialValue={data.title}
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input
                className="offer-editable-field"
                disabled={!isActive}
                showCount
                maxLength={25}
                onChange={(e) => onChange("title", e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={1.5}>
            {" "}
            <Form.Item
              className="offer-editable-field"
              name="subtitle"
              initialValue={data.subtitle}
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input
                className="offer-editable-field"
                disabled={!isActive}
                showCount
                maxLength={80}
                onChange={(e) => onChange("subtitle", e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={1.5}>
            <Form.Item
              className="offer-editable-field"
              name="link"
              initialValue={data.link}
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input
                className="offer-editable-field"
                disabled={!isActive}
                showCount
                onChange={(e) => onChange("link", e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              name="buttonText"
              initialValue={data.buttonText}
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input
                className="offer-editable-field"
                disabled={!isActive}
                showCount
                maxLength={25}
                onChange={(e) => onChange("buttonText", e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={1.5}>
            {" "}
            <Form.Item
              name="buttonTextColor"
              initialValue={data.buttonTextColor}
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <ColorPicker
                title="Button Text Color"
                height={50}
                value={form.getFieldValue("buttonTextColor")}
                onChange={(e) => onChange("buttonTextColor", e)}
              />
            </Form.Item>
          </Col>
          <Col span={1.5}>
            <Form.Item
              name="buttonBackgroundColor"
              initialValue={data.buttonBackgroundColor}
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <ColorPicker
                title="Button Color"
                height={50}
                value={form.getFieldValue("buttonBackgroundColor")}
                onChange={(e) => onChange("buttonBackgroundColor", e)}
              />
            </Form.Item>
          </Col>
          <Col span={1.5}>
            {" "}
            <Form.Item
              name="backgroundColor"
              initialValue={data.backgroundColor}
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <ColorPicker
                title="Background Color"
                height={50}
                value={form.getFieldValue("backgroundColor")}
                onChange={(e) => onChange("backgroundColor", e)}
              />
            </Form.Item>
          </Col>

          <Col span={3}>
            <Form.Item
              name={"buttonIcon"}
              initialValue={data.buttonIcon}
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <UploadImage
                name={`${itemId}-buttonIcon`}
                disabled={!isActive}
                hideLabel
                hideUploadLabel
                id="buttonIcon"
                path={`games/${game}/banners`}
                url={
                  form.getFieldValue("buttonIcon")
                    ? `${getCdnUrl()}${form.getFieldValue("buttonIcon")}`
                    : ""
                }
                className="offer-image"
                onUpload={(imgData) => {
                  onChange("buttonIcon", imgData);
                }}
                onRemoveImg={() => {
                  onChange("buttonIcon", "");
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default BannerEditableList;
