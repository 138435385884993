import { FC, useEffect, useState } from "react";
import TriumphPage from "src/shared/layout/TriumphPage";
import { Button, Col, Divider, message, Row, Tooltip, Typography } from "antd";
import OffersEditableList, {
  ConfigureOfferDataType,
} from "./components/OffersEditableList";
import { InfoCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import OfferPreview from "./components/OfferPreview";
import { axiosInstance } from "src/helpers";
import OverlayLoader from "src/shared/components/overlayLoader/overlayLoader";
import { useParams } from "react-router-dom";
import { Unsubscribe } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { colNames, getCollection } from "src/collections";

const GameOffers: FC = () => {
  const [, contextHolder] = message.useMessage();
  const [list, setList] = useState<Array<ConfigureOfferDataType>>([]);
  const [selectedView, setSelectedView] =
    useState<ConfigureOfferDataType | null>(null);

  const [activeItem, setActiveItem] = useState<string | null>(null);
  const { game } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let unSubConfigureOffer: Unsubscribe;
    if (game) {
      const gameRef = doc(getCollection(colNames.games), game);
      const configureOfferRef = getCollection(
        colNames.configureOffers,
        gameRef
      );

      unSubConfigureOffer = onSnapshot(configureOfferRef, (colSnap) => {
        const config: typeof list = [];
        for (const snap of colSnap.docs) {
          const data = snap.data();
          config.push(data);
        }
        setList(config);
      });
    }
    return () => {
      unSubConfigureOffer?.();
    };
  }, [game]);

  const onAddItem = () => {
    const id = Date.now();
    setList((prev) => [
      ...prev,
      {
        bonusCashReward: 0,

        heading: "",
        tag: "",

        color: "#FF6726",
        imagePath: "",

        type: "all",

        expiration: undefined,
        duration: undefined,

        isNew: true,
        newId: id,
      },
    ]);

    setActiveItem(`${id}`);
  };

  const onRemoveItem = async (item: (typeof list)[0]) => {
    if (item.isNew) {
      setList((prev) => {
        return prev.filter((f) => f.newId !== item.newId);
      });
      setActiveItem(null);
    } else {
      try {
        setIsLoading(true);
        await axiosInstance.post<{ success: string }>(
          `admin/games/${game}/offer/delete`,
          {
            uid: item.uid,
          }
        );

        message.success("Offer deleted successfully");
        setActiveItem(null);
      } catch (e) {
        message.error("Offer deletion failed");
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <TriumphPage>
      <div className="wrap-editable-view">
        <div className="wrap-inner">
          {/* Message Context */}
          {contextHolder}
          {isLoading && <OverlayLoader />}

          <OfferPreview
            data={selectedView}
            onClose={() => setSelectedView(null)}
          />
          <Row justify={"space-between"}>
            <Col span={1}>
              <Row>
                <Button
                  disabled={!!activeItem}
                  type="link"
                  onClick={onAddItem}
                  className="pb-2 add-notif"
                >
                  <PlusCircleOutlined />
                </Button>
                <Typography.Text strong style={{ paddingLeft: 4 }}>
                  Actions
                </Typography.Text>
              </Row>
            </Col>
            <Col span={1}>
              <Row>
                <Typography.Text strong className="pl-2">
                  Image
                </Typography.Text>
                <Tooltip title="Required: square">
                  <InfoCircleOutlined
                    style={{
                      paddingLeft: "4px",
                      fontSize: "10px",
                      color: "gray",
                    }}
                  />
                </Tooltip>
              </Row>
            </Col>
            <Col span={1}>
              <Row>
                <Typography.Text strong className="pl-2">
                  Color
                </Typography.Text>
              </Row>
            </Col>
            <Col span={3}>
              <Row>
                <Typography.Text strong className="pl-2">
                  Heading
                </Typography.Text>
              </Row>
            </Col>
            <Col span={3}>
              <Row>
                <Typography.Text strong className="pl-2">
                  Tag
                </Typography.Text>
              </Row>
            </Col>
            <Col span={1.5}>
              <Row>
                <Typography.Text strong className="pl-2">
                  Deposit Amount
                </Typography.Text>
              </Row>
            </Col>
            <Col span={1.5}>
              <Row>
                <Typography.Text strong className="pl-2">
                  Bonus Cash Given
                </Typography.Text>
              </Row>
            </Col>
            <Col span={1.5}>
              <Row>
                <Typography.Text strong className="pl-2">
                  Gems Given
                </Typography.Text>
              </Row>
            </Col>
            <Col span={5}>
              <Row>
                <Typography.Text strong className="pl-2">
                  Assigned to
                </Typography.Text>
              </Row>
            </Col>
          </Row>
          <Divider />
          {list.map((e, i) => (
            <OffersEditableList
              onView={setSelectedView}
              data={e}
              setActive={setActiveItem}
              onRemove={onRemoveItem}
              isActive={
                activeItem === null
                  ? true
                  : `${e.uid || e.newId}` === activeItem
              }
              index={i}
            />
          ))}
        </div>
      </div>
    </TriumphPage>
  );
};

export default GameOffers;
