import { Tabs } from "antd";
import { collection, doc, onSnapshot, Unsubscribe } from "firebase/firestore";
import { FC, useEffect, useState } from "react";
import { orgConverter } from "src/converters";
import { db, generateFirestorePath } from "src/helpers";
import BalancesSettings from "./components/balances";
import { useNavigate, useParams } from "react-router-dom";
import BalancesOnboarding from "./BalancesOnboarding";
import { useAppSelector } from "src/app/hooks";

const BalancesPage: FC = () => {
  const { page } = useParams();
  const { user } = useAppSelector((state) => state.userState);
  const [org, setOrg] = useState<Organization>();
  const [current, setCurrent] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    let unsubOrganizationSnapshot: Unsubscribe | undefined;
    if (user?.activeOrgId) {
      const organizationRef = collection(
        db,
        generateFirestorePath("organizations")
      ).withConverter(orgConverter);

      const orgDoc = doc(organizationRef, user.activeOrgId);
      unsubOrganizationSnapshot = onSnapshot(orgDoc, (orgsSnap) => {
        const org = orgsSnap.data();
        if (org) {
          setOrg(org);
          if (org.dwollaId) {
            setCurrent(1);
          }
          if (
            org.dwollaFundingSrc &&
            org.dwollaFundingSrc.status === "unverified"
          ) {
            setCurrent(2);
          }
          if (org.dwollaFundingSrc?.status === "verified") {
            setCurrent(3);
          }
          if (org.dwollaFundingSrc?.status === "maxAttemptsReached") {
            setCurrent(4);
          }
        }
      });
    }
    return () => {
      unsubOrganizationSnapshot?.();
    };
  }, [user?.activeOrgId]);

  return (
    <Tabs
      className="triumph-tab text-disable-button"
      onChange={(tab) => {
        navigate(`/organization/balances/${tab}`);
      }}
      activeKey={page ?? "settings"}
      type="card"
      size="large"
      destroyInactiveTabPane
      items={[
        {
          label: "Settings",
          key: "settings",
          children: (
            <BalancesSettings org={org} onboardingComplete={current >= 3} />
          ),
        },
        {
          label: "Onboarding",
          key: "onboarding",
          children: <BalancesOnboarding />,
        },
      ]}
    />
  );
};

export default BalancesPage;
