import { FC, useEffect, useState } from "react";
import { axiosInstance, calculateOrgAmounts, db } from "src/helpers";
import {
  Button,
  Divider,
  Dropdown,
  Menu,
  Modal,
  Row,
  Space,
  Table,
  Tooltip,
} from "antd";
import { collection, onSnapshot, Unsubscribe } from "firebase/firestore";
import {
  FormOutlined,
  ExclamationCircleOutlined,
  DownOutlined,
} from "@ant-design/icons";
import TriumphPage from "src/shared/layout/TriumphPage";
import { orgConverter } from "src/converters";
import { MenuInfo } from "rc-menu/lib/interface";
import Currency from "src/shared/components/currency/Currency";
import Contract from "src/components/settings/Contract";
/** type imports */
import type { TableColumnsType } from "antd";

interface AdminOrgsTableProps
  extends Pick<
    Organization,
    | "name"
    | "uid"
    | "activeContractId"
    | "platformAgreement"
    | "balance"
    | "promoBalance"
  > {
  name: string;
  uid: string;
  activeContractId: string;
  platformAgreement: "signed" | "unsigned";
}

const InternalOrgs: FC = () => {
  const [orgs, setOrgs] = useState<{
    [x: string]: AdminOrgsTableProps;
  } | null>(null);
  const [orgsLoading, setOrgsLoading] = useState(true);
  const [selectedContract, setSelectedContract] = useState<string | null>(null);
  const [selectedOrg, setSelectedOrg] = useState<string | null>(null);
  const [selectedOrgName, setSelectedOrgName] = useState<string | null>(null);

  // fetch orgs list
  useEffect(() => {
    let unsubOrgs: Unsubscribe;
    const orgsRef = collection(db, "organizations").withConverter(orgConverter);

    unsubOrgs = onSnapshot(orgsRef, (colSnap) => {
      const orgsList: typeof orgs = {};
      for (const snap of colSnap.docs) {
        const orgId = snap.id;
        const {
          name,
          uid,
          activeContractId,
          platformAgreement,
          promoBalance,
          balance,
        } = snap.data();
        orgsList[orgId] = {
          name,
          uid,
          activeContractId,
          platformAgreement,
          promoBalance,
          balance,
        };
      }
      setOrgs(orgsList);
      setOrgsLoading(false);
    });
    return () => {
      unsubOrgs?.();
    };
  }, []);

  const orgNameFilters: {
    text: string;
    value: Organization["name"];
  }[] = Object.values(
    Object.values(orgs ?? {}).reduce<{
      [type: string]: { text: string; value: string };
    }>((aggreg, currOrg) => {
      if (currOrg.name in aggreg) {
        return aggreg;
      } else {
        aggreg[currOrg.name] = {
          text: currOrg.name,
          value: currOrg.name,
        };
        return aggreg;
      }
    }, {})
  );

  const orgIdFilters: {
    text: string;
    value: Organization["uid"];
  }[] = Object.values(
    Object.values(orgs ?? {}).reduce<{
      [type: string]: { text: string; value: string };
    }>((aggreg, currOrg) => {
      if (currOrg.uid in aggreg) {
        return aggreg;
      } else {
        aggreg[currOrg.uid] = {
          text: currOrg.uid,
          value: currOrg.uid,
        };
        return aggreg;
      }
    }, {})
  );

  const platformAgreementFilters: {
    text: string;
    value: Organization["platformAgreement"];
  }[] = Object.values(
    Object.values(orgs ?? {}).reduce<{
      [type: string]: {
        text: string;
        value: Organization["platformAgreement"];
      };
    }>((aggreg, currOrg) => {
      if (currOrg.platformAgreement in aggreg) {
        return aggreg;
      } else {
        aggreg[currOrg.platformAgreement] = {
          text: currOrg.platformAgreement,
          value: currOrg.platformAgreement,
        };
        return aggreg;
      }
    }, {})
  );

  function createDropdownConfirmationModal(
    e: MenuInfo,
    field: string,
    validValues: string[],
    orgId: string,
    path: string,
    payload: any
  ) {
    return {
      title: `Confirm change to ${field} to ${e.key} ?`,
      icon: <ExclamationCircleOutlined />,
      content: `By clicking confirm, the ${field} will be updated to ${e.key}.`,
      okText: "Confirm",
      cancelText: "Back",
      onOk: async () => {
        if (validValues.includes(e.key)) {
          try {
            await axiosInstance.post<{ success: string }>(
              `/internal/orgs/${orgId}/${path}`,
              payload
            );
          } catch (error) {
            console.error(error);
          }
        }
      },
      okCancel: true,
    };
  }

  function getDisplayPlatformAgreement(
    platformAgreement: Organization["platformAgreement"]
  ) {
    switch (platformAgreement) {
      case "unsigned":
        return "Unsigned";
      case "signed":
        return "Signed";
      default:
        return "";
    }
  }

  interface MenuProps {
    orgId: string;
  }

  const PlatformAgreementMenu: FC<MenuProps> = ({ orgId }) => {
    return (
      <Menu
        onClick={(e) => {
          Modal.confirm(
            createDropdownConfirmationModal(
              e,
              "platform agreement",
              ["unsigned", "signed"],
              orgId,
              "update_platform_agreement",
              {
                platformAgreement: e.key,
              }
            )
          );
        }}
      >
        <Menu.Item key={"unsigned"}>Unsigned</Menu.Item>
        <Menu.Item key={"signed"}>Signed</Menu.Item>
      </Menu>
    );
  };

  const columns: TableColumnsType<AdminOrgsTableProps> = [
    {
      title: "Organization",
      dataIndex: "name",
      key: "name",
      filters: orgNameFilters,
      filterSearch: true,
      onFilter: (value, record) => record.name === value,
    },
    {
      title: "Organization ID",
      dataIndex: "uid",
      key: "uid",
      filters: orgIdFilters,
      filterSearch: true,
      onFilter: (value, record) => record.uid === value,
    },
    {
      title: "Platform Agreement Status",
      dataIndex: "platformAgreement",
      key: "platformAgreement",
      width: 250,
      filters: platformAgreementFilters,
      onFilter: (value, record) => record.platformAgreement === value,
      render: (value, record) => (
        <Dropdown overlay={<PlatformAgreementMenu orgId={record.uid} />}>
          <Button>
            <Space>
              {getDisplayPlatformAgreement(value)}
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
    {
      title: "Contract",
      key: "contract",
      align: "right",
      render: (value: string, record: AdminOrgsTableProps) => (
        <Row justify="end">
          <Tooltip title={`Edit ${record.name} Contract`} placement="left">
            <Button
              type="text"
              shape="round"
              onClick={async () => {
                setSelectedOrg(record.uid);
                setSelectedContract(record.activeContractId);
                setSelectedOrgName(record.name);
              }}
            >
              <FormOutlined />
            </Button>
          </Tooltip>
        </Row>
      ),
    },
    {
      title: "Manual Disbursement",
      key: "manual-disbursement",
      align: "right",
      render: (value: string, record: AdminOrgsTableProps) => (
        <Row justify="end">
          <Tooltip
            title={`Submit ${record.name} Manual Disbursement`}
            placement="left"
          >
            <Button
              type="text"
              shape="round"
              onClick={async () => {
                const { amount, promoAmount, externalAmount } =
                  calculateOrgAmounts(
                    {
                      promoBalance: record.promoBalance,
                      uid: record.uid,
                      balance: record.balance,
                    },
                    null
                  );
                Modal.confirm({
                  title: `Confirm submit manual disbursement for ${record.name} ?`,
                  icon: <ExclamationCircleOutlined />,
                  content: (
                    <>
                      <p>
                        Balance Amount:{" "}
                        <Currency
                          value={amount}
                          prefix="$"
                          currency="cents-to-dollar"
                        />
                      </p>
                      <p>
                        Promo Balance Amount:{" "}
                        <Currency
                          value={promoAmount}
                          prefix="$"
                          currency="cents-to-dollar"
                        />
                      </p>
                      <Divider />
                      <p>
                        Transfer Amount:{" "}
                        <Currency
                          value={externalAmount}
                          prefix="$"
                          currency="cents-to-dollar"
                        />
                      </p>
                    </>
                  ),
                  okText: "Confirm",
                  cancelText: "Back",
                  className: externalAmount <= 0 ? "disabled-confirm" : "",
                  onOk:
                    externalAmount <= 0
                      ? undefined
                      : async () => {
                          await axiosInstance.post(
                            `/internal/orgs/${record.uid}/record_manual_disbursement`
                          );
                        },
                  okCancel: true,
                });
              }}
            >
              Initiate
            </Button>
          </Tooltip>
        </Row>
      ),
    },
  ];

  return (
    <TriumphPage>
      <Modal
        bodyStyle={{ backgroundColor: "#1a1818" }}
        open={selectedOrg !== null}
        onCancel={() => {
          setSelectedOrg(null);
          setSelectedOrgName(null);
        }}
        width={"75%"}
        destroyOnClose={true}
        closable={false}
        footer={null}
      >
        {selectedOrg && selectedOrgName && selectedContract && (
          <Contract
            userOrgId={selectedOrg}
            userOrgName={selectedOrgName}
            userContract={selectedContract}
          />
        )}
      </Modal>
      <Table
        columns={columns}
        loading={orgs === null || orgsLoading}
        rowKey="uid"
        dataSource={Object.values(orgs || {})}
        className="mobile-table-small"
      />
    </TriumphPage>
  );
};

export default InternalOrgs;
