import { Button, Col, Form, InputNumber, Modal, Row, Tag } from "antd";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { axiosInstance } from "src/helpers";
import DollarInput from "src/shared/components/dollarInput/dollarInput";
import {
  formSubmit,
  validatePositiveNumericValue,
} from "src/shared/config/constants";

interface Props {
  config: BlitzDefinitionV2 | null;
  isAdmin: boolean;
  onClose: () => void;
}

const BlitzConfigForm: FC<Props> = ({ config, isAdmin, onClose }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { game } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [configData, setConfigData] = useState<BlitzDefinitionV2 | null>(null);
  const [isTouched, setIsTouched] = useState<{
    [c: string]: boolean;
  }>({});
  const [form] = Form.useForm();

  const handleSubmit = formSubmit(form);

  useEffect(() => {
    if (config) {
      setIsOpen(true);
      const data = {
        ...config,
        entryPrice:
          typeof config.entryPrice === "number"
            ? config.entryPrice / 100
            : config.entryPrice,
        entryGems: config.entryGems,
      };
      form.setFieldsValue(data);
      setConfigData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  function onDialogClose() {
    onClose();
    setIsLoading(false);
    form.resetFields();
    setConfigData(null);
    setError("");
    setIsOpen(false);
  }

  async function onSave() {
    setError("");

    try {
      setIsLoading(true);

      if (!game) {
        throw new Error("missing game");
      }
      const data = form.getFieldsValue();
      if (!config) {
        // add
        const formData: Pick<
          BlitzDefinitionV2,
          "entryPrice" | "jackpotWinPercent" | "entryGems"
        > = {
          entryPrice: data.entryPrice * 100,
          jackpotWinPercent: data.jackpotWinPercent || 0,
          entryGems: data.entryGems || 0,
        };
        await axiosInstance.post<{ success: string }>(
          `/internal/games/${game}/blitz_definitions`,
          formData
        );
        onDialogClose();
      } else {
        // edit
        const formData: Pick<
          BlitzDefinitionV2,
          "archived" | "jackpotWinPercent" | "entryGems"
        > = {
          archived: configData?.archived || false,
          jackpotWinPercent: data.jackpotWinPercent || 0,
          entryGems: data.entryGems || 0,
        };
        await axiosInstance.post<{ success: string }>(
          `/internal/games/${game}/blitz_definitions/${config.uid}`,
          formData
        );
        onDialogClose();
      }
    } catch (error) {
      setError("Unknown Error");
      console.error("Unable to add new Blitz Definition with error: ", error);
    } finally {
      setIsLoading(false);
    }
  }

  function onChange(
    name: keyof BlitzDefinitionV2,
    value: string | number | null
  ) {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      [name]: value,
    });
    if (configData) {
      setIsTouched({
        ...isTouched,
        [name]: form.getFieldValue(name) !== configData[name],
      });
    }
  }

  return (
    <>
      {isAdmin ? (
        <Button
          onClick={() => {
            form.resetFields();
            setIsOpen(true);
          }}
          type="primary"
        >
          Add Config
        </Button>
      ) : (
        <></>
      )}

      <Modal
        title={`${!config ? "Add" : "Edit"} Blitz Tournament Config`}
        centered
        open={isOpen}
        onOk={() => handleSubmit(onSave)}
        okButtonProps={{
          disabled: config
            ? Object.values(isTouched).findIndex((f) => f === true) === -1
            : false,
        }}
        onCancel={onDialogClose}
        destroyOnClose
        confirmLoading={isLoading}
      >
        {isOpen && (
          <Form name="Blitz Game Config" form={form} autoComplete="off">
            <Row>
              <Col span={24}>
                <Form.Item
                  initialValue={configData?.entryPrice}
                  label="Entry Price"
                  className="app-config-input-wrap"
                  name="entryPrice"
                  rules={[
                    {
                      required: true,
                      validator: validatePositiveNumericValue("Entry Price", 0),
                    },
                  ]}
                >
                  <DollarInput
                    name="entryPrice"
                    size="middle"
                    disabled={configData !== null}
                    onChange={(value) => onChange("entryPrice", value)}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  initialValue={configData?.jackpotWinPercent || 0}
                  label="Jackpot Win Percent"
                  className="app-config-input-wrap"
                  name="jackpotWinPercent"
                  rules={[
                    {
                      required: true,
                      message: "Jackpot Win Percent value is required",
                    },
                  ]}
                >
                  <InputNumber
                    name="jackpotWinPercent"
                    size="middle"
                    type="number"
                    max={1}
                    min={0}
                    onChange={(value) => onChange("jackpotWinPercent", value)}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  initialValue={configData?.entryGems || 0}
                  label="Entry Gems"
                  className="app-config-input-wrap"
                  name="entryGems"
                  rules={[
                    {
                      required: false,
                      message: "Entry Gems value is required",
                    },
                  ]}
                >
                  <InputNumber
                    name="entryGems"
                    size="middle"
                    type="number"
                    max={1}
                    min={0}
                    onChange={(value) => onChange("entryGems", value)}
                  />
                </Form.Item>
              </Col>
            </Row>
            {error && <Tag color="warning">{error}</Tag>}
          </Form>
        )}
      </Modal>
    </>
  );
};

export default BlitzConfigForm;
