import { useState } from "react";
import { Input, Table, Button, Typography, Modal, Row } from "antd";
import moment, { Moment } from "moment";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import { generatePrivateKey } from "src/helpers/generateSecret";

const { Text, Title } = Typography;

interface KeyData {
  key: string;
  name: string;
  createdAt: Moment;
  lastUsed: Moment | string;
}

const KeyTable = () => {
  // Define the table columns
  const columns = [
    {
      title: <Text strong>NAME</Text>,
      dataIndex: "name",
      key: "name",
      editable: true,
    },
    {
      title: "KEY",
      dataIndex: "key",
      key: "key",
      editable: true,
    },
    {
      title: <Text strong>CREATED</Text>,
      dataIndex: "createdAt",
      key: "createdAt",
      editable: false,
      width: "20%", // Adjust the width of the column
      render: (text: Moment | string, record: KeyData) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Text>{moment(text).format("MMMM DD, YYYY")}</Text>
        </div>
      ),
    },
    {
      title: <Text strong>LAST USED</Text>,
      dataIndex: "lastUsed",
      editable: false,
      width: "35%", // Adjust the width of the column
      render: (text: Moment, record: KeyData) => (
        <Row style={{ alignItems: "center" }} justify={"space-between"}>
          {typeof text === "string" ? (
            "Never"
          ) : (
            <Text>{moment(text).format("MMMM DD, YYYY")}</Text>
          )}
          <div>
            <Button
              type="link"
              icon={<DeleteOutlined style={{ fontSize: "12px" }} />}
              onClick={() => showDeleteConfirmation(record.key)}
            />
            <Button
              type="link"
              icon={<EditOutlined style={{ fontSize: "12px" }} />}
              onClick={() => handleEditRow(record.key)}
            />
          </div>
        </Row>
      ),
    },
  ];

  // Define the table data (initially empty)
  const [data, setData] = useState<KeyData[]>([]);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  // const [saveModalVisible, setSaveModalVisible] = useState(false);
  const [selectedRowKey, setSelectedRowKey] = useState("");
  const [keyName, setKeyName] = useState("");

  // Function to handle adding a new row
  const createKey = async () => {
    const sk = await generatePrivateKey();
    const newData: KeyData = {
      name: keyName === "" ? "Secret Key" : keyName,
      key: "sk - . . . " + sk.substring(sk.length - 4),
      createdAt: moment(), // Set the current timestamp
      lastUsed: moment(),
    };
    setData([...data, newData]);
    setAddModalVisible(false);
  };

  // Function to handle editing a row
  const handleEditRow = (key: string) => {
    // Handle the edit logic here
    console.log("Editing row with key:", key);
  };

  // Function to show the delete confirmation modal
  const showDeleteConfirmation = (key: string) => {
    setSelectedRowKey(key);
    setDeleteConfirmationVisible(true);
  };

  // Function to delete a row
  const deleteKey = () => {
    // Find the index of the row to delete
    const rowIndex = data.findIndex((item) => item.key === selectedRowKey);
    if (rowIndex > -1) {
      // Create a copy of the data array and remove the row
      const newData = [...data];
      newData.splice(rowIndex, 1);
      setData(newData);
    }
    setDeleteConfirmationVisible(false);
  };

  // Function to cancel the delete operation
  const cancelDelete = () => {
    setDeleteConfirmationVisible(false);
  };

  const tableLocale = {
    emptyText: "You have no existing API Keys", // Customize the "No Data" message
  };

  const addModal = (
    <Modal
      width={"500px"}
      centered
      closable={false}
      title={null}
      style={{ borderRadius: "48px" }}
      className="modalStyle"
      visible={addModalVisible}
      footer={null}
    >
      <Title className={"text-disable"} level={4}>
        Create new secret key
      </Title>
      <Text strong style={{ fontSize: "13px" }} className={"text-disable"}>
        Name
      </Text>

      <Text
        italic
        type={"secondary"}
        style={{ fontSize: "11px" }}
        className={"text-disable"}
      >
        {" "}
        Optional
      </Text>
      <Input
        size="small"
        placeholder="My API Key"
        prefix={<KeyOutlined />}
        style={{ marginTop: "12px", marginBottom: "16px", borderRadius: "6px" }}
        onChange={(e) => setKeyName(e.target.value)}
      />
      <Row align={"middle"} justify={"end"}>
        <div>
          <Button
            type={"primary"}
            style={{
              marginRight: "8px",
              backgroundColor: "grey",
              border: "0px",
            }}
            onClick={() => setAddModalVisible(false)}
          >
            {" "}
            Cancel{" "}
          </Button>
          <Button type={"primary"} onClick={createKey}>
            Create secret key
          </Button>
        </div>
      </Row>
    </Modal>
  );

  const deleteModal = (
    <Modal
      width={"500px"}
      centered
      closable={false}
      title={null}
      style={{ borderRadius: "48px" }}
      className="custom-modal"
      visible={deleteConfirmationVisible}
      footer={null}
    >
      <Title className={"text-disable"} level={4}>
        Revoke secret key
      </Title>
      <Text style={{ fontSize: "13px" }} className={"text-disable"}>
        This API key will be immediately deactivated, resulting in the rejection
        of any API requests made with it. This could potentially lead to the
        disruption of systems that rely on it. Once the key is revoked, you will
        lose the ability to view or modify it.
      </Text>
      <Input
        disabled
        style={{ marginTop: "12px", marginBottom: "32px", height: "36px" }}
        placeholder={"hi"}
      />
      <Row align={"middle"} justify={"end"}>
        <div>
          <Button
            type={"primary"}
            style={{
              marginRight: "8px",
              backgroundColor: "grey",
              border: "0px",
            }}
            onClick={cancelDelete}
          >
            {" "}
            Cancel{" "}
          </Button>
          <Button danger type={"primary"} onClick={deleteKey}>
            Revoke key
          </Button>
        </div>
      </Row>
    </Modal>
  );

  return (
    <div>
      <Table
        dataSource={data}
        columns={columns}
        pagination={false}
        bordered={false} // Remove table borders
        className="custom-table" // Add a custom CSS class for styling
        locale={tableLocale}
      />
      <Button
        onClick={() => setAddModalVisible(true)}
        type="default"
        icon={<PlusOutlined />}
      >
        Create New Secret Key
      </Button>
      {deleteModal}
      {addModal}
      {/*{saveModal}*/}
    </div>
  );
};

export default KeyTable;
