import { Row, Typography, Upload } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import type { UploadFile, UploadProps } from "antd/es/upload/interface";
import React, { useEffect, useState } from "react";
import { storage } from "src/helpers";
import {
  uploadBytes,
  ref,
  deleteObject,
  getDownloadURL,
  FullMetadata,
} from "firebase/storage";

const UploadImage: React.FC<{
  name: string;
  id: string;
  url?: string;
  path: string;
  onUpload: (x: string, y: FullMetadata) => void;
  onRemoveImg?: () => void;
}> = ({ name, id, url, path, onUpload, onRemoveImg }) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (url && url !== "") {
      setFileList([{ name, uid: id, url }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const onChange: UploadProps["onChange"] = async ({ file }) => {
    if (!file.originFileObj) {
      return;
    }
    setLoading(true);

    const storageRef = ref(storage, `${path}/${file.name}`);
    const uploadResult = await uploadBytes(storageRef, file.originFileObj);

    const url = await getDownloadURL(storageRef);
    onUpload(url, uploadResult.metadata);
    setLoading(false);
  };

  const onRemove = () => {
    if (!url) {
      setFileList([]);
      onRemoveImg?.();
      return;
    }

    const desertRef = ref(
      storage,
      `${path}/${url.split("%2F")[1].split("?")[0]}`
    );

    deleteObject(desertRef).finally(() => {
      setFileList([]);
      onRemoveImg?.();
    });
  };

  function uploadButton() {
    if (fileList.length === 0) {
      return (
        <div>
          {loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
      );
    }
  }

  return (
    <>
      <Row align="middle" justify="center">
        <Upload
          listType="picture-card"
          className="uploader-wrap"
          fileList={fileList}
          onChange={onChange}
          onRemove={onRemove}
        >
          {uploadButton()}
        </Upload>

        <Typography.Text strong>{name}</Typography.Text>
      </Row>
    </>
  );
};

export default UploadImage;
