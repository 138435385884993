import { Avatar, Descriptions, Tag } from "antd";
import { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { dateFormatter } from "src/shared/config/constants";
import { UserOutlined } from "@ant-design/icons";
import Currency from "src/shared/components/currency/Currency";
import GameName from "src/shared/components/GameName/gameName";
import { getCdnPhoto, isTriumphEmployee } from "src/helpers";
import {
  appUserPublicSelect,
  getAppUserPublicDetails,
} from "src/features/appUsersPublic/appUserPublicSlice";

interface Props {
  row: ReferrerBonusTrx;
  user: AppUserPublic;
}

const RefereeBonusTransaction: FC<Props> = ({ row: trx, user }) => {
  const { usersPublic } = useAppSelector(appUserPublicSelect);
  const dispatch = useAppDispatch();
  const [referee, setReferree] = useState<AppUserPublic | null>(user);
  const referrer = user;
  useEffect(() => {
    const referee = usersPublic[trx.refereeUid];
    if (referee) {
      setReferree(referee);
    }
    if (!referee) {
      dispatch(getAppUserPublicDetails(trx.refereeUid));
    }
  }, [usersPublic, trx, dispatch]);

  if (!referrer || !referee) return <></>;
  return (
    <>
      <Descriptions
        title="Referrer Details"
        layout="horizontal"
        colon
        column={1}
        bordered
        className="pb-2"
      >
        <Descriptions.Item>
          <Avatar size={100} src={getCdnPhoto(referrer.profilePhotoPath)}>
            <UserOutlined />
          </Avatar>
        </Descriptions.Item>
        <Descriptions.Item label="User Name">
          {referrer.username}
        </Descriptions.Item>

        <Descriptions.Item label="User Name">
          {referrer.username}
        </Descriptions.Item>
        <Descriptions.Item label="UID">{referrer.uid}</Descriptions.Item>
        <Descriptions.Item label="Joining Date">
          {dateFormatter(referrer.createdAt)}
        </Descriptions.Item>
        <Descriptions.Item label="Last Updated">
          {dateFormatter(referrer.updatedAt)}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions
        title="Referee Details"
        layout="horizontal"
        colon
        column={1}
        bordered
        className="pb-2 pt-2"
      >
        <Descriptions.Item>
          <Avatar size={100} src={getCdnPhoto(referee.profilePhotoPath)}>
            <UserOutlined />
          </Avatar>
        </Descriptions.Item>
        <Descriptions.Item label="User Name">
          {referee.username}
        </Descriptions.Item>

        <Descriptions.Item label="User Name">
          {referee.username}
        </Descriptions.Item>
        <Descriptions.Item label="UID">{referee.uid}</Descriptions.Item>
        <Descriptions.Item label="Joining Date">
          {dateFormatter(referee.createdAt)}
        </Descriptions.Item>
        <Descriptions.Item label="Last Updated">
          {dateFormatter(referee.updatedAt)}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions
        title="Transaction Details"
        layout="horizontal"
        colon
        column={1}
        bordered
        className="pb-2"
      >
        <Descriptions.Item label="ID">{trx.uid}</Descriptions.Item>
        <Descriptions.Item label="Trace ID">{trx.traceId}</Descriptions.Item>

        <Descriptions.Item label="App User username">
          {user.username}
        </Descriptions.Item>
        <Descriptions.Item label="App User ID">
          {trx.appUserUid}
        </Descriptions.Item>
        <Descriptions.Item label="Type">
          <Tag color="blue">{trx.type}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Description">
          {trx.description}{" "}
        </Descriptions.Item>
        <Descriptions.Item label="Amount">
          <Currency currency="cents-to-dollar" prefix="$" value={trx.amount} />
        </Descriptions.Item>
        <Descriptions.Item label="Token Amount">
          <Currency
            currency="cents-to-dollar"
            prefix="$"
            value={trx.tokenAmount}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Bonus Cash Amount">
          <Currency
            currency="cents-to-dollar"
            prefix="$"
            value={trx.bonusCashAmount}
          />
        </Descriptions.Item>
        {isTriumphEmployee() && (
          <Descriptions.Item label="Game ID">{trx.gameId}</Descriptions.Item>
        )}
        <Descriptions.Item label="Game">
          <GameName id={trx.gameId} />
        </Descriptions.Item>
        <Descriptions.Item label="Created Date">
          {dateFormatter(trx.createdAt)}
        </Descriptions.Item>
        <Descriptions.Item label="Transaction Date">
          {dateFormatter(trx.transactionDate)}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default RefereeBonusTransaction;
