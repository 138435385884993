import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  message,
  Select,
} from "antd";
import { FC, useEffect, useMemo, useState } from "react";
import { CheckCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { formSubmit } from "src/shared/config/constants";
import OverlayLoader from "src/shared/components/overlayLoader/overlayLoader";
import { axiosInstance } from "src/helpers";
import { useParams } from "react-router-dom";

type Props = {
  data: PushNotification & { isNew?: boolean; newId?: number };
  index: number;
  onRemove: (
    item: PushNotification & { isNew?: boolean; newId?: number }
  ) => void;
  setActive: (item: string | null) => void;
  isActive: boolean;
  offerslist: Array<ConfigureOffer>;
};
const SchedulePNForm: FC<Props> = ({
  data,
  index: key,
  offerslist,
  onRemove,
  setActive,
  isActive,
}) => {
  const [form] = Form.useForm();
  const { game } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isTouched, setIsTouched] = useState<{
    [x: string]: boolean;
  }>({});

  function onChange(
    name: keyof PushNotification,
    value: string | number | null
  ) {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      [name]: value,
    });
    setIsTouched({
      ...isTouched,
      [name]: form.getFieldValue(name) !== data[name],
    });
  }
  const handleSubmit = formSubmit<PushNotification>(form);

  const onSave = async (values: PushNotification) => {
    try {
      setIsLoading(true);
      const { title, subtitle, body, scheduledIn, offer } = values;

      const scheduleInMiliSeconds = 24 * 60 * 60 * 1000 * scheduledIn;
      if (data.isNew) {
        await axiosInstance.post<{ success: string }>(
          `admin/games/${game}/push_notification`,
          {
            title,
            body,
            scheduledIn: scheduleInMiliSeconds,
            subtitle,
            data: {},
            offer: offer ?? "none",
          }
        );
        message.success("Scehdule PN created successfully");
      } else {
        await axiosInstance.post<{ success: string }>(
          `admin/games/${game}/push_notification/update`,
          {
            title,
            body,
            scheduledIn: scheduleInMiliSeconds,
            subtitle,
            notificationId: data.notificationId,
            data: {},
            offer: offer ?? "none",
          }
        );
        message.success("Scehdule PN updated successfully");
        setActive(null);
      }
    } catch (e) {
      message.error("Failed to save data");

      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const itemId = data.notificationId || data.newId;
  const isAnyValueChanged = useMemo(() => {
    if (data.isNew) return true;
    if (Object.keys(isTouched).length === 0) return false;
    if (Object.values(isTouched).findIndex((f) => f === true) >= 0) return true;
  }, [data, isTouched]);

  useEffect(() => {
    setActive(isAnyValueChanged ? `${itemId}` : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnyValueChanged]);

  return (
    <Row key={itemId}>
      {isLoading && <OverlayLoader />}
      <Form
        className="schedule-pn-container"
        name="Push Notification Form"
        form={form}
        autoComplete="off"
      >
        <Col span={1}>{key + 1}.</Col>
        <Col span={2} className="schedule-input scheduledIn">
          <Form.Item
            name="scheduledIn"
            initialValue={data.scheduledIn === 0 ? null : data.scheduledIn}
            rules={[
              {
                required: true,
                message: "Required",
              },
            ]}
          >
            <InputNumber
              disabled={!isActive}
              type="number"
              min={0}
              onChange={(e) => onChange("scheduledIn", e)}
            />
          </Form.Item>
        </Col>
        <Col span={4} className="title schedule-input">
          <Form.Item
            name="title"
            initialValue={data.title}
            rules={[
              {
                required: true,
                message: "Required",
              },
            ]}
          >
            <Input
              disabled={!isActive}
              showCount
              maxLength={100} // aka 'notificationName'
              onChange={(e) => onChange("title", e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={5} className="subtitle schedule-input">
          <Form.Item
            name="subtitle"
            initialValue={data.subtitle}
            rules={[
              {
                required: true,
                message: "Required",
              },
            ]}
          >
            <Input
              disabled={!isActive}
              showCount
              maxLength={200}
              onChange={(e) => onChange("subtitle", e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={5} className="body schedule-input">
          <Form.Item
            initialValue={data.body}
            name="body"
            rules={[
              {
                required: true,
                message: "Required",
              },
            ]}
          >
            <Input
              disabled={!isActive}
              showCount
              maxLength={400}
              onChange={(e) => onChange("body", e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={3} className="offer schedule-input">
          <Form.Item
            initialValue={data.offer || "none"}
            name="offer"
            rules={[
              {
                required: true,
                message: "Required",
              },
            ]}
          >
            <Select
              className="normal-select"
              size="middle"
              onChange={(value) => onChange("offer", value)}
            >
              <Select.Option value="none">None</Select.Option>
              {offerslist.map((e) => (
                <Select.Option value={e.uid}>{e.tag}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={2}>
          {isAnyValueChanged && (
            <Button
              type="link"
              onClick={() => handleSubmit(onSave)}
              className="scehdule-pn-icons save-pn"
            >
              <CheckCircleOutlined />
            </Button>
          )}

          <Button
            disabled={!isActive}
            type="link"
            onClick={() => onRemove(data)}
            className="scehdule-pn-icons delete-pn"
          >
            <MinusCircleOutlined />
          </Button>
        </Col>
      </Form>
      <Divider style={{ margin: "20px 0 0 0" }} />
    </Row>
  );
};

export default SchedulePNForm;
