import { Card, Col, Row } from "antd";
import { FC, useState } from "react";
import { Unsubscribe } from "firebase/auth";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { useEffect } from "react";
import { useAppSelector } from "src/app/hooks";
import { orgConverter } from "src/converters";
import { db, generateFirestorePath } from "src/helpers";
import AppleTeamId from "./components/appleTeamId";
import ApnsKey from "./components/apnsKey";
import DeviceKey from "./components/deviceCheckKey";

const AppleKeys: FC = () => {
  const { user } = useAppSelector((state) => state.userState);
  const [org, setOrg] = useState<Organization>();

  useEffect(() => {
    let unsubOrganizationSnapshot: Unsubscribe;
    if (user && user.activeOrgId) {
      const organizationRef = collection(
        db,
        generateFirestorePath("organizations")
      ).withConverter(orgConverter);

      const orgDoc = doc(organizationRef, user.activeOrgId);
      unsubOrganizationSnapshot = onSnapshot(orgDoc, (orgsSnap) => {
        const data = orgsSnap.data();
        setOrg(data);
      });
    }
    return () => {
      unsubOrganizationSnapshot();
    };
  }, [user]);

  const cardStyles = {
    width: "450px",
    margin: "20px",
  };
  return (
    <>
      <Row justify="start">
        <Col>
          <Card
            title="Apple Team Id"
            headStyle={{ borderWidth: 0, fontSize: 28 }}
            bordered={false}
            style={cardStyles}
          >
            {org && <AppleTeamId org={org} />}
          </Card>
        </Col>
        <Col>
          <Card
            title="APNS Key"
            headStyle={{ borderWidth: 0, fontSize: 28 }}
            bordered={false}
            style={cardStyles}
          >
            {org && <ApnsKey org={org} />}
          </Card>
        </Col>
        <Col>
          <Card
            title="Device Check Key"
            headStyle={{ borderWidth: 0, fontSize: 28 }}
            bordered={false}
            style={cardStyles}
          >
            {org && <DeviceKey org={org} />}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AppleKeys;
