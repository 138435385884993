import { Button, Popover } from "antd";
import { FC, useEffect, useState } from "react";
import { SketchPicker } from "react-color";

const ColorPicker: FC<{
  value: string;
  title: string;
  disabled?: boolean;
  height?: string | number;
  onChange: (e: string) => void;
}> = ({ value, title, onChange, disabled, height }) => {
  const [color, setColor] = useState<string>();

  useEffect(() => {
    if (value) {
      setColor(value);
    }
  }, [value]);

  return (
    <Popover
      content={
        <SketchPicker
          className="primary-color-picker"
          color={color}
          onChangeComplete={(e) => {
            setColor(e.hex);
            onChange(e.hex);
          }}
        />
      }
      title={title}
      trigger="click"
    >
      <Button
        style={{
          background: color,
          color: color,
          border: "5px solid white",
          height: height,
        }}
        disabled={disabled}
      >
        <span className="d-none">-</span>
      </Button>
    </Popover>
  );
};

export default ColorPicker;
