import { FC, useState } from "react";
import { Col, Row, Form, Button, Tag, Input } from "antd";
import { useAppSelector } from "src/app/hooks";
import { axiosInstance } from "src/helpers";
import { AxiosError } from "axios";

const CustomerInfoSettings: FC = () => {
  const { user } = useAppSelector((state) => state.userState);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [error, setError] = useState("");

  function onChange(name: string, value: string | null) {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      [name]: value,
    });
  }

  async function onSave() {
    setError("");
    const { businessName, firstName, lastName } = form.getFieldsValue();
    const isUndefined =
      Object.values({
        businessName,
        firstName,
        lastName,
      }).findIndex((f) => f === undefined || f === null || f === "") !== -1;

    const isError = form.getFieldsError().find((f) => f.errors.length > 0);
    if (isUndefined || isError) {
      setError("Please fill all fields.");
      return;
    }

    setIsLoading(true);
    try {
      const response = await axiosInstance.post(
        `/admin/orgs/${user?.activeOrgId}/dwolla_create_customer`,
        {
          email: user?.email || "",
          businessName: form.getFieldValue("businessName"),
          firstName: form.getFieldValue("firstName"),
          lastName: form.getFieldValue("lastName"),
          correlationId: user?.activeOrgId || "",
          ipAddress: "",
        }
      );

      if (response.status !== 200) {
        setError("An error occurred");
      }
    } catch (error) {
      setError(
        error instanceof AxiosError
          ? error.response?.data.error.body._embedded.errors
              .map((e: Error) => e.message)
              .join(", ")
          : "Unknown Error"
      );
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Form
        name="payments"
        className="spaced-form"
        form={form}
        initialValues={{
          businessName: user?.displayName,
          email: user?.email || "",
          firstName: "",
          lastName: "",
        }}
        onFinish={onSave}
        autoComplete="off"
      >
        <Row justify="center">
          <Col span={14}>
            <Form.Item
              label="Business Name"
              name="businessName"
              rules={[
                {
                  required: true,
                  message: "Business Name is required",
                },
              ]}
            >
              <Input
                name="businessName"
                className="pd-8"
                size="large"
                onChange={(e) => onChange("businessName", e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item label="Email" name="email">
              <Input
                name="email"
                value={user?.email || ""}
                disabled
                className="pd-8"
                size="large"
              />
            </Form.Item>
          </Col>

          <Col span={14}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "First Name is required",
                },
              ]}
            >
              <Input
                name="firstName"
                className="pd-8"
                size="large"
                onChange={(e) => onChange("firstName", e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: "Last Name is required",
                },
              ]}
            >
              <Input
                name="lastName"
                className="pd-8"
                size="large"
                onChange={(e) => onChange("lastName", e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        {error && <Tag color="warning">{error}</Tag>}

        <Row justify="end">
          <Button loading={isLoading} onClick={onSave} type="primary">
            Next
          </Button>
        </Row>
      </Form>
    </>
  );
};

export default CustomerInfoSettings;
