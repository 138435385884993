import {
  Button,
  Col,
  Form,
  InputNumber,
  Row,
  Spin,
  Tag,
  Typography,
} from "antd";
import { FC, useEffect, useState } from "react";
import { axiosInstance } from "src/helpers";
import { WarningOutlined } from "@ant-design/icons";

interface Props {
  org?: Organization;
}

const InitiateMicrodeposit: FC<Props> = ({ org }) => {
  const [status, setStatus] = useState<DwollaMicroDeposits["status"] | null>(
    org?.dwollaFundingSrc?.microdeposits?.status ?? null
  );
  const [form] = Form.useForm();

  const [error, setError] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (org && org.dwollaFundingSrc && org.dwollaFundingSrc.microdeposits) {
      setStatus(org.dwollaFundingSrc.microdeposits.status);
    }
  }, [org]);

  function onChange(name: string, value: string | number | null) {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      [name]: value,
    });
  }

  async function onSubmit() {
    setError("");
    const { amount1, amount2 } = form.getFieldsValue();
    const isUndefined =
      Object.values({
        amount1,
        amount2,
      }).findIndex((f) => f === undefined || f === null || f === "") !== -1;

    const isError = form.getFieldsError().find((f) => f.errors.length > 0);
    if (isUndefined || isError) {
      setError("Please fill all fields.");
      return;
    }

    setIsLoading(true);
    try {
      await axiosInstance.post(
        `/admin/orgs/${org?.uid}/dwolla_verify_microdeposits`,
        {
          amount1: {
            value: `${amount1}`,
            currency: "USD",
          },
          amount2: {
            value: `${amount2}`,
            currency: "USD",
          },
        }
      );
    } catch (error) {
      setError(error instanceof Error ? error.message : "Unknown Error");
    }
    setIsLoading(false);
  }

  if (status === "pending")
    return (
      <Row justify="center">
        We are adding microdeposits to your account. Once added you will be
        prompted to verify the amount of each deposit.
      </Row>
    );
  if (status === "processed")
    return (
      <>
        <Row className="mb-2" justify="center">
          <Typography.Title level={4}>
            Check your account and enter the amount that we sent you.
          </Typography.Title>
        </Row>
        <Form
          name="microdeposits"
          className="spaced-form"
          form={form}
          initialValues={{
            amount1: "",
            amount2: "",
          }}
          onFinish={onSubmit}
          autoComplete="off"
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label="Amount 1 (USD)"
                name="amount1"
                rules={[
                  {
                    required: true,
                    message: "Amount is required",
                  },
                ]}
              >
                <InputNumber
                  name="amount1"
                  type="number"
                  onChange={(e) => onChange("amount1", e)}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Amount 2 (USD)"
                name="amount2"
                rules={[
                  {
                    required: true,
                    message: "Amount is required",
                  },
                ]}
              >
                <InputNumber
                  name="amount2"
                  type="number"
                  onChange={(e) => onChange("amount2", e)}
                />
              </Form.Item>
            </Col>
          </Row>
          {error && (
            <Row>
              <Tag color="orange">{error}</Tag>
            </Row>
          )}
          <Row justify="end">
            <Button loading={isLoading} onClick={onSubmit} type="primary">
              Verify Deposits
            </Button>
          </Row>
        </Form>
      </>
    );
  if (status === null)
    if (org?.dwollaFundingSrc?.status === "verified") {
      // microdeposit success
      return (
        <>
          <Row justify="center">
            <WarningOutlined style={{ fontSize: "4rem", color: "orange" }} />
          </Row>
          <Row justify="center">Successfully verified account.</Row>
        </>
      );
    } else {
      // about to send request to Dwolla
      return (
        <>
          <Row justify="center">
            <Spin />
          </Row>
        </>
      );
    }

  if (status === "failed")
    return (
      <>
        <Row justify="center">
          <WarningOutlined style={{ fontSize: "4rem", color: "orange" }} />
        </Row>
        <Row justify="center">Failed to verify account.</Row>
      </>
    );
  return <></>;
};

export default InitiateMicrodeposit;
