import {
  Col,
  Descriptions,
  Divider,
  Row,
  Skeleton,
  Table,
  TableColumnsType,
  TablePaginationConfig,
  Tag,
  Typography,
} from "antd";
import { Unsubscribe } from "firebase/auth";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { FC, useEffect, useState } from "react";
import { orgTransferConverter, orgConverter } from "src/converters";
import { db, generateFirestorePath } from "src/helpers";
import Currency from "src/shared/components/currency/Currency";
import { dateFormatter } from "src/shared/config/constants";
import TriumphPage from "src/shared/layout/TriumphPage";
import InitiateTransfer from "./initiateTransfer";

const pageinationConfig: TablePaginationConfig = {
  defaultPageSize: 30,
  className: "mobile-pagination-large",
};

const statusCode = {
  "in-progress": "blue",
  success: "green",
  failed: "red",
  refunded: "orange",
} as const;

interface Props {
  org?: Organization;
  onboardingComplete: boolean;
}

const BalancesSettings: FC<Props> = ({ org, onboardingComplete }) => {
  const [orgTransferInGoing, setOrgTransferInGoing] = useState<{
    [x: string]: OrgDwollaTransfer;
  }>({});

  const [orgTransferOutGoing, setOrgTransferOutGoing] = useState<{
    [x: string]: OrgDwollaTransfer;
  }>({});

  const [totalPaidEarning, setTotalPaidEarnings] = useState(0);

  useEffect(() => {
    let unsubOrgTransferSnapshot: Unsubscribe | undefined;
    if (org) {
      const organizationRef = collection(
        db,
        generateFirestorePath("organizations")
      ).withConverter(orgConverter);

      const orgDoc = doc(organizationRef, org.uid);

      const orgTransferRef = collection(orgDoc, "orgTransfers").withConverter(
        orgTransferConverter
      );

      unsubOrgTransferSnapshot = onSnapshot(
        orgTransferRef,
        (orgTransfernap) => {
          const inGoingdefs: typeof orgTransferInGoing = {};
          const outGoingDefs: typeof orgTransferOutGoing = {};
          for (const orgTransferDefSnap of orgTransfernap.docs) {
            const orgTransferDefId = orgTransferDefSnap.id;
            const orgTransferDef = orgTransferDefSnap.data();
            if (orgTransferDef.direction === "incoming") {
              inGoingdefs[orgTransferDefId] = orgTransferDef;
            } else if (orgTransferDef.direction === "outgoing") {
              outGoingDefs[orgTransferDefId] = orgTransferDef;
            }
          }
          const totalPaid = Object.values(outGoingDefs).reduce((prev, curr) => {
            return prev + curr.amount;
          }, 0);

          setTotalPaidEarnings(totalPaid);
          setOrgTransferOutGoing(outGoingDefs);
          setOrgTransferInGoing(inGoingdefs);
        }
      );
    }
    return () => {
      unsubOrgTransferSnapshot?.();
    };
  }, [org]);

  if (!org) return <Skeleton title paragraph avatar />;

  const columns: TableColumnsType<OrgDwollaTransfer> = [
    {
      title: "status",
      dataIndex: "status",
      key: "status",
      width: 120,
      render: (v: OrgDwollaTransfer["status"]) => (
        <Tag color={statusCode[v]}>{v}</Tag>
      ),
    },
    {
      title: "Transfer Type",
      dataIndex: "direction",
      key: "direction",
      width: 350,
      render: (v: OrgDwollaTransfer["direction"]) =>
        v === "outgoing" ? <>Withdrawal</> : <>Deposit</>,
    },
    {
      title: "createdAt",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 350,
      render: (v) => <>Created at {dateFormatter(v)}</>,
      sortOrder: "descend",
      sorter: (a, b) => a.createdAt - b.createdAt,
    },
    {
      title: "amount",
      dataIndex: "amount",
      key: "amount",
      width: 150,
      render: (v) => (
        <Currency currency="cents-to-dollar" prefix="$" value={v} />
      ),
    },
  ];
  return (
    <TriumphPage>
      <Row justify="end">
        {onboardingComplete && <InitiateTransfer org={org} />}
      </Row>
      <Row>
        <Col span={8}>
          <Typography.Title level={4}>Balance</Typography.Title>
          <Descriptions>
            <Descriptions.Item label="Currently your balance is">
              <Currency
                value={org.balance}
                currency="cents-to-dollar"
                prefix="$"
              />
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={8}>
          <Typography.Title level={4}>Promo Balance</Typography.Title>
          <Descriptions>
            <Descriptions.Item label="Currently your promo balance is">
              <Currency
                value={org.promoBalance}
                currency="cents-to-dollar"
                prefix="$"
              />
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={8}>
          <Typography.Title level={4}>Total Paid</Typography.Title>
          <Descriptions>
            <Descriptions.Item label="Lifetime earnings: ">
              <Currency
                value={totalPaidEarning}
                currency="cents-to-dollar"
                prefix="$"
              />
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Row>
        <Col
          span={17}
          style={{
            marginRight: "10px",
            paddingRight: "10px",
          }}
        >
          <Row>
            <Divider />
            <Col span={24}>
              <Typography.Title level={4}>
                Recent outgoing transfers
              </Typography.Title>
            </Col>
            <Col span={24}>
              <Table
                columns={columns}
                loading={!orgTransferOutGoing}
                rowKey="transactionId"
                dataSource={Object.values(orgTransferOutGoing)}
                showHeader={false}
                pagination={pageinationConfig}
                className="mobile-table-large"
              />
            </Col>
          </Row>
          <Row>
            <Divider />
            <Col span={24}>
              <Typography.Title level={4}>
                Recent incoming transfers
              </Typography.Title>{" "}
            </Col>
            <Col span={24}>
              <Table
                columns={columns}
                loading={!orgTransferInGoing}
                rowKey="transactionId"
                dataSource={Object.values(orgTransferInGoing)}
                showHeader={false}
                pagination={pageinationConfig}
                className="mobile-table-large"
              />
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <div className="guidance-box">
              <Row>
                <Typography.Text strong>
                  Get Paid Straight to Your Bank
                </Typography.Text>
              </Row>
              <Row>
                <Typography.Text>
                  See complete detail and history of all incoming and outgoing
                  transfers.
                </Typography.Text>
              </Row>
            </div>
          </Row>
        </Col>
      </Row>
    </TriumphPage>
  );
};

export default BalancesSettings;
