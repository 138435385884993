import { Descriptions, Typography } from "antd";
import { FC } from "react";
import { useMediaQuery } from "react-responsive";
import { dateFormatter } from "src/shared/config/constants";

interface Props {
  details: UserState & { id: string };
}
const UserStateNotes: FC<Props> = ({ details }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });

  return (
    <>
      {Object.keys(details.notes).length > 0 && (
        <Typography.Title level={5}>Notes </Typography.Title>
      )}
      {Object.entries(details.notes).map(([id, record]) => (
        <Descriptions
          layout={!isMobile ? "horizontal" : "vertical"}
          column={1}
          bordered
          title={id}
          className="pb-2"
        >
          <Descriptions.Item label="Content Type">
            {record.contentType}
          </Descriptions.Item>
          <Descriptions.Item label="Contents">
            {record.contents}
          </Descriptions.Item>
          <Descriptions.Item label="Created At">
            {dateFormatter(record.createdAt)}
            <Descriptions.Item label="Created By Uid">
              {record.createdByUid}
            </Descriptions.Item>
            <Descriptions.Item label="Event Sequence Id">
              {record.eventSequenceId}
            </Descriptions.Item>
          </Descriptions.Item>
        </Descriptions>
      ))}
    </>
  );
};

export default UserStateNotes;
