import { Avatar, Menu, MenuProps, message } from "antd";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { fetchUser } from "src/features/authentication/userSlice";
import { auth, axiosInstance, db, generateFirestorePath } from "src/helpers";
import { PlusOutlined } from "@ant-design/icons";
import React from "react";
import NewOrg from "./NewOrg";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { adminUserConverter } from "src/converters";
import { getOrganizations } from "src/features/orgs/orgSlice";
import {
  clearStats,
  fetchStats,
} from "src/features/statistics/statisticsSlice";
import { getGames } from "src/features/games/gamesSlice";
import OverlayLoader from "src/shared/components/overlayLoader/overlayLoader";

const OrgMenu: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [addingOrg, setAddingOrg] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { user } = useAppSelector((state) => state.userState);
  const { orgs } = useAppSelector((state) => state.orgState);
  const [selectedOrg, setSelectedOrg] = React.useState<string | null>(
    user?.activeOrgId ?? null
  );
  const [messageApi, contextHolder] = message.useMessage();

  const newOrgKey = "create-new-org";

  /** fetch user admin on org change */
  useEffect(() => {
    const adminUsersColRef = collection(
      db,
      generateFirestorePath("adminUsers")
    ).withConverter(adminUserConverter);
    const uid = auth.currentUser?.uid;
    if (!uid) {
      return;
    }

    const adminUserRef = doc(adminUsersColRef, uid);
    onSnapshot(adminUserRef, async () => {
      await dispatch(fetchUser());
    });
  }, [dispatch, selectedOrg]);

  /** fetch orgs the user is apart of */
  useEffect(() => {
    const fetchOrgs = async () => {
      await dispatch(getOrganizations());
    };
    fetchOrgs();
  }, [dispatch, user]);

  /** fetch other org info (games / stats) for this user */
  useEffect(() => {
    const fetchOrgData = async () => {
      if (orgs) {
        await Promise.all([dispatch(getGames()), dispatch(fetchStats())]);
      } else {
        dispatch(clearStats());
      }
    };
    fetchOrgData();
  }, [dispatch, orgs]);

  async function handleOrgSelection(orgId: string) {
    if (orgId === newOrgKey) {
      setAddingOrg(true);
    } else {
      await switchActiveOrg(orgId);
    }
  }

  async function switchActiveOrg(orgId: string) {
    try {
      const switchOrgRequestBody = {
        orgId,
      };
      if (!user) {
        return;
      }

      setIsLoading(true);
      await axiosInstance.post(`/admin/users/switch_org`, switchOrgRequestBody);
      setSelectedOrg(orgId);
      navigate("/");
    } catch (error) {
      console.error(error);
      messageApi.error("Failed to switch Organization!");
    } finally {
      setIsLoading(false);
    }
  }

  const orgSelectionList = user?.activeOrgId
    ? /** if user has active org, show that org name */
      {
        label: orgs[user?.activeOrgId ?? ""]?.name,
        key: "org-name",
        children: [
          ...Object.values(orgs).map((org) => {
            return {
              label: org.name,
              key: org.uid,
            };
          }),
          /**
           * don't allow any admin user to create / be a member of more than 10 orgs for now
           * due to the firebase 'in' operator limit
           */
          user.orgIds.length < 10 && {
            label: "Add New Organization",
            key: newOrgKey,
            icon: (
              <Avatar shape="square" size="small" icon={<PlusOutlined />} />
            ),
          },
        ],
      }
    : /** if user has no active org Id, default to add new organization */
      {
        label: "Add New Organization",
        key: newOrgKey,
        icon: <Avatar shape="square" size="small" icon={<PlusOutlined />} />,
      };

  const orgMenuItems: MenuProps["items"] = [orgSelectionList];

  return (
    <>
      {contextHolder}
      {isLoading && <OverlayLoader />}
      <NewOrg
        open={addingOrg}
        setSelectedOrg={setSelectedOrg}
        setAddingOrg={setAddingOrg}
      />
      <Menu
        className="text-disable-button"
        style={{ display: "inline-block" }}
        selectable={false}
        items={orgMenuItems}
        onClick={async (e) => await handleOrgSelection(e.key)}
      />
    </>
  );
};

export default OrgMenu;
