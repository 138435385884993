import React, { FC, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  TableColumnsType,
  Typography,
  Row,
  Col,
  DatePicker,
  Button,
} from "antd";
import {
  onSnapshot,
  query,
  where,
  orderBy,
  QueryDocumentSnapshot,
  startAfter,
  limit,
  endBefore,
  limitToLast,
  QueryConstraint,
  Unsubscribe,
  getDocs,
} from "firebase/firestore";
import csvDownload from "json-to-csv-export";
import { colGroupNames, getCollectionGroup } from "src/collections";
import { isTriumphEmployee } from "src/helpers";
import dayjs from "dayjs";
import UserInfo from "../../../balanceTransactions/userInfo/UserInfo";
import { getAppUserDetails } from "src/features/appUsers/AppUserSlice";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import UserBalanceTransactionDetails from "../../../balanceTransactions/TransactionModal";
import { LinkOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Currency from "src/shared/components/currency/Currency";
import moment from "moment";
import Pagination from "src/shared/components/pagination/Pagination";
import { PAGE_SIZE_OPTIONS } from "src/shared/config/constants";
import TriumphPage from "src/shared/layout/TriumphPage";
import GameName from "src/shared/components/GameName/gameName";
/** type imports */
import type { TablePaginationConfig } from "antd";
import type { RangeValue } from "rc-picker/lib/interface";

type TrxTableProps = Exclude<BalanceTransaction, TriumphDepositTrx>;

const defaultPageSize = 100;

const AllBalanceTransactions: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.userState);
  const { listOfGames } = useAppSelector((state) => state.gameState);

  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  // const [usersPublicDetails, setUsersPublicDetails] = useState<{
  //   [userId: string]: AppUserPublic;
  // } | null>(null);
  const [selectedRow, setSelectedRow] = useState<BalanceTransaction | null>(
    null
  );
  const [transactions, setTransactions] = useState<{
    [transactionId: string]: Exclude<BalanceTransaction, TriumphDepositTrx>;
  } | null>(null);
  const [dateFilter, setDateFilter] = useState<RangeValue<moment.Moment>>();
  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [tableQuery, setTableQuery] = useState<{
    [x: string]: QueryConstraint;
  }>({});
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [lastVisible, setLastVisible] =
    useState<QueryDocumentSnapshot<BalanceTransaction> | null>(null);
  const [firstVisible, setFirstVisible] =
    useState<QueryDocumentSnapshot<BalanceTransaction> | null>(null);

  const { orgs } = useAppSelector((state) => state.orgState);

  React.useEffect(() => {
    let unsubTournCol: Unsubscribe | undefined;
    if (tableQuery) {
      unsubTournCol?.();
      setIsLoading(true);
      const balanceTransactions = getCollectionGroup(
        colGroupNames.balanceTransactions
      );
      const q = query(
        balanceTransactions,
        // where("orgId", "==", user?.activeOrgId),
        orderBy("createdAt", "desc"),
        ...Object.values(tableQuery),
        ...(Object.keys(tableQuery).length === 0 ? [limit(pageSize)] : [])
      );

      unsubTournCol = onSnapshot(
        q,
        (colSnap) => {
          const defs: typeof transactions = {};
          for (const tourDefSnap of colSnap.docs) {
            const tourDefId = tourDefSnap.id;
            const tourDef = tourDefSnap.data();
            if (tourDef.type === "triumph-deposit") continue;
            defs[tourDefId] = tourDef;
          }

          setLastVisible(colSnap.docs[colSnap.docs.length - 1] ?? lastVisible);
          setFirstVisible(colSnap.docs[0] ?? firstVisible);
          setIsLoading(false);
          setTransactions(defs);
        },
        (error) => console.error(error.message)
      );
    }
    return () => {
      unsubTournCol?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableQuery]);

  // React.useEffect(() => {
  //   const usersPublicDetailsColRef = getCollection(colNames.appUsersPublic);

  //   const unsubUserPublicDetails = onSnapshot(
  //     usersPublicDetailsColRef,
  //     (usersSnap) => {
  //       const defs: typeof usersPublicDetails = {};
  //       for (const userDefSnap of usersSnap.docs) {
  //         const userDefId = userDefSnap.id;
  //         const userDef = userDefSnap.data();
  //         defs[userDefId] = userDef;
  //       }
  //       setUsersPublicDetails(defs);
  //     },
  //     (error) => console.error(error.message)
  //   );
  //   return () => {
  //     unsubUserPublicDetails();
  //   };
  // }, []);

  async function saveCSV(colKeys: string[]) {
    const exportTrxs: object[] = [];
    const balanceTransactions = getCollectionGroup(
      colGroupNames.balanceTransactions
    );
    const downloadQuery = query(
      balanceTransactions,
      where("orgId", "==", user?.activeOrgId),
      orderBy("createdAt", "desc"),
      ...Object.values(tableQuery)
    );
    const querySnapshot = await getDocs(downloadQuery);
    querySnapshot.forEach((doc) => {
      const balTrx = doc.data();
      const entry = {
        gameId: balTrx.type === "triumph-deposit" ? "null" : balTrx.gameId,
        type: balTrx.type,
        orgAmount: balTrx.orgAmount,
        orgPromoAmount: balTrx.orgPromoAmount,
        triumphAmount: balTrx.triumphAmount,
        amount: balTrx.amount,
        bonusCashAmount: balTrx.bonusCashAmount,
        createdAt: balTrx.createdAt,
        appUserUid: balTrx.appUserUid,
        uid: balTrx.uid,
      };

      exportTrxs.push(entry);
    });
    const data = {
      data: exportTrxs,
      filename: `export_transactions`,
      delimiter: ",",
      headers: colKeys,
    };
    csvDownload(data);
  }

  const typeFilters: { text: string; value: BalanceTransaction["type"] }[] =
    Object.values(
      Object.values(transactions ?? {}).reduce<{
        [type: string]: { text: string; value: BalanceTransaction["type"] };
      }>((aggreg, trx) => {
        if (trx.type in aggreg) {
          return aggreg;
        } else {
          aggreg[trx.type] = { text: trx.type, value: trx.type };
          return aggreg;
        }
      }, {})
    );

  const gameFilter: { text: string; value: string }[] = Object.values(
    Object.values(transactions ?? {}).reduce<{
      [game: string]: { text: string; value: string };
    }>((aggreg, trx) => {
      const game = "gameId" in trx ? trx.gameId : null;
      if (!game) {
        return aggreg;
      } else if (game in aggreg) {
        return aggreg;
      } else {
        aggreg[game] = {
          text: listOfGames.find((f) => f.id === game)?.name || game,
          value: game,
        };
        return aggreg;
      }
    }, {})
  );

  const orgFilter: { text: string; value: string }[] = Object.values(
    Object.values(transactions ?? {}).reduce<{
      [game: string]: { text: string; value: string };
    }>((aggreg, trx) => {
      const orgId = "orgId" in trx ? trx.orgId : null;
      if (!orgId) {
        return aggreg;
      } else if (orgId in aggreg) {
        return aggreg;
      } else {
        if (orgs[orgId]) {
          aggreg[orgId] = {
            text: orgs[orgId].name,
            value: orgId,
          };
        }
        return aggreg;
      }
    }, {})
  );

  const tournamentFilter: {
    text: string;
    value: string;
  }[] = Object.values(
    Object.values(transactions ?? {}).reduce<{
      [tournamentId: string]: { text: string; value: string };
    }>((aggreg, trx) => {
      if ("tournamentId" in trx && trx.tournamentId in aggreg) {
        return aggreg;
      } else {
        aggreg["tournamentId" in trx ? trx.tournamentId : "No tournament"] = {
          text: "tournamentId" in trx ? trx.tournamentId : "No tournament",
          value: "tournamentId" in trx ? trx.tournamentId : "No tournament",
        };
        return aggreg;
      }
    }, {})
  );

  const columns: TableColumnsType<TrxTableProps> = [
    {
      title: "Game",
      dataIndex: "gameId",
      key: "gameId",
      render: (value) => <GameName id={value} />,
      filters: gameFilter,
      onFilter: (value, record) =>
        "gameId" in record ? record.gameId === value : false,
    },
    {
      title: "Org Id",
      dataIndex: "orgId",
      key: "orgId",
      render: (value) => orgs[value].name,
      filters: orgFilter,
      onFilter: (value, record) =>
        "orgId" in record ? record.orgId === value : false,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      filters: typeFilters,
      onFilter: (value, record) => record.type === value,
    },
    {
      title: "Tournament Id",
      dataIndex: "tournamentId",
      key: "tournamentId",
      filters: tournamentFilter,
      onFilter: (value, record) => {
        if ("tournamentId" in record) return record.tournamentId === value;
        return value === "No tournament";
      },
      render: (value) => value ?? "No tournament",
    },
    {
      title: "Org Amount",
      dataIndex: "orgAmount",
      key: "orgAmount",
      sorter: (a, b) => a.orgAmount - b.orgAmount,
      render: (orgAmount: number) => (
        <Currency value={orgAmount} currency="cents-to-dollar" prefix="$" />
      ),
    },
    {
      title: "Org Promo Amount",
      dataIndex: "orgPromoAmount",
      key: "orgPromoAmount",
      sorter: (a, b) => a.orgPromoAmount - b.orgPromoAmount,
      render: (orgPromoAmount: number) => (
        <Currency
          value={orgPromoAmount}
          currency="cents-to-dollar"
          prefix="$"
        />
      ),
    },
    {
      title: "Triumph Amount",
      dataIndex: "triumphAmount",
      key: "triumphAmount",
      sorter: (a, b) => a.triumphAmount - b.triumphAmount,
      render: (triumphAmount) => (
        <Currency value={triumphAmount} currency="cents-to-dollar" prefix="$" />
      ),
    },
    {
      title: "User Amount",
      dataIndex: "amount",
      key: "amount",
      sorter: (a, b) => a.amount - b.amount,
      render: (amount: number) => (
        <Currency value={amount} currency="cents-to-dollar" prefix="$" />
      ),
    },
    {
      title: "User Bonus Cash Amount",
      dataIndex: "bonusCashAmount",
      key: "bonusCashAmount",
      sorter: (a, b) => a.bonusCashAmount - b.bonusCashAmount,
      render: (amount: number) => (
        <Currency value={amount} currency="cents-to-dollar" prefix="$" />
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
      render: (a: number) => dayjs(a).format("M/D/YY h:mm A"),
      defaultSortOrder: "descend",
    },
    {
      title: "User",
      dataIndex: "appUserUid",
      key: "appUserUid",
      render: (id: string) => (
        <Row>
          <Col>
            <Typography.Link
              onClick={(e) => {
                if (isTriumphEmployee()) {
                  e.stopPropagation();
                  onSelectUser(id);
                }
              }}
              className={`mr-1 ${!isTriumphEmployee() ? "disabled-link" : ""}`}
            >
              {id}
            </Typography.Link>
          </Col>
          {isTriumphEmployee() && (
            <Col>
              <LinkOutlined onClick={() => navigate(`/users/${id}`)} />
            </Col>
          )}
        </Row>
      ),
    },
    {
      title: "Balance Transaction ID",
      dataIndex: "uid",
      key: "uid",
      render: (id, record) => {
        if (
          [
            "finish-blitz",
            "start-blitz",
            "referrer-bonus",
            "start-group-tournament",
            "finish-group-tournament",
            "leaderboard-award",
            "new-game",
            "account-creation-deposit",
            "triumph-deposit",
            "deposit",
            "withdrawal",
            "withdrawal-redeposit",
          ].includes(record.type)
        ) {
          return (
            <Typography.Link
              onClick={(e) => {
                e.stopPropagation();
                setSelectedRow(record);
              }}
            >
              {id}
            </Typography.Link>
          );
        }
        return id;
      },
    },
    {
      title: "Tournament ID",
      dataIndex: "tournamentId",
      key: "tournamentId",
      render: (id, record) => {
        switch (record.type) {
          case "finish-blitz":
          case "start-blitz":
            return (
              <Link
                className="ant-typography"
                to={`/games/${record.gameId}/tournaments/blitz/${record.tournamentId}`}
              >
                {id}
              </Link>
            );
          case "finish-group-tournament":
          case "start-group-tournament":
          case "start-tournament":
          case "finish-tournament":
            return (
              <Link
                className="ant-typography"
                to={`/games/${record.gameId}/tournaments/asyncgroupv1/${record.tournamentId}`}
              >
                {id}
              </Link>
            );
          default:
            return id;
        }
      },
    },
  ];

  function onSelectUser(id: string) {
    dispatch(getAppUserDetails(id));
    setSelectedUser(id);
  }

  function resetFilters() {
    setPageSize(defaultPageSize);
    setFirstVisible(null);
    setLastVisible(null);
  }

  function computeQueries(
    type: "next" | "prev" | "page-size" | "date",
    filter?: number | RangeValue<moment.Moment>
  ) {
    let q: typeof tableQuery = {};
    switch (type) {
      case "date": {
        q = {
          "date-start": where(
            "createdAt",
            ">=",
            typeof filter !== "number" && filter?.[0]?.valueOf()
          ),
          "date-end": where(
            "createdAt",
            "<=",
            typeof filter !== "number" && filter?.[1]?.valueOf()
          ),
        };
        break;
      }
      case "next": {
        q = {
          ...tableQuery,
          next: startAfter(lastVisible),
          "page-size": limit(pageSize),
        };
        break;
      }
      case "prev": {
        q = {
          ...tableQuery,
          next: endBefore(firstVisible),
          "page-size": limitToLast(pageSize + 1),
        };
        break;
      }
      case "page-size": {
        if (typeof filter === "number") {
          setPageSize(filter);
          q = { ...tableQuery, "page-size": limit(filter) };
        }
        break;
      }
    }
    setTableQuery(q);
  }

  const paginationConfig: TablePaginationConfig = {
    pageSize: pageSize,
    onChange: (page, size) => setPageSize(size),
    pageSizeOptions: PAGE_SIZE_OPTIONS.map((e) => `${e}`),
    className: `mobile-pagination-small ${!dateFilter && "hide-pagination"}`,
  };

  const dataSource = useMemo(() => {
    return Object.entries(transactions ?? {}).map(
      ([id, transaction]): TrxTableProps => {
        switch (transaction.type) {
          case "start-blitz":
          case "finish-blitz":
          case "referrer-bonus":
          case "finish-group-tournament":
          case "start-group-tournament":
          case "new-game":
          case "account-creation-deposit":
          case "deposit":
          case "withdrawal":
          case "withdrawal-redeposit": {
            return transaction;
          }
          default: {
            return {
              ...transaction,
              gameId:
                "gameId" in transaction ? transaction.gameId ?? "none" : "none",
            };
          }
        }
      }
    );
  }, [transactions]);

  return (
    <TriumphPage>
      <UserBalanceTransactionDetails
        selectedRow={selectedRow}
        onClose={() => setSelectedRow(null)}
      />
      <UserInfo user={selectedUser} onClose={() => setSelectedUser(null)} />

      <Row className="pd-8" justify="space-between">
        <Row style={{ width: "fit-content" }}>
          <Button
            shape="round"
            onClick={async () => {
              setIsExporting(true);
              await saveCSV(columns.map((col) => col.key as string));
              setIsExporting(false);
            }}
          >
            {isExporting ? "Exporting..." : "Export to .csv"}
          </Button>
        </Row>
        {dateFilter && (
          <Button
            type="dashed"
            onClick={() => {
              resetFilters();
              setDateFilter(null);
              setTableQuery({});
            }}
          >
            Reset to first {defaultPageSize} records
          </Button>
        )}
        <DatePicker.RangePicker
          value={dateFilter}
          onChange={(e) => {
            resetFilters();
            setDateFilter(e);
            computeQueries("date", e);
          }}
        />
      </Row>
      <Table
        columns={columns}
        loading={transactions === null || isLoading}
        rowKey="uid"
        dataSource={dataSource}
        pagination={paginationConfig}
        className="mobile-table-small"
      />
      {!dateFilter && (
        <Pagination
          pageSize={pageSize}
          onNext={() => {
            computeQueries("next");
          }}
          onPrevious={() => {
            computeQueries("prev");
          }}
          onPageSize={(size: number) => {
            setPageSize(size);
            computeQueries("page-size", size);
          }}
        />
      )}
    </TriumphPage>
  );
};

export default AllBalanceTransactions;
