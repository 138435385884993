import { Button, Form, Input, Row, Tag } from "antd";
import { ChangeEvent, FC, useState } from "react";
import { useAppSelector } from "src/app/hooks";
import { axiosInstance } from "src/helpers";
import { CheckCircleFilled } from "@ant-design/icons";

interface Props {
  org?: Organization;
}

const ApnsKey: FC<Props> = ({ org }) => {
  const [form] = Form.useForm();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAppSelector((state) => state.userState);
  const [image, setImage] = useState<File>();

  function onChange(name: string, value: string | number | null) {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      [name]: value,
    });
  }

  async function onChangeImage(e: ChangeEvent<HTMLInputElement>) {
    const file = e.currentTarget.files?.[0];
    setError("");
    setIsLoading(false);
    setImage(file);
  }

  async function onSubmit() {
    setError("");
    const { apnsKeyId } = form.getFieldsValue();

    if (!image) {
      setError("Please upload apple notification service key");
      return;
    }
    const isUndefined =
      Object.values({
        apnsKeyId,
      }).findIndex((f) => f === undefined || f === null || f === "") !== -1;

    const isError = form.getFieldsError().find((f) => f.errors.length > 0);
    if (isUndefined || isError) {
      setError("Please fill all fields.");
      return;
    }

    const content = await readFileContent(image);
    setIsLoading(true);
    try {
      await axiosInstance.post(`/admin/orgs/${user?.activeOrgId}/apns_key`, {
        keyId: apnsKeyId,
        key: content,
      });
    } catch (error) {
      setError(error instanceof Error ? error.message : "Unknown Error");
    } finally {
      setIsLoading(false);
    }
  }

  function readFileContent(file: File) {
    const reader = new FileReader();
    return new Promise<string | ArrayBuffer | null | undefined>(
      (resolve, reject) => {
        reader.onload = (event) => {
          const result = event?.target?.result;
          return resolve(result);
        };
        reader.onerror = (error) => reject(error);
        reader.readAsText(file);
      }
    );
  }

  return (
    <>
      {" "}
      <Form
        name="apnsKeyId"
        className="spaced-form apple-team-id"
        form={form}
        onFinish={onSubmit}
        autoComplete="off"
      >
        <Row>
          <Form.Item
            label="Apple APNS Key ID"
            name="apnsKeyId"
            initialValue={org?.apnsKeyId}
            rules={[
              {
                required: true,
                message: "Apple APNS Key ID is required",
              },
            ]}
          >
            <Input
              name="apnsKeyId"
              className="pd-8"
              size="middle"
              disabled={org?.apnsStatus === "key-uploaded"}
              onChange={(event) => onChange("apnsKeyId", event.target.value)}
            />
          </Form.Item>
        </Row>
        {org?.apnsStatus !== "key-uploaded" && (
          <Row>
            {!isLoading ? (
              <input type="file" accept=".p8" onChange={onChangeImage} />
            ) : (
              <></>
            )}
          </Row>
        )}
        {error && (
          <Row>
            <Tag color="orange">{error}</Tag>
          </Row>
        )}
        {org?.apnsStatus !== "key-uploaded" ? (
          <Row justify="end">
            <Button loading={isLoading} onClick={onSubmit} type="primary">
              Submit
            </Button>
          </Row>
        ) : (
          <>
            <CheckCircleFilled /> Uploaded!
          </>
        )}
      </Form>
    </>
  );
};

export default ApnsKey;
