import { Button, Col, Form, Input, Row, Tag, Typography } from "antd";
import { FC, useState } from "react";
import { useAppSelector } from "src/app/hooks";
import { axiosInstance } from "src/helpers";
interface Props {
  onNext: () => void;
}

const MerchantIdForm: FC<Props> = ({ onNext }) => {
  const [form] = Form.useForm();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAppSelector((state) => state.userState);

  function onChange(name: string, value: string | number | null) {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      [name]: value,
    });
  }

  async function onSubmit() {
    setError("");
    const { merchantId } = form.getFieldsValue();
    const isUndefined =
      Object.values({
        merchantId,
      }).findIndex((f) => f === undefined || f === null || f === "") !== -1;

    const isError = form.getFieldsError().find((f) => f.errors.length > 0);
    if (isUndefined || isError) {
      setError("Please fill all fields.");
      return;
    }

    setIsLoading(true);
    try {
      await axiosInstance.post(
        `/admin/orgs/${user?.activeOrgId}/apple_pay_merchant_id`,
        {
          merchantId,
        }
      );
      onNext();
    } catch (error) {
      setError(error instanceof Error ? error.message : "Unknown Error");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Row className="mb-2 pb-2" justify="center">
        <Typography.Title level={5}>
          Go to apple pay and create your merchant id and fill the form below.
        </Typography.Title>
      </Row>
      <Form
        name="merchantId"
        className="spaced-form"
        form={form}
        initialValues={{
          merchantId: "",
        }}
        onFinish={onSubmit}
        autoComplete="off"
      >
        <Row justify="center">
          <Col span={14}>
            <Form.Item
              label="Merchant Id"
              name="merchantId"
              rules={[
                {
                  required: true,
                  message: "Merchant Id is required",
                },
              ]}
            >
              <Input
                name="merchantId"
                className="pd-8"
                size="middle"
                onChange={(event) => onChange("merchantId", event.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        {error && (
          <Row>
            <Tag color="orange">{error}</Tag>
          </Row>
        )}
        <Row justify="end">
          <Button loading={isLoading} onClick={onSubmit} type="primary">
            Submit Merchant Id
          </Button>
        </Row>
      </Form>
    </>
  );
};

export default MerchantIdForm;
