import { FC, useEffect, useState } from "react";
import {
  Modal,
  Avatar,
  Skeleton,
  Row,
  Col,
  Typography,
  Divider,
  Tag,
  Descriptions,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import { dateFormatter } from "src/shared/config/constants";
import Currency from "src/shared/components/currency/Currency";
import { useMediaQuery } from "react-responsive";
import {
  collection,
  documentId,
  onSnapshot,
  query,
  Unsubscribe,
  where,
} from "firebase/firestore";
import { db, getCdnPhoto } from "src/helpers";
import { appUsersConverter, appUsersPublicConverter } from "src/converters";
import UserActionButtons from "src/components/userDetail/components/actions";

const { Title } = Typography;
interface Props {
  user: string | null;
  onClose: () => void;
}
const UserInfo: FC<Props> = ({ onClose, user }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  const lg = useMediaQuery({ maxWidth: 1600, minWidth: 800 });
  const xl = useMediaQuery({ minWidth: 1600 });

  const [selectedUser, setSelectedUser] = useState<AppUser & AppUserPublic>();

  useEffect(() => {
    let unsubUsersPublicCol: Unsubscribe;
    let unsubUsersCol: Unsubscribe;
    if (user) {
      const appUsersColRef = collection(db, "appUsers").withConverter(
        appUsersConverter
      );

      const appUserQuery = query(
        appUsersColRef,
        where(documentId(), "==", user)
      );

      const appUsersPublicColRef = collection(
        db,
        "appUsersPublic"
      ).withConverter(appUsersPublicConverter);

      const appUserPublcQuery = query(
        appUsersPublicColRef,
        where(documentId(), "==", user)
      );
      unsubUsersPublicCol = onSnapshot(appUserPublcQuery, (colSnap) => {
        setSelectedUser(
          (data) =>
          ({
            ...data,
            ...colSnap.docs[0].data(),
          } as AppUser & AppUserPublic)
        );
      });
      unsubUsersCol = onSnapshot(appUserQuery, (colSnap) => {
        setSelectedUser(
          (data) =>
          ({
            ...data,
            ...colSnap.docs[0].data(),
          } as AppUser & AppUserPublic)
        );
      });
    }
    return () => {
      unsubUsersCol?.();
      unsubUsersPublicCol?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  function onCloseDialog() {
    onClose();
  }

  const columnSize = () => {
    if (xl) return 3;
    if (lg) return 2;
    if (isMobile) return 1;
    return 3;
  };

  return (
    <>
      <Modal
        title="User Details"
        centered
        open={!!user}
        onOk={onCloseDialog}
        onCancel={onCloseDialog}
        width={"70vw"}
      >
        {selectedUser ? (
          <>
            {" "}
            <Row align="middle">
              <Col span={!isMobile ? 3 : 9}>
                <Avatar
                  src={getCdnPhoto(selectedUser.profilePhotoPath)}
                  size={64}
                  icon={<UserOutlined />}
                />
              </Col>
              <Col>
                <Title level={3}>{selectedUser.username}</Title>
              </Col>
            </Row>
            <Divider />
            <Row justify="end">
              <UserActionButtons hideUserStateFields user={selectedUser} />
            </Row>
            <Divider />{" "}
            <Descriptions
              layout={!isMobile ? "horizontal" : "vertical"}
              column={columnSize()}
              bordered
              title="Personal Details"
              className="pb-2"
            >
              <Descriptions.Item label="User Name">
                {selectedUser.username}
              </Descriptions.Item>
              <Descriptions.Item label="UID">
                {selectedUser.uid}
              </Descriptions.Item>
              <Descriptions.Item label="Joining Date">
                {dateFormatter(selectedUser.createdAt)}
              </Descriptions.Item>
              <Descriptions.Item label="Last Updated">
                {dateFormatter(selectedUser.updatedAt)}
              </Descriptions.Item>

              <Descriptions.Item label="Status">
                <Tag color={selectedUser.banned ? "red" : "green"}>
                  {selectedUser.banned ? "Banned" : "Not Banned"}
                </Tag>
              </Descriptions.Item>

              <Descriptions.Item label="KYC Status">
                {selectedUser.kycStatus ? "Yes" : "No"}
              </Descriptions.Item>
              <Descriptions.Item label="isInSupportedLocation">
                {selectedUser.isInSupportedLocation ? "Yes" : "No"}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions
              title="Balance"
              layout={!isMobile ? "horizontal" : "vertical"}
              column={columnSize()}
              bordered
            >
              <Descriptions.Item label="Balance">
                <Currency
                  currency="cents-to-dollar"
                  prefix="$"
                  value={selectedUser.balance}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Bonus Cash Balance">
                <Currency
                  currency="cents-to-dollar"
                  prefix="$"
                  value={selectedUser.bonusCashBalance}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Withdrawable Balance">
                <Currency
                  currency="cents-to-dollar"
                  prefix="$"
                  value={selectedUser.withdrawableBalance}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Gem Balance">
                {selectedUser.gemBalance}
              </Descriptions.Item>
            </Descriptions>
          </>
        ) : (
          <Skeleton avatar title paragraph />
        )}
      </Modal>
    </>
  );
};

export default UserInfo;
