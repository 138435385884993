import { Button, Col, Form, Input, Modal, Row, Select, Tag } from "antd";
import { FC, useState } from "react";
import { axiosInstance } from "src/helpers";

interface Props {
  userState?: UserState;
  user: AppUser & AppUserPublic;
}

const FlagUnFlagUser: FC<Props> = ({ userState, user }) => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  async function onFlagClick() {
    form.submit();
    try {
      setError("");
      const isUndefined =
        Object.values(form.getFieldsValue()).findIndex(
          (f) => f === undefined || f === null
        ) !== -1;

      const isError = form.getFieldsError().find((f) => f.errors.length > 0);
      if (isUndefined || isError) {
        setError("Please fill all fields.");
        return;
      }
      setIsLoading(true);

      const data = form.getFieldsValue();

      const formData = {
        appUserUid: user.uid,
        noteContents: data.noteContents,
        noteContentType: data.noteContentType,
      };
      await axiosInstance.post<{ success: boolean }>(
        "/internal/users/flag_for_review",
        formData
      );
      onClose();
    } catch (error) {
      const _e = error as Error;
      setError(_e.message || "Unknown Error");
      console.error("Unable to  flag user with error: ", error);
    } finally {
      setIsLoading(false);
    }
  }
  async function onUnFlagClick() {
    form.submit();
    try {
      setError("");
      const isUndefined =
        Object.values(form.getFieldsValue()).findIndex(
          (f) => f === undefined || f === null
        ) !== -1;

      const isError = form.getFieldsError().find((f) => f.errors.length > 0);
      if (isUndefined || isError) {
        setError("Please fill all fields.");
        return;
      }
      setIsLoading(true);

      const data = form.getFieldsValue();

      const formData = {
        appUserUid: user.uid,
        noteContents: data.noteContents,
        noteContentType: data.noteContentType,
      };
      await axiosInstance.post<{ success: string }>(
        `/internal/users/unflag_for_review`,
        formData
      );
      onClose();
    } catch (error) {
      const _e = error as Error;
      setError(_e.message || "Unknown Error");
      console.error("Unable to un-flag user with error: ", error);
    } finally {
      setIsLoading(false);
    }
  }

  function onClick() {
    setOpen(true);
  }
  function onClose() {
    form.resetFields();
    setIsLoading(false);
    setOpen(false);
    setError("");
  }

  function onChange(
    field: "noteContents" | "noteContentType",
    value: string | null
  ) {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      [field]: value,
    });
  }
  return (
    <>
      <Modal
        title={userState?.requiresReview ? "Un-flag User" : "Flag User"}
        centered
        okText={userState?.requiresReview ? "Un-flag User" : "Flag User"}
        open={open}
        confirmLoading={isLoading}
        onOk={!userState?.requiresReview ? onFlagClick : onUnFlagClick}
        onCancel={onClose}
        width={600}
      >
        <Form
          name="Flag user"
          className="app-config-form"
          form={form}
          autoComplete="off"
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label="Note Contents"
                className="app-config-input-wrap"
                name="noteContents"
                rules={[
                  {
                    required: true,
                    message: "Note contents is required",
                  },
                ]}
              >
                <Input.TextArea
                  name="noteContents"
                  size="middle"
                  onChange={(event) =>
                    onChange("noteContents", event.target.value)
                  }
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                className="app-config-input-wrap"
                label="Note Content Type"
                name="noteContentType"
                initialValue="text"
                rules={[
                  {
                    required: true,
                    message: "Note Content Type is required",
                  },
                ]}
              >
                <Select
                  className="normal-select"
                  size="middle"
                  onChange={(value) => onChange("noteContentType", value)}
                >
                  <Select.Option value="text">Text</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {error && <Tag color="red">{error}</Tag>}
        </Form>
      </Modal>
      <Button
        className="mr-1"
        type="primary"
        danger={!userState?.requiresReview}
        onClick={onClick}
      >
        {userState?.requiresReview ? "Un-flag" : "Flag"}
      </Button>
    </>
  );
};

export default FlagUnFlagUser;
