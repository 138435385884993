import { Tabs } from "antd";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GameConfig from "../appConfig";
import GoLive from "./components/GoLive/GoLive";
import Statistics from "../statistics/Statistics";
import AsyncGroupConfigList from "./components/AsyncGroup/asyncGroupConfigList";
import ComplianceQuestionnaire from "./components/ComplianceQuestionnaire/ComplianceQuestionnaire";
import PushNotification from "./components/PushNotification";
import GameOffers from "./components/Offers";
import Banners from "./components/Banners";

const GamesDashboard: FC = () => {
  const { config, game } = useParams();
  const navigate = useNavigate();

  if (game === "allGames") return <Statistics />;
  return (
    <>
      <Tabs
        className="triumph-tab text-disable-button"
        onChange={(tab) =>
          navigate(`/games/${game}/${tab !== "stats" ? `config/${tab}` : tab}`)
        }
        activeKey={config ?? "stats"}
        type="card"
        size="large"
        destroyInactiveTabPane
        items={[
          {
            label: "Statistics",
            key: "stats",
            children: <Statistics />,
          },

          {
            label: "Async Group Tournament Config",
            key: "async-group",
            children: <AsyncGroupConfigList isAdmin={false} />,
          },
          {
            label: "Push Notifications",
            key: "push-notifications",
            children: <PushNotification />,
          },
          {
            label: "Offers",
            key: "offers",
            children: <GameOffers />,
          },
          {
            label: "Game Config",
            key: "app-config",
            children: <GameConfig isInternal={false} />,
          },
          {
            label: "Compliance Questionnaire",
            key: "compliance-questionnaire",
            children: <ComplianceQuestionnaire />,
          },
          {
            label: "Go Live",
            key: "go-live",
            children: <GoLive />,
          },
          {
            label: "CTA Button",
            key: "banners",
            children: <Banners />,
          },
        ]}
      />
    </>
  );
};

export default GamesDashboard;
