import { Alert, Card, Col, Divider, Empty, Row, Typography } from "antd";
import { FC } from "react";
import TriumphPage from "src/shared/layout/TriumphPage";
import {
  SettingOutlined,
  TableOutlined,
  TabletOutlined,
  BorderlessTableOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

const options = [
  {
    icon: <SettingOutlined />,
    title: "Statistics",
    link: "/games/allGames/stats",
  },
  {
    icon: <TableOutlined />,
    title: "Users Balance Transactions",
    link: "/user_balance_transactions",
  },
  {
    icon: <TabletOutlined />,
    title: "Blitz Tournaments",
    link: "/tournaments/blitz",
  },
  {
    icon: <BorderlessTableOutlined />,
    title: "Async Group Tournaments",
    link: "/tournaments/async-group",
  },
];

const notices = ["Global Search v2 is live."];
const Welcome: FC = () => {
  return (
    <TriumphPage>
      <div
        className="sign-in-container"
        style={{ height: "unset", alignItems: "unset" }}
      >
        <div className="login-form">
          <Col>
            <img
              className="triumph-logo"
              src="/Logo_Gradient.png"
              alt="Triumph Logo"
              width={64}
            />
          </Col>
          <Col>
            <Typography.Title level={2}>
              Welcome to Triumph Dashboard
            </Typography.Title>
          </Col>
          <Divider />

          <Col className="pb-2">
            <Typography.Title level={4}>
              Frequently used items!
            </Typography.Title>
            <Row>
              {options.map((e) => (
                <Col key={e.title} style={{ margin: 6 }} span={11}>
                  <Link to={e.link}>
                    <Card bordered={true}>
                      {e.icon}
                      <Typography.Title level={5}>{e.title}</Typography.Title>
                    </Card>
                  </Link>
                </Col>
              ))}
            </Row>

            <Divider />
            <Typography.Title level={5}>
              <WarningOutlined /> Announcements & Notices
            </Typography.Title>
            {notices.length > 0 ? (
              notices.map((e) => (
                <div style={{ margin: 5 }} key={e}>
                  <Alert type="warning" message={e} />
                </div>
              ))
            ) : (
              <Empty />
            )}
          </Col>
        </div>
      </div>
    </TriumphPage>
  );
};

export default Welcome;
