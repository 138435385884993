import {
  Button,
  Modal,
  Switch,
  Table,
  TableColumnsType,
  Image,
  TablePaginationConfig,
} from "antd";
import {
  collection,
  doc,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { FC, useEffect, useState } from "react";
import { OffersConverter } from "src/converters";
import { db, getCdnUrl } from "src/helpers";
import { EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import Currency from "src/shared/components/currency/Currency";
import { dateFormatter } from "src/shared/config/constants";
import OffersForm from "./offersForm";
import { useAppSelector } from "src/app/hooks";
import TriumphPage from "src/shared/layout/TriumphPage";

const OffersPage: FC = () => {
  const { user } = useAppSelector((state) => state.userState);
  const [offers, setOffers] = useState<{
    [x: string]: Offer;
  } | null>(null);

  const [selectedOffer, setSelectedOffer] = useState<Offer | null>(
    null
  );

  useEffect(() => {
    const offersColRef = collection(db, "offers").withConverter(
      OffersConverter
    );
    const offersQuery = query(
      offersColRef,
      where("orgId", "==", user?.activeOrgId)
    );

    const unSubOffers = onSnapshot(offersQuery, (colSnap) => {
      const list: typeof offers = {};
      for (const snap of colSnap.docs) {
        const uid = snap.id;
        const data = snap.data();
        list[uid] = { ...data, uid };
      }
      setOffers(list);
    });
    return () => {
      unSubOffers();
    };
  }, [user?.activeOrgId]);

  const paginationConfig: TablePaginationConfig = {
    pageSize: 50,
    className: "mobile-pagination-small",
  };

  const onDelete = async (data: Offer) => {
    const OffersRef = collection(db, "offers").withConverter(OffersConverter);

    const offerDoc = doc(OffersRef, data.uid);

    const formData = {
      ...data,
      archived: !data.archived,
    };

    // delete formData.uid;
    // remove undefined values
    Object.keys(formData).forEach((key) => {
      const formKey = key as keyof typeof formData;
      if (formData[formKey] === undefined) delete formData[formKey];
    });
    updateDoc(offerDoc, formData);
  };

  const columns: TableColumnsType<Offer> = [
    {
      title: "Thumbnail",
      dataIndex: "imgPath",
      key: "imgPath",
      render: (v) => (
        <Image
          className="table-img"
          src={`${getCdnUrl()}/${v}`}
          fallback={"fallback_img.png"}
        />
      ),
    },
    {
      title: "Image",
      dataIndex: "expandedImgPath",
      key: "expandedImgPath",
      render: (v) => (
        <Image
          className="table-img"
          src={`${getCdnUrl()}/${v}`}
          fallback={"fallback_img.png"}
        />
      ),
    },

    {
      title: "Bonus Cash Reward",
      dataIndex: "bonusCashReward",
      key: "bonusCashReward",
      render: (v) => (
        <Currency value={v} currency="cents-to-dollar" prefix="$" />
      ),
    },

    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (v) => dateFormatter(v),
    },
    {
      title: "Offer Ends At",
      dataIndex: "offerEndsAt",
      key: "offerEndsAt",
      render: (v) => dateFormatter(v),
    },
    {
      title: "Purchase Price",
      dataIndex: "purchasePrice",
      key: "purchasePrice",
      render: (v) => (
        <Currency value={v} currency="cents-to-dollar" prefix="$" />
      ),
    },

    {
      title: "Should Show On Open",
      dataIndex: "shouldShowOnOpen",
      key: "shouldShowOnOpen",
      render: (v) => (v ? "Yes" : "No"),
    },
    {
      title: "Archive",
      dataIndex: "archived",
      key: "archived",
      render: (value, record) => (
        <Switch
          checked={value}
          onChange={() =>
            Modal.confirm({
              title: `Confirm ${value ? "Un-Archive" : "Archive"} ?`,
              icon: <ExclamationCircleOutlined />,
              content: `By clicking confirm the record will be ${
                value ? "un-archieved" : "archieved"
              }.`,
              okText: "Confirm",
              cancelText: "Back",
              onOk: () => onDelete(record),
              okCancel: true,
            })
          }
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 100,
      render: (_, record) => (
        <>
          <Button
            onClick={() => setSelectedOffer(record)}
            icon={<EditOutlined style={{ fontSize: 24 }} />}
          />
        </>
      ),
    },
  ];

  return (
    <TriumphPage>
      <OffersForm
        offer={selectedOffer}
        onClose={() => setSelectedOffer(null)}
      />
      <Table
        columns={columns}
        loading={offers === null}
        rowKey="uid"
        dataSource={Object.values(offers || {})}
        pagination={paginationConfig}
        className="mobile-table-small"
      />
    </TriumphPage>
  );
};

export default OffersPage;
