import { FC, useEffect, useState } from "react";
import { axiosInstance, db, storage } from "src/helpers";
import {
  Button,
  Dropdown,
  Menu,
  Modal,
  notification,
  Space,
  Table,
} from "antd";
import { collection, onSnapshot, Unsubscribe } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import {
  DownOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { dateFormatter } from "src/shared/config/constants";
import { gameConverter } from "src/converters";
import TriumphPage from "src/shared/layout/TriumphPage";
import { useNavigate } from "react-router-dom";
import axios from "axios";
/** type imports */
import type { MenuInfo } from "rc-menu/lib/interface";
import type { TableColumnsType } from "antd";

type AdminGamesTableProps = Pick<
  Game,
  | "uid"
  | "createdAt"
  | "complianceStatus"
  | "goLiveStatus"
  | "blitzSeedStatus"
  | "displayName"
>;

const InternalGames: FC = () => {
  const navigate = useNavigate();
  const [games, setGames] = useState<{
    [x: string]: AdminGamesTableProps;
  } | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    let unsubGames: Unsubscribe;
    const gamesRef = collection(db, "games").withConverter(gameConverter);

    unsubGames = onSnapshot(gamesRef, (colSnap) => {
      const config: typeof games = {};
      for (const snap of colSnap.docs) {
        const gameId = snap.id;
        const data = snap.data();
        config[gameId] = data;
      }
      setGames(config);
    });
    return () => {
      unsubGames?.();
    };
  }, []);

  function createDropdownConfirmationModal(
    e: MenuInfo,
    field: string,
    validValues: string[],
    gameId: string,
    path: string,
    payload: any
  ) {
    return {
      title: `Confirm change to ${field} to ${e.key} ?`,
      icon: <ExclamationCircleOutlined />,
      content: `By clicking confirm, the ${field} will be updated to ${e.key}.`,
      okText: "Confirm",
      cancelText: "Back",
      onOk: async () => {
        if (validValues.includes(e.key)) {
          setIsLoading(true);
          try {
            await axiosInstance.post<{ success: string }>(
              `/internal/games/${gameId}/${path}`,
              payload
            );
          } catch (error) {
            console.error(error);
          }
          setIsLoading(false);
        }
      },
      okCancel: true,
    };
  }

  interface MenuProps {
    gameId: string;
  }

  const ComplianceStatusMenu: FC<MenuProps> = ({ gameId }) => {
    return (
      <Menu
        onClick={(e) => {
          Modal.confirm(
            createDropdownConfirmationModal(
              e,
              "compliance status",
              ["incomplete", "pending-approval", "approved", "denied"],
              gameId,
              "compliance_status",
              {
                complianceStatus: e.key,
              }
            )
          );
        }}
      >
        <Menu.Item key={"incomplete"}>Incomplete</Menu.Item>
        <Menu.Item key={"pending-approval"}>Pending Approval</Menu.Item>
        <Menu.Item key={"approved"}>Approved</Menu.Item>
        <Menu.Item key={"denied"}>Denied</Menu.Item>
      </Menu>
    );
  };

  const GoLiveStatusMenu: FC<MenuProps> = ({ gameId }) => {
    return (
      <Menu
        onClick={(e) => {
          Modal.confirm(
            createDropdownConfirmationModal(
              e,
              "go live status",
              ["unstarted", "in-progress", "live"],
              gameId,
              "go_live_status",
              {
                goLiveStatus: e.key,
              }
            )
          );
        }}
      >
        <Menu.Item key={"unstarted"}>Unstarted</Menu.Item>
        <Menu.Item key={"in-progress"}>In Progress</Menu.Item>
        <Menu.Item key={"live"}>Live</Menu.Item>
      </Menu>
    );
  };

  function getDisplayComplianceStatus(
    complianceStatus: Game["complianceStatus"]
  ) {
    switch (complianceStatus) {
      case "incomplete":
        return "Incomplete";
      case "pending-approval":
        return "Pending Approval";
      case "approved":
        return "Approved";
      case "denied":
        return "Denied";
      default:
        return "";
    }
  }

  function getDisplayGoLiveStatus(goLiveStatus: Game["goLiveStatus"]) {
    switch (goLiveStatus) {
      case "unstarted":
        return "Unstarted";
      case "in-progress":
        return "In Progress";
      case "live":
        return "Live";
      default:
        return "";
    }
  }

  const gameNameFilters: {
    text: string;
    value: Game["displayName"];
  }[] = Object.values(
    Object.values(games ?? {}).reduce<{
      [type: string]: { text: string; value: string };
    }>((aggreg, currGame) => {
      if (currGame.displayName in aggreg) {
        return aggreg;
      } else {
        aggreg[currGame.displayName] = {
          text: currGame.displayName,
          value: currGame.displayName,
        };
        return aggreg;
      }
    }, {})
  );

  const gameIdFilters: {
    text: string;
    value: string;
  }[] = Object.values(
    Object.values(games ?? {}).reduce<{
      [type: string]: { text: string; value: string };
    }>((aggreg, currGame) => {
      if (currGame.uid in aggreg) {
        return aggreg;
      } else {
        aggreg[currGame.uid] = {
          text: currGame.uid,
          value: currGame.uid,
        };
        return aggreg;
      }
    }, {})
  );

  const complianceStatusFilters: {
    text: string;
    value: Game["complianceStatus"];
  }[] = Object.values(
    Object.values(games ?? {}).reduce<{
      [type: string]: {
        text: string;
        value: Game["complianceStatus"];
      };
    }>((aggreg, currentGame) => {
      if (currentGame.complianceStatus in aggreg) {
        return aggreg;
      } else {
        aggreg[currentGame.complianceStatus] = {
          text: currentGame.complianceStatus,
          value: currentGame.complianceStatus,
        };
        return aggreg;
      }
    }, {})
  );

  const goLiveStatusFilters: {
    text: string;
    value: Game["goLiveStatus"];
  }[] = Object.values(
    Object.values(games ?? {}).reduce<{
      [type: string]: {
        text: string;
        value: Game["goLiveStatus"];
      };
    }>((aggreg, currentGame) => {
      if (currentGame.goLiveStatus in aggreg) {
        return aggreg;
      } else {
        aggreg[currentGame.goLiveStatus] = {
          text: currentGame.goLiveStatus,
          value: currentGame.goLiveStatus,
        };
        return aggreg;
      }
    }, {})
  );

  async function blitz_data_missing(gameId: string) {
    const storageRef = ref(storage, `/blitz_initial_data/${gameId}.json`);

    let missing: boolean = false;

    try {
      await getDownloadURL(storageRef);
      missing = false;
    } catch (error) {
      missing = true;
    }
    return missing;
  }

  const columns: TableColumnsType<AdminGamesTableProps> = [
    {
      title: "Game",
      dataIndex: "displayName",
      key: "displayName",
      filters: gameNameFilters,
      filterSearch: true,
      onFilter: (value, record) => record.displayName === value,
    },
    {
      title: "Game ID",
      dataIndex: "uid",
      key: "uid",
      filters: gameIdFilters,
      filterSearch: true,
      onFilter: (value, record) => record.uid === value,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      defaultSortOrder: "descend",
      sorter: (a, b) => (a.createdAt || 0) - (b.createdAt || 0),
      render: (a) => dateFormatter(a),
    },
    {
      title: "Compliance Status",
      dataIndex: "complianceStatus",
      key: "complianceStatus",
      filters: complianceStatusFilters,
      onFilter: (value, record) => record.complianceStatus === value,
      render: (value, record) => (
        <Dropdown overlay={<ComplianceStatusMenu gameId={record.uid} />}>
          <Button>
            <Space>
              {getDisplayComplianceStatus(value)}
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
    {
      title: "Blitz Seed Status",
      dataIndex: "blitzSeedStatus",
      key: "blitzSeedStatus",
      render: (value: string, record: AdminGamesTableProps) => (
        <Button
          disabled={value === "seeded"}
          onClick={async () => {
            Modal.confirm({
              title: `Confirm seeding blitz data for ${record.displayName} ?`,
              icon: <ExclamationCircleOutlined />,
              content: (await blitz_data_missing(record.uid))
                ? `Please make sure to have the blitz data in /initial_blitz_data/${record.uid}.json.`
                : "This will load the blitz seeded data into the database.",
              okText: "Confirm",
              okButtonProps: {
                disabled: await blitz_data_missing(record.uid),
              },
              cancelText: "Back",
              onOk: async () => {
                try {
                  await axiosInstance.post<{ success: boolean; results: any }>(
                    `/internal/games/${record.uid}/blitz_data`
                  );
                } catch (error) {
                  let message = "An error occured";
                  if (axios.isAxiosError(error) || error instanceof Error) {
                    message = error.message;
                  }
                  api.error({
                    message,
                    placement: "top",
                  });
                  console.error(error);
                }
              },
              okCancel: true,
            });
          }}
        >
          {value.charAt(0).toUpperCase() + value.slice(1)}
        </Button>
      ),
    },
    {
      title: "Go Live Status",
      dataIndex: "goLiveStatus",
      key: "goLiveStatus",
      filters: goLiveStatusFilters,
      onFilter: (value, record) => record.goLiveStatus === value,
      render: (value, record) => (
        <Dropdown overlay={<GoLiveStatusMenu gameId={record.uid} />}>
          <Button>
            <Space>
              {getDisplayGoLiveStatus(value)}
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
    {
      title: "Game Config",
      width: 100,
      render: (_, record) => (
        <Button
          icon={
            <EditOutlined
              style={{ fontSize: 24 }}
              onClick={() =>
                navigate(
                  `/internal/games/${record.uid}/game_config`
                )
              }
            />
          }
        />
      ),
    },
    {
      title: "Group Tournament Definitions",
      width: 100,
      render: (_, record) => (
        <Button
          icon={
            <EditOutlined
              style={{ fontSize: 24 }}
              onClick={() =>
                navigate(
                  `/internal/games/${record.uid}/group_tournament_definitions`
                )
              }
            />
          }
        />
      ),
    },
    {
      title: "Blitz Tournament Definitions",
      width: 100,
      render: (_, record) => (
        <Button
          icon={
            <EditOutlined
              style={{ fontSize: 24 }}
              onClick={() =>
                navigate(
                  `/internal/games/${record.uid}/blitz_tournament_definitions`
                )
              }
            />
          }
        />
      ),
    },
    {
      title: "Deposit Definitions",
      width: 100,
      render: (_, record) => (
        <Button
          icon={
            <EditOutlined
              style={{ fontSize: 24 }}
              onClick={() =>
                navigate(`/internal/games/${record.uid}/deposit_definitions`)
              }
            />
          }
        />
      ),
    },
  ];

  return (
    <TriumphPage>
      {contextHolder}
      <Table
        columns={columns}
        loading={games === null || isLoading}
        rowKey="uid"
        dataSource={Object.values(games || {})}
        className="mobile-table-small"
      />
    </TriumphPage>
  );
};

export default InternalGames;
