import { Modal, Skeleton } from "antd";
import { FC } from "react";
import AdminAddAccountNote from "./AdminAddAccountNote";
import AdminBanAccount from "./AdminBanAccount";
import AdminSetRequiresReview from "./AdminSetRequiresReview";
import AdminUnbanAccount from "./AdminUnbanAccount";
import RecaptchaAssessment from "./RecaptchaAssessment";
import { EventType } from "src/shared/abuse";

interface Props {
  selectedRow: UserEvent | null;
  onClose: () => void;
}

interface ModalProps extends Omit<Props, "onClose"> {}

const RenderModalContent: FC<ModalProps> = ({ selectedRow }) => {
  if (!selectedRow) return <Skeleton avatar title paragraph />;
  switch (selectedRow.eventType) {
    case EventType.AdminAddAccountNote:
      return <AdminAddAccountNote eventDetails={selectedRow} />;
    case EventType.AdminBanAccount:
      return <AdminBanAccount eventDetails={selectedRow} />;
    case EventType.AdminSetRequiresReview:
      return <AdminSetRequiresReview eventDetails={selectedRow} />;
    case EventType.AdminUnbanAccount:
      return <AdminUnbanAccount eventDetails={selectedRow} />;
    case EventType.RecaptchaAssessment:
      return <RecaptchaAssessment eventDetails={selectedRow} />;
    default:
      return <></>;
  }
};

const UserAbuseEventModal: FC<Props> = ({ selectedRow, onClose }) => {
  return (
    <Modal
      title="User Abuse Event Details"
      centered
      open={!!selectedRow}
      onOk={onClose}
      onCancel={onClose}
      width={1000}
    >
      <RenderModalContent selectedRow={selectedRow} />
    </Modal>
  );
};

export default UserAbuseEventModal;
