import { Button, Form, Input, Row, Tag } from "antd";
import { FC, useState } from "react";
import { useAppSelector } from "src/app/hooks";
import { axiosInstance } from "src/helpers";

interface Props {
  org?: Organization;
}

const AppleTeamId: FC<Props> = ({ org }) => {
  const [form] = Form.useForm();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAppSelector((state) => state.userState);

  function onChange(name: string, value: string | number | null) {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      [name]: value,
    });
  }

  async function onSubmit() {
    setError("");
    const { appleTeamId } = form.getFieldsValue();
    const isUndefined =
      Object.values({
        appleTeamId,
      }).findIndex((f) => f === undefined || f === null || f === "") !== -1;

    const isError = form.getFieldsError().find((f) => f.errors.length > 0);
    if (isUndefined || isError) {
      setError("Please fill all fields.");
      return;
    }

    setIsLoading(true);
    try {
      await axiosInstance.post(
        `/admin/orgs/${user?.activeOrgId}/apple_team_id`,
        {
          appleTeamId,
        }
      );
    } catch (error) {
      setError(error instanceof Error ? error.message : "Unknown Error");
    } finally {
      setIsLoading(false);
    }
  }
  const defaultAppleTeamId = org?.appleTeamId;

  return (
    <>
      {" "}
      <Form
        name="appleTeamId"
        className="spaced-form apple-team-id"
        form={form}
        onFinish={onSubmit}
        autoComplete="off"
      >
        <Row>
          <Form.Item
            label="Apple Team Id"
            name="appleTeamId"
            initialValue={defaultAppleTeamId}
            rules={[
              {
                required: true,
                message: "Apple Team Id is required",
              },
            ]}
          >
            <Input
              name="appleTeamId"
              className="pd-8"
              size="middle"
              onChange={(event) => onChange("appleTeamId", event.target.value)}
            />
          </Form.Item>
        </Row>
        {error && (
          <Row>
            <Tag color="orange">{error}</Tag>
          </Row>
        )}
        <Row justify="end">
          <Button loading={isLoading} onClick={onSubmit} type="primary">
            Submit Apple Team ID
          </Button>
        </Row>
      </Form>
    </>
  );
};

export default AppleTeamId;
