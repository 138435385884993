import { Descriptions, Tag, Typography } from "antd";
import { FC } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "src/app/hooks";
import { appUserPublicSelect } from "src/features/appUsersPublic/appUserPublicSlice";
import { dateFormatter } from "src/shared/config/constants";

interface Props {
  details: UserState & { id: string };
  hideUsername?: boolean;
}
const UserStateDetail: FC<Props> = ({ details, hideUsername }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  const { usersPublic } = useAppSelector(appUserPublicSelect);
  const navigate = useNavigate();

  return (
    <>
      <Descriptions
        layout={!isMobile ? "horizontal" : "vertical"}
        column={1}
        bordered
        title="User Abuse/Fraud State"
        className="pb-2"
      >
        <Descriptions.Item label="ID">{details.id}</Descriptions.Item>
        <Descriptions.Item label="App User Id">
          {details.appUserUid}
        </Descriptions.Item>
        {!hideUsername && (
          <Descriptions.Item label="App User name">
            <Typography.Link
              onClick={() => navigate(`/users/${details.appUserUid}`)}
            >
              {usersPublic[details.appUserUid]?.username}
            </Typography.Link>
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Requires Review">
          <Tag color={details.requiresReview ? "orange" : "green"}>
            {details.requiresReview ? "Yes" : "No"}
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Banned">
          <Tag color={details.banned ? "red" : "green"}>
            {details.banned ? "Banned" : "Not Banned"}
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Last Event Sequence Id">
          {details.lastEventSequenceId}
        </Descriptions.Item>
        <Descriptions.Item label="Last Event Time">
          {dateFormatter(details.lastEventTime)}
        </Descriptions.Item>
        <Descriptions.Item label="Created At">
          {dateFormatter(details.createdAt)}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default UserStateDetail;
