import { message } from "antd";
import { Unsubscribe } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { colNames, getCollection } from "src/collections";
import TriumphPage from "src/shared/layout/TriumphPage";

import PushNotificationForm from "./PushNotificationForm";
import SchedulePNList from "./SchedulePN/SchedulePNList";

const PushNotification: FC = () => {
  const { game } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [messageApi, contextHolder] = message.useMessage();
  const [offerslist, setOffersList] = useState<Array<ConfigureOffer>>([]);

  const [notificationList, setNotificationList] = useState<{
    [x: string]: PushNotification;
  } | null>(null);

  useEffect(() => {
    let unSubPushNotification: Unsubscribe;
    let unSubConfigureOffer: Unsubscribe;

    if (game) {
      const gameRef = doc(getCollection(colNames.games), game);
      const pushNotificationRef = getCollection(
        colNames.pushNotifications,
        gameRef
      );

      unSubPushNotification = onSnapshot(pushNotificationRef, (colSnap) => {
        const config: typeof notificationList = {};
        for (const snap of colSnap.docs) {
          const data = snap.data();
          config[data.notificationId] = { ...data };
        }
        setNotificationList(config);
      });

      const configureOfferRef = getCollection(
        colNames.configureOffers,
        gameRef
      );

      unSubConfigureOffer = onSnapshot(configureOfferRef, (colSnap) => {
        const config: typeof offerslist = [];
        for (const snap of colSnap.docs) {
          const data = snap.data();
          config.push(data);
        }
        setOffersList(config);
      });
    }
    return () => {
      unSubPushNotification?.();
      unSubConfigureOffer?.();
    };
  }, [game]);

  return (
    <TriumphPage>
      {/* Message Context */}
      {contextHolder}
      <PushNotificationForm offerslist={offerslist} />
      <SchedulePNList
        offerslist={offerslist}
        notifications={notificationList}
      />
    </TriumphPage>
  );
};

export default PushNotification;
