import { useEffect, useState } from "react";
import {
  Statistic as StatComp,
  Card,
  Row,
  Col,
  DatePicker,
  Button,
  Modal,
  Radio,
  Space,
  Menu,
  Dropdown,
  Tooltip,
  Typography,
  Spin,
} from "antd";
import { AreaChartOutlined, DownOutlined } from "@ant-design/icons";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as ChartJSTooltip,
  Legend,
} from "chart.js";
// import Zoom from "chartjs-plugin-zoom";
import {
  auth,
  axiosInstance,
  db,
  generateFirestorePath,
  sumArr,
} from "src/helpers";
import "chart.js/auto";
import { Chart, Bar } from "react-chartjs-2";
import { useParams } from "react-router-dom";
import { statisticConverter } from "src/converters";
import {
  doc,
  getDocs,
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
  Unsubscribe,
} from "firebase/firestore";
import {
  updateGranularity,
  updateRange,
  updateSnapGranularity,
  updateWindow,
} from "src/features/statistics/statRangeSlice";
import { batch } from "react-redux";
import dayjs from "dayjs";
import { useMediaQuery } from "react-responsive";
import { useThemeSwitcher } from "../theme/ThemeContextInterface";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import TriumphPage from "src/shared/layout/TriumphPage";
import moment from "moment-timezone";
import { colNames, getCollection } from "src/collections";
import AnalyticsWelcome from "../onboarding/AnalyticsWelcome";
/** type imports */
import type { CollectionReference } from "firebase/firestore";
import type { ScriptableScaleContext, ScriptableContext } from "chart.js";
import type { MenuProps } from "antd";
import type { FC } from "react";

const { RangePicker } = DatePicker;
const { Text } = Typography;
/**
 * Developer `Statistics` Page Component
 */
const Statistics: FC = () => {
  const dispatch = useAppDispatch();
  const { game: statsId } = useParams();
  const { user } = useAppSelector((state) => state.userState);
  const {
    granularity,
    snapGranularity,
    interval,
    window,
    validGranularities,
    range: { start: startTime, end: endTime },
  } = useAppSelector((state) => state.statRangeState);

  let timePart = "Week";
  if (granularity === "Monthly") {
    timePart = "Month";
  } else if (granularity === "Yearly") {
    timePart = "Year";
  }

  const snapMsg = `Selection rounded to beginning and end of the ${timePart}, up to and including Today`;

  const tzOffset = dayjs().utcOffset();

  const [activeOrg, setActiveOrg] = useState<null | Organization>(null);

  const hourly = "Hourly";
  const daily = "Daily";
  const weekly = "Weekly";
  const monthly = "Monthly";
  const yearly = "Yearly";

  /** if statsId is `allGames` then show org overview stats */
  const isOrg = statsId === "allGames";
  const statDocId = isOrg ? activeOrg?.uid : statsId;
  const statColName = isOrg ? "orgStatistics" : "gameStatistics";

  const menuValues = {
    Today: "Today",
    pastWeek: "Last 7 days",
    pastMonth: "Last 4 weeks",
    pastQuarter: "Last 3 months",
    pastYear: "Last 12 months",
    mtd: "Month to date",
    qtd: "Quarter to date",
    ytd: "Year to date",
    allTime: "All time",
    custom: "Custom",
  } as const;

  const granularityMap = {
    Hourly: "hour",
    Daily: "day",
    Weekly: "week",
    Monthly: "month",
    Yearly: "year",
  } as const;

  const totalWithdrawn = "Total Withdrawn";
  const totalAppUsers = "Total Users";
  const totalDeposited = "Total Deposited";
  const profitLoss = "Total Profit";
  const red = "#cc0b0bd6";
  const singleColSpan = 24;

  const { currentTheme } = useThemeSwitcher();
  const [isDarkMode, setIsDarkMode] = useState(currentTheme === "dark");
  const [statData, setStatData] = useState<null | Statistic>(null);
  const [showDateRangeTooltip, setShowDateRangeTooltip] =
    useState<boolean>(false);
  const [timeStatData, setTimeStatData] = useState<null | TimeStatistic>(null);
  const [selectedStatTitle, setSelectedStatTitle] = useState<null | string>("");
  const [selectedStatData, setSelectedStatData] =
    useState<null | BaseChartData>(null);
  const [isRefreshingFirestore, setIsRefreshingFirestore] =
    useState<boolean>(false);
  const [isResettingFirestore, setIsResettingFirestore] =
    useState<boolean>(false);
  const [isChartExpanded, setIsChartExpanded] = useState<boolean>(false);
  const [loadingStats, setLoadingStats] = useState<
    "idle" | "pending" | "finish"
  >("idle");
  const [showCum, setShowCum] = useState<boolean>(false);
  const [hoverlabel, setHoverLabel] = useState<string | null>(null);
  const [hoverDelta, setHoverDelta] = useState<number | null>(null);
  const [hoverStatTitle, setHoverStatTitle] = useState<string | null>(null);
  const [groupTournDefs, setGroupTournDefs] = useState<{
    [tourDefId: string]: TournamentGroupConfig;
  }>({});
  const [blitzTournDefs, setBlitzTournDefs] = useState<{
    [tourDefId: string]: BlitzDefinitionV2;
  }>({});
  /** BEGIN HOOKS */

  /** Fetch active org */
  useEffect(() => {
    let unsubOrg: Unsubscribe | undefined;
    if (user) {
      const orgId = user.activeOrgId;
      if (orgId) {
        const orgRef = doc(getCollection(colNames.organizations), orgId);

        unsubOrg = onSnapshot(orgRef, (docSnap) => {
          const org = docSnap.data();
          if (org) {
            setActiveOrg(org);
          }
        });
      }
    }

    return () => {
      unsubOrg?.();
    };
  }, [user]);

  /** Client Theme Hook */
  useEffect(() => {
    setIsDarkMode(currentTheme === "dark");
  }, [currentTheme]);

  /** Timeout Date Range Tooltip */
  useEffect(() => {
    async function timeout() {
      await new Promise((resolve) => setTimeout(resolve, 5000));
    }

    const resetTooltip = async () => {
      if (showDateRangeTooltip) {
        await timeout();
        setShowDateRangeTooltip(false);
      }
    };
    resetTooltip();
  }, [showDateRangeTooltip]);

  /** Snap Dates */
  useEffect(() => {
    const granularityMap = {
      Hourly: "hour",
      Daily: "day",
      Weekly: "week",
      Monthly: "month",
      Yearly: "year",
    } as const;

    function snapDates(granularity: keyof typeof granularityMap) {
      const oldStart = moment(startTime);
      const oldEnd = moment(endTime);

      /** "want to hop to BEGINNING of day, not beginning of curr user's hour" */
      let rawStartTime = oldStart.startOf(granularityMap.Daily);
      let rawEndTime = oldEnd.endOf(granularityMap.Daily);
      if (granularity !== hourly && granularity === snapGranularity) {
        rawStartTime = rawStartTime.startOf(granularityMap[granularity]);
        rawEndTime = rawEndTime.endOf(granularityMap[granularity]);
      }

      /** cap endTime by current time */
      const endTimeLimit = moment().endOf("day").valueOf();
      const endTimeCapped =
        rawEndTime.valueOf() > endTimeLimit
          ? endTimeLimit
          : rawEndTime.valueOf();

      let shouldChangeToCustom = false;
      let shouldSetSnapGranularity = false;
      /** checks if year, month, and day are same */
      if (
        rawStartTime.day() !== oldStart.day() ||
        oldEnd.day() !== rawEndTime.day()
      ) {
        if (granularity !== hourly && granularity !== daily) {
          setShowDateRangeTooltip(true);
          shouldSetSnapGranularity = true;
        }
        shouldChangeToCustom = true;
      }

      /** Update the new date range selection */
      batch(() => {
        if (shouldChangeToCustom) {
          dispatch(updateWindow("custom"));
        }
        if (shouldSetSnapGranularity) {
          dispatch(updateSnapGranularity(granularity));
        }
        dispatch(
          updateRange({ start: rawStartTime.valueOf(), end: endTimeCapped })
        );
      });
    }

    snapDates(granularity);
  }, [
    dispatch,
    endTime,
    granularity,
    snapGranularity,
    snapMsg,
    startTime,
    window,
  ]);

  /** Firestore Timestats Refresh Hook */
  useEffect(() => {
    async function updateFirestoreStats() {
      if (!isRefreshingFirestore && !isResettingFirestore) {
        return;
      }
      if (!statDocId) {
        return;
      }

      const statsRequestBody = {
        gameId: statDocId,
        resetAll: isResettingFirestore,
      };
      try {
        await axiosInstance.post<{
          success: boolean;
        }>("/statistics/aggregate", statsRequestBody);
      } catch (error) {
        console.error(error);
      } finally {
        setIsRefreshingFirestore(false);
        setIsResettingFirestore(false);
      }
    }

    updateFirestoreStats();
    return () => {};
  }, [granularity, isRefreshingFirestore, isResettingFirestore, statDocId]);

  /** Client-Side Lookup for Time stats */
  useEffect(() => {
    function getEmptyStat(statDocId: string): Statistic {
      const createdAt = Date.now();
      if (isOrg) {
        const orgStat: OrgStats = {
          displayName: "All Games",
          type: "org",
          totalProfit: 0,
          totalBlitzProfit: 0,
          totalAsyncGroupProfit: 0,
          totalDeposited: 0,
          totalBlitzWagered: 0,
          totalBlitzPaidOut: 0,
          blitzTotalStarts: 0,
          blitzTotalFinishes: 0,
          asyncGroupTotalStarts: 0,
          asyncGroupTotalFinishes: 0,
          totalAsyncGroupPaidOut: 0,
          totalAsyncGroupWagered: 0,
          processedBalanceTrxs: [],
          totalAppUsers: 0,
          totalWithdrawn: 0,
          createdAt,
          updatedAt: createdAt,
        };
        return orgStat;
      } else {
        const gameStat: GameStats = {
          displayName: statDocId,
          type: "game",
          totalProfit: 0,
          totalBlitzProfit: 0,
          totalAsyncGroupProfit: 0,
          totalWithdrawn: 0,
          totalDeposited: 0,
          totalBlitzWagered: 0,
          totalBlitzPaidOut: 0,
          blitzTotalStarts: 0,
          blitzTotalFinishes: 0,
          asyncGroupTotalStarts: 0,
          asyncGroupTotalFinishes: 0,
          blitzTotalStartsPerTourn: {},
          blitzTotalFinishesPerTourn: {},
          asyncGroupTotalStartsPerTourn: {},
          asyncGroupTotalFinishesPerTourn: {},
          asyncGroupWageredPerTourn: {},
          asyncGroupPaidOutPerTourn: {},
          asyncGroupProfitPerTourn: {},
          blitzWageredPerTourn: {},
          blitzPaidOutPerTourn: {},
          blitzProfitPerTourn: {},
          totalAsyncGroupPaidOut: 0,
          totalAsyncGroupWagered: 0,
          processedBalanceTrxs: [],
          totalAppUsers: 0,
          createdAt,
          updatedAt: createdAt,
        };
        return gameStat;
      }
    }

    function getEmptyTimeStat(gameId: string): TimeStatistic {
      const createdAt = Date.now();
      if (gameId === "allGames") {
        const timeOrgStat: OrgTimeStats = {
          displayName: "All Games",
          type: "org",
          statType: "time",
          totalProfit: initChartData(),
          totalBlitzProfit: initChartData(),
          totalAsyncGroupProfit: initChartData(),
          totalDeposited: initChartData(),
          totalBlitzWagered: initChartData(),
          totalBlitzPaidOut: initChartData(),
          blitzTotalStarts: initChartData(),
          blitzTotalFinishes: initChartData(),
          asyncGroupTotalStarts: initChartData(),
          asyncGroupTotalFinishes: initChartData(),
          totalAsyncGroupPaidOut: initChartData(),
          totalAsyncGroupWagered: initChartData(),
          totalAppUsers: initChartData(),
          processedBalanceTrxs: [],
          totalWithdrawn: initChartData(),
          createdAt,
          updatedAt: createdAt,
        };
        return timeOrgStat;
      } else {
        const gameStat: GameTimeStats = {
          displayName: gameId,
          type: "game",
          statType: "time",
          totalProfit: initChartData(),
          totalBlitzProfit: initChartData(),
          totalAsyncGroupProfit: initChartData(),
          totalWithdrawn: initChartData(),
          totalDeposited: initChartData(),
          totalBlitzWagered: initChartData(),
          totalBlitzPaidOut: initChartData(),
          blitzTotalStarts: initChartData(),
          blitzTotalFinishes: initChartData(),
          asyncGroupTotalStarts: initChartData(),
          asyncGroupTotalFinishes: initChartData(),
          processedBalanceTrxs: [],
          totalAsyncGroupPaidOut: initChartData(),
          totalAsyncGroupWagered: initChartData(),
          totalAppUsers: initChartData(),
          // Per Tourn
          asyncGroupPaidOutPerTourn: {},
          asyncGroupWageredPerTourn: {},
          asyncGroupProfitPerTourn: {},
          asyncGroupTotalStartsPerTourn: {},
          asyncGroupTotalFinishesPerTourn: {},
          blitzPaidOutPerTourn: {},
          blitzWageredPerTourn: {},
          blitzProfitPerTourn: {},
          blitzTotalStartsPerTourn: {},
          blitzTotalFinishesPerTourn: {},
          createdAt,
          updatedAt: createdAt,
        };
        return gameStat;
      }
    }

    function incrementRollingSum(rollingSum: Statistic, stat: Statistic) {
      rollingSum.totalProfit += stat.totalProfit;
      rollingSum.totalBlitzProfit += stat.totalBlitzProfit;
      rollingSum.totalAsyncGroupProfit += stat.totalAsyncGroupProfit;
      rollingSum.totalAppUsers += stat.totalAppUsers;
      rollingSum.totalAsyncGroupPaidOut += stat.totalAsyncGroupPaidOut;
      rollingSum.totalAsyncGroupWagered += stat.totalAsyncGroupWagered;
      rollingSum.blitzTotalFinishes += stat.blitzTotalFinishes;
      rollingSum.blitzTotalStarts += stat.blitzTotalStarts;
      rollingSum.asyncGroupTotalStarts += stat.asyncGroupTotalStarts;
      rollingSum.asyncGroupTotalFinishes += stat.asyncGroupTotalFinishes;
      rollingSum.totalBlitzPaidOut += stat.totalBlitzPaidOut;
      rollingSum.totalBlitzWagered += stat.totalBlitzWagered;
      rollingSum.totalDeposited += stat.totalDeposited;
      rollingSum.totalWithdrawn += stat.totalWithdrawn;

      /** Per TournDef Rolling Sum */
      if (stat.type === "game" && rollingSum.type === "game") {
        /** Increment Blitz Per Tourn Profit Rolling Sum */
        for (const [tournDef, newAmount] of Object.entries(
          stat.blitzProfitPerTourn
        )) {
          const prevTournDefTotal =
            rollingSum.blitzProfitPerTourn[tournDef] ?? 0;
          rollingSum.blitzProfitPerTourn[tournDef] =
            prevTournDefTotal + newAmount;
        }

        /** Increment Async Group Per Tourn Profit Rolling Sum */
        for (const [tournDef, newAmount] of Object.entries(
          stat.asyncGroupProfitPerTourn
        )) {
          const prevTournDefTotal =
            rollingSum.asyncGroupProfitPerTourn[tournDef] ?? 0;
          rollingSum.asyncGroupProfitPerTourn[tournDef] =
            prevTournDefTotal + newAmount;
        }
      }
    }

    if (startTime === 0 || endTime === 0 || !statDocId) {
      return;
    }

    setLoadingStats("pending");
    const statColRef = collection(db, generateFirestorePath(statColName));
    const statsDocToUpdate = doc(statColRef, statDocId);
    const timeCollectionRef = collection(
      statsDocToUpdate,
      generateFirestorePath(granularity.toLowerCase())
    ).withConverter(statisticConverter);

    /** Perform Queries in UTC Time */
    const utcStart = dayjs(startTime).add(tzOffset, "minutes").valueOf();
    const utcEnd = dayjs(endTime).add(tzOffset, "minutes").valueOf();
    const priorQuery = query(
      timeCollectionRef,
      where("timestamp", "<", utcStart)
    );

    const timeStatsQuery = query(
      timeCollectionRef,
      where("timestamp", "<=", utcEnd),
      where("timestamp", ">=", utcStart),
      orderBy("timestamp")
    );
    const unsubTimeStats = onSnapshot(
      timeStatsQuery,
      async (timeStatsSnapshot) => {
        const priorSnapshot = await getDocs(priorQuery);
        // Update timestats object for rendering
        if (!statDocId) {
          return;
        }
        const priorTotalStat: Statistic = getEmptyStat(statDocId);
        const priorData = priorSnapshot.docs.map((snap, _) => {
          const stat = snap.data();
          return stat;
        });

        // Set prior stat object for cumulative data
        for (const stat of priorData) {
          incrementRollingSum(priorTotalStat, stat);
        }

        const explicitTimestamps: { [ts: number]: Statistic } =
          Object.fromEntries(
            timeStatsSnapshot.docs.map((doc) => {
              let ts = moment(Number(doc.id));
              return [ts.valueOf(), doc.data()];
            })
          );

        const timeStats: TimeStatistic = getEmptyTimeStat(statDocId);
        for (const [tsStr, stat] of Object.entries(explicitTimestamps)) {
          const ts = Number(tsStr);

          // Check for whether we're populating a game stat or
          // an org stat
          const isGameStat =
            timeStats.type === "game" &&
            stat.type === "game" &&
            priorTotalStat.type === "game";

          incrementRollingSum(priorTotalStat, stat);

          // Total Withdrawn
          timeStats.totalWithdrawn.deltas.push(stat.totalWithdrawn);
          timeStats.totalWithdrawn.labels.push(ts);
          timeStats.totalWithdrawn.cumulative.push(
            priorTotalStat.totalWithdrawn
          );

          // Total Profit
          timeStats.totalProfit.deltas.push(stat.totalProfit);
          timeStats.totalProfit.cumulative.push(priorTotalStat.totalProfit);
          timeStats.totalProfit.labels.push(ts);

          // Total Blitz Profit
          timeStats.totalBlitzProfit.deltas.push(stat.totalBlitzProfit);
          timeStats.totalBlitzProfit.cumulative.push(
            priorTotalStat.totalBlitzProfit
          );
          timeStats.totalBlitzProfit.labels.push(ts);

          // Total Async Group Profit
          timeStats.totalAsyncGroupProfit.deltas.push(
            stat.totalAsyncGroupProfit
          );
          timeStats.totalAsyncGroupProfit.cumulative.push(
            priorTotalStat.totalAsyncGroupProfit
          );
          timeStats.totalAsyncGroupProfit.labels.push(ts);

          // Total App Users
          timeStats.totalAppUsers.deltas.push(stat.totalAppUsers);
          timeStats.totalAppUsers.labels.push(ts);
          timeStats.totalAppUsers.cumulative.push(priorTotalStat.totalAppUsers);

          // Total Async Group Paid Out
          timeStats.totalAsyncGroupPaidOut.deltas.push(
            stat.totalAsyncGroupPaidOut
          );
          timeStats.totalAsyncGroupPaidOut.labels.push(ts);
          timeStats.totalAsyncGroupPaidOut.cumulative.push(
            priorTotalStat.totalAsyncGroupPaidOut
          );

          // Total Async Group Wagered
          timeStats.totalAsyncGroupWagered.deltas.push(
            stat.totalAsyncGroupWagered
          );
          timeStats.totalAsyncGroupWagered.labels.push(ts);
          timeStats.totalAsyncGroupWagered.cumulative.push(
            priorTotalStat.totalAsyncGroupWagered
          );

          // Async Group Total Starts
          timeStats.asyncGroupTotalStarts.deltas.push(
            stat.asyncGroupTotalStarts
          );
          timeStats.asyncGroupTotalStarts.labels.push(ts);
          timeStats.asyncGroupTotalStarts.cumulative.push(
            priorTotalStat.asyncGroupTotalStarts
          );

          // Async Group Total Finishes
          timeStats.asyncGroupTotalFinishes.deltas.push(
            stat.asyncGroupTotalFinishes
          );
          timeStats.asyncGroupTotalFinishes.labels.push(ts);
          timeStats.asyncGroupTotalFinishes.cumulative.push(
            priorTotalStat.asyncGroupTotalFinishes
          );

          // Blitz Total Finishes
          timeStats.blitzTotalFinishes.deltas.push(stat.blitzTotalFinishes);
          timeStats.blitzTotalFinishes.labels.push(ts);
          timeStats.blitzTotalFinishes.cumulative.push(
            priorTotalStat.blitzTotalFinishes
          );

          // Blitz Total Starts
          timeStats.blitzTotalStarts.deltas.push(stat.blitzTotalStarts);
          timeStats.blitzTotalStarts.labels.push(ts);
          timeStats.blitzTotalStarts.cumulative.push(
            priorTotalStat.blitzTotalStarts
          );

          // Total Blitz Paid Out
          timeStats.totalBlitzPaidOut.deltas.push(stat.totalBlitzPaidOut);
          timeStats.totalBlitzPaidOut.labels.push(ts);
          timeStats.totalBlitzPaidOut.cumulative.push(
            priorTotalStat.totalBlitzPaidOut
          );

          // Total Blitz Wagered
          timeStats.totalBlitzWagered.deltas.push(stat.totalBlitzWagered);
          timeStats.totalBlitzWagered.labels.push(ts);
          timeStats.totalBlitzWagered.cumulative.push(
            priorTotalStat.totalBlitzWagered
          );

          // Total Deposited
          timeStats.totalDeposited.deltas.push(stat.totalDeposited);
          timeStats.totalDeposited.labels.push(ts);
          timeStats.totalDeposited.cumulative.push(
            priorTotalStat.totalDeposited
          );

          // BEGIN -- TOURNAMENT STATS

          if (isGameStat) {
            // Set group profit time stat
            for (const [tournDef, updateValue] of Object.entries(
              stat.asyncGroupProfitPerTourn
            )) {
              const prevChartData =
                timeStats.asyncGroupProfitPerTourn[tournDef] ?? initChartData();
              prevChartData.deltas.push(updateValue);
              prevChartData.labels.push(ts);
              prevChartData.cumulative.push(
                priorTotalStat.asyncGroupProfitPerTourn[tournDef]
              );
              timeStats.asyncGroupProfitPerTourn[tournDef] = prevChartData;
            }

            // Set blitz profit time stat
            for (const [tournDef, updateValue] of Object.entries(
              stat.blitzProfitPerTourn
            )) {
              let prevChartData =
                timeStats.blitzProfitPerTourn[tournDef] ?? initChartData();
              prevChartData.deltas.push(updateValue);
              prevChartData.labels.push(ts);
              prevChartData.cumulative.push(
                priorTotalStat.blitzProfitPerTourn[tournDef]
              );
              timeStats.blitzProfitPerTourn[tournDef] = prevChartData;
            }
          }
        }

        // Set the updated TimeStat Data
        setTimeStatData(timeStats);
        setLoadingStats("finish");
      }
    );

    return () => {
      unsubTimeStats();
    };
  }, [
    granularity,
    startTime,
    endTime,
    statColName,
    isOrg,
    statDocId,
    tzOffset,
  ]);

  /** Client-side lookup for aggregate stats/missions/tournDefs */
  useEffect(() => {
    if (!statDocId) {
      return;
    }

    const isOrg = statColName === colNames.orgStatistics;

    const statColRef: CollectionReference<Statistic> = isOrg
      ? getCollection(colNames.orgStatistics)
      : getCollection(colNames.gameStatistics);

    const statRef = doc(statColRef, statDocId);
    const gameRef = doc(getCollection(colNames.games), statDocId);

    const tournamentDefColRef = getCollection(
      colNames.groupTournamentDefinitions,
      gameRef
    );

    const blitzTournDefColRef = getCollection(
      colNames.blitzTournamentDefinitions,
      gameRef
    );

    // subscribe stat data
    const unsubStat = onSnapshot(statRef, (statSnap) => {
      const stat = statSnap.data();
      if (stat) {
        setStatData(stat);
      }
    });

    // subscribe async group tourn. defs.
    const unsubAsyncGroupTournDef = onSnapshot(
      tournamentDefColRef,
      (colSnap) => {
        const defs: typeof groupTournDefs = {};
        for (const tourDefSnap of colSnap.docs) {
          defs[tourDefSnap.id] = tourDefSnap.data();
        }
        setGroupTournDefs(defs);
      }
    );

    // subscribe blitzV2 tourn. defs.
    const unsubBlitzTournDef = onSnapshot(blitzTournDefColRef, (colSnap) => {
      const defs: typeof blitzTournDefs = {};
      for (const blitzTournDefSnap of colSnap.docs) {
        defs[blitzTournDefSnap.id] = blitzTournDefSnap.data();
      }
      setBlitzTournDefs(defs);
    });

    return () => {
      unsubStat();
      unsubAsyncGroupTournDef();
      unsubBlitzTournDef();
    };
  }, [statColName, statDocId, statsId]);
  // END HOOKS

  /** Init time-series data structure */
  function initChartData(): BaseChartData {
    return { labels: [], deltas: [], cumulative: [] };
  }

  function getFontColor() {
    return { color: "#fff" }; // Default: white
  }

  /**
   *
   * @param {string} explainer Tooltip explainer description
   * @param {string} statTitle Statistic Display Name
   * @returns {JSX.Element} A `Tooltip` Component
   */
  function getExplainerTooltip(
    explainer: string,
    statTitle: string
  ): JSX.Element {
    return (
      <Tooltip
        title={explainer}
        className="point-on-hover"
        mouseEnterDelay={0.5}
      >
        <span>{statTitle}</span>
      </Tooltip>
    );
  }

  function getHoverFormattedValue(statTitle: string) {
    const centsInDollar = 100;
    const isDollarAmount =
      statTitle !== totalAppUsers && statTitle !== "Tournament Entries";
    if (statTitle !== hoverStatTitle || hoverDelta === null) {
      return "";
    }

    let result: string | number = hoverDelta;
    if (isDollarAmount) {
      result = Math.ceil(centsInDollar * result) / centsInDollar;
    }

    if (isDollarAmount) {
      if (showCum) {
        result = cumulativeFormatter.format(result);
      } else {
        result = deltaFormatter.format(result);
      }
    } else {
      result = userDeltaFormatter.format(result);
    }

    return result;
  }

  function getDeltaColor(statTitle: string) {
    if (statTitle !== hoverStatTitle || hoverDelta === null) {
      return { color: "rgba(0,0,0,0)" };
    }
    const green = "rgb(100,168,56)";
    const red = "rgb(153,44,44)";

    return hoverDelta < 0 ? { color: red } : { color: green };
  }

  /**
   *
   * @param {string} statTitle Statistic Display Name
   * @param {number} totalValueRaw Cumulative Statistic Value
   * @param {BaseChartData | null} chartDataRaw Time statistic data
   * @param {string} explainer Tooltip explainer description
   *
   * @return {JSX.Element} A fully-fledged statistic component,
   * with Title, Aggregate, and Time-Series Data
   */
  function statComponent(
    statTitle: string,
    totalValueRaw: number,
    chartDataRaw: BaseChartData | null,
    explainer: string
  ): JSX.Element {
    const currencyPrecision = 2;
    const centsInDollar = 100;
    const isDollarAmount = statTitle !== totalAppUsers;

    if (!statData || !timeStatData) {
      // !! Should never enter this case !!
      return <></>;
    }

    // show overall delta over time period in delta mode
    if (chartDataRaw) {
      totalValueRaw = sumArr(chartDataRaw.deltas);
    }

    // Either get total for all users, or mean total per user
    const totalValue = totalValueRaw;

    const chartData = chartDataRaw;

    const rawDollarVal = totalValue / centsInDollar;

    function getFormattedValue() {
      let result: string | number = totalValue;
      if (isDollarAmount) {
        result = Math.ceil(centsInDollar * rawDollarVal) / centsInDollar;
      }

      if (statTitle !== totalAppUsers) {
        if (showCum) {
          result = cumulativeFormatter.format(result);
        } else {
          result = deltaFormatter.format(result);
        }
      } else {
        result = userDeltaFormatter.format(result);
      }

      return result;
    }

    function getFontColor() {
      return { color: "#fff" }; // Default: white
    }

    if (chartData) {
      return (
        <Card
          className="stat-card stat-title-card"
          bordered={false}
          style={{ borderRadius: "20px" }}
        >
          <StatComp
            title={getExplainerTooltip(explainer, statTitle)}
            className={"topStat text-disable"}
            value={getFormattedValue()}
            precision={isDollarAmount ? currencyPrecision : 0}
            valueStyle={getFontColor()}
          />
          <Row justify="space-between" align="middle">
            <StatComp
              className={"text-disable"}
              value={getHoverFormattedValue(statTitle)}
              valueStyle={getDeltaColor(statTitle)}
              precision={isDollarAmount ? currencyPrecision : 0}
            />
            <Text type="secondary" strong={true} className={"text-disable"}>
              {statTitle === hoverStatTitle ? hoverlabel ?? "" : ""}{" "}
            </Text>
          </Row>
          {chartData && timeStatData && (
            <div
              className="py-4"
              onDoubleClick={() => {
                setSelectedStatTitle(statTitle);
                setSelectedStatData(chartData);
                expandChart();
              }}
            >
              {getChartForData(statTitle, isDollarAmount, chartData)}
            </div>
          )}
        </Card>
      );
    } else {
      return (
        <Space direction="vertical" size={14}>
          <Card className="stat-card" style={{ borderRadius: "20px" }}>
            <StatComp
              className={"text-disable"}
              title={getExplainerTooltip(explainer, statTitle)}
              value={
                isDollarAmount
                  ? Math.ceil(centsInDollar * rawDollarVal) / centsInDollar
                  : totalValue
              }
              precision={isDollarAmount ? currencyPrecision : 0}
              prefix={isDollarAmount ? "$" : ""}
              valueStyle={
                statTitle === totalWithdrawn
                  ? { color: red }
                  : isDollarAmount
                  ? { color: "#fff" }
                  : {}
              }
            />
          </Card>
          <Card className="stat-card" style={{ borderRadius: "20px" }}>
            {getChartForData(statTitle, isDollarAmount, chartData)}
          </Card>
        </Space>
      );
    }
  }

  /**
   *
   * @param {ChartJS} chart
   * @param {CanvasRenderingContext2D} ctx
   *
   * @returns {CanvasGradient | undefined} A color gradient for the Statistic Charts
   */
  function getGradient(
    chart: ChartJS,
    ctx: CanvasRenderingContext2D
  ): CanvasGradient | undefined {
    if (chart && ctx) {
      const gradient = ctx.createLinearGradient(0, 0, 0, chart.height);
      gradient.addColorStop(0, "rgba(255, 123, 0, 0)");
      gradient.addColorStop(0.1, "rgba(255, 123, 0, 0.2)");
      gradient.addColorStop(1, "rgba(255, 123, 0, 0)");
      return gradient;
    }
  }

  const cumulativeFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const deltaFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    signDisplay: "always",
  });

  const userDeltaFormatter = new Intl.NumberFormat("en-US", {
    signDisplay: "always",
  });

  /**
   * Calculates new start time object given Interval Menu Item
   *
   * @param {string} title Statistic Display Name
   * @param {boolean} isDollarAmount Whether Statistic is denominated in USD $
   * @param {BaseChartData | null} chartData The chart data to seed component with
   *
   * @return {JSX.Element} A `Chart` Component
   */
  function getChartForData(
    title: string,
    isDollarAmount: boolean,
    chartData: BaseChartData | null
  ): JSX.Element {
    const formattedLabels = chartData
      ? chartData.labels.map((date) => {
          const localTZDate = moment(date);
          if (granularity === hourly) {
            return localTZDate.format("MM/DD/YY hh:mm A");
          } else if (granularity === daily) {
            return localTZDate.format("MMM DD, YYYY");
          } else if (granularity === weekly) {
            return "Week of: " + localTZDate.format("MMM DD, YYYY");
          } else if (granularity === monthly) {
            return localTZDate.format("MMMM YYYY");
          } else {
            return localTZDate.format("YYYY");
          }
        })
      : [];

    let data: number[] = [];
    if (chartData) {
      if (showCum) {
        data = chartData.cumulative;
      } else {
        data = chartData.deltas;
      }
    }

    const formattedValues = data.map((val) => {
      if (isDollarAmount) {
        return val / 100;
      } else {
        return val;
      }
    });

    return (
      <Chart
        datasetIdKey="id"
        onMouseLeave={() => {
          setHoverLabel(null);
          setHoverDelta(null);
          setHoverStatTitle(null);
        }}
        type="line"
        data={{
          labels: formattedLabels,
          datasets: [
            {
              fill: "origin",
              label: "total",
              data: formattedValues,
              borderColor: "#ff7b00",
              pointBackgroundColor: "rgba(0,0,0,0)",

              backgroundColor: function (context) {
                const chart = context.chart;
                if (chart.attached) {
                  const { ctx } = chart;
                  return getGradient(chart, ctx);
                }
              },
            },
          ],
        }}
        options={{
          animation: { duration: 500 },
          interaction: { mode: "index", intersect: false },
          scales: {
            x: {
              display: false,
            },
            y: {
              grid: {
                color: selectedStatData ? "rgba(0,0,0,1)" : "rgba(0,0,0,0)",
              },
              display: true,
            },
          },
          elements: {
            point: {
              borderWidth: 0,
              radius: 10,
              backgroundColor: "rgba(0,0,0,0)",
            },
          },
          plugins: {
            title: {
              display: true,
              text: title,
            },
            legend: {
              display: false,
            },
            tooltip: {
              backgroundColor: "rgba(0,0,0,0)",
              animation: {
                duration: 0,
              },
              displayColors: false,
              bodyFont: { size: 16 },
              callbacks: {
                title(_) {
                  return "";
                },
                label(this, tooltipItem) {
                  const idx = tooltipItem.dataIndex;
                  setHoverDelta(formattedValues[idx]);
                  setHoverStatTitle(title);
                  setHoverLabel(tooltipItem.label);
                  return "";
                },
              },
            },
          },
        }}
      />
    );
  }

  /**
   * Calculates new start time object given Interval Menu Item
   *
   * @param {string} menuKey Range selection from Interval Menu
   *
   * @return {moment.Moment} The new Start Time
   */
  function getStartTimeFromMenu(menuKey: string): moment.Moment {
    let newStartTime = moment().startOf("day");
    if (menuKey === "Today") {
      // Do nothing, initialized to 'Today' case
    } else if (menuKey === "pastWeek") {
      newStartTime = newStartTime.subtract(1, "week");
    } else if (menuKey === "pastMonth") {
      newStartTime = newStartTime.subtract(1, "month");
    } else if (menuKey === "pastQuarter") {
      newStartTime = newStartTime.subtract(1, "quarter");
    } else if (menuKey === "pastYear") {
      newStartTime = newStartTime.subtract(1, "year");
    } else if (menuKey === "mtd") {
      newStartTime = newStartTime.startOf("month");
    } else if (menuKey === "qtd") {
      newStartTime = newStartTime.startOf("quarter");
    } else if (menuKey === "ytd") {
      newStartTime = newStartTime.startOf("year");
    } else {
      newStartTime = moment("01/01/2021");
    }
    return newStartTime;
  }

  /**
   * Handles Interval Selection Click Event
   */
  const handleMenuClick: MenuProps["onClick"] = (event) => {
    const menuKey = event.key as keyof typeof menuValues;
    const newEndTime = moment().endOf("day").valueOf();
    const newStartTime = getStartTimeFromMenu(menuKey).valueOf();

    // update store with new range info
    batch(() => {
      dispatch(updateWindow(menuKey));
      dispatch(updateRange({ start: newStartTime, end: newEndTime }));
    });
  };

  /**
   * Create Interval Selector ('Last 7 Days, Today, ... Etc.')
   *
   * @return {JSX.Element} Interval `Menu` Component
   */
  function getIntervalMenu(): JSX.Element {
    return (
      <Menu
        defaultValue={window}
        onClick={handleMenuClick}
        items={Object.entries(menuValues)
          .filter(([, val]) => val !== "Custom") // Don't display Custom item on selection
          .map(([key, val]) => {
            return { key, label: val };
          })}
      />
    );
  }

  /**
   * Create Bar Chart Component for the Grouped Stats (Per. Tourn. Stats)
   *
   * @return {JSX.Element} `Bar` Component
   */
  function getBarChart(
    statTitle: string,
    data: number[],
    labels: number[] | string[],
    isDollarAmount: boolean
  ): JSX.Element {
    /**
     * Fetches a color code for a ChartJs
     * `Bar` data element
     *
     * @param {ScriptableContext} ctx ChartJs context
     * @return {string} Color code for bar background, either `red` or `green`
     */
    function getBarBackgroundColor(ctx: ScriptableContext<"bar">): string {
      const green = "rgb(100,168,56)";
      const red = "rgb(153,44,44)";
      return data[ctx.dataIndex] >= 0 ? green : red;
    }

    /**
     *
     * @param {ScriptableContext} ctx
     * @returns
     */
    function getBarRadius(ctx: ScriptableContext<"bar">) {
      return ctx.dataset.data.length > 4 ? 4 : 8;
    }

    /**
     * Sets the horizontal grid color depending on active theme
     *
     * @param {ScriptableScaleContext} ctx ChartJs Scale Context
     * @return {string} Color code for chart gridlines
     */
    function getBarGridColor(ctx: ScriptableScaleContext): string {
      if (ctx.tick.value === 0) {
        return isDarkMode ? "rgb(60,60,60)" : "rgb(215,215,215)";
      }
      return "rgba(0,0,0,0)";
    }

    /**
     *  Transparent Color
     */
    const transparent = "rgba(0,0,0,0)";

    // divide raw amounts by 100 cents if stat is denominated in USD $
    // otherwise it's a count (ex: totalAppUsers)
    const divisor = isDollarAmount ? 100 : 1;

    // get axis bounds for the bar graph...depends on value of display data

    const showNegMinBound = data.find((elem) => elem < 0);

    // Get min y axis value

    const maxAbsVal = Math.max(...data.map((num) => Math.abs(num / divisor)));
    let minBound = 0;

    // if not $ amount, stat is a count, which can't be negative
    if (showNegMinBound) {
      // don't round if largest datapoint has magnitude less than 1.0
      if (maxAbsVal < 1.0) {
        minBound = -maxAbsVal - maxAbsVal * 0.1;
      } else {
        minBound = -Math.round(maxAbsVal) - Math.round(maxAbsVal * 0.1);
      }
    }

    // Get max y axis value
    let maxBound: number;
    if (maxAbsVal < 1.0) {
      maxBound = maxAbsVal + maxAbsVal * 0.1;
    } else {
      maxBound = Math.round(maxAbsVal) + Math.round(maxAbsVal * 0.1);
    }

    return (
      <Bar
        style={{ marginTop: "16px" }}
        options={{
          interaction: { mode: "index", intersect: false },
          plugins: {
            legend: { display: false },

            tooltip: {
              backgroundColor: "rgba(0,0,0,0)",
              animation: {
                duration: 0,
              },
              displayColors: false,
              bodyFont: { size: 16 },
              callbacks: {
                title(_) {
                  return "";
                },
                label(this, tooltipItem) {
                  const idx = tooltipItem.dataIndex;

                  setHoverDelta(isDollarAmount ? data[idx] / 100 : data[idx]);
                  setHoverStatTitle(statTitle);
                  setHoverLabel(tooltipItem.label);

                  return "";
                },
              },
            },
          },
          scales: {
            x: {
              display: true,
              grid: { color: transparent },
            },
            y: {
              ticks: { maxTicksLimit: 20 },
              display: true,
              grid: {
                color: getBarGridColor,
              },
              min: minBound,
              max: maxBound,
            },
          },
        }}
        data={{
          labels: labels.map((elem) => elem.toString()),
          datasets: [
            {
              data: data.map((elem) => elem / divisor),
              categoryPercentage: 0.6,
              maxBarThickness: 80,
              borderRadius: getBarRadius,
              backgroundColor: getBarBackgroundColor,
            },
          ],
        }}
      />
    );
  }

  /**
   * Creates time stat. granularity selector based on current
   * date range selection
   *
   * @return {JSX.Element} Granularity Selector Component
   */
  function displayGranularitySelector(): JSX.Element {
    return (
      <Radio.Group
        value={granularity}
        buttonStyle="solid"
        onChange={(event) => {
          if (event.target.value) {
            const granularity = event.target
              .value as keyof typeof granularityMap;
            const oldStart = moment(startTime);
            const oldEnd = moment(endTime);

            // "want to hop to BEGINNING of day, not beginning of curr user's hour"
            let rawStartTime = oldStart.startOf(granularityMap.Daily);
            // "want to hop to END of day, not beginning of curr user's hour"
            let rawEndTime = oldEnd.endOf(granularityMap.Daily);
            let shouldUpdateSnapGranularity = false;
            // For larger granularities, perform additional truncation
            if (
              granularity !== hourly &&
              granularity !== daily &&
              window !== "custom"
            ) {
              rawStartTime = rawStartTime.startOf(granularityMap[granularity]);
              rawEndTime = rawEndTime.endOf(granularityMap[granularity]);
            }

            // cap endTime by current end of day
            const endTimeLimit = moment().endOf("day").valueOf();

            const endTimeCapped =
              rawEndTime.valueOf() > endTimeLimit.valueOf()
                ? endTimeLimit.valueOf()
                : rawEndTime.valueOf();

            let shouldChangeToCustom = false;

            // May need to update Window, granularity, and range
            if (
              moment(startTime).day() !== rawStartTime.day() ||
              moment(endTime).day() !== rawEndTime.day()
            ) {
              if (
                granularity !== hourly &&
                granularity !== daily &&
                (snapGranularity === granularity || window !== "custom")
              ) {
                // message.info(snapMsg, 3);
                setShowDateRangeTooltip(true);
                shouldUpdateSnapGranularity = true;
              }
              shouldChangeToCustom = true;
            }

            /**
             * if this change doesn't result in a range snap
             * don't update window
             */
            batch(() => {
              if (shouldChangeToCustom) {
                dispatch(updateWindow("custom"));
              }
              if (shouldUpdateSnapGranularity) {
                dispatch(updateSnapGranularity(granularity));
              }
              dispatch(updateGranularity(granularity));
              dispatch(
                updateRange({
                  start: rawStartTime.valueOf(),
                  end: endTimeCapped,
                })
              );
            });
          }
        }}
        disabled={loadingStats === "pending"}
        className="granularity-radio"
      >
        {Object.keys(granularityMap).map((currGranularity) => {
          /** First perform check to prevent different granularity snapping back-to-back
           *  This helps prevent range from continuously growing
           */
          if (currGranularity !== hourly && currGranularity !== daily) {
            if (window === "custom" && currGranularity !== snapGranularity) {
              return null;
            }
          }
          /** Next check if remaining granularities are valid */
          if (
            validGranularities.includes(
              currGranularity as keyof typeof granularityMap
            )
          ) {
            return (
              <Radio.Button
                className="granularity-radio-button text-disable-button"
                key={currGranularity}
                value={currGranularity}
              >
                {currGranularity}
              </Radio.Button>
            );
          } else {
            return null;
          }
        })}
      </Radio.Group>
    );
  }

  /** The cum selector component */
  function displayCumSelector(): JSX.Element {
    return (
      <Tooltip title={showCum ? "Show +/-" : "Show Cumulative"}>
        <Radio.Group style={{ paddingLeft: "8px" }} value={true}>
          <Radio.Button onClick={() => setShowCum(!showCum)}>
            <AreaChartOutlined />
          </Radio.Button>
        </Radio.Group>
      </Tooltip>
    );
  }

  function expandChart() {
    setIsChartExpanded(true);
  }

  function handleCancel() {
    setIsChartExpanded(false);
  }

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    ChartJSTooltip,
    Legend
  );

  const isDollarAmount = selectedStatTitle !== totalAppUsers;
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  return (
    <TriumphPage>
      {(loadingStats === "pending" || loadingStats === "idle") && (
        <div className="stats-loader">
          <Spin />
        </div>
      )}

      <Modal
        open={isChartExpanded}
        onCancel={() => {
          handleCancel();
          setSelectedStatData(null);
          setSelectedStatTitle(null);
        }}
        width={"80%"}
        footer={null}
      >
        {selectedStatTitle &&
          getChartForData(selectedStatTitle, isDollarAmount, selectedStatData)}
      </Modal>
      {!((!statData || !timeStatData) && loadingStats === "finish") && (
        <Row gutter={16} className="mb-10">
          <Col
            span={singleColSpan}
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: `${isMobile ? "wrap" : "nowrap"}`,
            }}
          >
            <Space>
              <Button
                type="primary"
                style={{
                  visibility:
                    auth &&
                    auth.currentUser &&
                    auth.currentUser.email &&
                    (auth.currentUser.email === "derek@triumpharcade.com" ||
                      auth.currentUser.email === "david@triumpharcade.com")
                      ? "visible"
                      : "hidden",
                }}
                onClick={() => {
                  setIsRefreshingFirestore(true);
                }}
                loading={isRefreshingFirestore}
                disabled={isRefreshingFirestore || isResettingFirestore}
                className="game-stats-filter-button btn-brand"
              >
                {isRefreshingFirestore ? "Updating..." : "Update Firestore"}
              </Button>
              <Button
                danger
                type="primary"
                style={{
                  visibility:
                    auth &&
                    auth.currentUser &&
                    auth.currentUser.email &&
                    (auth.currentUser.email === "derek@triumpharcade.com" ||
                      auth.currentUser.email === "david@triumpharcade.com")
                      ? "visible"
                      : "hidden",
                }}
                onClick={() => {
                  setIsResettingFirestore(true);
                }}
                loading={isResettingFirestore}
                disabled={isResettingFirestore || isRefreshingFirestore}
                className="btn-brand"
              >
                {isRefreshingFirestore ? "Resetting..." : "Reset Firestore"}
              </Button>
            </Space>

            <Space
              size={"small"}
              style={{
                display: "flex",
                flexWrap: `${isMobile ? "wrap" : "nowrap"}`,
              }}
            >
              <Dropdown overlay={getIntervalMenu()}>
                <Button>
                  <Space>
                    {interval}
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
              <Tooltip
                title={snapMsg}
                open={showDateRangeTooltip}
                placement="topLeft"
              >
                <RangePicker
                  picker={(() => {
                    if (granularity === weekly) {
                      return "week";
                    } else if (granularity === monthly) {
                      return "month";
                    } else if (granularity === yearly) {
                      return "year";
                    } else {
                      return "date";
                    }
                  })()}
                  className="text-disable-button"
                  style={{ width: "350px" }}
                  value={[moment(startTime), moment(endTime)]}
                  disabledDate={(currentDate) => {
                    return currentDate.valueOf() > moment().valueOf();
                  }}
                  format="MMMM D, YYYY"
                  onChange={(range) => {
                    if (range && range.length === 2 && range[0] && range[1]) {
                      // const oldStart = moment(startTime);
                      // const oldEnd = moment(endTime);
                      const newStart = range[0];
                      const newEnd = range[1];

                      // "want to hop to BEGINNING of day, not beginning of curr user's hour"
                      let rawStartTime = newStart.startOf(granularityMap.Daily);
                      // "want to hop to END of day, not beginning of curr user's hour"
                      let rawEndTime = newEnd.endOf(granularityMap.Daily);
                      let shouldUpdateSnapGranularity = false;
                      // Only snap if not hourly or daily, or already snapped previously
                      if (
                        granularity !== hourly &&
                        granularity !== daily &&
                        window !== "custom"
                      ) {
                        rawStartTime = rawStartTime.startOf(
                          granularityMap[granularity]
                        );
                        rawEndTime = rawEndTime.endOf(
                          granularityMap[granularity]
                        );
                        shouldUpdateSnapGranularity = true;
                      }

                      // cap endTime by current time
                      const endTimeLimit = moment().endOf("day").valueOf();
                      const endTimeCapped =
                        rawEndTime.valueOf() > endTimeLimit.valueOf()
                          ? endTimeLimit.valueOf()
                          : rawEndTime.valueOf();
                      let shouldChangeToCustom = false;

                      /** checks if year, month, and day are same */
                      if (
                        moment(startTime).day() !== rawStartTime.day() ||
                        moment(endTime).day() !== rawEndTime.day()
                      ) {
                        if (granularity !== hourly && granularity !== daily) {
                          // message.info(snapMsg, 3);
                          setShowDateRangeTooltip(true);
                        }
                        shouldChangeToCustom = true;
                      }

                      batch(() => {
                        if (shouldChangeToCustom) {
                          dispatch(updateWindow("custom"));
                        }

                        if (shouldUpdateSnapGranularity) {
                          dispatch(updateSnapGranularity(granularity));
                        }

                        dispatch(updateGranularity(granularity));
                        dispatch(
                          updateRange({
                            start: rawStartTime.valueOf(),
                            end: endTimeCapped,
                          })
                        );
                      });
                    }
                  }}
                  disabled={loadingStats === "pending"}
                />
              </Tooltip>
            </Space>

            <Space
              size={!isMobile ? "large" : "small"}
              style={{
                display: "flex",
                flexWrap: `${isMobile ? "wrap" : "nowrap"}`,
              }}
            ></Space>
          </Col>
        </Row>
      )}

      {(!statData || !timeStatData) && loadingStats === "finish" && (
        <AnalyticsWelcome isDashboard={true} />
      )}
      {statData && timeStatData && (
        <Col>
          <Card className="section-card">
            <div className="section">
              <Row
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <h4 className="text-uppercase text-disable">Overview</h4>
                <div>
                  {displayGranularitySelector()}
                  {displayCumSelector()}
                </div>
              </Row>
              <Row gutter={12}>
                <Col sm={24} md={12} lg={12} xl={6}>
                  {statComponent(
                    profitLoss,
                    statData.totalProfit,
                    timeStatData.totalProfit,
                    "Net Profit, calculated by deducting Expenses from Revenue"
                  )}
                </Col>

                <Col sm={24} md={12} lg={12} xl={6}>
                  {statComponent(
                    totalDeposited,
                    statData.totalDeposited,
                    timeStatData.totalDeposited,
                    "Total User Deposits"
                  )}
                </Col>

                <Col sm={24} md={12} lg={12} xl={6}>
                  {statComponent(
                    totalWithdrawn,
                    statData.totalWithdrawn,
                    timeStatData.totalWithdrawn,
                    "Total User Withdrawals"
                  )}
                </Col>

                <Col sm={24} md={12} lg={12} xl={6}>
                  {statComponent(
                    totalAppUsers,
                    statData.totalAppUsers,
                    timeStatData.totalAppUsers,
                    "Total Number of Users"
                  )}
                </Col>
              </Row>
            </div>
          </Card>

          {/* BEGIN allGames tournament summary */}
          <Card className="section-card">
            <div className="section">
              <h4 className="text-uppercase mb-5 text-disable">
                {"Tournament Summary"}
              </h4>
              <Row gutter={12}>
                <Col sm={24} md={12} lg={12} xl={6}>
                  <Card
                    className="stat-card"
                    bordered={false}
                    style={{ borderRadius: "20px" }}
                  >
                    <StatComp
                      className="topStat text-disable"
                      title={getExplainerTooltip(
                        "The total number of user entries into either Group or Blitz tournaments",
                        "Tournament Entries"
                      )}
                      value={userDeltaFormatter.format(
                        sumArr(timeStatData.asyncGroupTotalStarts.deltas) +
                          sumArr(timeStatData.blitzTotalStarts.deltas)
                      )}
                      valueStyle={getFontColor()}
                    />
                    <Row justify="space-between" align="middle">
                      <StatComp
                        className={"text-disable"}
                        value={getHoverFormattedValue("Tournament Entries")}
                        valueStyle={getDeltaColor("Tournament Entries")}
                        precision={isDollarAmount ? 2 : 0}
                      />
                      <Text
                        type="secondary"
                        strong={true}
                        className={"text-disable"}
                      >
                        {"Tournament Entries" === hoverStatTitle
                          ? hoverlabel ?? ""
                          : ""}
                      </Text>
                    </Row>
                    {getBarChart(
                      "Tournament Entries",
                      [
                        sumArr(timeStatData.asyncGroupTotalStarts.deltas),
                        sumArr(timeStatData.blitzTotalStarts.deltas),
                      ],
                      ["Group", "Blitz"],
                      false
                    )}
                  </Card>
                </Col>
                <Col sm={24} md={12} lg={12} xl={6}>
                  <Card
                    className="stat-card"
                    bordered={false}
                    style={{ borderRadius: "20px" }}
                  >
                    <StatComp
                      className="topStat text-disable"
                      title={getExplainerTooltip(
                        "Profit from all tournaments played",
                        "Tournament Profit"
                      )}
                      value={deltaFormatter.format(
                        (sumArr(timeStatData.totalAsyncGroupProfit.deltas) +
                          sumArr(timeStatData.totalBlitzProfit.deltas)) /
                          100
                      )}
                      precision={2}
                      valueStyle={getFontColor()}
                    />
                    <Row justify="space-between" align="middle">
                      <StatComp
                        className={"text-disable"}
                        value={getHoverFormattedValue("Tournament Profit")}
                        valueStyle={getDeltaColor("Tournament Profit")}
                        precision={isDollarAmount ? 2 : 0}
                      />
                      <Text
                        type="secondary"
                        strong={true}
                        className={"text-disable"}
                      >
                        {"Tournament Profit" === hoverStatTitle
                          ? hoverlabel ?? ""
                          : ""}
                      </Text>
                    </Row>
                    {getBarChart(
                      "Tournament Profit",
                      [
                        sumArr(timeStatData.totalAsyncGroupProfit.deltas),
                        sumArr(timeStatData.totalBlitzProfit.deltas),
                      ],
                      ["Group", "Blitz"],
                      true
                    )}
                  </Card>
                </Col>
                {statData.type === "game" &&
                  timeStatData.type === "game" &&
                  Object.keys(blitzTournDefs).length && (
                    <Col sm={24} md={12} lg={12} xl={6}>
                      <Card
                        className="stat-card"
                        bordered={false}
                        style={{ borderRadius: "20px" }}
                      >
                        <StatComp
                          className="topStat text-disable"
                          title={getExplainerTooltip(
                            "Profit from all Blitz Tournaments Played",
                            "Blitz Tournament Profit"
                          )}
                          value={deltaFormatter.format(
                            sumArr(timeStatData.totalBlitzProfit.deltas) / 100
                          )}
                          precision={2}
                          valueStyle={getFontColor()}
                        />
                        <Row justify="space-between" align="middle">
                          <StatComp
                            className={"text-disable"}
                            value={getHoverFormattedValue(
                              "Blitz Tournament Profit"
                            )}
                            valueStyle={getDeltaColor(
                              "Blitz Tournament Profit"
                            )}
                            precision={isDollarAmount ? 2 : 0}
                          />
                          <Text
                            type="secondary"
                            strong={true}
                            className={"text-disable"}
                          >
                            {"Blitz Tournament Profit" === hoverStatTitle
                              ? hoverlabel ?? ""
                              : ""}
                          </Text>
                        </Row>
                        {getBarChart(
                          "Blitz Tournament Profit",
                          Object.keys(timeStatData.blitzProfitPerTourn)
                            .sort()
                            .map((key) => {
                              const tournStatMap =
                                timeStatData.blitzProfitPerTourn[key];
                              return sumArr(tournStatMap.deltas);
                            }),
                          Object.keys(timeStatData.blitzProfitPerTourn)
                            .sort()
                            .map(
                              (key) =>
                                cumulativeFormatter.format(
                                  (blitzTournDefs[key]?.entryPrice ?? 0) / 100
                                ) + " Entry"
                            ),
                          true
                        )}
                      </Card>
                    </Col>
                  )}
                {statData.type === "game" && timeStatData.type === "game" && (
                  <Col sm={24} md={12} lg={12} xl={6}>
                    <Card
                      className="stat-card"
                      bordered={false}
                      style={{ borderRadius: "20px" }}
                    >
                      <StatComp
                        className="topStat text-disable"
                        title={getExplainerTooltip(
                          "Profit from all Group Tournaments Played",
                          "Group Tournament Profit"
                        )}
                        value={deltaFormatter.format(
                          sumArr(timeStatData.totalAsyncGroupProfit.deltas) /
                            100
                        )}
                        precision={2}
                        valueStyle={getFontColor()}
                      />
                      <Row justify="space-between" align="middle">
                        <StatComp
                          className={"text-disable"}
                          value={getHoverFormattedValue(
                            "Group Tournament Profit"
                          )}
                          valueStyle={getDeltaColor("Group Tournament Profit")}
                          precision={isDollarAmount ? 2 : 0}
                        />
                        <Text
                          type="secondary"
                          strong={true}
                          className={"text-disable"}
                        >
                          {"Group Tournament Profit" === hoverStatTitle
                            ? hoverlabel ?? ""
                            : ""}
                        </Text>
                      </Row>
                      {getBarChart(
                        "Group Tournament Profit",
                        Object.keys(timeStatData.asyncGroupProfitPerTourn)
                          .sort()
                          .map((key) => {
                            const tournStatMap =
                              timeStatData.asyncGroupProfitPerTourn[key];
                            return sumArr(tournStatMap.deltas);
                          }),
                        Object.keys(timeStatData.asyncGroupProfitPerTourn)
                          .sort()
                          .map(
                            (key) =>
                              groupTournDefs[key]?.name ?? "Unknown Tournament"
                          ),
                        true
                      )}
                    </Card>
                  </Col>
                )}
              </Row>
              {/* END allGames tournament summary */}
            </div>
          </Card>
        </Col>
      )}
    </TriumphPage>
  );
};

export default Statistics;
