import { Row, Typography, Upload } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import type { UploadFile, UploadProps } from "antd/es/upload/interface";
import React, { useEffect, useState } from "react";
import { getCdnUrl, storage } from "src/helpers";
import { uploadBytes, ref, deleteObject } from "firebase/storage";
import { v4 } from "uuid";
import dayjs from "dayjs";

interface Props {
  name: string;
  hideLabel?: boolean;
  id: string;
  url?: string;
  path: string;
  className?: string;
  hideUploadLabel?: boolean;
  onUpload: (x: string) => void;
  onRemoveImg?: () => void;
  disabled?: boolean;
  width?: number;
  height?: number;
}
const UploadImage: React.FC<Props> = ({
  name,
  id,
  url,
  path,
  hideLabel,
  onUpload,
  onRemoveImg,
  className,
  hideUploadLabel,
  disabled,
  width,
  height,
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (url && url !== "") {
      setFileList([{ name, uid: id, url }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const onChange: UploadProps["onChange"] = async ({ file }) => {
    if (disabled) {
      alert("Sorry! the action is disabled.");
      return;
    }

    if (!file.originFileObj) {
      return;
    }

    const isValid = await checkDimension(file);
    if (!isValid) {
      return;
    }
    setLoading(true);

    const storageRef = ref(
      storage,
      `${path}/${dayjs().format("YYYY-MM-DD-THHmmss")}-${v4()}.${
        file.name.split(".")[1]
      }`
    );
    await uploadBytes(storageRef, file.originFileObj);
    onUpload(storageRef.fullPath);
    setLoading(false);
  };

  function checkDimension(file: UploadFile) {
    return new Promise((res, rej) => {
      if (!width && !height) {
        return res(true);
      }

      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj as Blob);
      reader.onload = function (e) {
        const image = new Image();

        image.src = e.target?.result as string;

        //Validate the File Height and Width.
        image.onload = function () {
          if (image.height !== height || image.width !== width) {
            alert(
              `Image should be of following size: ${width} x ${height} but your image is of size ${image.width} x ${image.height}`
            );

            return res(false);
          }
          return res(true);
        };
      };
    });
  }

  const onRemove: UploadProps["onRemove"] = (file) => {
    if (!url) {
      setFileList([]);
      onRemoveImg?.();
      return;
    }
    if (!file.url) {
      throw new Error("missing url for file");
    }
    const [, path] = file.url.split(getCdnUrl());
    const desertRef = ref(storage, path);

    deleteObject(desertRef).finally(() => {
      setFileList([]);
      onRemoveImg?.();
    });
  };

  function uploadButton() {
    if (fileList.length === 0) {
      return (
        <div>
          {loading ? <LoadingOutlined /> : <PlusOutlined />}
          {!hideUploadLabel && <div style={{ marginTop: 8 }}>Upload</div>}
        </div>
      );
    }
  }

  return (
    <>
      <Row align="middle" justify="center">
        <Upload
          listType="picture-card"
          className={`${className ? className : ""} uploader-wrap`}
          fileList={fileList}
          onChange={onChange}
          onRemove={onRemove}
        >
          {uploadButton()}
        </Upload>

        {!hideLabel && <Typography.Text strong>{name}</Typography.Text>}
      </Row>
    </>
  );
};

export default UploadImage;
