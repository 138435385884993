import { Descriptions } from "antd";
import { FC } from "react";
import { useMediaQuery } from "react-responsive";
import { dateFormatter } from "src/shared/config/constants";

interface Props {
  eventDetails: RecaptchaAssessmentEvent;
}

const RecaptchaAssessment: FC<Props> = ({ eventDetails }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });

  return (
    <>
      <Descriptions
        layout={!isMobile ? "horizontal" : "vertical"}
        column={1}
        bordered
        title="Personal Details"
        className="pb-2"
      >
        <Descriptions.Item label="ID">{eventDetails.uid}</Descriptions.Item>
        <Descriptions.Item label="Error Message">
          {eventDetails.errorMessage}
        </Descriptions.Item>
        <Descriptions.Item label="Error Reason">
          {eventDetails.errorReason}
        </Descriptions.Item>
        <Descriptions.Item label="Score">
          {eventDetails.score}
        </Descriptions.Item>
        <Descriptions.Item label="Success">
          {eventDetails.success ? "Yes" : "No"}
        </Descriptions.Item>

        <Descriptions.Item label="Type">
          {eventDetails.eventType}
        </Descriptions.Item>
        <Descriptions.Item label="Sequence ID">
          {eventDetails.sequenceId}
        </Descriptions.Item>
        <Descriptions.Item label="Created at">
          {dateFormatter(eventDetails.createdAt)}
        </Descriptions.Item>
      </Descriptions>
      {eventDetails.rawAssessment && (
        <Descriptions
          layout={!isMobile ? "horizontal" : "vertical"}
          column={1}
          bordered
          title="Raw Assessment"
          className="pb-2"
        >
          <Descriptions.Item label="Name">
            {eventDetails.rawAssessment.name}
          </Descriptions.Item>
          <Descriptions.Item label="Account Defender Labels">
            {eventDetails.rawAssessment.accountDefenderAssessment?.labels?.join(
              ", "
            )}
          </Descriptions.Item>
          {eventDetails.rawAssessment.event && (
            <>
              <Descriptions.Item label="Event Expected Action">
                {eventDetails.rawAssessment.event.expectedAction}
              </Descriptions.Item>
              <Descriptions.Item label="Event hashed Account Id">
                {eventDetails.rawAssessment.event.hashedAccountId}
              </Descriptions.Item>
              <Descriptions.Item label="Event Site Key">
                {eventDetails.rawAssessment.event.siteKey}
              </Descriptions.Item>

              <Descriptions.Item label="Event User Agent">
                {eventDetails.rawAssessment.event.userAgent}
              </Descriptions.Item>
              <Descriptions.Item label="Event IP Address">
                {eventDetails.rawAssessment.event.userIpAddress}
              </Descriptions.Item>
            </>
          )}

          {eventDetails.rawAssessment.riskAnalysis && (
            <>
              <Descriptions.Item label="Risk Analysis Reason">
                {eventDetails.rawAssessment.riskAnalysis.reasons?.join(", ")}
              </Descriptions.Item>
              <Descriptions.Item label="Risk Analysis Score">
                {eventDetails.rawAssessment.riskAnalysis.score}
              </Descriptions.Item>
            </>
          )}
          {eventDetails.rawAssessment.accountVerification && (
            <>
              <Descriptions.Item label="Account Verification Language Code">
                {eventDetails.rawAssessment.accountVerification.languageCode}
              </Descriptions.Item>
              <Descriptions.Item label="Account Verification Username">
                {eventDetails.rawAssessment.accountVerification.username}
              </Descriptions.Item>
              <Descriptions.Item label="Account Verification Result">
                {
                  eventDetails.rawAssessment.accountVerification
                    .latestVerificationResult
                }
              </Descriptions.Item>
              {eventDetails.rawAssessment.accountVerification.endpoints?.map(
                (endpoint) => (
                  <>
                    <Descriptions.Item label="Account Verification Endpoint Email Address">
                      {endpoint.emailAddress}
                    </Descriptions.Item>
                    <Descriptions.Item label="Account Verification Endpoint Phone Number">
                      {endpoint.phoneNumber}
                    </Descriptions.Item>
                  </>
                )
              )}
            </>
          )}
        </Descriptions>
      )}
    </>
  );
};

export default RecaptchaAssessment;
