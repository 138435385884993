import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import moment from "moment";
/** type imports */
import type { RootState } from "src/app/store";

const hourly = "Hourly";
const daily = "Daily";
const weekly = "Weekly";
const monthly = "Monthly";
const yearly = "Yearly";

const granularityMap = {
  Hourly: "hour",
  Daily: "day",
  Weekly: "week",
  Monthly: "month",
  Yearly: "year",
} as const;

function fetchValidGranularity(
  range: { start: number; end: number },
  granularity: StatRange["granularity"]
) {
  const { start: startTime, end: endTime } = range;
  const maxIntervalSize = 600;
  const minIntervalSize = 1;
  const valid: StatRange["granularity"][] = [];
  for (const [granularity, opUnit] of Object.entries(granularityMap)) {
    const diff = dayjs(endTime).diff(
      dayjs(startTime),
      opUnit as dayjs.OpUnitType
    );
    if (diff > minIntervalSize && diff < maxIntervalSize) {
      valid.push(granularity as StatRange["granularity"]);
    }
  }

  // Don't change granularity if still valid
  if (valid.includes(granularity)) {
    return { newGranularity: granularity, validGranularities: valid };
  }

  let newGranularity: keyof typeof granularityMap = "Hourly";
  // Otherwise, update it
  if (valid.includes(hourly)) {
    newGranularity = hourly;
  } else if (valid.includes(daily)) {
    newGranularity = daily;
  } else if (valid.includes(weekly)) {
    newGranularity = weekly;
  } else if (valid.includes(monthly)) {
    newGranularity = monthly;
  } else {
    newGranularity = yearly;
  }
  return { newGranularity, validGranularities: valid };
}

const menuValues = {
  Today: "Today",
  pastWeek: "Last 7 days",
  pastMonth: "Last 4 weeks",
  pastQuarter: "Last 3 months",
  pastYear: "Last 12 months",
  mtd: "Month to date",
  qtd: "Quarter to date",
  ytd: "Year to date",
  allTime: "All time",
  custom: "Custom",
} as const;

const pastWeekDefault = dayjs().subtract(1, "week").startOf("day").valueOf();
const endDefault = moment()
  .add(moment().utcOffset(), "minutes")
  .endOf("day")
  .valueOf();

interface StatRange {
  range: { start: number; end: number };
  granularity: keyof typeof granularityMap;
  snapGranularity: keyof typeof granularityMap;
  interval: typeof menuValues[keyof typeof menuValues];
  window: keyof typeof menuValues;
  validGranularities: (keyof typeof granularityMap)[];
}

const initialRange = { start: pastWeekDefault, end: endDefault };

// Initial state before fetching from localStorage
const initialState: StatRange = {
  range: initialRange,
  granularity: "Hourly",
  snapGranularity: "Hourly",
  interval: menuValues["pastWeek"],
  window: "pastWeek",
  validGranularities: fetchValidGranularity(initialRange, "Hourly")
    .validGranularities,
};

const statRangeSlice = createSlice({
  name: "statRange",
  initialState,
  reducers: {
    clearRangeSelection(state) {
      state = initialState;
    },

    updateGranularity(
      state,
      action: PayloadAction<keyof typeof granularityMap>
    ) {
      const { payload: granularity } = action;
      state.granularity = granularity;
    },

    updateSnapGranularity(
      state,
      action: PayloadAction<keyof typeof granularityMap>
    ) {
      const { payload: snapGranularity } = action;
      state.snapGranularity = snapGranularity;
    },

    updateWindow(state, action: PayloadAction<keyof typeof menuValues>) {
      const { payload: window } = action;
      state.interval = menuValues[window];
      state.window = window;
    },

    updateRange(state, action: PayloadAction<{ start: number; end: number }>) {
      const {
        payload: { start, end },
      } = action;
      state.range.start = start;
      state.range.end = endDefault < end ? endDefault : end;

      /**
       *  If new selected ranged makes current granularity
       *  infeasible (too many datapoints in graph) then pick
       *  a higher order granularity (ex: hourly => daily)
       */
      const { newGranularity, validGranularities } = fetchValidGranularity(
        state.range,
        state.granularity
      );
      state.granularity = newGranularity;
      state.validGranularities = validGranularities;
    },
  },
});

export const {
  updateGranularity,
  updateSnapGranularity,
  updateWindow,
  updateRange,
} = statRangeSlice.actions;
export const statRangeSelect = (state: RootState) => state.statRangeState;
export default statRangeSlice.reducer;
