import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  DatePicker,
  Tag,
  Typography,
  Divider,
} from "antd";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { FC, useEffect, useState } from "react";
import { useAppSelector } from "src/app/hooks";
import { OffersConverter } from "src/converters";
import { db } from "src/helpers";
import { validatePositiveNumericValue } from "src/shared/config/constants";
import UploadImage from "src/shared/components/Upload";
import DollarInput from "src/shared/components/dollarInput/dollarInput";

const OffersForm: FC<{
  offer: Offer | null;
  onClose: () => void;
}> = ({ offer, onClose }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isTouched, setIsTouched] = useState<{
    [c: string]: boolean;
  }>({});
  const { listOfGames } = useAppSelector((state) => state.gameState);
  const [offerData, setOfferData] = useState<Offer | null>(null);
  const { user } = useAppSelector((state) => state.userState);
  const [form] = Form.useForm();
  const [displayImage, setDisplayImage] = useState("");
  const [displayExpandedImage, setDisplayExpandedImage] = useState("");

  useEffect(() => {
    if (offer) {
      setIsOpen(true);
      const data = {
        ...offer,
        bonusCashReward:
          typeof offer.bonusCashReward === "number"
            ? offer.bonusCashReward / 100
            : offer.bonusCashReward,
        purchasePrice:
          typeof offer.purchasePrice === "number"
            ? offer.purchasePrice / 100
            : offer.purchasePrice,
      };
      form.setFieldsValue(data);
      setOfferData(data);
      setDisplayExpandedImage(offer.expandedImgURL);
      setDisplayImage(offer.imgURL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer]);

  const onDialogClose = () => {
    onClose();
    setIsLoading(false);
    form.resetFields();
    setIsOpen(false);
    setOfferData(null);
    setError("");
    setDisplayImage("");
    setDisplayExpandedImage("");
  };

  const onSave = async () => {
    setError("");
    form.submit();
    const isUndefined =
      Object.values(form.getFieldsValue()).findIndex(
        (f) => f === undefined || f === null
      ) !== -1;

    const isError = form.getFieldsError().find((f) => f.errors.length > 0);
    if (isUndefined || isError) {
      setError("Please fill all fields.");
      return;
    }

    if (!user || !user.activeOrgId) {
      setError("No active organization selected.");
      return;
    }

    const data = form.getFieldsValue();
    const imgURL = form.getFieldValue("imgURL");
    const imgPath = form.getFieldValue("imgPath");
    const expandedImgURL = form.getFieldValue("expandedImgURL");
    const expandedImgPath = form.getFieldValue("expandedImgPath");

    if (!imgURL || !expandedImgURL) {
      setError("Images are required");
      return;
    }
    setIsLoading(true);
    const OffersRef = collection(db, "offers").withConverter(OffersConverter);

    const formData = {
      orgId: user.activeOrgId,
      archived: offer?.archived || false,
      balanceReward: 0,
      bonusCashReward: (data.bonusCashReward || 0) * 100,
      tokenReward: 0,
      createdAt: data.createdAt || Date.now(),
      games: data.games,
      offerEndsAt: data.offerEndsAt || null,
      purchasePrice:
        typeof data.purchasePrice === "number"
          ? data.purchasePrice * 100
          : data.purchasePrice,
      claimButtonColor: data.claimButtonColor,
      version: "1",
      shouldShowOnOpen: data.shouldShowOnOpen,
      ...(imgURL && { imgURL }),
      ...(imgPath && { imgPath }),
      ...(expandedImgURL && { expandedImgURL }),
      ...(expandedImgPath && { expandedImgPath }),
    };

    if (!offer) {
      // add
      await addDoc(OffersRef, formData);
      setIsLoading(false);
      onDialogClose();
    } else {
      // edit
      const offerQuery = doc(OffersRef, offer.uid);

      await updateDoc(offerQuery, formData);
      setIsLoading(false);

      onDialogClose();
    }
  };

  const onChange = (
    name: keyof Offer,
    value: string | number | null | string[]
  ) => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      [name]: value,
    });
    if (offerData) {
      setIsTouched({
        ...isTouched,
        [name]: form.getFieldValue(name) !== offerData[name],
      });
    }
  };

  return (
    <>
      <Button
        onClick={() => {
          form.resetFields();
          setIsOpen(true);
        }}
        type="primary"
      >
        Add Offer
      </Button>
      <Modal
        title={`${!offer ? "Add" : "Edit"} Offer`}
        centered
        open={isOpen}
        onOk={onSave}
        okButtonProps={{
          disabled: offer
            ? Object.values(isTouched).findIndex((f) => f === true) === -1
            : false,
        }}
        onCancel={onDialogClose}
        destroyOnClose
        confirmLoading={isLoading}
      >
        {isOpen && (
          <Form
            name="Offer Form"
            className="offers-form"
            form={form}
            autoComplete="off"
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  initialValue={offerData?.purchasePrice}
                  label="Purchase Price"
                  className="app-config-input-wrap"
                  name="purchasePrice"
                  rules={[
                    {
                      required: true,
                      validator: validatePositiveNumericValue(
                        "Purchase Price",
                        0.01
                      ),
                    },
                  ]}
                >
                  <DollarInput
                    name="purchasePrice"
                    size="middle"
                    onChange={(value) => onChange("purchasePrice", value)}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  initialValue={offerData?.bonusCashReward || 0}
                  label="Bonus Cash Reward"
                  className="app-config-input-wrap"
                  name="bonusCashReward"
                >
                  <DollarInput
                    name="bonusCashReward"
                    size="middle"
                    onChange={(value) => onChange("bonusCashReward", value)}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  initialValue={offerData?.claimButtonColor || "FFFFFF"}
                  label="Claim Button Color"
                  className="app-config-input-wrap"
                  name="claimButtonColor"
                >
                  <Input
                    name="claimButtonColor"
                    size="middle"
                    onChange={(event) =>
                      onChange("claimButtonColor", event.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  initialValue={offerData?.games}
                  label="Games"
                  className="app-config-input-wrap"
                  name="games"
                  rules={[
                    {
                      required: true,
                      message: "Games value is required",
                    },
                  ]}
                >
                  <Select
                    size="middle"
                    mode="multiple"
                    allowClear
                    style={{ minWidth: 181 }}
                    onChange={(value) => onChange("games", value)}
                  >
                    {listOfGames.map((e) => (
                      <Select.Option key={e.id} value={e.id}>
                        {e.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  initialValue={offerData?.shouldShowOnOpen || false}
                  label="Should Show On Open"
                  className="app-config-input-wrap"
                  name="shouldShowOnOpen"
                  rules={[
                    {
                      required: true,
                      message: "Should Show On Open value is required",
                    },
                  ]}
                >
                  <Select
                    size="middle"
                    style={{ minWidth: 181 }}
                    onChange={(value) => onChange("shouldShowOnOpen", value)}
                  >
                    <Select.Option value={true}>Yes</Select.Option>
                    <Select.Option value={false}>No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col className="offer-ends-picker" span={24}>
                <Typography.Text>Offer Ends At</Typography.Text>
                <DatePicker
                  onChange={(v) =>
                    onChange("offerEndsAt", v?.valueOf() || null)
                  }
                />
              </Col>
              <Col span={24}>
                <Divider />
                <Typography.Title level={4}>Images</Typography.Title>
              </Col>
              <Col className="pt-2" span={12}>
                <UploadImage
                  name="Image Url"
                  id="imgURL"
                  path="offers"
                  url={displayImage}
                  onUpload={(imgData, meta) => {
                    onChange("imgURL", imgData);
                    onChange("imgPath", meta.fullPath);
                    setDisplayImage(imgData);
                  }}
                  onRemoveImg={() => {
                    onChange("imgURL", "");
                    onChange("imgPath", "");
                    setDisplayImage("");
                  }}
                />
              </Col>
              <Col className="pt-2" span={12}>
                <UploadImage
                  name="Expanded Image"
                  id="expandedImgURL"
                  path="offers"
                  url={displayExpandedImage}
                  onUpload={(imgData, meta) => {
                    onChange("expandedImgURL", imgData);
                    onChange("expandedImgPath", meta.fullPath);
                    setDisplayExpandedImage(imgData);
                  }}
                  onRemoveImg={() => {
                    onChange("expandedImgURL", "");
                    onChange("expandedImgPath", "");
                    setDisplayExpandedImage("");
                  }}
                />
              </Col>
            </Row>
            {error && <Tag color="warning">{error}</Tag>}
          </Form>
        )}
      </Modal>
    </>
  );
};

export default OffersForm;
