import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Tag,
} from "antd";
import {
  collection,
  doc,
  documentId,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { FC, useEffect, useState } from "react";
import { gameConverter } from "src/converters";
import { axiosInstance, db } from "src/helpers";
import { useParams } from "react-router-dom";
import TriumphPage from "src/shared/layout/TriumphPage";
import { pickBy } from "lodash";
import TextArea from "antd/lib/input/TextArea";
import { useAppSelector } from "src/app/hooks";
import { colNames, getCollection } from "src/collections";
import ColorConfig from "./components/colors";

interface GameData extends Partial<Game> {
  id: string;
  primaryColor?: string;
}
interface Props {
  isInternal: boolean;
}
const AppConfigContainer: FC<Props> = ({ isInternal }) => {
  const [games, setGames] = useState<GameData | undefined>();
  const [colorConfig, setColorConfig] = useState<{ [x: string]: ColorConfig }>(
    {}
  );
  const { game: gameQuery } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  const [isTouched, setIsTouched] = useState<{
    [x: string]: boolean;
  }>({});
  const [error, setError] = useState<Error | null>(null);
  const { user } = useAppSelector((state) => state.userState);

  useEffect(() => {
    form.setFieldsValue(games);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [games]);

  useEffect(() => {
    const gamesRef = collection(db, "games").withConverter(gameConverter);
    const gamesQuery = query(gamesRef, where(documentId(), "==", gameQuery));
    const unsubGamesDetails = onSnapshot(gamesQuery, (gamesSnap) => {
      setIsLoading(true);
      setGames(undefined);
      const gameDefSnap = gamesSnap.docs[0];
      const gameDef = gameDefSnap.data();
      const defs: GameData = {
        id: gameDefSnap.id,
        percentileWindow: gameDef.percentileWindow,
        displayName: gameDef.displayName,
        minimumSupportedVersionNumber: gameDef.minimumSupportedVersionNumber,
        trailingDollarBlitzLimit: gameDef.trailingDollarBlitzLimit,
        blitzLockdown: gameDef.blitzLockdown,
        asyncLockdown: gameDef.asyncLockdown,
        shouldShowUpdateButton: gameDef.shouldShowUpdateButton,
        lockdownScreenMessage: gameDef.lockdownScreenMessage,
        isReleased: gameDef.isReleased,
        appleBundleId: gameDef.appleBundleId,
        percentileMsCalcWindow: gameDef.percentileMsCalcWindow,
        appStoreURL: gameDef.appStoreURL,
        scoreDecimalPoints: gameDef.scoreDecimalPoints,
      };

      setGames(defs);
      setIsLoading(false);
    });
    const gameRef = doc(getCollection(colNames.games), gameQuery);
    const colorRef = getCollection(colNames.colors, gameRef);

    const unsubColors = onSnapshot(colorRef, (colorSnap) => {
      const colors: typeof colorConfig = {};
      for (const snap of colorSnap.docs) {
        const uid = snap.id;
        const data = snap.data();
        colors[uid] = data;
      }
      setColorConfig(colors);
    });

    return () => {
      unsubGamesDetails();
      unsubColors();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onSave() {
    const hasError = form.getFieldsError().find((f) => f.errors.length > 0);
    if (hasError) {
      setError(Error(form.getFieldsError().join(", ")));
      return;
    }
    setError(null);
    setIsLoading(true);
    const formValues = form.getFieldsValue();
    const primaryColor = form.getFieldValue("primaryColor");

    let payload: Partial<Game> = {
      displayName: formValues.displayName,
      appStoreURL: formValues.appStoreURL,
      scoreDecimalPoints: formValues.scoreDecimalPoints ?? 0,
      appleBundleId: formValues.appleBundleId,
    };

    if (isInternal) {
      payload = {
        ...payload,

        percentileWindow: formValues.percentileWindow,
        minimumSupportedVersionNumber: formValues.minimumSupportedVersionNumber,
        trailingDollarBlitzLimit: formValues.trailingDollarBlitzLimit,
        blitzLockdown: formValues.blitzLockdown || false,
        asyncLockdown: formValues.asyncLockdown || false,
        shouldShowUpdateButton: formValues.shouldShowUpdateButton || false,
        lockdownScreenMessage: formValues.lockdownScreenMessage,
        isReleased: formValues.isReleased || false,
        percentileMsCalcWindow: formValues.percentileMsCalcWindow,
      };
    }

    const cleanedPayload = pickBy(payload, (v) => v !== undefined);

    try {
      setIsTouched({});
      const updateReq = isInternal
        ? axiosInstance.post<{ success: string }>(
            `/internal/games/${gameQuery}/update_game`,
            cleanedPayload
          )
        : axiosInstance.post<{ success: string }>(
            `/admin/orgs/${user?.activeOrgId}/games/${gameQuery}/update_game`,
            cleanedPayload
          );
      const colorReq = primaryColor
        ? axiosInstance.post(`/admin/games/${gameQuery}/color_primary`, {
            value: primaryColor,
          })
        : undefined;

      await Promise.all([updateReq, colorReq]);
    } catch (error) {
      setError((error as Error) || Error("Unknown Error"));
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  function onChange(
    field: keyof GameData,
    value: string | boolean | number | null
  ) {
    if (!games) {
      return;
    }
    form.setFieldsValue({
      ...form.getFieldsValue(),
      [field]: value,
    });

    setIsTouched({
      ...isTouched,
      [field]: form.getFieldValue(field) !== games[field],
    });
  }

  const isGameTouched = isTouched && Object.values(isTouched).find((f) => f);

  const {
    displayName,
    appStoreURL,
    scoreDecimalPoints,
    appleBundleId,
    percentileWindow,
    minimumSupportedVersionNumber,
    trailingDollarBlitzLimit,
    blitzLockdown,
    asyncLockdown,
    shouldShowUpdateButton,
    lockdownScreenMessage,
    isReleased,
    percentileMsCalcWindow,
  } = games ?? {};

  return (
    <TriumphPage>
      <Form
        name="app config"
        className="app-config-form"
        form={form}
        autoComplete="off"
      >
        <Row>
          <Col span={12}>
            <Form.Item
              label="Display Name"
              className="app-config-input-wrap"
              name="displayName"
              initialValue={displayName}
            >
              <Input
                name="displayName"
                size="middle"
                onChange={(event) =>
                  onChange("displayName", event.target.value)
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className="app-config-input-wrap"
              label="Apple Bundle Id"
              name="appleBundleId"
              initialValue={appleBundleId}
            >
              <Input
                name="appleBundleId"
                size="middle"
                onChange={(event) =>
                  onChange("appleBundleId", event.target.value)
                }
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Apple App Store ID"
              className="app-config-input-wrap"
              name="appStoreURL"
              initialValue={appStoreURL}
            >
              <Input
                name="appStoreURL"
                size="middle"
                onChange={(event) =>
                  onChange("appStoreURL", event.target.value)
                }
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              className="app-config-input-wrap"
              label="Score Decimal Points"
              name={"scoreDecimalPoints"}
              initialValue={scoreDecimalPoints}
            >
              <Select
                className="normal-select"
                size="middle"
                onChange={(value) => onChange("scoreDecimalPoints", value)}
              >
                <Select.Option value={0}>0</Select.Option>
                <Select.Option value={1}>1</Select.Option>
                <Select.Option value={2}>2</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <ColorConfig
              data={colorConfig}
              onChange={(e) => {
                onChange("primaryColor", e);
              }}
            />
          </Col>
          <Col>{error && <Tag color="warning">{error.message}</Tag>}</Col>
        </Row>

        {isInternal && (
          <Row>
            <Col span={12}>
              <Form.Item
                initialValue={percentileWindow}
                label="Percentile Window"
                className="app-config-input-wrap"
                name="percentileWindow"
              >
                <InputNumber
                  name="percentileWindow"
                  size="middle"
                  type="number"
                  onChange={(value) => onChange("percentileWindow", value)}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                className="app-config-input-wrap"
                label="Trailing Dollar Blitz Limit"
                name="trailingDollarBlitzLimit"
                initialValue={trailingDollarBlitzLimit}
              >
                <InputNumber
                  name="trailingDollarBlitzLimit"
                  size="middle"
                  type="number"
                  onChange={(value) =>
                    onChange("trailingDollarBlitzLimit", value)
                  }
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                className="app-config-input-wrap"
                label="Percentile Ms Calc Window"
                name="percentileMsCalcWindow"
                initialValue={percentileMsCalcWindow}
              >
                <InputNumber
                  name="percentileMsCalcWindow"
                  size="middle"
                  type="number"
                  onChange={(value) =>
                    onChange("percentileMsCalcWindow", value)
                  }
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Blitz Lockdown"
                className="app-config-input-wrap p-left"
                name="blitzLockdown"
                initialValue={blitzLockdown}
              >
                <Switch
                  defaultChecked={blitzLockdown}
                  onChange={(value) => onChange("blitzLockdown", value)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Async Lockdown"
                className="app-config-input-wrap p-left"
                name="asyncLockdown"
                initialValue={asyncLockdown}
              >
                <Switch
                  defaultChecked={asyncLockdown}
                  onChange={(value) => onChange("asyncLockdown", value)}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Should Show Update Button"
                className="app-config-input-wrap p-left"
                name="shouldShowUpdateButton"
                initialValue={shouldShowUpdateButton}
              >
                <Switch
                  defaultChecked={shouldShowUpdateButton}
                  onChange={(value) =>
                    onChange("shouldShowUpdateButton", value)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Is Released"
                className="app-config-input-wrap p-left"
                name="isReleased"
                initialValue={isReleased}
              >
                <Switch
                  defaultChecked={isReleased}
                  onChange={(value) => onChange("isReleased", value)}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Minimum Supported Version Number"
                className="app-config-input-wrap"
                name="minimumSupportedVersionNumber"
                initialValue={minimumSupportedVersionNumber}
              >
                <InputNumber
                  name="minimumSupportedVersionNumber"
                  size="middle"
                  type="number"
                  onChange={(value) =>
                    onChange("minimumSupportedVersionNumber", value)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Lockdown Screen Message"
                name="lockdownScreenMessage"
                className="app-config-input-wrap"
                initialValue={lockdownScreenMessage}
              >
                <TextArea
                  rows={4}
                  placeholder="Lockdown Screen Message"
                  onChange={(event) =>
                    onChange("lockdownScreenMessage", event.target.value)
                  }
                />
              </Form.Item>
            </Col>

            <Col>{error && <Tag color="warning">{error.message}</Tag>}</Col>
          </Row>
        )}

        <Row justify="end">
          <Col>
            <Button
              loading={isLoading}
              onClick={onSave}
              type="primary"
              disabled={!isGameTouched}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </TriumphPage>
  );
};

export default AppConfigContainer;
