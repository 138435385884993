import { FC, useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Statistic,
  Table,
  Typography,
  message,
} from "antd";
import { doc, onSnapshot, query, Unsubscribe, where } from "firebase/firestore";
import TriumphPage from "src/shared/layout/TriumphPage";
import { colNames, getCollection } from "src/collections";
import { useAppSelector } from "src/app/hooks";
import { v4 as uuidv4 } from "uuid";
import { EditOutlined } from "@ant-design/icons";
import { axiosInstance, isTriumphEmployee } from "src/helpers";
import _ from "lodash";
import OverlayLoader from "src/shared/components/overlayLoader/overlayLoader";
/** type imports */

const { Text, Title } = Typography;
const { TextArea } = Input;

type Item = FeeDescription;

interface EditableCol {
  title: string;
  dataIndex: string;
  key: string;
  editable: boolean;
  render?: (_: any, record: Item, index: number) => JSX.Element;
  width?: number;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text";
  record: Item;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  let inputNode;

  if (inputType === "number") {
    if (dataIndex === "revshare") {
      inputNode = (
        <InputNumber
          addonBefore={<Text type="secondary">Triumph's Share (%)</Text>}
          min={0}
          step={0.01}
          max={1.0}
        />
      );
    } else {
      inputNode = <InputNumber />;
    }
  } else if (inputType === "text") {
    inputNode = <TextArea rows={4} />;
  } else {
    inputNode = null;
  }

  return (
    <td {...restProps}>
      {editing && inputNode ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

interface Props {
  isMaster?: boolean; // whether to render the master contract
  userOrgId?: string;
  userOrgName?: string;
  userContract?: string;
}

const Contract: FC<Props> = ({
  isMaster,
  userOrgId,
  userOrgName,
  userContract,
}) => {
  const { user } = useAppSelector((state) => state.userState);
  const { orgs } = useAppSelector((state) => state.orgState);
  const [orgId] = useState(userOrgId ? userOrgId : user?.activeOrgId ?? null);
  const [orgName, setOrgName] = useState("No Organization Name");
  const [platformFees, setPlatformFees] = useState<
    Contract["platformFees"] | null
  >(null);
  const [descriptions, setDescriptions] = useState<
    Contract["descriptions"] | null
  >(null);
  const [origDesc, setOrigDescriptions] = useState<
    Contract["descriptions"] | null
  >(null);
  const [contract, setContract] = useState<Contract | null>(null);
  const [masterContract, setMasterContract] = useState<MasterContract | null>(
    null
  );
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [hasChanged, setHasChanged] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [deploying, setDeploying] = useState(false);
  const isEditing = (record: Item) => record.uid === editingKey;

  const eventColWidth = 150;
  const feeTypeWidth = 300;
  const feeAmountWidth = 350;

  // fetch orgs list if not updating master
  useEffect(() => {
    if (Object.keys(orgs).length === 0 || !orgId) {
      return;
    }

    if (descriptions || platformFees) {
      return;
    }

    const activeContractId = userContract ?? orgs[orgId].activeContractId;
    const orgName = userOrgName ?? orgs[orgId].name;

    let unsubContract: Unsubscribe;
    const currentOrgRef = doc(getCollection(colNames.organizations), orgId);
    const contractRef = doc(
      getCollection(colNames.contracts, currentOrgRef),
      activeContractId
    );
    unsubContract = onSnapshot(contractRef, (contractSnap) => {
      const contract = contractSnap.data();
      if (!contract) {
        return;
      }
      for (const [key, elem] of Object.entries(contract.platformFees)) {
        const trxType = key as keyof typeof contract.platformFees;
        elem.fees = elem.fees.map((fee) => {
          return { ...fee, uid: uuidv4() };
        });
        contract.platformFees[trxType] = elem;
      }
      setPlatformFees(contract.platformFees);
      setDescriptions(contract.descriptions);
      setOrigDescriptions(_.cloneDeep(contract.descriptions));
      setContract(contract);
      setOrgName(orgName);
      setLoading(false);
    });
    return () => {
      unsubContract?.();
    };
  }, [descriptions, platformFees, orgId, orgs, userContract, userOrgName]);

  useEffect(() => {
    let unsubMasterContract: Unsubscribe;
    if (!isMaster) {
      return;
    }
    const masterContractColRef = getCollection(colNames.masterContract);
    const masterContractQuery = query(
      masterContractColRef,
      where("status", "==", "active")
    );

    unsubMasterContract = onSnapshot(
      masterContractQuery,
      (masterContractSnap) => {
        const masterContract = masterContractSnap.docs[0].data();
        setMasterContract(masterContract);
        setPlatformFees(masterContract.platformFees);
        setDescriptions(masterContract.descriptions);
        setOrigDescriptions(_.cloneDeep(masterContract.descriptions));
        setLoading(false);
      }
    );

    return () => {
      unsubMasterContract?.();
    };
  }, [isMaster]);

  /**
   * Creates new Platform Fees from User Input
   *
   * @returns {Contract["platformFees"]} A new Platform Fees object for the new Contract
   */
  function createNewFees(): Contract["platformFees"] {
    const desc = { description: "", displayName: "" };
    /** !!! KYC CASE !!! */
    const kycDesc = descriptions?.devFees
      .filter((desc) => desc.trx === "kyc")
      .at(0);
    if (!kycDesc) {
      throw new Error("No KYC Dev Flat Fee");
    }

    const kycFlatDevFee: KYCFee = {
      ...desc,
      amount: kycDesc.amount,
      type: "kyc",
      method: kycDesc.method,
      target: "org",
    };

    const kycRevshare: Revshare = {
      ...desc,
      amount: 1 - (kycDesc.revshare ?? 0),
      method: "variable",
      type: "revenue-share",
    };
    /** !!! KYC CASE !!! */

    /** !!! NEW-GAME CASE !!! */
    const newGameDesc = descriptions?.playerFees
      .filter((desc) => desc.trx === "new-game")
      .at(0);
    if (!newGameDesc) {
      throw new Error("No KYC Flat Fee");
    }

    const newGameExpenseShare: ExpenseShare = {
      ...desc,
      amount: newGameDesc.revshare ?? 0,
      promoAmount: newGameDesc.revshare ?? 0,
      method: "variable",
      promoMethod: "variable",
      type: "expense-share",
    };
    /** !!! NEW-GAME CASE !!! */

    /** !!! REFERRER-BONUS CASE !!! */
    const referrerDesc = descriptions?.playerFees
      .filter((desc) => desc.trx === "referrer-bonus")
      .at(0);
    if (!referrerDesc) {
      throw new Error("No Referrer Bonus revshare");
    }

    const referrerBonusExpenseShare: ExpenseShare = {
      ...desc,
      amount: referrerDesc.revshare ?? 0,
      promoAmount: referrerDesc.revshare ?? 0,
      method: "variable",
      promoMethod: "variable",
      type: "expense-share",
    };
    /** !!! REFERRER-BONUS CASE !!! */

    /** !!! HOTSTREAK CASE !!! */
    const hotstreakDesc = descriptions?.playerFees
      .filter((desc) => desc.trx === "hotStreak-award")
      .at(0);
    if (!hotstreakDesc) {
      throw new Error("No Hotstreak Revshare");
    }

    const hotstreakExpenseShare: ExpenseShare = {
      ...desc,
      amount: hotstreakDesc.revshare ?? 0,
      promoAmount: hotstreakDesc.revshare ?? 0,
      method: "variable",
      promoMethod: "variable",
      type: "expense-share",
    };
    /** !!! HOTSTREAK CASE !!! */

    /** !!! START_BLITZ CASE !!! */
    const startBlitzDesc = descriptions?.playerFees
      .filter((desc) => desc.trx === "start-blitz")
      .at(0);

    if (!startBlitzDesc) {
      throw new Error("No START BLITZ");
    }

    const startBlitzTournFee: TournamentFee = {
      displayName: "Start Blitz Tournament",
      description: "Fees assessed when user enters blitz tournament",
      type: "tournament",
    };

    const startBlitzRevshare: Revshare = {
      ...desc,
      amount: startBlitzDesc.revshare ?? 0,
      method: "variable",
      type: "revenue-share",
    };
    /** !!! START-BLITZ CASE !!! */

    /** !!! FINISH-BLITZ CASE !!! */
    const finishBlitzDesc = descriptions?.playerFees
      .filter((desc) => desc.trx === "finish-blitz")
      .at(0);

    if (!finishBlitzDesc) {
      throw new Error("No Finish BLITZ");
    }

    const finishBlitzExpenseShare: ExpenseShare = {
      ...desc,
      amount: finishBlitzDesc.revshare ?? 0,
      promoAmount: finishBlitzDesc.revshare ?? 0,
      method: "variable",
      promoMethod: "variable",
      type: "expense-share",
    };

    /** !!! FINISH-BLITZ CASE !!! */

    /** !!! START-GROUP CASE !!! */
    const startGroupTournDesc = descriptions?.playerFees
      .filter((desc) => desc.trx === "start-group-tournament")
      .at(0);

    if (!startGroupTournDesc) {
      throw new Error("No START GROUP ");
    }

    const startGroupTournFee: TournamentFee = {
      type: "tournament",
      displayName: "Start Group Tournament",
      description: "User Tournament Fee",
    };

    const startGroupRevshare: Revshare = {
      ...desc,
      amount: startGroupTournDesc.revshare ?? 0,
      method: "variable",
      type: "revenue-share",
    };
    /** !!! START-GROUP CASE !!! */

    /** !!! WITHDRAWAL CASE !!! */
    const withdrawalUserDescs = descriptions?.playerFees.filter(
      (desc) => desc.trx === "withdrawal"
    );

    const withdrawalDevDescs = descriptions?.playerFees.filter(
      (desc) => desc.trx === "withdrawal"
    );

    if (withdrawalUserDescs?.length !== 2) {
      throw new Error("No USER WITHDRAWAL");
    }
    if (withdrawalDevDescs?.length !== 2) {
      throw new Error("No Dev Withdrawal");
    }

    const [firstUserFee, secondUserFee] = withdrawalUserDescs;
    const [firstDevFee, secondDevFee] = withdrawalDevDescs;
    const [firstUserMethod, secondUserMethod] = withdrawalUserDescs.map(
      (desc) => desc.method
    );
    const [firstDevMethod, secondDevMethod] = withdrawalDevDescs.map(
      (desc) => desc.method
    );

    const withdrawalUserFees: WithdrawalFee[] = [
      {
        ...desc,
        amount: firstUserFee.amount,
        type: "withdrawal",
        method: firstUserMethod,
        target: "user",
      },
      {
        ...desc,
        amount: secondUserFee.amount,
        type: "withdrawal",
        method: secondUserMethod,
        target: "user",
      },
    ];
    const withdrawalRevshare: Revshare = {
      ...desc,
      amount: firstUserFee.revshare ?? 0,
      type: "revenue-share",
      method: "variable",
    };
    const withdrawalDevFees: WithdrawalFee[] = [
      {
        ...desc,
        amount: firstDevFee.amount,
        type: "withdrawal",
        method: firstDevMethod,
        target: "org",
      },
      {
        ...desc,
        amount: secondDevFee.amount,
        type: "withdrawal",
        method: secondDevMethod,
        target: "org",
      },
    ];

    /** !!! WITHDRAWAL CASE !!! */

    /** !!! DEPOSIT CASE !!! */
    const depositUserDescs = descriptions?.playerFees.filter(
      (desc) => desc.trx === "deposit"
    );

    const depositDevDescs = descriptions?.playerFees.filter(
      (desc) => desc.trx === "deposit"
    );

    if (depositUserDescs?.length !== 2) {
      throw new Error("No USER deposit");
    }
    if (depositDevDescs?.length !== 2) {
      throw new Error("No Dev deposit");
    }

    const [firstUserDepFee, secondUserDepFee] = depositUserDescs;
    const devVarFee = depositDevDescs.filter(
      (desc) => desc.method === "variable"
    )[0];
    const devFlatFee = depositDevDescs.filter(
      (desc) => desc.method === "flat"
    )[0];
    const [firstUserDepMethod, secondUserDepMethod] = depositUserDescs.map(
      (desc) => desc.method
    );

    const depositUserFees: DepositFee[] = [
      {
        ...desc,
        amount: firstUserDepFee.amount,
        type: "deposit",
        method: firstUserDepMethod,
        target: "user",
      },
      {
        ...desc,
        amount: secondUserDepFee.amount,
        type: "deposit",
        method: secondUserDepMethod,
        target: "user",
      },
    ];

    const depositDevVariableFee: DepositFee = {
      ...desc,
      amount: devVarFee.amount,
      type: "deposit",
      method: "variable",
      target: "org",
    };

    const depositRevshare: Revshare = {
      ...desc,
      amount: 1 - (firstUserDepFee.revshare ?? 0),
      type: "revenue-share",
      method: "variable",
    };

    const depositDevFlatFee: DepositFee = {
      ...desc,
      amount: devFlatFee.amount,
      type: "deposit",
      method: "flat",
      target: "org",
    };

    const depositExpenseShare: DepositBonusCashExpenseShare = {
      ...desc,
      promoAmount: firstUserDepFee.revshare ?? 0,
      promoMethod: "variable",
      type: "deposit-expense-share",
    };

    /** !!! DEPOSIT CASE !!! */

    const fees: Contract["platformFees"] = {
      kyc: { ...desc, fees: [kycFlatDevFee, kycRevshare] },
      "new-game": { ...desc, fees: [newGameExpenseShare] },
      "referrer-bonus": { ...desc, fees: [referrerBonusExpenseShare] },
      "hotStreak-award": { ...desc, fees: [hotstreakExpenseShare] },
      "start-blitz": {
        ...desc,
        fees: [startBlitzTournFee, startBlitzRevshare],
      },
      "finish-blitz": { ...desc, fees: [finishBlitzExpenseShare] },
      "start-group-tournament": {
        ...desc,
        fees: [startGroupTournFee, startGroupRevshare],
      },
      "finish-group-tournament": { ...desc, fees: [] },
      withdrawal: {
        ...desc,
        fees: [...withdrawalUserFees, withdrawalRevshare, ...withdrawalDevFees],
      },
      deposit: {
        ...desc,
        fees: [
          ...depositUserFees,
          depositDevVariableFee,
          depositRevshare,
          depositDevFlatFee,
          depositExpenseShare,
        ],
      },
      "triumph-deposit": { ...desc, fees: [] },
      "withdrawal-redeposit": { ...desc, fees: [] },
      "start-tournament": { ...desc, fees: [] } /** Deprecated */,
      "finish-tournament": { ...desc, fees: [] } /** Deprecated */,
      "account-creation-deposit": { ...desc, fees: [] } /** Deprecated */,
      "referee-bonus": { ...desc, fees: [] } /** Deprecated */,
    };

    return fees;
  }

  // redeploy active contract for this currently active Org
  async function redeployContract() {
    try {
      if (!contract || !descriptions || !user || !user.activeOrgId) {
        return;
      }
      setLoading(true);
      const newFees = createNewFees();
      const now = Date.now();
      const newContract: Contract = {
        maxLiability: contract.maxLiability,
        maxUserDepositFee: contract.maxUserDepositFee,
        maxUserWithdrawalFee: contract.maxUserWithdrawalFee,
        // maxLiability: form.getFieldValue("maxLiability"), // TODO:
        // maxUserDepositFee: form.getFieldValue("maxUserDepositFee"), //TODO:
        // maxUserWithdrawalFee: form.getFieldValue("maxUserWithdrawalFee"), //TODO:
        descriptions,
        platformFees: newFees,
        status: "active",
        version: contract.version, // can only edit current active version
        uid: contract.uid, // will be updated on BE
        createdAt: now,
        updatedAt: now,
        startedAt: now,
        endedAt: null,
      };

      const orgId = userOrgId ?? user.activeOrgId;

      const {
        data: { success },
      } = await axiosInstance.post<{ success: boolean; id: string }>(
        `/internal/orgs/${orgId}/update_contract`,
        { contract: newContract }
      );
      if (success) {
        message.success(
          `Contract for ${orgName ?? "UNKNOWN ORG"} redeployed!`,
          10
        );
      } else {
        message.error("Failed to redeploy contract!");
      }
    } catch (error) {
      message.error("Failed to redeploy contract!");
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function redeployMasterContract() {
    try {
      if (!masterContract || !descriptions) {
        return;
      }
      setLoading(true);
      const now = Date.now();
      const newFees = createNewFees();
      const newMasterContract: MasterContract = {
        maxLiability: masterContract.maxLiability,
        maxUserDepositFee: masterContract.maxUserDepositFee,
        maxUserWithdrawalFee: masterContract.maxUserWithdrawalFee,
        // maxLiability: form.getFieldValue("maxLiability"), // TODO:
        // maxUserDepositFee: form.getFieldValue("maxUserDepositFee"),
        // maxUserWithdrawalFee: form.getFieldValue("maxUserWithdrawalFee"),
        descriptions,
        platformFees: newFees,
        status: "active",
        version: masterContract.version, // can only edit current active version
        uid: masterContract.uid,
        createdAt: now,
        updatedAt: now,
      };

      const {
        data: { success },
      } = await axiosInstance.post<{ success: boolean; id: string }>(
        "/internal/master_contract",
        { newMasterContract }
      );
      if (success) {
        message.success("Master Contract Redeployed!");
      } else {
        message.error("Failed to redeploy master contract!");
      }
    } catch (_) {
      message.error("Failed to redeploy master contract!");
    }
  }

  const playerTargetFees: Item[] = descriptions?.playerFees ?? [];
  const devTargetFees: Item[] = descriptions?.devFees ?? [];

  const playerFeeColumns: EditableCol[] = [
    {
      title: "Event",
      dataIndex: "event",
      key: "event",
      editable: false,
      width: eventColWidth,
    },
    {
      title: "Fee Type",
      dataIndex: "feeDescription",
      key: "feeDescription",
      editable: true,
      width: feeTypeWidth,
    },
    {
      title: "Fee Amount",
      dataIndex: "amount",
      key: "amount",
      editable: true,
      width: feeAmountWidth,
      render(_, record) {
        let feeAmount: number = 0;
        let suffix = "";
        let prefix = "";
        let precision = 0;
        const valStyle = { fontSize: "16px" };
        const { amount, feeType, method } = record;
        if (
          feeType === "affiliate-marketing" ||
          feeType === "new-marketing" ||
          feeType === "expense-share" ||
          feeType === "deposit-expense-share"
        ) {
          return <Text> No Player Fee</Text>;
        }

        if (feeType === "tournament") {
          return <Text italic={true}>{amount}</Text>;
        } else {
          if (method === "flat") {
            prefix = "$";
            precision = 2;
            feeAmount = amount / 100;
          } else {
            suffix = "%";
            feeAmount = amount * 100;
          }
        }
        return (
          <Row>
            <Card style={{ width: "80px" }} className="fee-display">
              <Statistic
                value={feeAmount}
                suffix={suffix}
                valueStyle={valStyle}
                prefix={prefix}
                precision={precision}
              />
            </Card>
          </Row>
        );
      },
    },
    {
      title: "Developer / Triumph Split",
      dataIndex: "revshare",
      key: "revshare",
      editable: true,
      width: feeAmountWidth,
      render(_, record, index) {
        const isExpenseFee =
          record.trx === "finish-blitz" ||
          record.trx === "finish-group-tournament" ||
          record.feeType === "expense-share" ||
          record.feeType === "deposit-expense-share";

        const valStyle = {
          color: isExpenseFee ? "#cf1322" : "#3f8600",
          fontSize: "16px",
        };
        const revshareAmount = record.revshare ?? 0;
        return (
          <Row>
            <Card className="fee-display">
              <Statistic
                valueStyle={valStyle}
                value={Math.round((1.0 - revshareAmount) * 100)}
                suffix={"%"}
              />
            </Card>
            <Card className="fee-display mr-8">
              <Statistic
                valueStyle={valStyle}
                value={revshareAmount * 100}
                suffix={"%"}
              />
            </Card>
          </Row>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "eventDescription",
      key: "eventDescription",
      editable: true,
    },
  ];

  const devTargetColumns: EditableCol[] = [
    {
      title: "Event",
      dataIndex: "event",
      key: "event",
      editable: false,
      width: eventColWidth,
    },
    {
      title: "Fee Type",
      dataIndex: "feeDescription",
      key: "feeDescription",
      editable: true,
      width: feeTypeWidth,
    },
    {
      title: "Fee Amount",
      dataIndex: "amount",
      key: "amount",
      editable: true,
      width: feeAmountWidth,
      render(_, record) {
        let feeAmount: number = 0;
        let suffix = "";
        let prefix = "";
        let precision = 0;
        const valStyle = { fontSize: "16px" };
        if (record.feeType === "tournament") {
          const text =
            record.event === "Start Blitz Tournament"
              ? "Determined by Blitz Entry Price"
              : "Determined by Group Tournament Entry Price";
          return <Text italic={true}>({text})</Text>;
        } else if (record.feeType === "deposit-expense-share") {
          if (record.promoMethod === "flat") {
            prefix = "$";
            precision = 2;
            feeAmount = record.amount / 100;
          } else {
            suffix = "%";
            feeAmount = record.amount * 100;
          }
        } else {
          if (record.method === "flat") {
            prefix = "$";
            precision = 2;
            feeAmount = record.amount / 100;
          } else {
            suffix = "%";
            feeAmount = record.amount * 100;
          }
        }
        return (
          <Row>
            <Card style={{ width: "80px" }} className="fee-display">
              <Statistic
                value={feeAmount}
                suffix={suffix}
                valueStyle={valStyle}
                prefix={prefix}
                precision={precision}
              />
            </Card>
          </Row>
        );
      },
    },
    {
      title: "Developer / Triumph Revenue Sharing",
      dataIndex: "revshare",
      key: "revshare",
      editable: true,
      width: feeAmountWidth,
      render(_, record, index) {
        const valStyle = {
          color: "#cf1322",
          fontSize: "16px",
        };
        const revshareAmount = record.revshare ?? 0;
        return (
          <Row>
            <Card className="fee-display">
              <Statistic
                valueStyle={valStyle}
                value={Math.round((1.0 - revshareAmount) * 100)}
                suffix={"%"}
              />
            </Card>
            <Card className="fee-display">
              <Statistic
                valueStyle={valStyle}
                value={revshareAmount * 100}
                suffix={"%"}
              />
            </Card>
          </Row>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "eventDescription",
      key: "eventDescription",
      editable: true,
    },
  ];

  const edit = (record: Partial<Item>) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.uid ?? "");
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (
    key: React.Key,
    table: "devFees" | "playerFees",
    index: number
  ) => {
    try {
      const row = (await form.validateFields()) as Item;
      if (!row.revshare) {
        row.revshare = 0;
      } else if (row.revshare > 1.0) {
        row.revshare = (row.revshare ?? 0) / 100;
      }

      setEditingKey("");
      if (table === "devFees") {
        const prevDesc = devTargetFees[index];
        devTargetFees[index] = { ...prevDesc, ...row };
      } else if (table === "playerFees") {
        const prevDesc = playerTargetFees[index];
        playerTargetFees[index] = { ...prevDesc, ...row };
      }
      const newDescs = {
        devFees: devTargetFees,
        playerFees: playerTargetFees,
      };
      setHasChanged(!_.isEqual(origDesc, newDescs));
      setDescriptions(newDescs);
    } catch (errInfo) {
      console.error("Validate Failed:", errInfo);
    }
  };

  const playerFeesEditRender = (_: any, record: Item, index: number) => {
    const editable = isEditing(record);
    return editable ? (
      <span>
        <Typography.Link
          onClick={() => save(record.uid, "playerFees", index)}
          style={{ marginRight: 8 }}
        >
          Save
        </Typography.Link>
        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a>Cancel</a>
        </Popconfirm>
      </span>
    ) : (
      <Typography.Link
        disabled={editingKey !== ""}
        onClick={() => edit(record)}
      >
        <EditOutlined />
      </Typography.Link>
    );
  };

  const devFeesEditRender = (_: any, record: Item, index: number) => {
    const editable = isEditing(record);
    return editable ? (
      <span>
        <Typography.Link
          onClick={() => save(record.uid, "devFees", index)}
          style={{ marginRight: 8 }}
        >
          Save
        </Typography.Link>
        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a>Cancel</a>
        </Popconfirm>
      </span>
    ) : (
      <Typography.Link
        disabled={editingKey !== ""}
        onClick={() => edit(record)}
      >
        <EditOutlined />
      </Typography.Link>
    );
  };

  if (isTriumphEmployee()) {
    const editCol = {
      title: "Edit",
      width: 115,
      key: "edit",
      dataIndex: "",
      editable: false,
    };

    playerFeeColumns.push({ ...editCol, render: playerFeesEditRender });
    devTargetColumns.push({ ...editCol, render: devFeesEditRender });
  }

  const editablePlayerFeeCols = playerFeeColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    function getInputType(record: Item, col: EditableCol) {
      if (
        (col.dataIndex === "amount" && record.feeType !== "tournament") ||
        col.dataIndex === "revshare"
      ) {
        // Only fees are charged on Deposit
        if (record.trx !== "deposit" && col.dataIndex !== "revshare") {
          return null;
        }
        return "number";
      } else {
        return "text";
      }
    }

    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: getInputType(record, col),
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const editableDevFeeCols = devTargetColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType:
          (col.dataIndex === "amount" && record.feeType !== "tournament") ||
          col.dataIndex === "revshare"
            ? "number"
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const titleCreatedAt = isMaster
    ? masterContract?.createdAt
    : contract?.createdAt;

  const titleVersion = isMaster ? masterContract?.version : contract?.version;

  const deployContractTitle = (
    <>
      <Row style={{ width: "100%" }} justify="space-between" align="middle">
        <Title italic={true} type="secondary" level={5}>
          {isMaster ? "MASTER CONTRACT" : `Organization: ${orgName}`}
        </Title>
      </Row>
      <Row style={{ width: "100%" }} justify="space-between" align="middle">
        <Title italic={true} type="secondary" level={5}>
          Active Since:{" "}
          {new Date(titleCreatedAt ?? 0).toLocaleDateString() +
            " " +
            new Date(titleCreatedAt ?? 0).toLocaleTimeString()}
        </Title>
      </Row>
      {isMaster ?? (
        <Row style={{ width: "100%" }} justify="space-between" align="middle">
          <Title italic={true} type="secondary" level={5}>
            Last Update:{" "}
            {new Date(contract?.updatedAt ?? 0).toLocaleDateString() +
              " " +
              new Date(contract?.updatedAt ?? 0).toLocaleTimeString()}
          </Title>
        </Row>
      )}

      <Row style={{ width: "100%" }} justify="space-between" align="middle">
        <Title italic={true} type="secondary" level={5}>
          Version: {titleVersion}
        </Title>
      </Row>
    </>
  );

  if (loading) {
    return <OverlayLoader />;
  }
  return (
    <Form form={form} component={false}>
      {isTriumphEmployee() && (
        <Modal
          title={isMaster ? "Redeploy Master Contract?" : "Redeploy Contract?"}
          centered
          closable={false}
          open={showWarning}
          onCancel={() => {
            setShowWarning(false);
          }}
          width={550}
          footer={[
            <Button
              type="primary"
              key="submit"
              danger={true}
              loading={deploying}
              onClick={async () => {
                setDeploying(true);
                if (isMaster) {
                  await redeployMasterContract();
                } else {
                  await redeployContract();
                }
                setDeploying(false);
                setShowWarning(false);
              }}
            >
              Confirm
            </Button>,
          ]}
        >
          <Text type="secondary" strong={true}>
            <Text type="danger" strong={true}>
              Warning:{" "}
            </Text>
            {isMaster
              ? "Updated master contract terms will apply to all newly created Organizations from this point on. Are you sure?"
              : "Updated contract terms will apply to all new activity for this organization. Are you sure?"}
          </Text>
        </Modal>
      )}
      <TriumphPage>
        {isTriumphEmployee() && (
          <Row justify="space-between">
            {deployContractTitle}
            <Button
              style={{ margin: "8px 8px 8px 0px" }}
              shape="round"
              type="ghost"
              className="text-disable-button"
              disabled={!hasChanged}
              onClick={() => setShowWarning(true)}
            >
              Publish
            </Button>
          </Row>
        )}

        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          columns={editablePlayerFeeCols}
          rowClassName={"text-disable-button editable-row"}
          loading={loading}
          dataSource={[...playerTargetFees]}
          rowKey={"uid"}
          expandable={{
            expandedRowClassName: () => "text-disable-button",
            expandedRowRender: (record) => {
              if (record.uid === editingKey) {
                return (
                  <Form.Item
                    name={"example"}
                    initialValue={record.example}
                    style={{ margin: 0 }}
                    rules={[
                      {
                        required: true,
                        message: `Please Input Description for Example!`,
                      },
                    ]}
                  >
                    <TextArea
                      rows={4}
                      style={{ margin: 0, width: "75%" }}
                    ></TextArea>
                  </Form.Item>
                );
              } else {
                return (
                  <Text strong={true} style={{ margin: 0, width: "75%" }}>
                    Example: {record.example}
                  </Text>
                );
              }
            },
          }}
          className="mobile-table-small pd-8"
          pagination={false}
          title={() => (
            <>
              <Title level={4}>Player Fees & Revenue</Title>
              <Text strong={true} type="secondary">
                The revenue generating charges, fees, and marketing costs
                assessed or provided to Players are managed by Triumph and are
                split in accordance with the Revenue Share Agreement
              </Text>
            </>
          )}
        />
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          columns={editableDevFeeCols}
          rowClassName={"text-disable-button editable-row"}
          loading={loading}
          dataSource={[...devTargetFees]}
          rowKey={"uid"}
          expandable={{
            expandedRowClassName: () => "text-disable-button",
            expandedRowRender: (record) => {
              if (record.uid === editingKey) {
                return (
                  <Form.Item
                    name={"example"}
                    initialValue={record.example}
                    style={{ margin: 0 }}
                    rules={[
                      {
                        required: true,
                        message: `Please Input Description for ${record.example}!`,
                      },
                    ]}
                  >
                    <TextArea
                      rows={4}
                      style={{ margin: 0, width: "75%" }}
                    ></TextArea>
                  </Form.Item>
                );
              } else {
                return (
                  <Text strong={true} style={{ margin: 0, width: "75%" }}>
                    Example: {record.example}
                  </Text>
                );
              }
            },
          }}
          className="mobile-table-small pd-8"
          pagination={false}
          title={() => (
            <>
              <Title level={4}>Developer Fees</Title>
              <Text strong={true} type="secondary">
                The below fees are not assessed against Players but rather are
                shared by the Developer and Triumph. Each fee and the amount and
                whether the Revenue Share is applied (in which case the
                cost/expense is shared by Triumph) are identified below.
              </Text>
            </>
          )}
        />
      </TriumphPage>
    </Form>
  );
};

export default Contract;
