import {
  Button,
  Col,
  DatePicker,
  Row,
  Table,
  TableColumnsType,
  TablePaginationConfig,
  Typography,
} from "antd";
import {
  endBefore,
  limit,
  limitToLast,
  onSnapshot,
  orderBy,
  query,
  QueryConstraint,
  QueryDocumentSnapshot,
  startAfter,
  where,
} from "firebase/firestore";
import { colGroupNames, getCollectionGroup } from "src/collections";
import { FC, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "src/app/hooks";
import { fixDigits, isTriumphEmployee } from "src/helpers";
import Currency from "src/shared/components/currency/Currency";
import { dateFormatter, PAGE_SIZE_OPTIONS } from "src/shared/config/constants";
import { LinkOutlined } from "@ant-design/icons";
import UserInfo from "src/components/balanceTransactions/userInfo/UserInfo";
import { Unsubscribe } from "firebase/auth";
import { RangeValue } from "rc-picker/lib/interface";
import Pagination from "src/shared/components/pagination/Pagination";
import TriumphPage from "src/shared/layout/TriumphPage";
import { useFilter } from "src/app/useFilters";
import moment, { Moment } from "moment-timezone";
import { FilterValue } from "antd/lib/table/interface";

interface TournamentTableProps
  extends Pick<
    BlitzTournamentV2,
    | "tournamentUid"
    | "gameId"
    | "status"
    | "percentile"
    | "payout"
    | "score"
    | "createdAt"
    | "finishedAt"
    | "uid"
    | "multiplier"
  > {
  entryPrice: number;
  tournamentDefinitionName: string;
  gameName: string;
}

const { Link } = Typography;

const LinkControl: FC<{
  id: string;
  usersMap?: { [x: string]: AppUserPublic };
  cb: (id: string) => void;
}> = ({ id, cb }) => {
  const navigate = useNavigate();

  return (
    <Row>
      <Col>
        <Link
          onClick={(e) => {
            if (isTriumphEmployee()) {
              e.stopPropagation();
              cb(id);
            }
          }}
          className={`mr-1 ${!isTriumphEmployee() ? "disabled-link" : ""}`}
        >
          {/* {!usersMap[id] ? <Spin /> : usersMap[id].username} */}
          {id}
        </Link>
      </Col>
      {isTriumphEmployee() && (
        <Col>
          <LinkOutlined onClick={() => navigate(`/users/${id}`)} />
        </Col>
      )}
    </Row>
  );
};

const defaultPageSize = 10;

const getInitialDateFilter = (filters: Record<string, string | null>) => {
  if (filters["date-filter-start"] && filters["date-filter-end"]) {
    return [
      moment(filters["date-filter-start"]),
      moment(filters["date-filter-end"]),
    ] as RangeValue<Moment>;
  }

  return null;
};

const BlitzTournament: FC = () => {
  const { user } = useAppSelector((state) => state.userState);
  const { listOfGames } = useAppSelector((state) => state.gameState);
  // const { usersPublic: usersMap } = useAppSelector(appUserPublicSelect);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const navigate = useNavigate();
  const [tournaments, setTournaments] = useState<{
    [x: string]: BlitzTournamentV2 & { gameName: string };
  }>();
  // const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [{ filters, pagination, sorter }, setFilters] = useFilter<
    TournamentTableProps & {
      "date-filter-start"?: number;
      "date-filter-end"?: number;
    }
  >("blitz-tournament-table-filters");
  const [dateFilter, setDateFilter] =
    useState<RangeValue<moment.Moment> | null>(
      getInitialDateFilter(filters as Record<string, string | null>)
    );
  const [tableQuery, setTableQuery] = useState<{
    [x: string]: QueryConstraint;
  }>({});

  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [lastVisible, setLastVisible] =
    useState<QueryDocumentSnapshot<BlitzTournamentV2> | null>(null);
  const [firstVisible, setFirstVisible] =
    useState<QueryDocumentSnapshot<BlitzTournamentV2> | null>(null);

  const orgId = user?.activeOrgId;

  useEffect(() => {
    if (dateFilter && Object.keys(dateFilter).length > 0) {
      computeQueries("date", dateFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    let unsubTournCol: Unsubscribe | undefined;
    if (orgId) {
      setIsLoading(true);

      const blitzTournV2ColRef = getCollectionGroup(
        colGroupNames.blitzTournamentsV2
      );

      const q = query(
        blitzTournV2ColRef,
        orderBy("createdAt", "desc"),
        where("orgId", "==", orgId),
        ...Object.values(tableQuery),
        ...(Object.keys(tableQuery).length === 0 ? [limit(pageSize)] : [])
      );
      unsubTournCol = onSnapshot(
        q,
        (colSnap) => {
          const defs: typeof tournaments = {};
          for (const tourDefSnap of colSnap.docs) {
            const tourDefId = tourDefSnap.id;
            const tourDef = tourDefSnap.data();
            defs[tourDefId] = {
              ...tourDef,
              gameName:
                listOfGames.find((f) => f.id === tourDef.gameId)?.name ||
                tourDef.gameId,
            };
          }
          setTournaments(defs);
          setLastVisible(colSnap.docs[colSnap.docs.length - 1] ?? null);
          setFirstVisible(colSnap.docs[0] ?? null);
          setIsLoading(false);
        },
        (error) => console.error(error.message)
      );
    }
    return () => {
      unsubTournCol?.();
    };
  }, [tableQuery, orgId, listOfGames, pageSize]);

  // useEffect(() => {
  //   const appUserUids = Object.values(tournaments ?? {}).map(({ uid }) => uid);
  //   const missing = appUserUids.filter((uid) => !(uid in usersMap));
  //   dispatch(getAppUserPublicArrayDetails(missing));
  // }, [dispatch, tournaments, usersMap]);

  function resetFilters() {
    setPageSize(defaultPageSize);
    setFirstVisible(null);
    setLastVisible(null);
  }

  function computeQueries(
    type: "next" | "prev" | "page-size" | "date",
    filter?: number | RangeValue<moment.Moment>
  ) {
    let q: typeof tableQuery = {};
    switch (type) {
      case "date": {
        q = {
          "date-start": where(
            "createdAt",
            ">=",
            typeof filter !== "number" && filter?.[0]?.valueOf()
          ),
          "date-end": where(
            "createdAt",
            "<=",
            typeof filter !== "number" && filter?.[1]?.valueOf()
          ),
        };
        break;
      }
      case "next": {
        q = {
          ...tableQuery,
          next: startAfter(lastVisible),
          "page-size": limit(pageSize),
        };
        break;
      }
      case "prev": {
        q = {
          ...tableQuery,
          next: endBefore(firstVisible),
          "page-size": limitToLast(pageSize + 1),
        };
        break;
      }
      case "page-size": {
        if (typeof filter === "number") {
          setPageSize(filter);
          q = { ...tableQuery, "page-size": limit(filter) };
        }
        break;
      }
    }
    setTableQuery(q);
    return q;
  }

  const paginationConfig: TablePaginationConfig = {
    pageSize: pageSize,
    onChange: (page, size) => setPageSize(size),
    pageSizeOptions: PAGE_SIZE_OPTIONS.map((e) => `${e}`),
    className: `mobile-pagination-small ${!dateFilter && "hide-pagination"}`,
  };

  const statusFilters: {
    text: string;
    value: BlitzTournamentV2["status"];
  }[] = Object.values(
    Object.values(tournaments ?? {}).reduce<{
      [type: string]: { text: string; value: BlitzTournamentV2["status"] };
    }>((aggreg, currTournament) => {
      if (currTournament.status in aggreg) {
        return aggreg;
      } else {
        aggreg[currTournament.status] = {
          text: currTournament.status,
          value: currTournament.status,
        };
        return aggreg;
      }
    }, {})
  );

  const gameIdFilters: {
    text: string;
    value: BlitzTournamentV2["gameId"];
  }[] = Object.values(
    Object.values(tournaments ?? {}).reduce<{
      [type: string]: { text: string; value: BlitzTournamentV2["gameId"] };
    }>((aggreg, currTournament) => {
      if (currTournament.gameName in aggreg) {
        return aggreg;
      } else {
        aggreg[currTournament.gameName] = {
          text: currTournament.gameName,
          value: currTournament.gameName,
        };
        return aggreg;
      }
    }, {})
  );

  const columns: TableColumnsType<TournamentTableProps> = [
    {
      title: "Game",
      dataIndex: "gameName",
      key: "gameName",
      fixed: "left",
      width: 130,
      filters: gameIdFilters,
      defaultFilteredValue: filters["gameName"],
      onFilter: (value, record) => record.gameName === value,
    },
    {
      title: "Tournament Id",
      dataIndex: "tournamentUid",
      key: "tournamentUid",
      fixed: "left",
      width: 150,
      render: (value, record) => (
        <Link
          onClick={() =>
            navigate(`/games/${record.gameId}/tournaments/blitz/${value}`)
          }
        >
          {value}
        </Link>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      fixed: "left",
      width: 100,
      defaultFilteredValue: filters["status"],
      filters: statusFilters,
      onFilter: (value, record) => record.status === value,
    },
    {
      title: "Multiplier",
      dataIndex: "multiplier",
      key: "multiplier",
      fixed: "left",
      width: 150,
      render: (value: number | null) => (value ? fixDigits(value, 4) : ""),
    },
    {
      title: "Payout",
      dataIndex: "payout",
      key: "payout",
      width: 100,
      render: (amount) => (
        <Currency value={amount} currency="cents-to-dollar" prefix="$" />
      ),
    },
    {
      title: "Entry Price",
      dataIndex: "entryPrice",
      key: "entryPrice",
      width: 100,
      render: (amount) => (
        <Currency value={amount} currency="cents-to-dollar" prefix="$" />
      ),
    },
    {
      title: "Percentile",
      dataIndex: "percentile",
      key: "percentile",
      width: 100,
      render: (amount) => ((amount || 0) * 100).toFixed(2),
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
      width: 100,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      width: 150,
      key: "createdAt",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.createdAt - b.createdAt,
      render: (a) => dateFormatter(a),
    },

    {
      title: "Finished At",
      dataIndex: "finishedAt",
      width: 150,
      sorter: (a, b) => (a.finishedAt || 0) - (b.finishedAt || 0),
      render: (a) => dateFormatter(a),
      key: "finishedAt",
    },
    {
      title: "Player Username",
      dataIndex: "uid",
      width: 150,
      key: "uid",
      render: (id) =>
        (id && (
          <LinkControl
            id={id}
            //  usersMap={usersMap}
            cb={setSelectedUser}
          />
        )) ||
        "-",
    },
  ];

  const dataSource = useMemo(() => {
    return Object.entries(tournaments ?? {}).map(
      ([id, tournament]): TournamentTableProps => ({
        gameId: tournament.gameId,
        gameName: tournament.gameName,
        status: tournament.status,
        tournamentDefinitionName: tournament.tournamentDefinition.name,
        entryPrice: tournament.tournamentDefinition.entryPrice,
        payout: tournament.payout,
        createdAt: tournament.createdAt,
        finishedAt: tournament.finishedAt,
        uid: tournament.uid,
        tournamentUid: id,
        score: tournament.score,
        percentile: tournament.percentile,
        multiplier: tournament.multiplier,
      })
    );
  }, [tournaments]);

  return (
    <TriumphPage>
      <UserInfo user={selectedUser} onClose={() => setSelectedUser(null)} />
      <div className="range-picker-wrap">
        {dateFilter && (
          <Button
            type="dashed"
            onClick={() => {
              resetFilters();
              setDateFilter(null);
              setTableQuery({});
              setFilters({
                pagination,
                sorter,
                filters: {
                  ...filters,
                  "date-filter-start": null,
                  "date-filter-end": null,
                },
              });
            }}
          >
            Reset to first {defaultPageSize} records
          </Button>
        )}
        <DatePicker.RangePicker
          value={dateFilter}
          onChange={(e) => {
            resetFilters();
            setDateFilter(e);
            computeQueries("date", e);
            setFilters({
              pagination,
              sorter,
              filters: {
                ...filters,
                "date-filter-start": e?.[0]
                  ?.toDate()
                  .toString() as unknown as FilterValue,
                "date-filter-end": e?.[1]
                  ?.toDate()
                  .toString() as unknown as FilterValue,
              },
            });
          }}
        />
      </div>
      <Table
        columns={columns}
        loading={!tournaments || isLoading}
        rowKey="tournamentUid"
        dataSource={dataSource}
        pagination={paginationConfig}
        scroll={{ x: 1300 }}
        className="mobile-table-medium"
        onChange={(pagination, filters, sorter) => {
          setFilters({ pagination, filters, sorter });
        }}
      />
      {!dateFilter && (
        <Pagination
          pageSize={pageSize}
          onNext={() => {
            computeQueries("next");
          }}
          onPrevious={() => {
            computeQueries("prev");
          }}
          onPageSize={(size: number) => {
            setPageSize(size);
            computeQueries("page-size", size);
          }}
        />
      )}
    </TriumphPage>
  );
};

export default BlitzTournament;
