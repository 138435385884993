import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Tag,
  message,
  Select,
} from "antd";
import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { axiosInstance } from "src/helpers";
import { formSubmit } from "src/shared/config/constants";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { EyeOutlined } from "@ant-design/icons";
import OfferPreview from "../Offers/components/OfferPreview";

type Props = {
  offerslist: Array<ConfigureOffer>;
};
const PushNotificationForm: FC<Props> = ({ offerslist }) => {
  const [open, setOpen] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState<ConfigureOffer | null>(
    null
  );
  const { game } = useParams();
  const [error, setError] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm<PushNotification>();
  const handleSubmit = formSubmit<PushNotification>(form);
  async function onSave(data: PushNotification) {
    try {
      setError("");
      setIsLoading(true);
      const { title, subtitle, body, offer } = data;
      await axiosInstance.post(`/admin/games/${game}/push_notification/send`, {
        title,
        body,
        subtitle,
        offer: offer ?? "none",
      });

      message.success("Notification sent successfully");
      onDialogClose();
    } catch (e) {
      message.error("Notification not sent");

      setError("Unknown server error");
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }

  function onChange(name: keyof PushNotification, value: string | null) {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      [name]: value,
    });
  }

  function onDialogClose() {
    setIsLoading(false);
    form.resetFields();
    setOpen(false);
    setError("");
  }

  return (
    <>
      <OfferPreview
        data={selectedOffer}
        onClose={() => setSelectedOffer(null)}
      />

      <Button
        onClick={() => {
          form.resetFields();
          setOpen(true);
        }}
        type="primary"
        className="mb-2"
      >
        Send Notification
      </Button>

      <Modal
        title="Send Notification"
        centered
        open={open}
        okText="Send"
        onOk={() =>
          handleSubmit((data) =>
            Modal.confirm({
              title: `Send push notification to all players?`,
              icon: <ExclamationCircleOutlined />,
              content: `By clicking confirm, the "${data.title}" push notification will be sent. 
                It may take up to an hour before users begin receiving your notification. 
                This action cannot be undone.`,
              okText: "Confirm",
              cancelText: "Back",
              onOk: () => onSave(data),
              okCancel: true,
            })
          )
        }
        onCancel={onDialogClose}
        destroyOnClose
        confirmLoading={isLoading}
      >
        {open && (
          <Form
            name="Push Notification Form"
            className="spaced-form"
            form={form}
            autoComplete="off"
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Notification Title*"
                  className="app-config-input-wrap"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Notification title is required",
                    },
                  ]}
                >
                  <Input
                    name="title"
                    size="middle"
                    onChange={(e) => onChange("title", e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Notification Subtitle*"
                  className="app-config-input-wrap"
                  name="subtitle"
                  rules={[
                    {
                      required: true,
                      message: "Notification subtitle is required",
                    },
                  ]}
                >
                  <Input
                    name="subtitle"
                    size="middle"
                    onChange={(e) => onChange("subtitle", e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Notification Body*"
                  className="app-config-input-wrap"
                  name="body"
                  rules={[
                    {
                      required: true,
                      message: "Notification body is required",
                    },
                  ]}
                >
                  <Input.TextArea
                    name="body"
                    rows={4}
                    size="middle"
                    onChange={(e) => onChange("body", e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="offer"
                  label="Offer*"
                  className="app-config-input-wrap"
                >
                  <Select
                    className="normal-select"
                    size="middle"
                    onChange={(value) => onChange("offer", value)}
                  >
                    {offerslist.map((e) => (
                      <Select.Option value={e.uid}>{e.tag}</Select.Option>
                    ))}
                  </Select>
                  <Button
                    type="link"
                    onClick={() => {
                      const value = form.getFieldValue("offer");
                      if (value) {
                        setSelectedOffer(
                          offerslist.find((f) => f.uid === value) || null
                        );
                      }
                    }}
                    className="view-offer pl-0"
                  >
                    View Offer <EyeOutlined />
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            {error && <Tag color="warning">{error}</Tag>}
          </Form>
        )}
      </Modal>
    </>
  );
};

export default PushNotificationForm;
