import { InputNumber, InputNumberProps } from "antd";
import { FC } from "react";

interface Props extends InputNumberProps {}
const DollarInput: FC<Props> = (props) => {
  return (
    <InputNumber
      type="number"
      prefix="$"
      addonAfter="USD"
      {...props}
      formatter={(value, info) => {
        if (info.userTyping) return `${value}`;
        if (value) {
          return Number(value).toFixed(2);
        }
        return "";
      }}
    />
  );
};

export default DollarInput;
