import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import appUserReducer from "src/features/appUsers/AppUserSlice";
import appUserPublicReducer from "src/features/appUsersPublic/appUserPublicSlice";
import userReducer from "src/features/authentication/userSlice";
import adminUserReducer from "src/features/adminUsers/AdminUsersSlice";
import statisticsSlice from "src/features/statistics/statisticsSlice";
import statRangeSlice from "src/features/statistics/statRangeSlice";
import gamesReducer from "src/features/games/gamesSlice";
import orgReducer from "src/features/orgs/orgSlice";

export const store = configureStore({
  reducer: {
    statsState: statisticsSlice,
    statRangeState: statRangeSlice,
    userState: userReducer,
    appUserState: appUserReducer,
    appUserPublicState: appUserPublicReducer,
    gameState: gamesReducer,
    orgState: orgReducer,
    adminUserState: adminUserReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
