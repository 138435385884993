import { Form, InputNumber, Modal, Select, Tag } from "antd";
import { FC, useState } from "react";
import { useAppSelector } from "src/app/hooks";
import { selectUser } from "src/features/authentication/userSlice";
import { orgSelect } from "src/features/orgs/orgSlice";
import { axiosInstance } from "src/helpers";
import { formSubmit, validateNumericValue } from "src/shared/config/constants";

interface Props {
  open: boolean;
  onClose: () => void;
  user: AppUser & AppUserPublic;
}

const AddBalanceAmount: FC<Props> = ({ onClose: onModalClose, open, user }) => {
  const [form] = Form.useForm<{
    amount: TriumphDepositTrx["amount"] | string | null;
    description: TriumphDepositTrx["description"] | string | null;
    gameId: string
  }>();

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { user: loggedInUser } = useAppSelector(selectUser);
  const { orgs } = useAppSelector(orgSelect);
  const { listOfAllGames } = useAppSelector((state) => state.gameState);
  const handleSubmit = formSubmit(form)


  async function onFinish() {
    setError("");
    if (
      !user ||
      !loggedInUser ||
      !loggedInUser.activeOrgId ||
      !orgs[loggedInUser.activeOrgId] ||
      !orgs[loggedInUser.activeOrgId].activeContractId
    ) {
      setError("Refresh or retry, user information is missing");

      return;
    }



    const values = form.getFieldsValue();


    setIsLoading(true);

    const amount = Math.trunc(Number(values.amount) * 100);

    try {
      await axiosInstance.post("internal/users/update_balance", {
        appUserUid: user.uid,
        amount,
        bonusCashAmount: 0,
        description: values.description,
        gemAmount: 0,
        gameId: values.gameId,
      });
      onClose();
    } catch (error) {
      const _e = error as Error;
      setError(_e.message || "Unknown Error");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  function onClose() {
    form.resetFields();
    setError("");

    onModalClose();
  }


  return (
    <>
      <Modal
        title="Add to User Balance"
        centered
        open={open}
        okText="Add"
        confirmLoading={isLoading}
        onOk={() => handleSubmit(onFinish)}
        onCancel={onClose}
      >
        <Form
          name="balance transaction"
          form={form}
          className="app-config-form"
          initialValues={{ amount: 0 }}
          autoComplete="off"
        >
          <Form.Item
            label="User Balance"
            name="amount"
            className="app-config-input-wrap"
            rules={[
              {
                required: true,
                message: "Balance value is required",
                validator: validateNumericValue,
              },
            ]}
          >
            <InputNumber
              name="amount"
              size="middle"
              type="number"
              prefix="$"
              addonAfter="USD"
              onChange={(value) => {
                form.setFieldsValue({
                  ...form.getFieldsValue(),
                  amount: value,
                });
              }}
            />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            className="app-config-input-wrap bordered-select"
            rules={[
              {
                required: true,
                message: "Description is required",
              },
            ]}
          >
            <Select
              size="large"
              onChange={(value) => {
                form.setFieldsValue({
                  ...form.getFieldsValue(),
                  description: value,
                });
              }}
            >
              <Select.Option value="SDK-Async-1v1-crash">
                SDK-Async-1v1-crash
              </Select.Option>
              <Select.Option value="SDK-Async-group-crash">
                SDK-Async-group-crash
              </Select.Option>
              <Select.Option value="DK-Blitz-crash">
                SDK-Blitz-crash
              </Select.Option>
              <Select.Option value="Game-crash">Game-crash</Select.Option>
              <Select.Option value="Marketing-general">
                Marketing-general
              </Select.Option>
              <Select.Option value="Marketing-creator">
                Marketing-creator
              </Select.Option>
              <Select.Option value="Marketing-research">
                Marketing-research
              </Select.Option>
              <Select.Option value="Marketing-other">
                Marketing-other
              </Select.Option>
              <Select.Option value="Testing">Testing</Select.Option>
              <Select.Option value="Other"> Other </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Game"
            name="gameId"
            className="app-config-input-wrap bordered-select"
            rules={[
              {
                required: true,
                message: "Game is required",
              },
            ]}
          >
            <Select
              size="large"
              onChange={(value) => {
                form.setFieldsValue({
                  ...form.getFieldsValue(),
                  gameId: value,
                });
              }}
            >
              {listOfAllGames.filter(f => f.goLiveStatus === "live").map(e => <Select.Option value={e.id} >
                {e.name}</Select.Option>)}
            </Select>
          </Form.Item>

          {error && <Tag color="red">{error}</Tag>}
        </Form>
      </Modal>
    </>
  );
};

export default AddBalanceAmount;
