import { Card, Tag } from "antd";
import { Unsubscribe } from "firebase/auth";
import { doc, collection, onSnapshot } from "firebase/firestore";
import React from "react";
import { FC } from "react";
import { useAppSelector } from "src/app/hooks";
import { orgConverter } from "src/converters";
import { db } from "src/helpers";
import TriumphPage from "src/shared/layout/TriumphPage";

const PlatformAgreement: FC = () => {
  const [activeOrg, setActiveOrg] = React.useState<null | Organization>(null);
  const { user } = useAppSelector((state) => state.userState);

  React.useEffect(() => {
    let unsubOrg: Unsubscribe | undefined;
    if (user) {
      const orgId = user.activeOrgId;
      if (orgId) {
        const orgRef = doc(
          collection(db, "organizations"),
          orgId
        ).withConverter(orgConverter);

        unsubOrg = onSnapshot(orgRef, (docSnap) => {
          const org = docSnap.data();
          if (org) {
            setActiveOrg(org);
          }
        });
      }
    }

    return () => {
      unsubOrg?.();
    };
  }, [user]);
  return (
    <TriumphPage>
      <Card>
        Platform Agreement Status:{" "}
        <Tag
          color={
            activeOrg?.platformAgreement === "signed" ? "success" : "error"
          }
        >
          {activeOrg?.platformAgreement ?? "unsigned"}
        </Tag>
        {activeOrg?.platformAgreement !== "signed" && (
          <a
            target="_blank"
            rel="noreferrer"
            href="https://sign.zoho.com/signform?form_link=234b4d535f495623c3ebc15c0a3d4d78307a841557c11d022eb3e2740bbc0ad492e4d3585f95ab5c2b52b8206250c390b4346bc81726e2046d4c0a8931a903f87f66b51185650532#"
          >
            Sign here
          </a>
        )}
      </Card>
    </TriumphPage>
  );
};

export default PlatformAgreement;
