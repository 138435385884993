import { Button, Row, Tag } from "antd";
import { FC, useRef, useState } from "react";
import { useAppSelector } from "src/app/hooks";
import { axiosInstance } from "src/helpers";

interface Props {
  onNext: () => void;
}

const DownloadCsr: FC<Props> = ({ onNext }) => {
  const { user } = useAppSelector((state) => state.userState);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const ref = useRef<HTMLAnchorElement>(null);

  async function getCertificate() {
    try {
      let data = await axiosInstance.get<{ csr: string }>(
        `/admin/orgs/${user?.activeOrgId}/apple_pay_csr`
      );
      if (data.data.csr === null) {
        data = await axiosInstance.post<{ csr: string }>(
          `/admin/orgs/${user?.activeOrgId}/apple_pay_csr`
        );
      }

      ref.current?.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(data.data.csr)
      );
      ref.current?.click();
    } catch (error) {
      setError(error instanceof Error ? error.message : "Unknown Error");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Row justify="center">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className="d-none"
          ref={ref}
          download="triumph-certificate-signing-request.csr"
        >
          certificate signing request
        </a>

        <Button loading={isLoading} type="ghost" onClick={getCertificate}>
          Download certificate signing request
        </Button>
      </Row>
      <Row justify="center">and go to apple pay and upload it.</Row>
      {error && (
        <Row>
          <Tag color="orange">{error}</Tag>
        </Row>
      )}
      <Row justify="end">
        <Button onClick={onNext} type="primary">
          Next
        </Button>
      </Row>
    </>
  );
};

export default DownloadCsr;
