import { FC, useMemo, useState } from "react";
import {
  Collapse,
  Divider,
  Row,
  Tag,
  Descriptions,
  Typography,
  Skeleton,
  Table,
  TableColumnsType,
  Button,
} from "antd";
import {
  CaretRightOutlined,
  LoadingOutlined,
  BulbOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { dateFormatter } from "src/shared/config/constants";
import Currency from "src/shared/components/currency/Currency";
import { useMediaQuery } from "react-responsive";
import TriumphPage from "src/shared/layout/TriumphPage";
import { Link, useNavigate } from "react-router-dom";
import ReCreditForm from "./ReCreditForm";
import { isTriumphEmployee } from "src/helpers";
import ReplayVideo from "../ReplayVideo";

const { Title } = Typography;
const { Panel } = Collapse;

type Props = {
  tournament?: AsyncGroupTournamentV1;
  selectedUser?: { [x: string]: AppUserPublic & AsyncGroupPlayer };
  userTransactions: {
    [x: string]: BalanceTransaction[];
  };
};
const TournamentGroupDetailsContainer: FC<Props> = ({
  tournament,
  selectedUser,
  userTransactions,
}) => {
  const navigate = useNavigate();
  const [selectedAppUserUid, setSelectedAppUserId] = useState<string | null>(
    null
  );

  const statusTag = useMemo(() => {
    if (!tournament) return;
    const { status } = tournament;
    switch (status) {
      case "finished":
        return {
          color: "green",
          tag: "Finished",
          icon: <BulbOutlined />,
          hideResult: false,
        };
      case "in-progress":
        return {
          color: "processing",
          tag: "In Progress",
          icon: <LoadingOutlined />,
          hideResult: true,
        };
      case "waiting-to-match":
        return {
          color: "warning",
          tag: "Waiting to Match",
          icon: <ClockCircleOutlined />,
          hideResult: true,
        };
      default:
        return { color: "purple", tag: status };
    }
  }, [tournament]);

  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  if (!tournament || !selectedUser) return <Skeleton title paragraph />;

  const columns: TableColumnsType<AppUserPublic & AsyncGroupPlayer> = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      width: 130,
      render: (data, record) => (
        <Link className="ant-typography" to={`/users/${record.uid}`}>
          {data}
        </Link>
      ),
    },
    {
      title: "Matched At",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 130,
      render: (id, record) => dateFormatter(record.createdAt),
    },
    {
      title: "Score",
      dataIndex: "finalScore.value",
      key: "finalScore.value",
      width: 130,
      render: (id, record) =>
        record.finalScore ? record.finalScore.value : "-",
    },
    {
      title: "Score Submitted At",
      dataIndex: "finalScore.createdAt",
      key: "finalScore.createdAt",
      width: 130,
      render: (id, record) =>
        record.finalScore ? dateFormatter(record.finalScore.createdAt) : "-",
    },
    {
      title: "Replay",
      dataIndex: "uid",
      key: "replay",
      width: 130,
      render: (id, record) => (
        <ReplayVideo
          replay={tournament.replays[record.uid]}
          username={record.username}
        />
      ),
    },
    {
      title: "Place",
      dataIndex: "place",
      key: "place",
      width: 130,
      defaultSortOrder: "ascend",
      sorter: (a, b) =>
        (tournament?.results?.[a.uid]?.place || 0) -
        (tournament?.results?.[b.uid]?.place || 0),
      render: (id, record) => tournament?.results?.[record.uid]?.place || "-",
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      width: 130,
      render: (id, record) => {
        if (tournament.status === "finished") {
          const isWinner = Boolean(tournament.results?.[record.uid]?.won);
          return (
            <Tag color={isWinner ? "green" : "red"}>
              {isWinner ? "Winner" : "Loser"}
            </Tag>
          );
        }
        return "-";
      },
    },
    {
      title: "Payout",
      dataIndex: "Payout",
      key: "Payout",
      width: 130,
      render: (id, record) => (
        <Currency
          value={tournament?.results?.[record.uid]?.payout}
          currency="cents-to-dollar"
          prefix="$"
        />
      ),
    },
    {
      title: "Payout BonusCash",
      dataIndex: "payoutBonusCash",
      key: "payoutBonusCash",
      width: 130,
      render: (id, record) =>
        tournament?.results?.[record.uid]?.payoutBonusCash || "-",
    },
  ];

  if (isTriumphEmployee()) {
    columns.push({
      title: "Action",
      dataIndex: "uid",
      key: "uid",
      width: 130,
      fixed: "right",
      render: (id: string) => (
        <Button
          disabled={
            !!userTransactions[id]?.find(
              (f) => f.type === "reverse-start-group-tournament"
            )
          }
          onClick={() => setSelectedAppUserId(id)}
        >
          Re Credit
        </Button>
      ),
    });
  }

  return (
    <TriumphPage>
      <ReCreditForm
        selectedAppUserUid={selectedAppUserUid}
        onCancel={() => setSelectedAppUserId(null)}
        tournamentId={tournament.uid}
      />
      <Button
        type="primary"
        onClick={() => navigate("/tournaments/async-group")}
      >
        Return to Tournaments
      </Button>
      <Row justify="center" className="pb-2">
        <Tag
          className="tournament-status-tag"
          icon={statusTag?.icon}
          color={statusTag?.color}
        >
          {statusTag?.tag}
        </Tag>
      </Row>
      <Row>
        <Title level={4}>Players</Title>
      </Row>
      <Table
        columns={columns}
        loading={!selectedUser}
        rowKey="id"
        dataSource={Object.entries(selectedUser || {}).map(([id, user]) => ({
          id,
          ...user,
        }))}
        scroll={{ x: 1300 }}
        className="mobile-table-large"
      />

      <Divider />
      <Descriptions
        title="Tournament Details"
        layout={!isMobile ? "horizontal" : "vertical"}
        colon
        column={1}
        bordered
        className="pb-2"
      >
        <Descriptions.Item label="RNG">{tournament.RNG}</Descriptions.Item>
        <Descriptions.Item label="Status">
          <Tag color="geekblue">{tournament.status}</Tag>
        </Descriptions.Item>

        <Descriptions.Item label="Participants Max">
          {tournament.participantsMax}
        </Descriptions.Item>
        <Descriptions.Item label="Participants Size">
          {tournament.participantsSize}
        </Descriptions.Item>
        <Descriptions.Item label="Created At">
          {dateFormatter(tournament.createdAt)}
        </Descriptions.Item>
        <Descriptions.Item label="Last Matched At">
          {dateFormatter(tournament.lastMatchedAt)}
        </Descriptions.Item>
        <Descriptions.Item label="Finished At">
          {dateFormatter(tournament.finishedAt)}
        </Descriptions.Item>
        <Descriptions.Item label="Version">
          {tournament.version}
        </Descriptions.Item>
      </Descriptions>
      <Divider />
      <Collapse
        bordered={false}
        defaultActiveKey={["1"]}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        className="site-collapse-Descriptions.-collapse"
      >
        <Panel
          header={
            <Descriptions>
              <Descriptions.Item label="Config ID">
                {tournament.configId}
              </Descriptions.Item>
            </Descriptions>
          }
          key="1"
          className="site-collapse-Descriptions.-panel"
        >
          <Descriptions
            layout={!isMobile ? "horizontal" : "vertical"}
            colon
            column={1}
            bordered
          >
            <Descriptions.Item label="isArchived">
              <Tag color="cyan">
                {tournament.config.archived ? "Yes" : "No"}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Entry Price">
              <Currency
                currency="cents-to-dollar"
                prefix="$"
                value={-tournament.config.entryPrice}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Name">
              {tournament.config.name}
            </Descriptions.Item>
            <Descriptions.Item label="Size">
              {tournament.config.size}
            </Descriptions.Item>
          </Descriptions>
        </Panel>
      </Collapse>
      <Divider />
    </TriumphPage>
  );
};

export default TournamentGroupDetailsContainer;
