import { Col, Form, Modal, Row, Select, Tag } from "antd";
import { FC, useState } from "react";
import { axiosInstance } from "src/helpers";

interface Props {
  selectedAppUserUid: string | null;
  tournamentId: string;
  onCancel: () => void;
}

const ReCreditForm: FC<Props> = ({
  selectedAppUserUid,
  tournamentId,
  onCancel,
}) => {
  const [reason, setReason] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [form] = Form.useForm();

  function onCloseModal() {
    setIsLoading(false);
    setReason(null);
    form.resetFields();
    setError("");

    onCancel();
  }

  async function onRecredit() {
    form.submit();
    if (!selectedAppUserUid || !reason) {
      setError("Please enter reason to re credit");
      return;
    }
    setError("");
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(
        `/internal/tournaments/async_group/${tournamentId}/reverse_start_transaction`,
        {
          reason,
          appUserUid: selectedAppUserUid,
        }
      );

      if (response.status === 200 && response.data) {
        onCloseModal();
      } else {
        setError("An error occured");
      }
    } catch (error) {
      setError(
        error instanceof Error
          ? error.message
          : (typeof error === "string" && error) || "Unknown Error"
      );
    } finally {
      setIsLoading(false);
    }
  }
  return (
    <Modal
      title="Re credit the user"
      centered
      open={!!selectedAppUserUid}
      okText="Re Credit"
      confirmLoading={isLoading}
      onOk={onRecredit}
      onCancel={onCloseModal}
    >
      <Row className="pb-2" justify="center">
        <Col span={24}>
          <Form
            className="spaced-form"
            name="app-config"
            form={form}
            colon={false}
            autoComplete="off"
          >
            <Form.Item
              className="app-config-input-wrap"
              name="reason"
              label="Reason"
              rules={[
                {
                  required: true,
                  message: "Reason is required",
                },
              ]}
            >
              <Select
                className="large-select-center-aligned"
                placeholder="Select Reason"
                onChange={setReason}
              >
                <Select.Option value="sdk-failure">SDK Failure</Select.Option>
                <Select.Option value="game-failure">Game Failure</Select.Option>
              </Select>
            </Form.Item>
          </Form>
        </Col>
        {error && (
          <Col span={24}>
            <Tag color="orange">{error}</Tag>
          </Col>
        )}
      </Row>
    </Modal>
  );
};

export default ReCreditForm;
